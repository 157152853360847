import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';

import ReactDOM from "react-dom"; import { ArrowBackIos, ArrowForward, ArrowForwardIos, Close, Print } from '@material-ui/icons';
import BiltiPrint from './BiltiPrint';
import Invoice from './Invoice';
import { useReactToPrint } from 'react-to-print';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { primary } from '../styles/colors';
const styles = {
    heading: {
        margin: "0",
        fontSize: "1rem",
        width: "calc(100% - 173.94px)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "left", fontWeight: "bold"
    },
}
function FilePrevie(props) {

    const pdfExportComponent = useRef(null);
    const contentArea = useRef(null);

    const contentAreaBilti = useRef(null);
    const [pageNoToShow, setpageNoToShow] = useState(0)

    const handleExportWithFunctionPrint = useReactToPrint({
        content: () => contentArea.current,
    });
    const handleExportWithFunctionPrintBilti = useReactToPrint({
        content: () => contentAreaBilti.current,
    });
    const { openInvoice, openBilti, title } = props
    return (<>
        <div style={{ height: "0", width: "0", overflow: "hidden" }}>
            <div ref={contentArea} style={{ width: "100%", height: "100%" }}>
                <Invoice
                    {...props}
                    singleDate={openBilti.transit_pass ? openBilti.transit_pass.DateOfDispach_TP : new Date()}
                    singleTrip={openBilti ?
                        {
                            ownerName: "Vaibhav Agrawal",
                            DName: openBilti.driver_name,
                            DlNo: openBilti.driver_number, consignee: openBilti.consignee,
                            consignor: openBilti.consignor,
                            weight: `${openBilti.net_weight} mt`,
                            quantity: "2H.93MT",
                            descriptiomOfGood: openBilti.item_grade.name,
                            from: openBilti.source.city,
                            to: "Raipur",
                            lr:
                                openBilti.lr_number,
                            VehicleINo: openBilti.vehicle.vehicle_no,
                            party: openBilti.consignee.name,
                            NetMt: `${openBilti.net_weight} mt`,
                            Group: openBilti.transporter_group.name,
                            tP: "approved",
                            Invoice: "UPLOAD",
                            bilti: "print",
                            status: openBilti.dispatch_status === "DISPATCHED" ? "READY" : openBilti.dispatch_status === "REACHED" ? "COMPLETE" : openBilti.dispatch_status
                            ,
                        } : {}} />
            </div></div>
        <div style={{ height: "0", width: "0", overflow: "hidden" }}>
            <div ref={contentAreaBilti} style={{ width: "100%", height: "100%" }}>
                <BiltiPrint  {...props}
                    singleDate={openBilti.transit_pass ? openBilti.transit_pass.DateOfDispach_TP : new Date()}
                    singleTrip={openBilti ?
                        {
                            ownerName: "Vaibhav Agrawal",
                            DName: openBilti.driver_name,
                            DlNo: openBilti.driver_number, consignee: openBilti.consignee,
                            consignor: openBilti.consignor,
                            weight: `${openBilti.net_weight} mt`,
                            quantity: "2H.93MT",
                            descriptiomOfGood: openBilti.item_grade.name,
                            from: openBilti.source.city,
                            to: "Raipur",
                            lr:
                                openBilti.lr_number,
                            VehicleINo: openBilti.vehicle.vehicle_no,
                            party: openBilti.consignee.name,
                            NetMt: `${openBilti.net_weight} mt`,
                            Group: openBilti.transporter_group.name,
                            tP: "approved",
                            Invoice: "UPLOAD",
                            bilti: "print",
                            status: openBilti.dispatch_status === "DISPATCHED" ? "READY" : openBilti.dispatch_status === "REACHED" ? "COMPLETE" : openBilti.dispatch_status
                            ,
                        } : {}} />
            </div></div>
        <Dialog
            ref={(e) => {
                try {
                    const target = ReactDOM.findDOMNode(e).children[2].children[0];
                    target.style.borderRadius = "0";
                    target.style.width = "80%";
                    target.style.maxWidth = "100%";
                    target.style.height = "calc(100vh - 40px)";
                    target.style.minHeight = "calc(100vh - 40px)";

                    target.style.maxHeight = "calc(100vh - 40px)";
                    target.style.margin = "20px 0";
                    target.style.position = "relative";
                    target.style.overflow = "hidden";
                } catch (e) {
                    console.log(e);
                }
            }}
            open={true}
            onClose={() => { props.handleClose() }}
            aria-labelledby="alert-dialog-title"

        >  <DialogActions
            style={{
                display: "flex",
                margin: "15px 24px",
                padding: "0",
            }}
        >
                <span style={styles.heading}>{title ? title : openInvoice ? "Invoice" : "Bilti"}</span>
                {openBilti ? <>
                    <div style={{ width: "", display: "flex" }}>
                        <IconButton
                            style={{
                                backgroundColor: primary,
                                borderRadius: "11px",
                                padding: "6px",
                                color: "white",
                                opacity: pageNoToShow === 0 ? "0.26" : "",
                            }}
                            onClick={() => {
                                if (pageNoToShow !== 0) {
                                    setpageNoToShow(0)
                                }
                            }}
                        >
                            <ArrowBackIos />
                        </IconButton>

                        <IconButton
                            style={{
                                backgroundColor: primary,
                                borderRadius: "11px",
                                padding: "6px",
                                color: "white",
                                marginLeft: "10px",
                                opacity:
                                    pageNoToShow === 1
                                        ? "0.26"
                                        : "",
                            }}
                            onClick={() => {
                                if (pageNoToShow !== 1) {
                                    setpageNoToShow(1)
                                }
                            }}
                        >
                            <ArrowForwardIos />
                        </IconButton></div>
                </> : ""}
                <Tooltip title="print" arrow placement="top">
                    <IconButton
                        onClick={() => {
                            if (openBilti) { handleExportWithFunctionPrintBilti() } else { handleExportWithFunctionPrint() }
                        }}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                        }}
                    >
                        <Print />{" "}
                    </IconButton>
                </Tooltip>
                <Tooltip title="close" arrow placement="top">
                    <IconButton

                        onClick={() => { props.handleClose() }}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                        }}
                    >
                        <Close />{" "}
                    </IconButton>
                </Tooltip>
                {/* )} */}
            </DialogActions>
            <div style={{ height: "calc(100% - 63px)", width: "100%", overflowY: "hidden" }}> <div
                className="d-flex width-100 d-flex-column"
                style={{
                    transform: openBilti ? "scale(1, 0.5)" : "scale(1, 0.6)", margin: openBilti ? "-250px 0 0 0" : "-200px 0 0 0",
                    padding: "15px",
                }}

            >{console.log(pageNoToShow, "pageNoToShowe")}
                {openBilti ? <>
                    {
                        pageNoToShow === 0 ?
                            <BiltiPrint
                                pageNoToShow={0}
                                fromPreview={true}
                                {...props}
                                singleDate={openBilti.transit_pass ? openBilti.transit_pass.DateOfDispach_TP : new Date()}
                                singleTrip={openBilti ?
                                    {
                                        ownerName: "Vaibhav Agrawal",
                                        DName: openBilti.driver_name,
                                        DlNo: openBilti.driver_number, consignee: openBilti.consignee,
                                        consignor: openBilti.consignor,
                                        weight: `${openBilti.net_weight} mt`,
                                        quantity: "2H.93MT",
                                        descriptiomOfGood: openBilti.item_grade.name,
                                        from: openBilti.source.city,
                                        to: "Raipur",
                                        lr:
                                            openBilti.lr_number,
                                        VehicleINo: openBilti.vehicle.vehicle_no,
                                        party: openBilti.consignee.name,
                                        NetMt: `${openBilti.net_weight} mt`,
                                        Group: openBilti.transporter_group.name,
                                        tP: "approved",
                                        Invoice: "UPLOAD",
                                        bilti: "print",
                                        status: openBilti.dispatch_status === "DISPATCHED" ? "READY" : openBilti.dispatch_status === "REACHED" ? "COMPLETE" : openBilti.dispatch_status
                                        ,
                                    } : {}}
                            /> : ""}
                    {

                        pageNoToShow === 1 ?
                            <BiltiPrint
                                pageNoToShow={1}
                                fromPreview={true}
                                {...props}
                                singleDate={openBilti.transit_pass ? openBilti.transit_pass.DateOfDispach_TP : new Date()}
                                singleTrip={openBilti ?
                                    {
                                        ownerName: "Vaibhav Agrawal",
                                        DName: openBilti.driver_name,
                                        DlNo: openBilti.driver_number, consignee: openBilti.consignee,
                                        consignor: openBilti.consignor,
                                        weight: `${openBilti.net_weight} mt`,
                                        quantity: "2H.93MT",
                                        descriptiomOfGood: openBilti.item_grade.name,
                                        from: openBilti.source.city,
                                        to: "Raipur",
                                        lr:
                                            openBilti.lr_number,
                                        VehicleINo: openBilti.vehicle.vehicle_no,
                                        party: openBilti.consignee.name,
                                        NetMt: `${openBilti.net_weight} mt`,
                                        Group: openBilti.transporter_group.name,
                                        tP: "approved",
                                        Invoice: "UPLOAD",
                                        bilti: "print",
                                        status: openBilti.dispatch_status === "DISPATCHED" ? "READY" : openBilti.dispatch_status === "REACHED" ? "COMPLETE" : openBilti.dispatch_status
                                        ,
                                    } : {}}
                            />
                            : ""

                    }</> :
                    <Invoice fromPreview={true}
                        {...props}
                        singleDate={openBilti.transit_pass ? openBilti.transit_pass.DateOfDispach_TP : new Date()}
                        singleTrip={openBilti ?
                            {
                                ownerName: "Vaibhav Agrawal",
                                DName: openBilti.driver_name,
                                DlNo: openBilti.driver_number, consignee: openBilti.consignee,
                                consignor: openBilti.consignor,
                                weight: `${openBilti.net_weight} mt`,
                                quantity: "2H.93MT",
                                descriptiomOfGood: openBilti.item_grade.name,
                                from: openBilti.source.city,
                                to: "Raipur",
                                lr:
                                    openBilti.lr_number,
                                VehicleINo: openBilti.vehicle.vehicle_no,
                                party: openBilti.consignee.name,
                                NetMt: `${openBilti.net_weight} mt`,
                                Group: openBilti.transporter_group.name,
                                tP: "approved",
                                Invoice: "UPLOAD",
                                bilti: "print",
                                status: openBilti.dispatch_status === "DISPATCHED" ? "READY" : openBilti.dispatch_status === "REACHED" ? "COMPLETE" : openBilti.dispatch_status
                                ,
                            } : {}} />}  </div></div>

        </Dialog >
    </>
    );

}



export default FilePrevie;