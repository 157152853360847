import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

import Drivers from "../components/Drivers";
import { fetchDriversDetail } from "../redux/index";
class DriversContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ drivers: next.drivers });
  }

  fetchUsers = () => {
    if (this.props.user.user.uid) {
      this.props.fetchDriversDetail(this.props.user.user.uid).then((res) => {
        if (res) {
          console.log("this.props.user.uidaaaaaaaaaaaaaaaa", res);
          this.setState({ drivers: res });
        }
      });
    }
  };
  render() {
    return (
      <Route
        exact
        path={[
          "/driversDetails",
          "/driversDetails/:tab/:id",
          "/driversDetails/new",
        ]}
        render={(props) => <Drivers {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  drivers: state.masters.drivers,
});
const mapDispatchToProps = {
  fetchDriversDetail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriversContainer);
