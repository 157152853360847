import React, { Component } from "react";

import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import moment from "moment";
import { Paper, Divider, Backdrop } from "@material-ui/core";
import { darkestGray, primary, white } from "../styles/colors";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { withStyles } from "@material-ui/styles";
import Moment from "moment";
import domtoimage from "dom-to-image";
import { BottomArrow, Reload, TopArrow } from "../styles/Icons";
import Layout from "../layouts/Layout";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#424242",
      borderColor: white,

      // background: "#F4F4FA",
    },
    "& .MuiInputBase-input": {
      padding: "14px 0",
      borderRadius: "2px",
      color: "transparent",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      // background: white,
      background: primary,
      borderRadius: "13px",
      color: white,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(13.5px, 13.5px) scale(1)",
      color: white,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: darkestGray,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInput-underline:before": { borderBottom: "none" },
    "& .MuiFormHelperText-root": {
      margin: "0",
      backgroundColor: white,
      color: white,
      height: "0",
      display: "none",
    },
    "& .MuiPickersBasePicker-container": {
      maxWidth: "350px",
      width: "100%",
      margin: "0 0 0 -43px",
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
    "& .MuiOutlinedInput-root": {
      "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
      "& .MuiOutlinedInput-input ": {
        padding: "13.5px  0 13.5px 14px",
        color: white,
        caretColor: "transparent",
      },
      "& .MuiIconButton-root": {
        padding: "2px 0 2px 0",
        margin: "10px 0 10px 12px",
        borderRadius: "2px",
        color: white,
      },
      "& fieldset": {
        // borderColor: darkestGray,
      },
      "&:hover fieldset": { borderColor: darkestGray },
      "&.Mui-focused fieldset": {
        // borderColor: darkestGray,
      },
    },
  },
})(KeyboardDatePicker);

const styles = {
  chip: {
    padding: "5px",
    borderRadius: "13px",
    background: primary,
    minWidth: "77px",
  },
};
class MobileDispatches extends Component {
  constructor(props) {
    super(props);
    const loadedAt = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : new Date();
    this.state = {
      loadedAt,
      dates: [],
      selectedDateData: {},
      selectedDate: new Date(), //).format("Do MMM, ddd"),
      consignees: [],
      liveParty: {},
      noData: false,
      total_weight: 0,
      total_trips: 0,
      calenderOpen: false,
      loadFirst: false,
      loading: {},
    };
  }

  setInitialValues = (props) => {
    const loadedAt = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : new Date();
    if (props) {
      if (props.dates && props.dates.length > 0) {
        props.dates.sort((a, b) => {
          var dateA = b.date; // moment(b.date).format("Do MMM");
          var dateB = a.date; //moment(a.date).format("Do MMM");
          return dateA > dateB ? 1 : -1;
        });
        props.dates.map((data, index) => {
          data.formattedDate = moment(data.date).format("Do MMM, ddd");
        });
      }
      if (
        !this.state.loadFirst &&
        this.state.dates.length !== 0 &&
        props.loading.dates === false &&
        this.state.dates.length === props.dates.length
      ) {
        this.setState(
          {
            loading: props.loading,
            loadedAt,
            loadFirst: true,
            dates: props.dates && props.dates.length > 0 ? props.dates : [],
            consignees: props.consignees && props.consignees.length > 0 ? props.consignees : [],
            liveParty: props.liveParty && props.liveParty,
          },
          () => {
            this.handleDateChangenew(new Date());
            if (this.state.liveParty.total_per_transporter) {
              const tripArr = [];
              const weightARr = [];
              this.state.liveParty.total_per_transporter.map((data, i) => {
                tripArr.push(data.total_trips);
                weightARr.push(data.total_net_weight);
              });
              if (tripArr.length === this.state.liveParty.total_per_transporter.length) {
                this.setState({
                  total_weight: weightARr.reduce((a, b) => a + b, 0),
                  total_trips: tripArr.reduce((a, b) => a + b, 0),
                });
              }
            }
          }
        );
      } else {
        this.setState(
          {
            loading: props.loading,
            loadedAt,
            dates: props.dates && props.dates.length > 0 ? props.dates : [],
            consignees: props.consignees && props.consignees.length > 0 ? props.consignees : [],
            liveParty: props.liveParty && props.liveParty,
          },
          () => {
            if (this.state.liveParty.total_per_transporter) {
              const tripArr = [];
              const weightARr = [];
              this.state.liveParty.total_per_transporter.map((data, i) => {
                tripArr.push(data.total_trips);
                weightARr.push(data.total_net_weight);
              });
              if (tripArr.length === this.state.liveParty.total_per_transporter.length) {
                this.setState({
                  total_weight: weightARr.reduce((a, b) => a + b, 0),
                  total_trips: tripArr.reduce((a, b) => a + b, 0),
                });
              }
            }
          }
        );
      }
    }
  };
  handleDateChangenew = (selectedDate) => {
    localStorage.setItem("selectedDate", JSON.stringify(moment(selectedDate).format("YYYY-MM-DD")));
    this.setState(
      {
        selectedDate: selectedDate,
      },

      () => {
        const selectedDateData = this.props.dates.filter(
          (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
        )[0];
        this.props.fetchSingleDateDetails(selectedDateData, this.props.user.user.uid);
        localStorage.setItem("loadedAt", new Date());

        this.setState({
          selectedDateData,
          noData: false,
        });
      }
    );
  };
  handleDateChange = (selectedDate) => {
    localStorage.setItem("selectedDate", JSON.stringify(moment(selectedDate).format("YYYY-MM-DD")));
    this.setState(
      {
        selectedDate: selectedDate,
      },

      () => {
        const { dates, selectedDate } = this.state;
        console.log(dates, selectedDate, "vvvvvvvvvvvvvvvvv dates, selectedDate");
        if (
          dates.filter(
            (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
          ) &&
          dates.filter(
            (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
          ).length > 0 &&
          dates.filter(
            (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
          )[0]
        ) {
          const selectedDateData = dates.filter(
            (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
          )[0];
          this.props.fetchSingleDateDetails(selectedDateData, this.props.user.user.uid);
          localStorage.setItem("loadedAt", new Date());

          this.setState({
            selectedDateData,
            noData: false,
          });
        } else {
          localStorage.setItem("loadedAt", new Date());

          this.setState({
            noData: true,
          });

          // liveParty:
        }
      }
    );
  };
  UNSAFE_componentWillMount() {
    localStorage.setItem("loadedAt", new Date());
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  downloadImageFunction = () => {
    const { selectedDate } = this.state;
    const fileName = `PLIPL-${Moment(selectedDate).format("Do MMM, ddd")}`;
    this.setState({ toPrint: true }, () => {
      domtoimage
        .toJpeg(document.getElementById("mycanvas"), { quality: 0.95 })
        .then(function(dataUrl) {
          var link = document.createElement("a");
          link.download = fileName ? `${fileName}.jpeg` : "image.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          this.setState({ toPrint: false });
        });
    });
  };
  render() {
    const {
      selectedDate,
      consignees,
      selectedDateData,
      liveParty,
      noData,
      total_weight,
      total_trips,
      calenderOpen,
      toPrint,
      loadedAt,
      loading,
    } = this.state;
    const dateTimeAgo = moment(loadedAt).fromNow();
    return (
      <Layout
        title={"Dispatches"}
        subTitle={`Last updated ${dateTimeAgo}!`}
        // ShareImage={() => {
        //   this.shareImageFunction();
        // }}
        DownloadImage={() => {
          this.downloadImageFunction();
        }}
      >
        <div className="d-flex width-100 height-100 postionR d-flex-column padding20 ">
          {" "}
          {loading.dates === true ? (
            <div
              className="alignC justiyC d-flex"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              {" "}
              {toPrint ? (
                <Backdrop style={{ zIndex: 1, color: "#fff" }} open={toPrint}>
                  <StyledCircularProgress color="inherit" />
                </Backdrop>
              ) : (
                ""
              )}{" "}
              <div className="d-flex width-100 justifyC" style={{ margin: "0 0 20px 0" }}>
                <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                  <CssKeyboardDatePicker
                    variant="inline"
                    onOpen={() => {
                      this.setState({ calenderOpen: true });
                    }}
                    onClose={() => {
                      this.setState({ calenderOpen: false });
                    }}
                    keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                    maxDate={new Date()}
                    disableFuture
                    minDate={"2022-01-22"}
                    error={false}
                    invalidLabel=""
                    onKeyPress={(ev) => {
                      ev.preventDefault();
                    }}
                    allowKeyboardControl={false}
                    clearable
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label={selectedDate ? "" : "Date"}
                    format="do MMM, yyyy"
                    style={{ maxWidth: "350px", cursor: "none" }}
                    className="width-100"
                    value={selectedDate ? selectedDate : ""}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => this.handleDateChange(date)}
                  />{" "}
                </MuiPickersUtilsProvider>{" "}
              </div>
              <div
                style={{
                  height: "calc(100% - 66px)",
                  width: "calc(100% + 40px)",
                  padding: "0 20px",
                  margin: "0 -20px",
                }}
                className="overflowYA"
              >
                <div
                  id="mycanvas"
                  style={{
                    width: toPrint ? "fit-content" : "100%",
                    height: "100%",
                    minWidth: toPrint ? "fit-content" : "100%",
                    minHeight: toPrint ? "fit-content" : "fit-content",
                    padding: toPrint && "20px",
                    borderRadius: toPrint && "7px",
                    backgroundColor: toPrint && "#98AFC7",
                  }}
                  className=""
                >
                  {" "}
                  {toPrint && (
                    <div
                      style={{
                        padding: "0 0 30px 0",
                        width: "100%",

                        height: "50px",
                        width: "fit-content",
                        minWidth: "100%",
                        color: primary,
                        fontWeight: "400",
                      }}
                    >{`Trip dispatch date :- ${moment(selectedDate).format("MMMM Do YYYY")}`}</div>
                  )}
                  {loading.liveParty ? (
                    <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
                      <StyledCircularProgress color="inherit" />
                    </div>
                  ) : liveParty === {} ? (
                    <div className="width-100 d-flex justifyC alignC height-100 "> No trips on this date.</div>
                  ) : noData ||
                    (liveParty && liveParty.total_per_consignee && liveParty.total_per_consignee.length === 0) ? (
                    <div className="width-100 d-flex justifyC alignC height-100"> No trips on this date.</div>
                  ) : liveParty && liveParty.total_per_consignee && liveParty.total_per_consignee.length === 0 ? (
                    <div className="width-100 d-flex justifyC alignC height-100"> No trips on this date.</div>
                  ) : liveParty && liveParty.total_per_consignee && liveParty.total_per_consignee.length > 0 ? (
                    <>
                      {" "}
                      <div className="d-flex width-100 " style={{ margin: "0 0 10px 0", minHeight: "247px" }}>
                        <Paper className="height-100 d-flex padding20 borderRadius13 d-flex-column width-100">
                          <div
                            className={`d-flex width-100 ${
                              liveParty.total_per_consignee && liveParty.total_per_consignee.length > 2
                                ? ""
                                : "justifySA"
                            }  fontSize1 textC padding20 ${
                              toPrint ? "minWidthFC" : "overflowXA"
                            } scrollBluePrimarythin`}
                          >
                            {liveParty.total_per_consignee &&
                              liveParty.total_per_consignee.map((singleConsignee, i) => (
                                <>
                                  <div
                                    className="d-flex  d-flex-column minWidthFC paddingRL10"
                                    style={{ minWidth: "fit-content" }}
                                  >
                                    <div className="d-flex  justifyC textC marginTB10 fontWeightBold">
                                      {consignees &&
                                        consignees.filter((data) => data.name === singleConsignee.consignee)[0] &&
                                        consignees.filter((data) => data.name === singleConsignee.consignee)[0]
                                          .short_name &&
                                        consignees.filter((data) => data.name === singleConsignee.consignee)[0]
                                          .short_name}
                                    </div>
                                    <div
                                      className="d-flex justifyC textColorprimary  textC textColorprimary"
                                      style={{
                                        fontSize: "0.9rem",
                                        minWidth: "fit-content",
                                        maxHeight: "20.59px",
                                        display: "flex",
                                      }}
                                    >
                                      {`${singleConsignee.total_net_weight}MT`}
                                    </div>
                                  </div>{" "}
                                  {i !== liveParty.total_per_consignee.length - 1 && (
                                    <Divider className="height-100" variant="vertical" style={{ minHeight: "62px" }} />
                                  )}{" "}
                                </>
                              ))}
                          </div>
                          <Divider className="width-100" />
                          <div className="d-flex width-100 justifySA textColorprimary fontSize1 textC paddingTB20">
                            <div className="d-flex  justifyC textC widthFC d-flex-column ">
                              <div className="d-flex  justifyC textC alignC marginB10">Total trips</div>
                              <div className="d-flex  justifyC textC alignC textCW" style={styles.chip}>
                                {selectedDateData.dispatch_details &&
                                  selectedDateData.dispatch_details.total_trips &&
                                  selectedDateData.dispatch_details.total_trips}
                              </div>
                            </div>
                            <div className="d-flex justifyC textColorprimary  textC widthFC d-flex-column">
                              <div className="d-flex  justifyC textC alignC marginB10">Total MT</div>
                              <div className="d-flex  justifyC textC alignC textCW" style={styles.chip}>
                                {selectedDateData.dispatch_details &&
                                  selectedDateData.dispatch_details.total_net_weight &&
                                  selectedDateData.dispatch_details.total_net_weight}
                              </div>
                            </div>
                          </div>
                        </Paper>
                      </div>{" "}
                      <div className="d-flex width-100" style={{ padding: "0 0 10px 0" }}>
                        {liveParty.total_per_transporter && liveParty.total_per_transporter.length > 0 ? (
                          <Paper
                            className={`d-flex width-100 padding20 borderRadius13 d-flex-column fontSize09 ${
                              toPrint ? "minWidthFC" : "overflowXA"
                            } scrollBluePrimarythin`}
                            style={{ height: "fit-content" }}
                          >
                            <div className="d-flex width-100  textColorprimary textC minWidthFC">
                              <div
                                style={{
                                  minWidth: "107.22px",
                                  width: "107.22px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "5px",
                                    padding: "10px",
                                    width: "100%",
                                    display: "flex",
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    color: primary,
                                  }}
                                />
                              </div>
                              <div
                                className="d-flex justifySA"
                                style={{ width: "fit-content", minWidth: "calc(100% - 155.63px" }}
                              >
                                {liveParty &&
                                  liveParty.total_per_consignee &&
                                  liveParty.total_per_consignee.map((singleConsignee) => (
                                    <div
                                      className=""
                                      style={{
                                        width: "48.41px",
                                        minWidth: "48.41px",
                                        alignItems: "center",
                                      }}
                                    >
                                      {" "}
                                      <div
                                        className="textOEllips d-flex justifyC fontSize1"
                                        style={{
                                          color: primary,
                                          fontWeight: "500",
                                          textTransform: "capitalize",
                                          height: "23.75px",
                                          width: "100%",
                                        }}
                                      >
                                        {consignees &&
                                          consignees.filter((data) => data.name === singleConsignee.consignee)[0] &&
                                          consignees.filter((data) => data.name === singleConsignee.consignee)[0]
                                            .short_name &&
                                          consignees.filter((data) => data.name === singleConsignee.consignee)[0]
                                            .short_name}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            </div>

                            {liveParty &&
                              liveParty.total_per_transporter &&
                              liveParty.total_per_transporter.map((singleTrans) => (
                                <>
                                  <div className="d-flex width-100 justifyC textC minWidthFC">
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                      }}
                                    >
                                      <div
                                        style={{
                                          minWidth: "107.22px",
                                          width: "107.22px",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          display: "flex",
                                        }}
                                      >
                                        <div
                                          className="fontSize1"
                                          style={{
                                            borderRadius: "5px",
                                            padding: "10px",
                                            width: "100%",
                                            display: "flex",
                                            fontWeight: "500",
                                            textTransform: "capitalize",
                                            color: primary,
                                          }}
                                        >
                                          {singleTrans.transporter_group}
                                        </div>
                                      </div>
                                      <div
                                        className="d-flex justifySA"
                                        style={{ width: "fit-content", minWidth: "calc(100% - 155.63px" }}
                                      >
                                        {liveParty &&
                                          liveParty.total_per_consignee &&
                                          liveParty.total_per_consignee.map((single_dispatch_details) => (
                                            <div
                                              style={{
                                                width: "48.41px",
                                                minWidth: "48.41px",
                                                display: "flex",
                                                borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    width: "100%",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    display: "flex",
                                                  }}
                                                >
                                                  {liveParty.dispatch_details
                                                    .filter(
                                                      (data) => data.consignee === single_dispatch_details.consignee
                                                    )
                                                    .filter(
                                                      (single_dispatch_details) =>
                                                        singleTrans.transporter_group ===
                                                        single_dispatch_details.transporter_group_name
                                                    )
                                                    ? liveParty.dispatch_details
                                                        .filter(
                                                          (data) => data.consignee === single_dispatch_details.consignee
                                                        )
                                                        .filter(
                                                          (single_dispatch_details) =>
                                                            singleTrans.transporter_group ===
                                                            single_dispatch_details.transporter_group_name
                                                        ).length > 0
                                                      ? liveParty.dispatch_details
                                                          .filter(
                                                            (data) =>
                                                              data.consignee === single_dispatch_details.consignee
                                                          )
                                                          .filter(
                                                            (single_dispatch_details) =>
                                                              singleTrans.transporter_group ===
                                                              single_dispatch_details.transporter_group_name
                                                          )[0].total_trips !== null &&
                                                        liveParty.dispatch_details
                                                          .filter(
                                                            (data) =>
                                                              data.consignee === single_dispatch_details.consignee
                                                          )
                                                          .filter(
                                                            (single_dispatch_details) =>
                                                              singleTrans.transporter_group ===
                                                              single_dispatch_details.transporter_group_name
                                                          )[0].total_trips !== "null"
                                                        ? liveParty.dispatch_details
                                                            .filter(
                                                              (data) =>
                                                                data.consignee === single_dispatch_details.consignee
                                                            )
                                                            .filter(
                                                              (single_dispatch_details) =>
                                                                singleTrans.transporter_group ===
                                                                single_dispatch_details.transporter_group_name
                                                            )[0].total_trips
                                                        : "-"
                                                      : "-"
                                                    : "-"}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                      </div>
                                      <div
                                        style={{
                                          width: "48.41px",
                                          minWidth: "48.41px",
                                          display: "flex",
                                        }}
                                        className="fontSize1"
                                      >
                                        <div style={{ width: "100%", display: "flex" }}>
                                          <div
                                            style={{
                                              width: "100%",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              display: "flex",
                                              color: primary,
                                            }}
                                          >
                                            {singleTrans.total_trips}
                                          </div>
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </div>{" "}
                                </>
                              ))}
                            <div className="minWidthFC" style={{ width: "100%", display: "flex" }}>
                              <div
                                style={{
                                  minWidth: "107.22px",
                                  width: "107.22px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "5px",
                                    padding: "10px",
                                    width: "100%",
                                    display: "flex",
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    color: primary,
                                  }}
                                />
                              </div>
                              <div
                                className="d-flex justifySA"
                                style={{ width: "fit-content", minWidth: "calc(100% - 155.63px" }}
                              >
                                {liveParty &&
                                  liveParty.total_per_consignee &&
                                  liveParty.total_per_consignee.map((singleTrans) => (
                                    <div
                                      style={{
                                        width: "48.41px",
                                        minWidth: "48.41px",
                                        display: "flex",
                                      }}
                                      className="fontSize1"
                                    >
                                      <div style={{ width: "100%", display: "flex" }}>
                                        <div
                                          style={{
                                            width: "100%",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                            color: primary,
                                            padding: "10px 0 0 0",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {singleTrans.total_trips ? singleTrans.total_trips : "-"}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div style={{ width: "48.41px", minWidth: "48.41px", display: "flex" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    color: primary,
                                    padding: "10px 0 0 0",
                                    fontWeight: "500",
                                  }}
                                >
                                  {total_trips}
                                </div>
                              </div>
                            </div>
                          </Paper>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="width-100 d-flex justifyC alignC height-100"> No trips on this date.</div>
                  )}{" "}
                  {toPrint && (
                    <div
                      className="d-flex justifyFE"
                      style={{
                        height: "50px",
                        width: "100%",
                        padding: "30px 0 0 0",
                        minWidth: "100%",
                        color: primary,
                        fontWeight: "400",
                      }}
                    >{`Summary generated at :- ${moment(new Date()).format("MMMM Do YYYY, h:mm A")}`}</div>
                  )}
                </div>{" "}
              </div>
            </>
          )}
        </div>
      </Layout>
    );
  }
}

export default MobileDispatches;
