import React from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  DashBoardIcon,
  AssignmentIcon,
  TransportIcon,
  ReciptsIcon,
  UserPermissionIcon,
  DispatchIcon,
  MastersManagementsIcon,
  TransportGroupIcon,
  DestinationIcon,
  SourceIcon,
  ConsigneeIcon,
  ConsignorIcon,
  CarIcon,
  ItemIcon,
} from "../components/layoutComponents/icons";
import { primary } from "../styles/colors";
function MobileViewBottomBar(props) {
  const chectSelected = (path) => {
    const tofilterWIth =
      props.match.path.indexOf("/", props.match.path.indexOf("/") + 1) === -1
        ? props.match.path
        : props.match.path.toString().slice(0, props.match.path.indexOf("/", props.match.path.indexOf("/") + 1));
    // if (path === "/masters" && childRoutesARR.includes(tofilterWIth)) {
    //   return true;
    // } else {
    if (tofilterWIth === path) {
      return true;
    } else {
      return false;
    }
    // }
  };
  const listItems = [
    {
      path: "/dispatches",
      title: "Dispatches",
      access: "dispatches",
      icon: (path) => <DispatchIcon color={chectSelected(path) ? primary : "#989898"} />,
    },
    {
      path: "/salesorders",
      title: "Sales Orders",
      access: "sales_order",
      icon: (path) => <DashBoardIcon color={chectSelected(path) ? primary : "#989898"} />,
    },
    {
      path: "/e-permit",
      title: "E-Permits",
      access: "e_permit",
      icon: (path) => <ReciptsIcon color={chectSelected(path) ? primary : "#989898"} />,
    },
  ];
  return (
    <AppBar position="static" style={{ borderRadius: "14px 14px 0 0", background: "white" }}>
      <div
        className="d-flex alignC width-100"
        style={{
          height: "60px",
          fontSize: "1rem",
          justifyContent: props?.user?.accessibleModules.length > 1 ? "space-around" : "",
        }}
      >
        {listItems &&
          listItems.map(
            (item) =>
              props?.user?.accessibleModules.includes(item.access) && (
                <div
                  className="d-flex d-flex-column padding10"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push(item.path);
                  }}
                >
                  <div
                    style={{ color: chectSelected(item.path) ? primary : "#989898" }}
                    className="d-flex alignC justifyC width-100"
                  >
                    {item.title}
                  </div>
                  <div className="d-flex alignC justifyC width-100">{item.icon(item.path)}</div>
                </div>
              )
          )}
      </div>
    </AppBar>
  );
}

export default MobileViewBottomBar;
