import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import TranspotersGroup from "../components/TranspotersGroup";
import Consignees from "../components/Consignees";
import { fetchConsignees, consigneesAdd, consigneesEdit, fetchDestinationCities } from "../redux/index";
class ConsigneesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      destinationCities: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
    console.log("aaaaaaaaaaaaa");
  }
  componentWillReceiveProps(next) {
    this.setState({ consignees: next.consignees });
  }

  fetchUsers = () => {
    if (this.props.user.user.uid) {
      this.props.fetchConsignees(this.props.user.user.uid).then((res) => {
        if (res) {
          this.setState({ consignees: res });
        }
      });
      this.props.fetchDestinationCities(this.props.user.user.uid).then((res) => {
        if (res) {
          this.setState({ destinationCities: res });
        }
      });
    }
  };

  render() {
    return (
      <Route
        exact
        path={["/consignees", "/consignees/:tab/:id", "/consignees/new"]}
        render={(props) => <Consignees {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  consignees: state.masters.consignees,
  destinationCities: state.masters.destinationCities,
});
const mapDispatchToProps = {
  fetchConsignees,
  consigneesAdd,
  fetchDestinationCities,
  consigneesEdit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsigneesContainer);
