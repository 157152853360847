import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Transporter from "../components/Transporter";
import {
  fetchTransportersGroups,
  fetchTransporters,
  transpotersEdit,
  transpotersAdd,
  fetchTripsTransGroups,
  lazyLoadFetch,
  fetchLRdetails,
  PaymentVoucherAdd,
  fetchPaymentVoucher,
  fetchRateMatrixs,
  fetchDestinationCities,
  postRateMatrix,
  fetchPaymentAdvices,
  postPaymentAdvices,
  fetchDates,
} from "../redux/index";
class TransporterContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // users: [],
      // transpoterslist: [],
      // destinationCities: [],
      // dates: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    this.fetchdates(month, year);
  }
  componentWillReceiveProps(next) {
    this.setState({ transpoterslist: next.transpoterslist, dates: next.dates });
  }
  fetchdates = (month, year) => {
    if (this.props.user.user.uid) {
      this.props.fetchDates(this.props.user.user.uid, month, year).then((res) => {
        if (res) {
          const length = [];
          if (res.length > 0) {
            res.map((data, index) => {
              if (data.date === moment(new Date()).format("YYYY-MM-DD")) {
                length.push(data);
              }
            });
          } else {
            res = [];
          }
          const date = new Date();
          const monthNow = date.getMonth() + 1;
          if (length.length === 0 && month === monthNow) {
            var newVal = {
              id: res.length + 1,
              dispatch_details: {
                total_trips: 0,
                total_net_weight: null,
                total_pending_trips: 0,
                total_net_weight_pending_trips: null,
                total_new_trips: 0,
                total_dispatched_trips: 0,
                total_correction_trips: 0,
                total_reached_trips: 0,
                total_ready_trips: 0,
                total_billing_trips: 0,
              },
              date: moment(new Date()).format("YYYY-MM-DD"),
              is_active: true,
            };
            res.push(newVal);
          }
          this.setState({ dates: res });
        }
      });
    }
  };

  fetchUsers = async () => {
    console.log("this.props.user.uid", this.props.user.user.uid);

    await this.props.fetchTransportersGroups(this.props.user.user.uid).then((res) => {
      if (res) {
        this.setState({ transpoterslist: res });
      }
    });
    await this.props.fetchTransporters(this.props.user.user.uid).then((res) => {
      if (res) {
        this.setState({ transporter: res });
      }
    });
  };
  render() {
    return (
      <Route
        exact
        path={["/transpoters", "/transpoters/:tab/:id", "/transpoters/new"]}
        render={(props) => <Transporter {...this.props} {...this.state} fetchdates={this.fetchdates} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  loading: state.masters.loading,
  dates: state.masters.dates,
  transpoterslist: state.masters.transpoterslist,
  transporter: state.masters.transporter,
  singleTransGroupsTrips: state.masters.singleTransGroupsTrips,
  lrDetails: state.masters.lrDetails,
  voucher: state.masters.voucher,

  rateMatrixes: state.masters.rateMatrixes,
  destinationCities: state.masters.destinationCities,
  paymentAdvices: state.masters.paymentAdvices,
});
const mapDispatchToProps = {
  fetchTransportersGroups,
  fetchTransporters,
  transpotersEdit,
  transpotersAdd,
  fetchTripsTransGroups,
  lazyLoadFetch,
  fetchLRdetails,
  PaymentVoucherAdd,
  fetchPaymentVoucher,
  fetchDates,
  fetchRateMatrixs,
  fetchDestinationCities,
  postRateMatrix,
  fetchPaymentAdvices,
  postPaymentAdvices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransporterContainer);
