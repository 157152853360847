import React, { Component } from "react";
import { Route ,withRouter} from "react-router-dom";
import LoginForm from "../components/LoginForm";
import { LoginFunction } from "../redux/index"
import { connect } from "react-redux";
// import { withRouter } from "react-router";
class LoginContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    return (
      <LoginForm {...this.props} {...this.state}
        loginFunction={
          this.props.LoginFunction}

      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication
});
const mapDispatchToProps = {
  LoginFunction
};

export default withRouter((connect(mapStateToProps, mapDispatchToProps)(LoginContainer)));
