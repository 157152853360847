import React, { Component } from "react";
import PropTypes from "prop-types";
import { lightGrey, primary } from "../styles/colors";
import "../styles/styles.css";
import { Divider } from "@material-ui/core";
import QRCode from "qrcode.react";
import moment from "moment";
import { ArrowBack } from "@material-ui/icons";
import { withStyles } from "@material-ui/styles";
const CssDivider = withStyles({
  root: {
    "& .MuiDivider-root": {
      //   backgroundColor: "rgba(0, 0, 0, 1)",
      //   border: "2px solid red",
    },
  },
})(Divider);
const styles = {
  mainContainer: (fromPreview) => ({
    border: `2px solid ${"rgba(0, 0, 0, 1)"}`,
    margin: fromPreview ? "0" : "40px",
    minHeight: fromPreview ? "100%" : "calc(100% - 84px)",
    width: fromPreview ? "100%" : "calc(100% - 84px)",
    height: fromPreview ? "100%" : "calc(100% - 84px)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  }),
  widthWithDivider: { width: "calc(50% - 0.5px)" },
  widthWithDivider25: { width: "calc(25% - 0.5px)" },
  widthWithDivider75: { width: "calc(75% - 0.5px)" },
  widthWithDivider40: { width: "calc(40% - 0.5px)" },
  widthWithDivider60: { width: "calc(60% - 0.5px)" },
  widthWithDivider33: { width: "calc(33% - 0.5px)" },
  widthWithDivider67: { width: "calc(67% - 0.5px)" },
  widthWithDivider30: { width: "calc(30% - 0.5px)", display: "flex", padding: "10px" },
  widthWithDivider70: { width: "calc(70% - 0.5px)" },
};
class BiltiPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      copyArr: ["Consignor Copy", "Consignee Copy"],
    };
  }

  componentWillMount() {
    const { fromPreview, pageNoToShow } = this.props;
    if (fromPreview) {
      var copyArr = pageNoToShow === 0 ? ["Consignor Copy"] : ["Consignee Copy"];
      this.setState({ copyArr: copyArr });
    }
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    const { fromPreview, pageNoToShow } = nextProps;
    console.log(fromPreview, pageNoToShow, "vvvvvvvvvvvvv");
    if (fromPreview) {
      var copyArr = pageNoToShow === 0 ? ["Consignor Copy"] : ["Consignee Copy"];
      this.setState({ copyArr: copyArr });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {}

  componentWillUpdate(nextProps, nextState) {}

  componentDidUpdate(prevProps, prevState) {}

  componentWillUnmount() {}

  render() {
    const { user, singleTrip, singleDate, fromPreview, pageNoToShow } = this.props;
    const { copyArr } = this.state;
    console.log(copyArr, "copyArr");
    return (
      copyArr &&
      copyArr.map((singleCopy) => (
        <div
          style={{
            display: "flex",
            height: "100%",
            fontSize: "0.8rem",
            textAlign: "left",
            width: "100%",
            margin: fromPreview ? "0 0 20px 0" : "",
          }}
        >
          <div style={styles.mainContainer(fromPreview)}>
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                height: "100%",
                width: "40px",
                left: "-42px",
              }}
            >
              <ArrowBack style={{ padding: "0", margin: "0", height: "10px", width: "10px" }} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",

                padding: "0 0 10px 0",
                margin: "10px 10px 0 10px",
                fontSize: "0.7rem",
                width: "calc(100% - 20px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                <span>
                  CONSIGNMENT NOTE <span style={{ fontWeight: "bold" }}>"BILTI"</span>
                </span>

                <div style={{ position: "absolute", right: "0", fontSize: "0.7rem" }}>{singleCopy}</div>
              </div>
              <div
                style={{
                  textTransform: "uppercase",
                  fontSize: "1.2rem",
                  // fontFamily: "Cursive",
                  margin: "10px 0 5px 0",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  fontWeight: "bold",
                }}
              >
                <span style={{ textAlign: "center", display: "flex", justifyContent: "center", width: "100%" }}>
                  {user.companyName}
                </span>
              </div>
              <div style={{ display: "flex", width: "100%", position: "relative", justifyContent: "center" }}>
                <span
                  style={{
                    textTransform: "capitalize",
                    fontSize: "1rem",
                    padding: "0 0 2px 0",
                    fontSize: "0.8rem",
                  }}
                >
                  {user.address}
                </span>
              </div>

              <div style={{ margin: "10px 0 10px 0", display: "flex", justifyContent: "center", width: "100%" }}>
                <div style={{ display: "flex", position: "absolute", bottom: "0", left: "0", padding: "0 5px 5px" }}>
                  <span>
                    <span style={{ fontWeight: "bold" }}>GSTIN :</span> 22AAGCP3023P1ZF
                  </span>
                </div>
                <div
                  style={{
                    width: "250px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span style={{ padding: "0 0 2px 0" }}>Subject to Raipur Jurisdiction</span>
                </div>
                <div style={{ display: "flex", padding: "0 5px 5px", right: "0", bottom: "0", position: "absolute" }}>
                  <div style={{ display: "flex" }}>
                    <span style={{ display: "flex", fontWeight: "bold" }}>
                      Mob. :<div style={{ minWidth: "3px" }} />{" "}
                    </span>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ padding: "0 0 2px 0" }}>8889155855</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>{" "}
            <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
            <div style={{ minHeight: "154px", height: "fit-content", width: "100%", display: "flex" }}>
              <div style={Object.assign({ height: "100%" }, styles.widthWithDivider)}>
                <div
                  style={{
                    height: "30px",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div
                    style={Object.assign(
                      { minHeight: "30px", maxHeight: "30px", padding: "0 5px", alignItems: "center", display: "flex" },
                      styles.widthWithDivider
                    )}
                  >
                    <span style={{ display: "flex", fontWeight: "bold" }}>
                      {" "}
                      L.R.NO.: <div style={{ minWidth: "5px" }} />{" "}
                    </span>{" "}
                    {" " + singleTrip.lr}
                  </div>
                  <CssDivider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                  <div
                    style={Object.assign(
                      { padding: "0 5px", minHeight: "30px", maxHeight: "30px", alignItems: "center", display: "flex" },
                      styles.widthWithDivider
                    )}
                  >
                    <span style={{ display: "flex", fontWeight: "bold" }}>
                      {" "}
                      Date: <div style={{ minWidth: "5px" }} />{" "}
                    </span>{" "}
                    {" " + moment(singleDate).format("Do MMM, ddd")}
                  </div>
                </div>
                <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                <div style={{ padding: "5px", display: "flex", height: "auto", flexDirection: "column" }}>
                  <div style={{ width: "fit-content", fontWeight: "bold" }}>Consignor:</div>
                  <div style={{ width: "100%", display: "flex", height: "auto" }}>
                    <div
                      style={{ minWidth: "56.42px", margin: "0 3px 0 0", justifyContent: "flex-end", display: "flex" }}
                    >
                      M/s.
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 83.69px)",
                        wordBreak: "break-word",
                        height: "auto",
                        flexWrap: "wrap",
                      }}
                    >
                      {singleTrip && singleTrip.consignor ? (
                        <>
                          {singleTrip.consignor.name}
                          <br />
                          {singleTrip.consignor.address}
                          <br />
                          GSTIN: {singleTrip.consignor.gstin_no}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <CssDivider
                variant="vertical"
                style={{ minHeight: "154px", height: "154px", backgroundColor: "rgba(0, 0, 0, 1)" }}
              />
              <div style={styles.widthWithDivider}>
                <div
                  style={{
                    height: "30px",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      maxHeight: "30px",
                      minHeight: "30px",
                      padding: "0 5px",
                    }}
                    //  style={styles.widthWithDivider}
                  >
                    <span style={{ display: "flex", fontWeight: "bold" }}>
                      {" "}
                      Truck No.: <div style={{ minWidth: "5px" }} />{" "}
                    </span>{" "}
                    {" " + singleTrip.VehicleINo}
                  </div>
                </div>
                <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />{" "}
                <div style={{ padding: "5px", display: "flex", height: "auto", flexDirection: "column" }}>
                  <div cstyle={{ width: "fit-content" }}>Consignee: </div>
                  <div style={{ width: "100%", display: "flex", height: "auto" }}>
                    <div
                      style={{ minWidth: "56.42px", margin: "0 3px 0 0", justifyContent: "flex-end", display: "flex" }}
                    >
                      M/s.
                    </div>
                    <div
                      style={{
                        width: "calc(100% - 83.69px)",
                        wordBreak: "break-word",
                        height: "auto",
                        flexWrap: "wrap",
                      }}
                    >
                      {singleTrip && singleTrip.consignee ? (
                        <>
                          {" "}
                          {singleTrip.consignee.name}
                          <br />
                          {singleTrip.consignee.address}
                          <br />
                          GSTIN: {singleTrip.consignee.gstin_no}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
            <div
              style={{
                height: "30px",
                width: "100%",
                display: "flex",
              }}
            >
              <div style={Object.assign({ display: "flex", alignItems: "center" }, styles.widthWithDivider)}>
                <span style={{ padding: "5px" }}>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> From: </span>
                  {singleTrip.from}
                </span>{" "}
              </div>{" "}
              <CssDivider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
              <div style={Object.assign({ display: "flex", alignItems: "center" }, styles.widthWithDivider)}>
                <span style={{ padding: "5px" }}>
                  {" "}
                  <span style={{ fontWeight: "bold" }}> To:</span> {singleTrip.to}
                </span>{" "}
              </div>
            </div>
            <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
            <div style={{ height: "309px", width: "100%", display: "flex" }}>
              <div style={{ minWidth: "calc(100% - 201px", display: "flex", height: "100%" }}>
                <div
                  style={{
                    minWidth: "32.47px",
                    maxWidth: "32.47px",
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                  }}
                >
                  <span
                    style={{
                      justifyContent: "center",
                      padding: "0 5px",
                      minHeight: "30px",
                      maxHeight: "30px",
                      alignItems: "center",
                      display: "flex",
                      fontWeight: "bold",
                      background: "#9e9e9e0d",
                    }}
                  >
                    S.no
                  </span>
                  <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      minHeight: "30px",
                      maxHeight: "30px",
                      padding: "0 5px",
                    }}
                  >
                    01
                  </div>
                </div>{" "}
                <CssDivider
                  variant="vertical"
                  style={{ minHeight: "309px", height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                    minWidth: "calc(100% - 180.47px)",
                    maxWidth: "calc(100% - 180.47px)",
                  }}
                >
                  <span
                    style={{
                      padding: "0 5px",
                      minHeight: "30px",
                      maxHeight: "30px",
                      alignItems: "center",
                      display: "flex",
                      fontWeight: "bold",
                      background: "#9e9e9e0d",
                    }}
                  >
                    Description
                  </span>
                  <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                  <div
                    style={{
                      wordBreak: "break-word",
                      padding: "0 5px",
                      minHeight: "30px",
                      maxHeight: "30px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    {singleTrip.descriptiomOfGood}
                  </div>
                </div>{" "}
                <CssDivider
                  variant="vertical"
                  style={{ minHeight: "309px", height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }}
                />
                <div style={{ minWidth: "80px", flexDirection: "column", height: "auto", display: "flex" }}>
                  <div style={{ width: "100%", height: "auto", display: "flex" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                      <div
                        style={{
                          padding: "0 5px",
                          minHeight: "30px",
                          maxHeight: "30px",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          background: "#9e9e9e0d",
                        }}
                      >
                        Weight
                      </div>
                      <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />{" "}
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          minHeight: "30px",
                          maxHeight: "30px",
                          padding: "0 5px",
                        }}
                      >
                        {singleTrip.weight}
                      </div>
                    </div>{" "}
                  </div>
                </div>{" "}
                <CssDivider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                <div
                  style={{ minWidth: "80px", width: "80px", height: "100%", display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      padding: "0 5px",
                      minHeight: "30px",
                      maxHeight: "30px",
                      alignItems: "center",
                      display: "flex",
                      fontWeight: "bold",
                      background: "#9e9e9e0d",
                    }}
                  >
                    Rate
                  </div>
                  <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />{" "}
                  <div
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      alignItems: "center",
                      minHeight: "30px",
                      maxHeight: "30px",
                      padding: "0 5px",
                    }}
                  >
                    {singleTrip.rate}
                  </div>
                </div>
              </div>
              <CssDivider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />

              <div style={{ minWidth: "200px", display: "flex", height: "100%" }}>
                <div style={{ display: "flex", width: "100%", height: "100%" }}>
                  <div style={{ width: "154px", height: "100%" }}>
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                        fontWeight: "bold",
                        background: "#9e9e9e0d",
                      }}
                    >
                      Particulars
                    </div>
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Freight
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Stastical Sur - Charged
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Addition Operation Cost
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Loading Charges
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Risk Charges
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Total Rs.
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Advance Rs.
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        maxHeight: "30px",
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Total Pay Rs.
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div
                      style={{
                        maxHeight: "30px",
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    />
                  </div>{" "}
                  <CssDivider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                  <div style={{ minWidth: "45px" }}>
                    <div
                      style={{
                        maxHeight: "30px",
                        minHeight: "30px",
                        fontWeight: "bold",
                        maxHeight: "30px",
                        background: "#9e9e9e0d",
                      }}
                    />
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.freight}</div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.stasticalSCharged}</div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.additionOperationCost}</div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.LoadingCharges} </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.RiskCharges}</div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.TotalRs}</div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.AdvanceRs}</div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }}>{singleTrip.Total}</div>
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ minHeight: "30px" }} />
                  </div>
                </div>
              </div>
            </div>
            <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
            <div style={{ height: "154px", width: "100%", display: "flex", height: "auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: singleCopy === "Consignor Copy" ? "80%" : "100%",
                }}
              >
                <div style={{ height: "93px", width: "100%", display: "flex" }}>
                  <div style={{ width: "160px", minWidth: "160px", display: "flex", flexDirection: "column" }}>
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minHeight: "30px",
                          maxHeight: "30px",
                          padding: "0 5px",
                          fontWeight: "bold",
                          background: "#9e9e9e0d",
                          width: "100%",
                        }}
                      >
                        Driver Details
                      </div>
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          minWidth: "60px",
                          width: "60px",
                          display: "flex",
                          alignItems: "center",
                          minHeight: "30px",
                          maxHeight: "30px",
                          padding: "0 5px",
                          fontWeight: "bold",
                          background: "#9e9e9e0d",
                        }}
                      >
                        Name
                      </div>
                      <CssDivider
                        variant="vertical"
                        style={{ height: "100%", minHeight: "30px", backgroundColor: "rgba(0, 0, 0, 1)" }}
                      />
                      <span
                        style={{
                          width: "calc(100% - 57.67px",
                          display: "flex",
                          alignItems: "center",
                          maxHeight: "30px",
                          minHeight: "30px",
                          padding: "0 5px",
                        }}
                      >
                        {" "}
                        {singleTrip.DName}
                      </span>
                    </div>
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />{" "}
                    <div style={{ width: "100%", display: "flex" }}>
                      <div
                        style={{
                          padding: "0 5px",
                          minHeight: "30px",
                          maxHeight: "30px",
                          alignItems: "center",
                          display: "flex",
                          fontWeight: "bold",
                          background: "#9e9e9e0d",
                          minWidth: "60px",
                          width: "60px",
                        }}
                      >
                        Number
                      </div>
                      <CssDivider
                        variant="vertical"
                        style={{ height: "100%", minHeight: "30px", backgroundColor: "rgba(0, 0, 0, 1)" }}
                      />
                      <span
                        style={{
                          width: "calc(100% - 57.67px",
                          display: "flex",
                          alignItems: "center",
                          maxHeight: "30px",
                          minHeight: "30px",
                          padding: "0 5px",
                        }}
                      >
                        {singleTrip.DlNo}
                      </span>
                    </div>
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />{" "}
                  </div>
                  <CssDivider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                  <div
                    style={{
                      width: "calc(100%- 161px)",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        wordBreak: "break-word",
                        height: "calc(100% - 1px)",
                        display: "flex",
                      }}
                    >
                      <span style={{ padding: "5px" }}>
                        No Liablity of transporter will be entertained. Any kind of toll Taxes or other taxes will be
                        paid by Truck/Trailer Driver/Owner. Shortage allowed up to :-
                      </span>
                      {/* <span style={{ margin: "0 0 0 3px", borderBottomStyle: "dotted" }}
                                        className=" width-100" >

                                    </span> */}
                    </div>{" "}
                    <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                  </div>
                </div>
                <div
                  style={{
                    justifyContent: "center",
                    padding: "0 5px",
                    minHeight: "30px",
                    maxHeight: "30px",
                    alignItems: "center",
                    display: "flex",
                    fontWeight: "bold",
                    background: "#9e9e9e0d",
                  }}
                />
                <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
                <div style={{ height: "30px", display: "flex", width: "100%", maxHeight: "30px" }}>
                  {/* <div style={styles.widthWithDivider}>
                                        <div style={{ padding: "0 5px", minHeight: "30px", maxHeight: "30px", alignItems: "center", display: "flex" }}>
                                            Value of goods Rs. __{" "}
                                        </div>
                                    </div>{" "}
                                    <CssDivider variant="vertical" style={{ height: "100%" }} /> */}
                  <div style={{ width: "100%" }}>
                    <div
                      style={{
                        padding: "0 5px",
                        minHeight: "30px",
                        maxHeight: "30px",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      Carrier's Risk/Owner's Risk
                    </div>
                  </div>
                </div>{" "}
              </div>
              {singleCopy === "Consignor Copy" ? (
                <>
                  {" "}
                  <CssDivider
                    variant="vertical"
                    style={{ height: "100%", minHeight: "80px", backgroundColor: "rgba(0, 0, 0, 1)" }}
                  />
                  <div style={{ width: "calc(20% - 1px)" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <QRCode
                        style={{ height: "80px", width: "80px" }}
                        id={singleTrip.lr ? singleTrip.lr : "1"}
                        size={80}
                        value={singleTrip.lr ? singleTrip.lr : "1"}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <CssDivider style={{ width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />
            <div
              style={{
                minHeight: "150px",
                height: "calc(100% - 775px)",
                width: "100%",
                display: "flex",
              }}
            >
              <div style={styles.widthWithDivider70}>
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {singleCopy === "Consignor Copy" ? (
                    <span style={{ fontSize: "0.6rem", fontWeight: "bold", paddingLeft: "5px" }}>
                      Please stamp here for receiving
                    </span>
                  ) : (
                    ""
                  )}
                  {/* <div style={{ margin: "0 0 10px 0" }} className="d-flex">
                                        <div
                                            className="d-flex justifyC alignC"
                                            style={{
                                                height: "19px",
                                                margin: "0 10px 0 0",
                                                width: "10px"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "5px",
                                                    height: "5px",
                                                    borderRadius: "50%",
                                                    background: "black"
                                                }}
                                            />
                                        </div>{" "}
                                        <span>
                                            We here by declare that I/We have not taken the credit of
                                            Excises Duty paid input of capital goods or service tax
                                            credit on inputs service.user the provision of the central
                                            rules 2004.
                                        </span>
                                    </div>
                                    <div className="d-flex" style={{ margin: "0 0 10px 0" }}>
                                        <div
                                            className="d-flex justifyC alignC"
                                            style={{
                                                height: "19px",
                                                margin: "0 10px 0 0",
                                                width: "10px"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "5px",
                                                    height: "5px",
                                                    borderRadius: "50%",
                                                    background: "black"
                                                }}
                                            />
                                        </div>{" "}
                                        <span>
                                            I/We here by declare the I/We have not availed the bender
                                            the notification of the government of India in the
                                            ministry of finance (Department of Revenue No.
                                            12/2003-service tax) dated the 20th june 2003.
                                        </span>{" "}
                                    </div>
                                    <div className="d-flex" style={{ margin: "0 0 10px 0" }}>
                                        <div
                                            className="d-flex justifyC alignC"
                                            style={{
                                                height: "19px",
                                                margin: "0 10px 0 0",
                                                width: "10px"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "5px",
                                                    height: "5px",
                                                    borderRadius: "50%",
                                                    background: "black"
                                                }}
                                            />
                                        </div>{" "}
                                        <span>
                                            I/We also agree to identify the company against any
                                            pavment/liability/loss of credit/damage caused to the
                                            company in case of our default to company with the said
                                            declaration.
                                        </span>{" "}
                                    </div> */}
                </div>
              </div>
              <CssDivider
                variant="vertical"
                style={{
                  height: "100%",
                  minHeight: "242px",
                  height: "calc(100% - 775px)",
                  backgroundColor: "rgba(0, 0, 0, 1)",
                }}
              />
              <div style={styles.widthWithDivider30}>
                <span style={{ fontSize: "0.6rem", fontWeight: "bold" }}>Seal and sign here</span>
              </div>
            </div>
          </div>
        </div>
      ))
    );
  }
}

BiltiPrint.propTypes = {};

export default BiltiPrint;
