import React from "react";
export const CrossIcon = (props) => (
  <svg
    style={{
      fill: props.color ? props.color : "white",
      stroke: props.color ? props.color : "white",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: props.strokeWidth ? props.strokeWidth : "3",
    }}
    width="14.828"
    height={props.height ? props.height : "14.828"}
    viewBox="0 0 14.828 14.828"
  >
    <g transform="translate(-4.586 -4.586)">
      <line x1="12" y2="12" transform="translate(6 6)" />
      <line x2="12" y2="12" transform="translate(6 6)" />
    </g>
  </svg>
);

export const CancelIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g id="Group_8952" data-name="Group 8952" transform="translate(-0.395)">
      <circle
        id="Ellipse_336"
        data-name="Ellipse 336"
        cx="12"
        cy="12"
        r="12"
        transform="translate(0.395)"
        fill="#bdbdbdd1"
      />
      <g id="Group_6325" data-name="Group 6325" transform="translate(12.256 3.702) rotate(45)">
        <g id="Add">
          <rect
            id="Rectangle_799"
            data-name="Rectangle 799"
            width="12.022"
            height="2.404"
            rx="1.202"
            transform="translate(0 4.809)"
            fill="#fff"
          />
          <rect
            id="Rectangle_800"
            data-name="Rectangle 800"
            width="12.022"
            height="2.404"
            rx="1.202"
            transform="translate(7.213) rotate(90)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export const SearchIcon = () => (
  <svg
    style={{
      color: "#bdbdbdd1",
      fill: "none",
      stroke: "#bdbdbdd1",
      strokeLinecap: "round",
      strokeLinejoin: "round",
      strokeWidth: "2px",
    }}
    xmlns="http://www.w3.org/2000/svg"
    width="15.414"
    height="15.414"
    viewBox="0 0 18.414 18.414"
  >
    <g transform="translate(-2 -2)">
      <circle class="a" cx="7.111" cy="7.111" r="7.111" transform="translate(3 3)" />
      <line class="a" x1="3.867" y1="3.867" transform="translate(15.133 15.133)" />
    </g>
  </svg>
);

export const PlusIcon = (props) => (
  <svg
    fill={props.color ? props.color : "#FFFFFF"}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width ? props.width : "20px"}
    height={props.height ? props.height : "20px"}
  >
    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 11 L 7 11 L 7 13 L 11 13 L 11 17 L 13 17 L 13 13 L 17 13 L 17 11 L 13 11 L 13 7 L 11 7 z" />
  </svg>
);
export const Reload = (props) => (
  <svg
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-refresh-ccw"
  >
    <polyline points="1 4 1 10 7 10" />
    <polyline points="23 20 23 14 17 14" />
    <path d="M20.49 9A9 9 0 0 0 5.64 5.64L1 10m22 4l-4.64 4.36A9 9 0 0 1 3.51 15" />
  </svg>
);
export const UploadIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-upload"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="17 8 12 3 7 8" />
    <line x1="12" y1="3" x2="12" y2="15" />
  </svg>
);
export const Download = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-download"
  >
    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
    <polyline points="7 10 12 15 17 10" />
    <line x1="12" y1="15" x2="12" y2="3" />
  </svg>
);

export const FliterIcon = (props) => (
  <svg
    enable-background="new 0 0 36 30"
    height="20px"
    id="Layer_1"
    version="1.1"
    viewBox="0 0 36 30"
    width="20px"
    xml="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns="http://www.w3.org/1999/xlink"
  >
    <polygon fill="#231F20" points="14,30 22,25 22,17 35.999,0 17.988,0 0,0 14,17 " />
  </svg>
);

export const ExportIcon = (props) => (
  <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2803 2.21967C11.9874 1.92678 11.5126 1.92678 11.2197 2.21967L6.21967 7.21967C5.92678 7.51256 5.92678 7.98744 6.21967 8.28033C6.51256 8.57322 6.98744 8.57322 7.28033 8.28033L11 4.56066V18.25C11 18.6642 11.3358 19 11.75 19C12.1642 19 12.5 18.6642 12.5 18.25V4.56066L16.2197 8.28033C16.5126 8.57322 16.9874 8.57322 17.2803 8.28033C17.5732 7.98744 17.5732 7.51256 17.2803 7.21967L12.2803 2.21967ZM5.25 20.5C4.83579 20.5 4.5 20.8358 4.5 21.25C4.5 21.6642 4.83579 22 5.25 22H18.25C18.6642 22 19 21.6642 19 21.25C19 20.8358 18.6642 20.5 18.25 20.5H5.25Z"
      fill="#FFFFFF"
    />
  </svg>
);
export const ThreeDotIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#FFFFFF"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-more-vertical"
  >
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);
export const BottomArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-chevron-down"
  >
    <polyline points="6 9 12 15 18 9" />
  </svg>
);
export const TopArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-chevron-up"
  >
    <polyline points="18 15 12 9 6 15" />
  </svg>
);

export const MoreVertical = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-more-vertical"
  >
    <circle cx="12" cy="12" r="1" />
    <circle cx="12" cy="5" r="1" />
    <circle cx="12" cy="19" r="1" />
  </svg>
);
export const Share = (props) => (
  <svg
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color ? props.color : "currentColor"}
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-share"
  >
    <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8" />
    <polyline points="16 6 12 2 8 6" />
    <line x1="12" y1="2" x2="12" y2="15" />
  </svg>
);
export const EditIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    viewBox="0 0 24 24"
    fill="none"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-edit"
  >
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
  </svg>
);
export const AddIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : "24"}
    height={props.height ? props.height : "24"}
    stroke={props.color ? props.color : "currentColor"}
    viewBox="0 0 24 24"
    fill="none"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-plus-square"
  >
    <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
    <line x1="12" y1="8" x2="12" y2="16" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);
