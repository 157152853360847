import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { connect } from "react-redux";
import { LogOutFunction } from "../../redux/index";
// import withFirebase from "../../hoc/withFirebase";
// import withUser from "../../hoc/withUser";

const useStyles = makeStyles((theme) => ({
  profile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
}));

function RightIcon(props) {
  const user = localStorage.getItem("PLIPL_USER") ? JSON.parse(localStorage.getItem("PLIPL_USER")) : "";
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(
    () => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }
      prevOpen.current = open;
    },
    [open]
  );
  return (
    <div className="d-flex alignC" style={{ height: "64px" }}>
      <div className={classes.profile}>
        {" "}
        {console.log(props?.user, "props?.user")}
        <p style={{ fontSize: 18, margin: "0" }}>{user?.username}</p>
        <span style={{ color: "rgb(255 255 255 / 0.8)" }}>{props?.user?.email}</span>
      </div>
      <Tooltip title="User information" placement="bottom">
        <Avatar
          variant="rounded"
          alt="Profile Information"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          src={props?.user?.photoURL}
        >
          {props?.user?.username?.substring(0, 1)}
        </Avatar>
      </Tooltip>

      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal={!props.isMobile}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      props.LogOutFunction().then(() => props.history.push("/login"));
                    }}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    Sign out
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

const mapStateToProps = (state) => ({
  version: state.authentication.version,
  user: state.authentication.user,
  loader: state.authentication.loading,
});
const mapDispatchToProps = { LogOutFunction };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightIcon);
