import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import Dispatches from "../components/Dispatches";
import { connect } from "react-redux";
import {
  fetchSingleDateDetails,
  fetchDates,
  fetchSingleDateTrips,
  fetchDetailsOfTp,
  fetchConsignees,
  fetchTransportersGroups,
  fetchSalesOrders,
  fetchitems_grade,
  postTrip,
  fetchVehicles,
  vehicleAdd,
  fetchConsigneeGradeUsingTp,
  sendForCorrection,
  editDate,
  fetchSalesOrderBulkPermit,
  fetchDestinationCities,
} from "../redux";
import { NewReleasesRounded } from "@material-ui/icons";
class DispatchesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
      destinationCities: [],
    };
  }

  componentWillMount() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    this.fetchdates(month, year);
  }

  fetchdates = (month, year) => {
    if (this.props.user.user.uid) {
      if (this.props.isMobile) {
        this.props.fetchDates(this.props.user.user.uid).then((res) => {
          if (res) {
            const length = [];
            if (res.length > 0) {
              res.map((data, index) => {
                if (data.date === moment(new Date()).format("YYYY-MM-DD")) {
                  length.push(data);
                }
              });
            } else {
              res = [];
            }
            if (length.length === 0) {
              var newVal = {
                id: res.length + 1,
                dispatch_details: {
                  total_trips: 0,
                  total_net_weight: null,
                  total_pending_trips: 0,
                  total_net_weight_pending_trips: null,
                  total_new_trips: 0,
                  total_dispatched_trips: 0,
                  total_correction_trips: 0,
                  total_reached_trips: 0,
                  total_ready_trips: 0,
                  total_billing_trips: 0,
                },
                date: moment(new Date()).format("YYYY-MM-DD"),
                is_active: true,
              };
              res.push(newVal);
            }
            this.setState({ dates: res });
            this.props.fetchConsignees(this.props.user.user.uid).then((res1) => {
              if (res1) {
                this.setState({ consignees: res1 });
                this.props.fetchTransportersGroups(this.props.user.user.uid).then((res2) => {
                  if (res2) {
                    this.setState({ transpoterslist: res2 });

                    this.props.fetchitems_grade(this.props.user.user.uid).then((res4) => {
                      if (res4) {
                        this.setState({ items_grade: res4 });
                      }
                    });
                    //   }
                    // });
                  }
                });
              }
            });
          }
        });
      } else {
        this.props.fetchDates(this.props.user.user.uid, month, year).then((res) => {
          if (res) {
            const length = [];
            if (res.length > 0) {
              res.map((data, index) => {
                if (data.date === moment(new Date()).format("YYYY-MM-DD")) {
                  length.push(data);
                }
              });
            } else {
              res = [];
            }
            if (length.length === 0) {
              var newVal = {
                id: res.length + 1,
                dispatch_details: {
                  total_trips: 0,
                  total_net_weight: null,
                  total_pending_trips: 0,
                  total_net_weight_pending_trips: null,
                  total_new_trips: 0,
                  total_dispatched_trips: 0,
                  total_correction_trips: 0,
                  total_reached_trips: 0,
                  total_ready_trips: 0,
                  total_billing_trips: 0,
                },
                date: moment(new Date()).format("YYYY-MM-DD"),
                is_active: true,
              };
              res.push(newVal);
            }
            this.setState({ dates: res });
            this.props.fetchConsignees(this.props.user.user.uid).then((res1) => {
              if (res1) {
                this.setState({ consignees: res1 });
                this.props.fetchTransportersGroups(this.props.user.user.uid).then((res2) => {
                  if (res2) {
                    this.setState({ transpoterslist: res2 });

                    this.props.fetchitems_grade(this.props.user.user.uid).then((res4) => {
                      if (res4) {
                        this.setState({ items_grade: res4 });
                      }
                    });
                    //   }
                    // });
                  }
                });
              }
            });
          }
        });
      }
      this.props.fetchDestinationCities(this.props.user.user.uid).then((res) => {
        if (res) {
          this.setState({ destinationCities: res });
        }
      });
      this.props.fetchVehicles(this.props.user.user.uid).then((vehicles) => {
        if (vehicles) {
          this.setState({ vehicles: vehicles });
        }
      });
    }
  };

  render() {
    return (
      <Route
        exact
        path={["/dispatches", "/dispatches/:tab/:id", "/dispatches/new"]}
        render={(props) => (
          <Dispatches
            {...props}
            {...this.props}
            {...this.state}
            setDates={(dateRR) => {
              this.setState({ dates: dateRR });
            }}
          />
        )}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  dates: state.masters.dates,
  dispatches: state.dispatches,
  destinationCities: state.masters.destinationCities,
  dispatchesCopy: state.dispatches,
  consignees: state.masters.consignees,
  transpoterslist: state.masters.transpoterslist,
  salesOrders: state.masters.salesOrders,
  items_grade: state.masters.items_grade,
  vehicles: state.masters.vehicles,
  bulkpermit: state.masters.bulkpermit,
  loading: state.masters.loading,
  liveParty: state.masters.liveParty,
});
const mapDispatchToProps = {
  fetchDates,
  fetchSingleDateTrips,
  fetchDetailsOfTp,
  fetchConsignees,
  fetchTransportersGroups,
  fetchSalesOrders,
  fetchitems_grade,
  postTrip,
  fetchVehicles,
  fetchSalesOrderBulkPermit,
  vehicleAdd,
  fetchConsigneeGradeUsingTp,
  sendForCorrection,
  editDate,
  fetchSingleDateDetails,
  fetchDestinationCities,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DispatchesContainer);
