import React, { Component } from "react";
import "../styles/styles.css";
export default class Loader extends Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: window.innerHeight,
        }}
      >
        <div class="lds-hourglass" />
      </div>
    );
  }
}
