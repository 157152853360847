import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { withRouter } from "react-router-dom";
import { primary } from "../styles/colors";
import { LoginFunction, setErrorClose, verifyOtp } from "../redux/index";
import { connect } from "react-redux";
import SnackbarComponent from "./layoutComponents/SnackbarComponent";
const theme = createMuiTheme({
  // typography: {
  //   fontFamily: "Poppins",
  // },

  // overrides: {
  //   MuiCssBaseline: {
  //     "@global": {
  //       // "@font-face": [poppinsBold, poppinsLight, poppinsMedium, poppinsRegular],
  //     },
  //   },
  // },
  palette: {
    primary: {
      light: primary,
      main: primary,
      dark: primary,
    },
    secondary: {
      light: primary,
      main: primary,
      dark: primary,
    },
  },
  status: {
    danger: "orange",
  },
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: primary,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
function SignIn(props) {
  const classes = useStyles();
  const [username, setusername] = useState(false);
  const [password, setPassword] = useState(false);
  // const [enterOTP, setEnterOTP] = useState(false);

  const [enterUser, setenterUser] = useState(true);
  const [otp, setOtp] = useState("1234");
  const [error, setError] = useState(false);
  const [sendToDashboard, setSendToDashboard] = useState(false);

  const [errorusername, seterrorusername] = useState(false);

  const [errorPass, seterrorPass] = useState(false);

  const [errOtp, seterrOtp] = useState(false);
  useEffect(
    () => {
      if (props.user && !sendToDashboard) {
        setSendToDashboard(true);
      }
    },
    [props.user]
  );
  const submitData = () => {
    if (username && password) {
      const data = { username: username, password: password };
      props.loginFunction(data).then((response) => {
        console.log(response, "responseresponse");
        if (response && response.status && response.status === "error") {
          setError(response.msg ? response.msg : "Invalid password or user name!");
        } else {
          setError(false);
          setenterUser(false);
          submitOtp()
          // setEnterOTP(true);
          //  props.history.push(
          //       response.data?.accessibleModules?.includes("dashboard") ? "/dashboard" : "/dispatches"
          //     );
        }
      });
    } else {
      if (!username) {
        seterrorusername("please enter user name!");
      } else if (!password) {
        seterrorPass("please enter your password !");
      }
    }
  };
  const submitOtp = () => {
    // if (enterOTP) {
      if (otp) {
        const toSend = { email: username, otp: otp };
        seterrOtp(false);
        props.verifyOtp(toSend).then((response) => {
          if (response.status && response.status === "error") {
            seterrOtp(response.msg ? response.msg : "Wrong Otp!");
          } else {
            if (response.status && response.status === "success") {
              seterrOtp(false);

              props.history.push(
                response.data?.accessibleModules?.includes("dashboard") ? "/dashboard" : "/dispatches"
              );
            }
          }
        });
      } else {
        seterrOtp("please enter otp !");
      }
    // }
  };
  document.addEventListener("keydown", (event) => {
    // if (event.keyCode === 13) {
    //   if (enterOTP === true) {
    //     if (otp) {
    //       submitOtp()
    //     }
    //   } else {
    //     if (enterUser === true) {
    //       if (username && password) {
    //         submitData()
    //       }
    //     }
    //   }
    // }
  });
  const { user } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {error && (
        <SnackbarComponent
          error={error ? error : false}
          message={error}
          handleCloseSnackbar={() => {
            setError(false);
            props.setErrorClose();
          }}
        />
      )}
      <div className="d-flex width-100 height-100vh justifyC alignC">
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form}>
              {/* {enterOTP ? (
                <>
                  {"An otp has been sent to your registered mobile number!"}
                  <TextField
                    onKeyPress={(ev) => {
                      console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter") {
                        ev.preventDefault();

                        submitOtp();
                      }
                    }}
                    error={errOtp ? true : false}
                    helperText={errOtp ? errOtp : false}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="otp"
                    label="Enter Otp"
                    name="otp"
                    autoComplete="off"
                    autoFocus={true}
                    value={otp ? otp : ""}
                    onChange={(event) => {
                      seterrOtp(false);
                      setOtp(event.target.value);
                    }}
                  />
                </>
              ) : ( */}
                <>
                  <TextField
                    error={errorusername ? true : false}
                    helperText={errorusername ? errorusername : false}
                    onKeyPress={(ev) => {
                      console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        // if (username && password) {
                        submitData();
                        // }
                      }
                    }}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="username"
                    name="username"
                    autoComplete="username"
                    autoFocus
                    value={username ? username : ""}
                    onChange={(event) => {
                      seterrorusername(false);
                      setusername(event.target.value);
                    }}
                  />
                  <TextField
                    value={password ? password : ""}
                    onChange={(event) => {
                      seterrorPass(false);
                      setPassword(event.target.value);
                    }}
                    onKeyPress={(ev) => {
                      console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter") {
                        submitData();
                        ev.preventDefault();
                      }
                    }}
                    error={errorPass ? true : false}
                    helperText={errorPass ? errorPass : false}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </>
              {/* )}
              {enterOTP ? (
                ""
              ) : (
                <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
              )} */}
              <Button
                // onClick={(e) => {
                //   if (enterOTP) {
                //     submitOtp();
                //   } else {
                //     submitData();
                //   }
                // }}
                onClick={(e)=>{
                      submitData();
                }}
                fullWidth
                variant="contained"
                color="primary"
                className={
                  classes.submit // type="submit"
                }
              >
                Sign In
                {/* {enterOTP ? "Verify Otp" : "Sign In"} */}
              </Button>
              {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
            </form>
          </div>
          {/* <Box mt={8}>
                <Copyright />
            </Box> */}
        </Container>
      </div>
    </MuiThemeProvider>
  );
}
const mapStateToProps = (state) => ({
  user: state.authentication,
});
const mapDispatchToProps = {
  LoginFunction,
  setErrorClose,
  verifyOtp,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SignIn)
);
