import React, { Component } from "react";
import { green, primary } from "../styles/colors";
import {
  Tooltip,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  DialogContent,
  Backdrop,
  MenuItem,
  Divider,
  ListItem,
  ListItemIcon,
  Checkbox,
  List,
  ListItemText,
} from "@material-ui/core";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { CssTextField10, StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import { withStyles } from "@material-ui/styles";
import ReactDOM from "react-dom";
import { Paper } from "@material-ui/core";
import PaymentAdvicePdf from "./PaymentAdvicePdf";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { BottomArrow, CrossIcon, TopArrow } from "../styles/Icons";
import { CheckCircle, MoreHorizOutlined } from "@material-ui/icons";
import TableComponent from "./TableComponent";
import moment from "moment";
import SnackbarComponent from "./SnackbarComponent";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
      },
    },
  },
})(KeyboardDatePicker);
const CssCheckbox = withStyles({
  "& .MuiCheckbox-root": {
    color: "red",
    padding: "30px",
  },
  root: {
    "& .MuiCheckbox-root": {
      color: "red",
      padding: "30px",
    },
  },
})(Checkbox);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
};
export default class PaymentAdvices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      addPaymentAdvice: false,
      paymentAdvices: [],
      added: false,
      error: "",
      success: "",
      paymentVouchersList: [],
      loading: {},
      loadingDialog: false,
      errorMsg: { pvArr: false },
      pvArr: [],
      header: ["Sr.No.", "Date", "Total Payment Vouchers", "Total Amount", "Status", "Cheque Number", "PDF", "XLSX"],
      apiHeader: [
        "Sr.No.",
        "newDate",
        "Total_Payment_Vouchers",
        "total_amount",
        "status",
        "cheque_number",
        "PDF",
        "XLSX",
      ],
      paymentAdvicesNewARr: [],
      pAStatusArr: ["unpaid", "paid", "cancelled"],
      selectedPaStatus: "unpaid",
      ChequeNumber: "",
      selectedList: false,
      wscols: [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }],
      transporter: [],
      downloadPdf: false,
      selectedId: "",
    };
  }
  componentWillMount() {
    this.props.fetchPaymentAdvices(this.props.selectedId, this.props.user.user.uid);
  }
  UNSAFE_componentWillReceiveProps(next) {
    if (next.selectedId !== this.state.selectedId) {
      this.props.fetchPaymentAdvices(next.selectedId, this.props.user.user.uid);
      this.setState({ selectedId: next.selectedId });
    }
    this.setInitialValues(next);
  }
  setInitialValues(props) {
    if (props) {
      this.setState(
        {
          paymentAdvicesNewARr: props.paymentAdvices,
          paymentAdvices: props.paymentAdvices,
          paymentVouchersList:
            props.paymentVouchersList && props.paymentVouchersList.length > 0 ? props.paymentVouchersList : [],
          loading: props.loading,
          transporter: props.transporter,
        },
        () => {
          const { header, apiHeader, paymentAdvices } = this.state;
          const newHead = header;
          const newApiHead = apiHeader;

          if (paymentAdvices.length > 0) {
            const newArr = [];
            paymentAdvices.map((sRM) => {
              // const srmNew = {};
              // srmNew.id = sRM.id;
              sRM.total_amount = Number(sRM.total_amount ? sRM.total_amount : 0).toFixed(2);
              sRM.newDate = moment(sRM.date).format("Do MMM, ddd");
              newArr.push(sRM);
            });
            this.setState({ paymentAdvicesNewARr: newArr, header: newHead, apiHeader: newApiHead });
          }
        }
      );
    }
  }

  handleToggle = (value) => {
    console.log(value, "innnnnnnnnnnn valll 123");
    const { pvArr, errorMsg } = this.state;
    errorMsg.pvArr = false;
    const currentIndex = pvArr.indexOf(value);
    const newChecked = [...pvArr];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    console.log(value, "innnnnnnnnnnn valll", newChecked);
    this.setState({ pvArr: newChecked, errorMsg });
  };
  handleClearFields = () => {
    this.setState({
      added: false,
      errorMsg: { pvArr: false },
      pvArr: [],
      ChequeNumber: "",
    });
  };
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    this.setState({
      [event.target.name]: event.target.value,
      errorMsg,
    });
  };
  exportToCSV = (sPA, fileName, apiData, Heading, wscols) => {
    const { fileType, fileExtension } = this.state;
    const ws = XLSX.utils.json_to_sheet(apiData, {
      skipHeader: true,
      origin: "A9",
    });

    ws["!merges"] = [
      { s: { c: 0, r: 0 }, e: { c: 8, r: 2 } },

      { s: { c: 0, r: apiData.length + 8 }, e: { c: 8, r: apiData.length + 8 } },
      { s: { c: 8, r: 0 }, e: { c: 8, r: apiData.length + 8 } },
      { s: { c: 0, r: 3 }, e: { c: 7, r: 3 } },
      { s: { c: 0, r: 4 }, e: { c: 4, r: 4 } },
      { s: { c: 5, r: 4 }, e: { c: 7, r: 4 } },
      { s: { c: 0, r: 5 }, e: { c: 4, r: 5 } },
      { s: { c: 6, r: 5 }, e: { c: 7, r: 5 } },
      { s: { c: 0, r: 6 }, e: { c: 4, r: 6 } },
      { s: { c: 6, r: 6 }, e: { c: 7, r: 6 } },
    ];
    if (Heading) {
      XLSX.utils.sheet_add_aoa(ws, [
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        ["", "", "", "", "", "", "", ""],
        [this.props.user.companyName, "", "", "", "", "", "", ""],
        [this.props.user.bankName, "", "", "", "", "PARTY PAYMENT", " ", ""],
        [
          `ACCOUNT NO. ${this.props.user.accountNo}`,
          "",
          "",
          "",
          "",
          "DATE",
          moment(sPA.date).format("Do MMM, YYYY"),

          "",
        ],
        ["", "", "", "", "", "CHEQUE NO.", sPA.cheque_number ? sPA.cheque_number : "", ""],
        Heading,
      ]);
    }
    ws["!cols"] = wscols;
    var wsrows = [
      { hpx: 15 },
      { hpx: 15 },
      { hpx: 15 },
      { hpx: 25 },
      { hpx: 25 },
      { hpx: 25 },
      { hpx: 25 },
      { hpx: 25 },
    ];
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}1`].s = {
        font: {
          name: "Calibri",
          sz: 16,
          bold: true,
          color: {
            rgb: "FFFFFF",
          },
        },

        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 18 },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}2`].s = {
        font: {
          name: "Calibri",
          sz: 16,
          bold: true,
          color: {
            rgb: "FFFFFF",
          },
        },

        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 18 },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}3`].s = {
        font: {
          name: "Calibri",
          sz: 16,
          bold: true,
          color: {
            rgb: "FFFFFF",
          },
        },

        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 18 },
        border: {
          bottom: {
            style: "thin",
            color: { rgb: "000000" },
          },
        },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}4`].s = {
        font: {
          name: "Calibri",
          sz: 16,
          bold: true,
          color: {
            rgb: "FFFFFF",
          },
        },
        fill: {
          fgColor: { rgb: "00A36C" },
        },
        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 18 },
        border: {
          bottom: {
            style: "thin",
            color: { rgb: "000000" },
          },
          top: {
            style: "thin",
            color: { rgb: "000000" },
          },
          right: {
            style: "thin",
            color: { rgb: "000000" },
          },
          left: {
            style: "thin",
            color: { rgb: "FFFFFF" },
          },
        },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}5`].s = {
        font: {
          name: "Calibri",
          sz: 12,
          bold: true,
          color: {
            rgb: "000000",
          },
        },
        fill: {
          fgColor: { rgb: "FFFFFF" },
        },
        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 16 },
        border: {
          bottom: {
            style: "thin",
            color: { rgb: "000000" },
          },
          top: {
            style: "thin",
            color: { rgb: "000000" },
          },
          right: i !== wscols.length && {
            style: "thin",
            color: { rgb: "000000" },
          },
          left: i + 1 !== wscols.length && {
            style: "thin",
            color: { rgb: "000000" },
          },
        },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}6`].s = {
        font: {
          name: "Calibri",
          sz: 12,
          bold: true,
          color: {
            rgb: "000000",
          },
        },
        fill: {
          fgColor: { rgb: "FFFFFF" },
        },
        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 16 },
        border: {
          bottom: {
            style: "thin",
            color: { rgb: "000000" },
          },
          top: {
            style: "thin",
            color: { rgb: "000000" },
          },
          right: {
            style: "thin",
            color: { rgb: "000000" },
          },
          left: {
            style: "thin",
            color: { rgb: "000000" },
          },
        },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}7`].s = {
        font: {
          name: "Calibri",
          sz: 12,
          bold: true,
          color: {
            rgb: "000000",
          },
        },

        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 16 },
        border: {
          bottom: {
            style: "thin",
            color: { rgb: "000000" },
          },
          top: {
            style: "thin",
            color: { rgb: "000000" },
          },
          right: {
            style: "thin",
            color: { rgb: "000000" },
          },
          left: {
            style: "thin",
            color: { rgb: "000000" },
          },
        },
      };
    });
    wscols.map((data, i) => {
      ws[`${(i + 10).toString(36).toUpperCase()}8`].s = {
        font: {
          name: "Calibri",
          sz: 12,
          bold: true,
          color: {
            rgb: "000000",
          },
        },
        fill: {
          fgColor: { rgb: "ECFFDC" },
        },
        alignment: {
          horizontal: "center",
          wrapText: true,
          vertical: "center",
        },
        height: { hpx: 16 },
        border: {
          bottom: {
            style: "thin",
            color: { rgb: "000000" },
          },
          top: {
            style: "thin",
            color: { rgb: "000000" },
          },
          right: {
            style: "thin",
            color: { rgb: "000000" },
          },
          left: {
            style: "thin",
            color: { rgb: "000000" },
          },
        },
      };
    });

    ws["!rows"] = wsrows;
    if (Heading) {
      apiData.map((data, i) => {
        if (apiData[i + 1] && apiData[i + 1].BENEFICIARY_name === "Total") {
          wsrows.push({ hpx: 25 });
          ws["!merges"].push({ s: { c: 1, r: i + 8 }, e: { c: 4, r: i + 8 } });
          wscols.map((data, i2) => {
            ws[`${(i2 + 10).toString(36).toUpperCase()}${i + 9}`].s = {
              font: {
                name: "Calibri",
                sz: 11,
                color: {
                  rgb: "000000",
                },
              },
              fill: {
                fgColor: { rgb: "FFFFFF" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
              },
              height: { hpx: 20 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
              },
            };
          });
        } else if (data.BENEFICIARY_name === "Total") {
          wsrows.push({ hpx: 25 });
          ws["!merges"].push({ s: { c: 1, r: i + 8 }, e: { c: 4, r: i + 8 } });

          wscols.map((data, i2) => {
            ws[`${(i2 + 10).toString(36).toUpperCase()}${i + 9}`].s = {
              font: {
                name: "Calibri",
                sz: 12,
                color: {
                  rgb: "000000",
                },
                bold: true,
              },
              fill: {
                fgColor: { rgb: "F1ECB9" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
              },
              height: { hpx: 20 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
              },
            };
          });
        } else {
          wsrows.push({ hpx: 20 });
          wscols.map((data, i2) => {
            ws[`${(i2 + 10).toString(36).toUpperCase()}${i + 9}`].s = {
              font: {
                name: "Calibri",
                sz: 11,
                color: {
                  rgb: "000000",
                },
                bold: i + 8 === 8 && true,
              },
              fill: {
                fgColor: { rgb: "FFFFFF" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
              },
              height: { hpx: 16 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
              },
            };
          });
        }
      });
    }
    const wb = {
      Sheets: { ["Payment Advice"]: ws },
      SheetNames: ["Payment Advice"],
    };
    wsrows.push({ hpx: 45 });
    // ws["!rows"][ws["!rows"].length - 1] = { hpx: 45 };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ loadingDialog: false, success: "Xlsx file downloaded successfully!" });
  };
  reset = () => {
    const { pvArr, ChequeNumber, selectedList } = this.state;

    if (pvArr.length === 0 && !ChequeNumber) {
      this.setState({ error: "Nothing to reset !" });
    } else {
      this.setState(
        selectedList === false
          ? {
              errorMsg: { pvArr: false },
              pvArr: [],
              ChequeNumber: "",
            }
          : {
              pvArr: selectedList.payment_voucher_numbers,
              selectedList: selectedList,
              ChequeNumber: selectedList.cheque_number,
              selectedPaStatus: selectedList.status,
            },
        () => {
          this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };
  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].props?.title
            ? it[key].props?.title.toString().length
            : it[key].props?.children
            ? it[key].props?.children.toString().length
            : it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  render() {
    const {
      ChequeNumber,
      addPaymentAdvice,
      paymentVouchersList,
      added,
      success,
      error,
      errorMsg,
      loading,
      loadingDialog,
      paymentAdvices,
      selectedList,
      header,
      paymentAdvicesNewARr,
      apiHeader,
      pAStatusArr,
      selectedPaStatus,
      pvArr,
      transporter,
      selectedId,
    } = this.state;
    const { Name } = this.props;
    return (
      <div
        className="height-100 width-100"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite ">
          {" "}
          {loadingDialog ? (
            <Backdrop style={{ zIndex: 1, color: "#fff" }} open={loadingDialog}>
              <StyledCircularProgress color="inherit" />
            </Backdrop>
          ) : (
            ""
          )}
          {error || success ? (
            <SnackbarComponent
              error={error}
              success={success}
              snackbarClose={() => this.setState({ error: "", success: "" })}
            />
          ) : (
            ""
          )}
          {this.state.downloadPdf !== false && (
            <PaymentAdvicePdf
              onClose={() => {
                this.setState({ downloadPdf: false });
              }}
              {...this.state}
              {...this.props}
              tableList={this.state.downloadPdf?.payment_vouchers_detail?.map((sT, i) => {
                const newOjT = {};
                newOjT["SR.NO."] = i + 1 > 9 ? i + 1 : `0${i + 1}`;
                newOjT["BENEFICIARY NAME"] = sT.transporter_details?.name ? sT.transporter_details?.name : "-";
                newOjT["ACCOUNT NO"] = sT.transporter_details?.bank_acc_no ? sT.transporter_details?.bank_acc_no : "-";
                newOjT["BANK NAME"] = sT.transporter_details?.bank_name ? sT.transporter_details?.bank_name : "-";
                newOjT.BRANCH = sT.transporter_details?.bank_branch ? sT.transporter_details?.bank_branch : "-";
                newOjT["IFSC CODE"] = sT.transporter_details?.ifsc ? sT.transporter_details?.ifsc : "-";
                newOjT.AMT = Number(sT.sub_total ? sT.sub_total : 0).toFixed(2);
                newOjT.UTR = "-";
                return newOjT;
              })}
              excelHeader={[
                "SR.NO.",
                "BENEFICIARY NAME",
                "ACCOUNT NO",
                "BANK NAME",
                "BRANCH",
                "IFSC CODE",
                "AMT",
                "UTR",
              ]}
              sPA={{
                date: moment(this.state.downloadPdf?.date ? this.state.downloadPdf?.date : new Date()).format(
                  "DD.MM.YYYY"
                ),
                cheque_number: this.state.downloadPdf?.cheque_number,
                total_amount: this.state.downloadPdf?.payment_vouchers_detail?.reduce(
                  (previousValue, currentValue) =>
                    Number(previousValue) + Number(currentValue?.sub_total ? currentValue.sub_total : 0),
                  0
                ),
              }}
            />
          )}
          <TableComponent
            descending={true}
            module={"Payment Advice"}
            loadingTitle={"paymentAdvices"}
            setSelectedListVal={(selectedList) => {
              this.setState({
                pvArr: selectedList.payment_voucher_numbers,
                selectedList: selectedList,
                ChequeNumber: selectedList.cheque_number,
                selectedPaStatus: selectedList.status,
              });
            }}
            onEdit={() => {
              this.setState({
                addPaymentAdvice: true,
              });
            }}
            header={header}
            apiHeader={apiHeader}
            onAdd={() => {
              this.setState({
                addPaymentAdvice: true,
                pvArr: [],
                selectedList: false,
                ChequeNumber: "",
                selectedPaStatus: "unpaid",
              });
            }}
            {...this.state}
            {...this.props}
            tableList={paymentAdvicesNewARr

              .map((sL) => {
                sL.total_amount = Number(sL.total_amount ? sL.total_amount : 0).toFixed(2);
                sL.XLSX = (
                  <div
                    onClick={(e) => {
                      this.setState({ loadingDialog: true });

                      const apiData = [];
                      sL.payment_vouchers_detail.map((sT, i) => {
                        const newOjT = {};
                        newOjT.SNo = i + 1 > 9 ? i + 1 : `0${i + 1}`;
                        newOjT.BENEFICIARY_name = sT.transporter_details?.name ? sT.transporter_details?.name : "-";
                        newOjT.ACCOUNT_NO = sT.transporter_details?.bank_acc_no
                          ? sT.transporter_details?.bank_acc_no
                          : "-";
                        newOjT.BANK_NAME = sT.transporter_details?.bank_name ? sT.transporter_details?.bank_name : "-";
                        newOjT.BRANCH = sT.transporter_details?.bank_branch ? sT.transporter_details?.bank_branch : "-";
                        newOjT.IFSC = sT.transporter_details?.ifsc ? sT.transporter_details?.ifsc : "-";
                        newOjT.AMT = Number(Number(sT.sub_total ? sT.sub_total : 0).toFixed(2));
                        newOjT.UTR = "-";
                        return apiData.push(newOjT);
                      });
                      const excelHeader = [
                        "SR.NO.",
                        "BENEFICIARY NAME",
                        "ACCOUNT NO",
                        "BANK NAME",
                        "BRANCH",
                        "IFSC CODE",
                        "AMT",
                        "UTR",
                      ];
                      const wscols = [];
                      if (sL.payment_vouchers_detail && sL.payment_vouchers_detail.length > 0) {
                        if (sL.payment_vouchers_detail[0] && !Array.isArray(sL.payment_vouchers_detail[0])) {
                          const widthList = {};
                          const array = excelHeader ? excelHeader : Object.keys(sL.payment_vouchers_detail[0]);
                          array.map((single, i) => {
                            return (widthList[excelHeader[i]] = `${Math.floor(
                              3 *
                                (single && single.toString().length > this.longest(single, sL.payment_vouchers_detail)
                                  ? single.toString().length
                                  : this.longest(single, sL.payment_vouchers_detail)
                                  ? this.longest(single, sL.payment_vouchers_detail)
                                  : 1)
                            )}`);
                          });

                          excelHeader.map((sH, i) => {
                            if (i + 1 === excelHeader.length - 1 || i + 1 === excelHeader.length) {
                              if (
                                moment(new Date().toISOString())
                                  .format("Do MMM, ddd, YYYY")
                                  .toString().length > widthList[sH]
                              ) {
                                wscols.push({
                                  wch: moment(new Date().toISOString())
                                    .format("Do MMM, ddd, YYYY")
                                    .toString().length,
                                });
                              } else {
                                wscols.push({ wch: widthList[sH] });
                              }
                            } else {
                              wscols.push({ wch: widthList[sH] });
                            }
                          });
                        }
                      }
                      const newOjQ = {};
                      newOjQ.SNo = "";
                      newOjQ.BENEFICIARY_name = "";
                      newOjQ.ACCOUNT_NO = "";
                      newOjQ.BANK_NAME = "";
                      newOjQ.BRANCH = "";
                      newOjQ.IFSC = "";
                      newOjQ.AMT = "";
                      newOjQ.UTR = "";
                      apiData.push(newOjQ);
                      const newOj = {};
                      newOj.SNo = "";
                      newOj.BENEFICIARY_name = "Total";
                      newOj.ACCOUNT_NO = "";
                      newOj.BANK_NAME = "";
                      newOj.BRANCH = "";
                      newOj.IFSC = "";
                      newOj.AMT = Number(
                        Number(
                          sL?.payment_vouchers_detail?.reduce(
                            (previousValue, currentValue) =>
                              Number(previousValue) + Number(currentValue?.sub_total ? currentValue.sub_total : 0),
                            0
                          )
                        ).toFixed(2)
                      );
                      newOj.UTR = "";
                      apiData.push(newOj);
                      this.exportToCSV(
                        sL,
                        `PLIPL-TRANSPORT PAYMENT BANK-${moment(sL.date).format("Do MMM, ddd")}`,
                        apiData,
                        excelHeader,
                        wscols.length > 0
                          ? wscols
                          : [
                              { wch: 20 },
                              { wch: 20 },
                              { wch: 20 },
                              { wch: 20 },
                              { wch: 20 },
                              { wch: 20 },
                              { wch: 20 },
                              { wch: 20 },
                            ]
                      );
                    }}
                    className="cursorP"
                    style={{ color: green }}
                  >
                    Export
                  </div>
                );
                sL.PDF = (
                  <div
                    className="cursorP"
                    style={{ color: green }}
                    onClick={() => {
                      this.setState({ downloadPdf: sL });
                    }}
                  >
                    Print
                  </div>
                );
                sL.Total_Payment_Vouchers =
                  sL.payment_voucher_numbers.length > 9
                    ? sL.payment_voucher_numbers.length
                    : `0${sL.payment_voucher_numbers.length}`;
                return sL;
              })
              .sort((a, b) => {
                var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
                var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
                return new Date(dateA) - new Date(dateB);
              })}
          />
        </Paper>
        {addPaymentAdvice == true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "5px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "517.98px";
                target.style.height = "517.98px";
                target.style.minWidth = "80%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({ addPaymentAdvice: false, success: "" });
              this.handleClearFields();
            }}
            TransitionComponent={Transition}
          >
            <DialogActions
              classname="d-flex justifySB"
              style={{ background: primary, color: "white", padding: "10px 24px" }}
              onClick={() => {
                this.setState({ addPaymentAdvice: false });
                this.handleClearFields();
              }}
            >
              <h3 style={styles.heading}>
                Payment Advice <div style={{ margin: "0 10px 0 0" }} />( Transporter Group :-
                <div style={{ margin: "0 10px 0 0" }} /> {Name} )
              </h3>

              <Tooltip title="close" arrow placement="top">
                <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>{" "}
            <DialogContent className="dashedDividerBottom" style={{ height: "100%" }}>
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  success={success}
                  snackbarClose={() => this.setState({ error: "", success: "" })}
                />
              ) : (
                ""
              )}{" "}
              {loadingDialog ? (
                <Backdrop style={{ zIndex: 1, color: "#fff" }} open={loadingDialog}>
                  <StyledCircularProgress color="inherit" />
                </Backdrop>
              ) : (
                ""
              )}
              {added ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
                  <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                    {added}
                  </span>
                </div>
              ) : (
                <div className="height-100 width-100 d-flex d-flex-column ">
                  {paymentVouchersList &&
                  paymentVouchersList.length > 0 &&
                  ((paymentVouchersList.filter((sP) => !sP.payment_advice) &&
                    paymentVouchersList.filter((sP) => !sP.payment_advice).length > 0) ||
                    selectedList !== false) ? (
                    <>
                      <List className="width-100 overflowYA " style={{ padding: "0", height: "calc(100% - 36.52px" }}>
                        <ListItem
                          style={{ minHeight: "0", maxHeight: "32px" }}
                          key={"head"}
                          dense
                          role={undefined}
                          button={
                            (selectedList !== false && selectedList["status"] !== "paid") || selectedList === false
                          }
                          onClick={() => {
                            if (
                              (selectedList !== false && selectedList["status"] !== "paid") ||
                              selectedList === false
                            ) {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.pvArr = false;
                              if (pvArr.length === paymentVouchersList.length) {
                                this.setState({ pvArr: [], errorMsg });
                              } else {
                                const pvArr1 = [];
                                paymentVouchersList.map((s) => pvArr1.push(s.payment_voucher_number.toString()));
                                if (pvArr1.length === paymentVouchersList.length) {
                                  this.setState({ pvArr: pvArr1, errorMsg });
                                }
                              }
                            } else {
                              console.log(selectedList !== false && selectedList["status"] !== "paid", "can;t click");
                            }
                          }}
                        >
                          <ListItemIcon>Sr.No.</ListItemIcon>
                          <ListItemText id={"head"} primary={"Select All"} />{" "}
                          <ListItemIcon>
                            {errorMsg.pvArr !== false ? (
                              <CssCheckbox
                                edge="start"
                                checked={pvArr.length === paymentVouchersList.length}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": "head" }}
                              />
                            ) : (
                              <Checkbox
                                edge="start"
                                checked={pvArr.length === paymentVouchersList.length}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": "head" }}
                              />
                            )}
                          </ListItemIcon>
                        </ListItem>
                        {paymentVouchersList && paymentVouchersList.length > 0 ? (
                          (paymentVouchersList.filter((sP) => !sP.payment_advice) &&
                            paymentVouchersList.filter((sP) => !sP.payment_advice).length > 0) ||
                          selectedList !== false ? (
                            paymentVouchersList
                              .sort((a, b) => {
                                var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
                                var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
                                return new Date(dateA) - new Date(dateB);
                              })
                              .map((value, i) => {
                                const labelId = `checkbox-list-label-${value.payment_voucher_number}`;
                                return (
                                  (!value.payment_advice || selectedList !== false) && (
                                    <ListItem
                                      key={value.payment_voucher_number}
                                      role={undefined}
                                      dense
                                      button={
                                        (selectedList !== false && selectedList["status"] !== "paid") ||
                                        selectedList === false
                                      }
                                      onClick={(e) => {
                                        if (
                                          (selectedList !== false && selectedList["status"] !== "paid") ||
                                          selectedList === false
                                        ) {
                                          e.preventDefault();
                                          this.handleToggle(value.payment_voucher_number);
                                        } else {
                                        }
                                      }}
                                    >
                                      <ListItemIcon>{i + 1 < 10 ? `0${i + 1}` : i + 1}</ListItemIcon>

                                      <ListItemText
                                        id={labelId}
                                        primary={`${
                                          value.payment_voucher_number ? value.payment_voucher_number : ""
                                        } - ${value.date ? moment(value.date).format("Do MMM, ddd") : ""} -  ${
                                          value.sub_total ? Number(value.sub_total).toFixed(2) : 0
                                        }`}
                                      />
                                      <ListItemIcon>
                                        {console.log(errorMsg, "errorMsg")}
                                        {errorMsg.pvArr !== false ? (
                                          <CssCheckbox
                                            edge="start"
                                            checked={pvArr.indexOf(value.payment_voucher_number.toString()) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": labelId }}
                                          />
                                        ) : (
                                          <Checkbox
                                            edge="start"
                                            checked={pvArr.indexOf(value.payment_voucher_number.toString()) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{ "aria-labelledby": labelId }}
                                          />
                                        )}
                                      </ListItemIcon>
                                    </ListItem>
                                  )
                                );
                              })
                          ) : (
                            <div className="height-100 width-100 justifyC alignC d-flex">
                              No Pending Vouchers Available!
                            </div>
                          )
                        ) : (
                          <div className="height-100 width-100 justifyC alignC d-flex">
                            No Pending Vouchers Available!
                          </div>
                        )}
                      </List>

                      <div className="width-100 d-flex justifyFE marginT20">
                        {" "}
                        <ListItem key={"head"} role={undefined} dense className="width-100 d-flex justifyFE">
                          <ListItemIcon>{`Total Amount :- ${pvArr
                            .reduce(
                              (previousValue, currentValue) =>
                                Number(previousValue) +
                                Number(
                                  paymentVouchersList.filter((sPv) => sPv.payment_voucher_number === currentValue)[0]
                                    ?.sub_total
                                    ? paymentVouchersList.filter(
                                        (sPv) => sPv.payment_voucher_number === currentValue
                                      )[0]?.sub_total
                                    : 0
                                ),
                              0
                            )
                            .toFixed(2)}   Total Selected :- ${pvArr.length}`}</ListItemIcon>
                        </ListItem>{" "}
                        <CssTextField10
                          autoComplete="off"
                          type="number"
                          InputProps={{
                            inputProps: {
                              min: 1,
                            },
                          }}
                          required={true}
                          onKeyDown={(evt) =>
                            (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") &&
                            evt.preventDefault()
                          }
                          style={
                            { width: "calc(50% - 15px)", margin: "0 20px 0 0" } //disabled={tp}
                          }
                          id="outlined-basic"
                          label="Cheque Number"
                          name="ChequeNumber"
                          variant="outlined"
                          autoFocus={true}
                          value={ChequeNumber}
                          error={errorMsg.ChequeNumber ? true : false}
                          helperText={errorMsg.ChequeNumber ? errorMsg.ChequeNumber : ""}
                          onChange={this.handleChange}
                        />{" "}
                        {selectedList !== false && (
                          <CssTextField10
                            required={true}
                            autoComplete="off"
                            value={
                              selectedPaStatus ? selectedPaStatus : "" //
                            }
                            onChange={(e) => {
                              this.setState({ selectedPaStatus: e.target.value });
                            }}
                            style={
                              {
                                width: "250px",
                                margin: "0",
                                textTransform: "capitalize",
                              } //disabled={tp}
                            }
                            id="outlined-select-currency-native"
                            select
                            label="Status"
                            variant="outlined"
                            InputLabelProps={{}}
                          >
                            {pAStatusArr.map((option) => (
                              <MenuItem key={option} value={option} id={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </CssTextField10>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="height-100 width-100 justifyC alignC d-flex">No Pending Vouchers Available!</div>
                  )}
                </div>
              )}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {paymentVouchersList &&
              paymentVouchersList.length > 0 &&
              ((paymentVouchersList.filter((sP) => !sP.payment_advice) &&
                paymentVouchersList.filter((sP) => !sP.payment_advice).length > 0) ||
                selectedList !== false) ? (
                added ? (
                  <StyledButton
                    onClick={() => {
                      this.handleClearFields();
                    }}
                    name={"Add Another"}
                    withBg={true}
                    background={primary}
                    width="120px"
                    margin="0 10px 0 0"
                  />
                ) : (
                  <>
                    <StyledButton
                      onClick={() => {
                        this.reset();
                      }}
                      name={"Reset"}
                      width="60px"
                      margin="0 10px 0 0"
                    />
                    <StyledButton
                      onClick={() => {
                        if (ChequeNumber) {
                          if (pvArr.length > 0 || (selectedList !== false && selectedList["status"] === "paid")) {
                            this.setState({ loadingDialog: true });
                            const data =
                              selectedList !== false && selectedList["status"] === "paid"
                                ? { cheque_number: ChequeNumber, status: selectedPaStatus }
                                : {
                                    cheque_number: ChequeNumber,
                                    transporter_group: selectedId,
                                    date: moment(new Date()).format("YYYY-MM-DD"),
                                    payment_voucher_numbers: pvArr,
                                    status: selectedPaStatus,
                                    total_amount: pvArr.reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) +
                                        Number(
                                          paymentVouchersList.filter(
                                            (sPv) => sPv.payment_voucher_number === currentValue
                                          )[0]?.sub_total
                                            ? paymentVouchersList.filter(
                                                (sPv) => sPv.payment_voucher_number === currentValue
                                              )[0]?.sub_total
                                            : 0
                                        ),
                                      0
                                    ),
                                  };
                            this.props
                              .postPaymentAdvices(
                                data,
                                this.props.user.user.uid,
                                selectedList !== false ? selectedList.id : ""
                              )
                              .then((res) => {
                                if (res.error) {
                                  this.setState({
                                    error: res.data,
                                    loadingDialog: false,
                                  });
                                } else {
                                  this.props
                                    .fetchPaymentAdvices(this.props.selectedId, this.props.user.user.uid)
                                    .then(() => {
                                      this.setState({
                                        added:
                                          selectedList !== false
                                            ? "Payment Advice Edited!"
                                            : "New Payment Advice Added",
                                        loadingDialog: false,
                                      });
                                    });
                                }
                              });
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.pvArr = "Please select atleast one Payment Voucher !";
                            this.setState({ errorMsg, error: "Please select atleast one Payment Voucher !" });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.ChequeNumber = "Please add cheque Number !";
                          this.setState({ errorMsg });
                        }
                      }}
                      name={selectedList !== false ? "Edit" : "Add"}
                      withBg={true}
                      background={"green"}
                      width="120px"
                    />
                  </>
                )
              ) : (
                ""
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
      </div>
    );
  }
}
