import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Vehicles from "../components/Vehicles";
import {
  fetchVehicles,
  vehicleAdd,
  vehicleEdit,
  fetchTransportersGroups, fetchTransporters
} from "../redux/index";
class VehiclesConatiner extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [], transpoterslist: [] };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ vehicles: next.vehicles });
  }

  fetchUsers = () => {
    console.log(
      "this.props.user.uidaaaaaaaaaaaaaaaa",
      this.props.user.user.uid
    );
    if (this.props.user.user.uid) {
      this.props.fetchVehicles(this.props.user.user.uid).then((res) => {
        if (res) {
          this.setState({ vehicles: res });
        }
      });
      this.props
        .fetchTransportersGroups(this.props.user.user.uid)
        .then((res) => {
          if (res) {
            this.props
              .fetchTransporters(this.props.user.user.uid)
              .then((res2) => {
                if (res2) {
                  this.setState({
                    transpoterslist: res2,
                    transpotersGroupslis: res,
                  });
                }
              })
          }

        });
    }
  };
  render() {
    return (
      <Route
        exact
        path={["/vehicles", "/vehicles/:tab/:id", "/vehicles/new"]}
        render={(props) => <Vehicles {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  vehicles: state.masters.vehicles,
  transpoterslist: state.masters.transporter,

  transpotersGroupslist: state.masters.transpoterslist,
});
const mapDispatchToProps = {
  fetchVehicles,
  vehicleAdd,
  vehicleEdit,
  fetchTransportersGroups, fetchTransporters
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VehiclesConatiner);
