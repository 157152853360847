let projectServiceBaseURL;

// if (process.env.REACT_APP_ENV === "production") {
//   projectServiceBaseURL = "https://103.71.21.114"; //103.71.21.114
// } else {
//   projectServiceBaseURL = "https://app.pitambarainfra.com"; //"https://pitambara2.herokuapp.com";
// }
if (process.env.REACT_APP_ENV === "production") {
  projectServiceBaseURL = "https://app.pitambarainfra.com"; //103.71.21.114
} else {
  projectServiceBaseURL = "https://app.pitambarainfra.com"; //"https://pitambara2.herokuapp.com";
}

export const loginUrl = projectServiceBaseURL + "/api/auth/";
export const tripEdit = (id) => projectServiceBaseURL + "/api/dispatch/trips/update/" + id;
export const tripsURL = (type) => projectServiceBaseURL + "/api/dispatch/trips/" + type;
export const fetchMatersURL = (type) => projectServiceBaseURL + `/api/masters/${type}/list/`;
export const fetchMatersUrlPayment = (type) => projectServiceBaseURL + "/api/masters/" + type + "/";

export const dateEdit = (type) => projectServiceBaseURL + `/api/masters/date/update/${type}`;
export const verifyotp = projectServiceBaseURL + "/api/auth/user-auth/verify-otp/";
export const fetchuserCountURL = (roleId, userId) => {
  return projectServiceBaseURL + "users/count/" + `${roleId}/${userId}`;
};
export const fetchConsigneeGradeUsingTransitPass = () => {
  return projectServiceBaseURL + "/api/masters/get/consignees-and-grades/";
};
// export const transpoterEditURL = (editnameId) => {
//   return projectServiceBaseURL + "/api/masters/transporter/" + `${editnameId}/`;
// };
// export const destinationEditURL = (editnameId) => {
//   return (
//     projectServiceBaseURL + "/api/masters/destinations/" + `${editnameId}/`
//   );
// };
// export const consignorEditURL = (editnameId) => {
//   return projectServiceBaseURL + "/api/masters/consignors/" + `${editnameId}/`;
// };
// export const consigneesEditURL = (editnameId) => {
//   return projectServiceBaseURL + "/api/masters/consignees/" + `${editnameId}/`;
// };
// export const sourceCitiesEditURL = (editnameId) => {
//   return projectServiceBaseURL + "/api/masters/sources/" + `${editnameId}/`;
// };
// export const vehiclesEditURL = (editnameId) => {
//   return projectServiceBaseURL + "/api/masters/vehicles/" + `${editnameId}/`;
// };

export const EditMatersURL = (type, id) => projectServiceBaseURL + `/api/masters/${type}/${id}/`;
export const DispatchURL = (type, id) => projectServiceBaseURL + `/api/dispatch/${type}/${id}/`;

export const SalesOrderURL = (type, id) =>
  id
    ? projectServiceBaseURL + `/api/sales-and-payments/${type}/${id}`
    : projectServiceBaseURL + `/api/sales-and-payments/${type}`;
export const SalesOrderEDitURL = (type, id) => projectServiceBaseURL + `/api/sales-and-payments/${type}/${id}`;

export const rateMatrixesURL = (type, id) => projectServiceBaseURL + `/api/masters/${type}/`;
