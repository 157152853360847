import React, { Component } from "react";
import { primary } from "../styles/colors";
import { List, ListItem, withStyles } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Reload } from "../styles/Icons";
import { addListener, launch } from "devtools-detector";

import { withRouter } from "react-router-dom";
const CssListItem = withStyles({
  root: {
    "&.MuiMenuItem-root": { borderRadius: "10px" },
    "&:hover": {
      backgroundColor: "RGBA(30,50,250,0.1)",
      borderRadius: "10px",
    },
  },
})(ListItem);
const styles = {
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10",
  },
};
class ContextMenu extends Component {
  state = {
    xPos: "0px",
    yPos: "0px",
    showMenu: false,
    left: false,
    top: false,
  };
  UNSAFE_componentWillMount() {
    // document.addEventListener("click", this.handleClick);
    document.onkeydown = function(e) {
      if (e.keyCode === 123) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "S".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "H".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "A".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "F".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
        return false;
      }
    };
  }
  windowReopen = () => {
    // window.open("https://app.pitambarainfra.com/", "_blank");
    // window.setTimeout(function () {
    //   window.open("http://www.google.com", "_self");
    // }, 1000);
  };
  componentDidMount() {
    addListener((isOpen) => (isOpen ? this.windowReopen() : ""));
    launch();
    document.addEventListener("contextmenu", this.handleContextMenu);
    // document.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    // document.removeEventListener("click", this.handleClick);
    document.removeEventListener("contextmenu", this.handleContextMenu);
    document.onkeydown = function(e) {
      if (e.keyCode === 123) {
      }
      if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.keyCode === "S".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.keyCode === "H".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.keyCode === "A".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.keyCode === "F".charCodeAt(0)) {
      }
      if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
      }
    };
  }

  // handleClick = () => {
  //   this.setState({ showMenu: false });
  // };

  handleContextMenu = (e) => {
    e.preventDefault();
    if (e.pageY > parseInt(window.innerHeight - 40)) {
      this.setState({
        yPos: `${parseInt(window.innerHeight - e.pageY)}px`,
        showMenu: true,
        top: true,
      });
    } else {
      this.setState({
        yPos: `${e.pageY}px`,
        showMenu: true,
        top: false,
      });
    }
    if (e.pageX > parseInt(window.innerWidth - 150)) {
      this.setState({
        xPos: `${parseInt(window.innerWidth - e.pageX)}px`,
        showMenu: true,
        left: true,
      });
    } else {
      this.setState({
        xPos: `${e.pageX}px`,
        showMenu: true,
        left: false,
      });
    }
  };

  render() {
    const { showMenu, xPos, yPos, left, top } = this.state;

    if (showMenu)
      return (
        <div>
          <ClickAwayListener
            onClickAway={() => {
              if (showMenu) {
                this.setState({ showMenu: false });
              }
            }}
          >
            <List
              style={{
                top: !top && yPos,
                left: !left && xPos,
                right: left && xPos,
                bottom: top && yPos,
                position: "absolute",
                zIndex: "10005",
                padding: "0",
                borderRadius: "10px",
                width: "150px",
                backgroundColor: "white",
              }}
            >
              <CssListItem
                // button
                onClick={(e) => {
                  window.location.reload();
                }}
                style={styles.listItemsStyle}
              >
                <div
                  style={{
                    width: "30px",
                    height: "24px",
                    margin: "0 10px 0 0",
                  }}
                >
                  <Reload color={primary} />
                </div>{" "}
                <p
                  style={{
                    margin: "0",
                    fontSize: "0.9rem",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    color: primary,
                  }}
                >
                  Refresh
                </p>
              </CssListItem>
            </List>
          </ClickAwayListener>
        </div>
      );
    else return null;
  }
}

export default withRouter(ContextMenu);
