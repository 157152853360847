import { Divider } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
const styles = {
  mainContainer: (fullwidth) => ({
    border: `1px solid ${"rgba(0, 0, 0, 1)"}`,
    margin: "40px",
    minHeight: "calc(100% - 84px)",
    minWidth: fullwidth ? `calc(89px + ${fullwidth}px)` : "calc(100% - 82px)",
    width: fullwidth ? `calc(89px + ${fullwidth}px)` : "calc(100% - 82px)",
    height: "calc(100% - 84px)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  }),

  djac: { display: "flex", justifyContent: "center", alignItems: "center" },
};

function PaymentAdvicePdf(props) {
  const contentArea = useRef(null);
  const [widthList, setWidthList] = useState({});

  const longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].props?.title
            ? it[key].props?.title.toString().length
            : it[key].props?.children
            ? it[key].props?.children.toString().length
            : it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  const handleExportWithFunction = useReactToPrint({
    content: () => contentArea.current,
    onAfterPrint: () => props.onClose(),
  });
  useEffect(
    () => {
      const { sPA } = props;
      if (props.tableList && props.tableList.length > 0) {
        if (props.tableList[0] && !Array.isArray(props.tableList[0])) {
          const widthList = {};
          const array = props.excelHeader ? props.excelHeader : Object.keys(props.tableList[0]);
          array.map((single, i) => {
            return (widthList[props.excelHeader[i]] = Number(
              Math.floor(
                10 *
                  ((single.props?.title
                    ? single.props?.title.toString().length
                    : single.props?.children
                    ? single.props.children.toString().length
                    : single.toString().length) > longest(single, props.tableList)
                    ? single.props?.title
                      ? single.props?.title.toString().length
                      : single.props?.children
                      ? single.props?.children?.toString().length
                      : single.toString().length
                    : longest(single, props.tableList)
                    ? longest(single, props.tableList)
                    : 1)
              )
            ));
          });
          const wscols = [];
          props.excelHeader.map((sH, i) => {
            if (i === 0) {
              wscols.push("43px");
            } else {
              if (i + 1 === props.excelHeader.length - 1 || i + 1 === props.excelHeader.length) {
                if (
                  moment(new Date().toISOString())
                    .format("Do MMM, ddd, YYYY")
                    .toString().length > widthList[sH]
                ) {
                  if (
                    i === 6 &&
                    sPA &&
                    Number(Math.floor(10 * sPA.total_amount.toString().length)) >
                      moment(new Date().toISOString())
                        .format("Do MMM, ddd, YYYY")
                        .toString().length
                  ) {
                    wscols.push(`${Number(Math.floor(10 * sPA.total_amount.toString().length))}px`);
                  } else {
                    wscols.push(
                      `${
                        moment(new Date().toISOString())
                          .format("Do MMM, ddd, YYYY")
                          .toString().length
                      }px`
                    );
                  }
                } else {
                  if (i === 6 && Number(Math.floor(10 * sPA.total_amount.toString().length)) > widthList[sH]) {
                    wscols.push(`${Number(Math.floor(10 * sPA.total_amount.toString().length))}px`);
                  } else {
                    wscols.push(`${widthList[sH]}px`);
                  }
                }
              } else {
                wscols.push(`${widthList[sH]}px`);
              }
            }
          });
          console.log(wscols, "wscols");
          setWidthList(wscols);
        }
      } else {
      }
    },
    [props.downloadPdf]
  );
  useEffect(
    () => {
      if (contentArea && widthList && widthList.length > 0) {
        handleExportWithFunction();
      }
    },
    [widthList]
  );
  const HDivider = <Divider style={{ minWidth: "100%", width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />;
  const VDivider = (
    <Divider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)", minHeight: "29.19px" }} />
  );
  const VDividerW = (
    <Divider variant="vertical" style={{ height: "100%", backgroundColor: "transparent", minHeight: "29.19px" }} />
  );
  const { user, sPA, excelHeader } = props;

  if (widthList && widthList.length > 0) {
    var right3W = Math.floor(
      Number(widthList[5].split("px")[0]) +
        Number(widthList[6].split("px")[0]) +
        Number(widthList[7].split("px")[0]) +
        31
    );
  }
  return (
    <>
      <div style={{ height: "0", width: "0", overflow: "hidden" }}>
        <div
          ref={contentArea}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
            height: "fit-content",
            fontSize: "0.8rem",
            textAlign: "left",
            minWidth: "100%",
            width: "100%",
            textTransform: "capitalize",
          }}
        >
          <div
            style={styles.mainContainer(
              widthList &&
                widthList.length > 0 &&
                widthList.reduce(
                  (previousValue, currentValue) => Number(previousValue) + Number(currentValue.split("px")[0]),
                  0
                )
            )}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                padding: "5px 0",
              }}
            >
              {user.companyName}
            </div>
            {HDivider}{" "}
            <div
              style={Object.assign(
                {
                  width: "100%",
                  fontWeight: "bold",
                },
                styles.djac
              )}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `calc(100% - 1px - ${right3W}px)`,
                  minWidth: `calc(100% - 1px - ${right3W}px)`,
                  maxWidth: `calc(100% - 1px - ${right3W}px)`,
                }}
              >
                {user.bankName}
              </div>{" "}
              {VDivider}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${right3W}px`,
                  minWidth: `${right3W}px`,
                  maxWidth: `${right3W}px`,
                }}
              >
                Party Payment
              </div>
            </div>
            {HDivider}{" "}
            <div
              style={Object.assign(
                {
                  width: "100%",
                  fontWeight: "bold",
                },
                styles.djac
              )}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `calc(100% - 1px - ${right3W}px)`,
                  minWidth: `calc(100% - 1px - ${right3W}px)`,
                }}
              >
                {` Account No. ${user.accountNo}`}
              </div>{" "}
              {VDivider}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${right3W}px`,
                  minWidth: `${right3W}px`,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: widthList[5],
                    minWidth: widthList[5],
                    margin: "0 5px",
                  }}
                >
                  Date
                </div>{" "}
                {VDivider}{" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: `calc(21px + ${widthList[6]}  + ${widthList[7]})`,
                    minWidth: `calc(21px + ${widthList[6]}  + ${widthList[7]})`,
                  }}
                >
                  {sPA.date}
                </div>
              </div>
            </div>
            {HDivider}{" "}
            <div
              style={Object.assign(
                {
                  width: "100%",
                  fontWeight: "bold",
                },
                styles.djac
              )}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `calc(100% - 1px - ${right3W}px)`,
                  minWidth: `calc(100% - 1px - ${right3W}px)`,
                }}
              />{" "}
              {VDivider}
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: `${right3W}px` }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 5px",
                    width: widthList[5],
                    minWidth: widthList[5],
                  }}
                >
                  CHEQUE NO.
                </div>{" "}
                {VDivider}{" "}
                <div
                  style={Object.assign(
                    {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: `calc(21px + ${widthList[6]}  + ${widthList[7]})`,
                      minWidth: `calc(21px + ${widthList[6]}  + ${widthList[7]})`,
                    },
                    styles.widthWithDivider
                  )}
                >
                  {sPA.cheque_number}
                </div>
              </div>
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    {" "}
                    <div
                      style={Object.assign(
                        {
                          margin: "0 5px",
                          width: widthList[i] ? widthList[i] : "fit-content",
                          minWidth: widthList[i] ? widthList[i] : "fit-content",
                        },
                        styles.djac
                      )}
                    >
                      {sH}
                    </div>
                    {i + 1 !== excelHeader.length && VDivider}
                  </>
                ))}
            </div>
            {HDivider}{" "}
            {props.tableList &&
              props.tableList.map((sT) => (
                <>
                  <div
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {excelHeader &&
                      excelHeader.map((sH, i) => (
                        <>
                          {" "}
                          <div
                            style={Object.assign(
                              {
                                margin: "0 5px",
                                width: widthList[i] ? widthList[i] : "fit-content",
                                minWidth: widthList[i] ? widthList[i] : "fit-content",
                              },
                              styles.djac
                            )}
                          >
                            {sT[sH]}
                          </div>
                          {i + 1 !== excelHeader.length && VDivider}
                        </>
                      ))}
                  </div>
                  {HDivider}
                </>
              ))}
            <div
              style={{
                minWidth: "100%",
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "relative",
                background: "rgba(158, 158, 158, 0.48)",
              }}
            >
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    {" "}
                    <div
                      style={Object.assign(
                        {
                          margin: "0 5px",
                          minWidth: widthList[i] ? widthList[i] : "fit-content",
                        },
                        styles.djac
                      )}
                    >
                      {sH === "SR.NO." ? "" : sH === "AMT" ? Number(sPA.total_amount).toFixed(2) : ""}
                    </div>
                    {i + 1 === excelHeader.length
                      ? ""
                      : sH === "SR.NO." || sH === "IFSC CODE" || sH === "AMT"
                      ? VDivider
                      : VDividerW}
                  </>
                ))}
              <div
                style={{
                  position: "absolute",
                  left: `calc(${widthList[0]} + 11px)`,
                  minWidth: `calc(${widthList[1]} + 11px + ${widthList[2]} + 11px + ${widthList[3]} + 11px + ${
                    widthList[4]
                  } + 11px + ${widthList[5]} + 11px)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Total
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentAdvicePdf;
