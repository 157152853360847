const initialState = {
  loading: true,
  user: null,
  version: "1.6.0",
  loginError: false,
  companyName: "PITAMBARA LOGISTICS & INFRASTRUCTURE PRIVATE LIMITED",
  bankName: "KOTAK MAHINDRA BANK",
  accountNo: "8612420353",
  address: "Shop No. 304, 3rd Floor, Gore Parisar, Civil Line, Raipur-492001(C.G)",
};

const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_USER_REQUEST":
      return { user: null, loading: true, version: state.version, ...state };
    case "AUTH_USER_LOGGEDIN":
      state.user = action.payload;
      state.loginError = false;
      state.loading = false;
      state.version = state.version;
      return { ...state };
    case "AUTH_USER_LOGGEDIN_ERROR":
      state.loginError = action.payload;
      state.loading = false;
      state.version = state.version;
      return { ...state };
    default:
      return { ...state };
  }
};

export default authenticationReducer;
