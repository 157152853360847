import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white } from "../styles/colors";

import Button from "@material-ui/core/Button";
import { Paper, Dialog, Tooltip, Slide } from "@material-ui/core";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import NoData from "../styles/Illustrations/NoData";
import Toggle from "./Toggle";
import SnackbarComponent from "./SnackbarComponent";
import ReactDOM from "react-dom";
import EditIcon from "@material-ui/icons/Create";
import Moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  contentStyle: {
    display: "flex",
    marginBottom: "10px",
    color: "black",
    fontSize: "1rem",
  },
  withDivider50: { width: "calc(50% - 5px)" },
  withDivider25: { width: "25%" },
};

const templateTabs = [
  {
    label: "TranspotersGroup",
    tooltip: "TranspotersGroup list",
    data: [],
    route: "/transpotersgroup/Transporter",
    primaryField: "name",
    primarySecondValue: "date2",
    primaryFieldFallback: "phone", // Optional
    secondaryField: "is_active",
    avatarField: "src",
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class TransportersGroup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabs: templateTabs,
      singletranspoterslist: null,
      SalesOrder: "",
      transpoterslist: [],
      openTranspoter: false,
      colorArr: [white, lightGrey],
      Name: "",
      Status: false,
      addNew: false,
      success: "",
      error: "",
      editTranspoters: false,
      openMessage: false,
      errorMsgName: false,
    }; //cardColor2, cardColor3, cardColor4],
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Transporter" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singletranspoterslist: this.state.tabs[0].data[next.match.params["id"]],
            Name: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].name
              : "",

            Status: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].is_active
              : false,
            addNew: false,
          },
          () => {
            console.log(this.state.singletranspoterslist, "singletranspoterslist");
          }
        );
      } else if (tab === "new") {
        this.setState({
          singletranspoterslist: null,
          Name: "",
          addNew: true,
          Status: true,
        });
      }
    } else this.setState({ singletranspoterslist: null });
  }

  listClickHandler = (value) => {
    console.log("click", "annuu11");
    this.setState({
      openTranspoter: true,
      addNew: false,
      Name: value.name,
      Status: value.is_active,
      createdAt: value.created_at,
      openMessage: false,
    },);
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.transpoterslist && props.transpoterslist.length > 0 ? props.transpoterslist : [];
      return tab;
    });
    console.log(tabs, "tabs");
    this.setState({ transpoterslist: props.transpoterslist, tabs });
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMsgName: false,
    });
  };
  handleClearFields = () => {
    this.setState({ Name: "" });
  };
  render() {
    const {
      tabs,
      transpoterslist,
      singletranspoterslist,
      Name,
      addNew,
      Status,
      success,
      error,
      errorMsgName,
      createdAt,
      name,
    } = this.state;
    return (
      <Layout
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          { data: name, hintText: "Search Name", labelField: "name" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={
          this.listClickHandler // }} //   this.props.history.push("/dashboard/new"); // fabClickHandler={() => {
        }
        fabClickHandler={() => {
          this.props.history.push("/transpotersgroup/new");
          this.setState({
            addNew: true,
            singletranspoterslist: false,
            Status: true,
            editTranspoters: true,
            Name: "",
          });
          this.handleClearFields();
        }}
      >
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        {this.state.openMessage ? (
          <div
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoData />
          </div>
        ) : (
          ""
        )}
        {this.state.editTranspoters ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "20px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "fit-content";
                target.style.minWidth = "45%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({
                editTranspoters: false,
                openMessage: true,
                errorMsgName: false,
              });
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 30px",
                fontSize: "1.5rem",
              }}
            >
              {" "}
              <span style={{ color: "black" }}>
                {addNew ? " Add TranspotersGroup Detail" : "Edit TranspotersGroup Detail"}
              </span>
              <div>
                <Toggle
                  status={Status}
                  handleChange={() => {
                    this.setState({ Status: !Status });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                fontSize: "1rem",
                color: "grey",
                background: "white",
                margin: "20px 0 20px 30px",
              }}
            >
              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsgName ? true : false}
                helperText={errorMsgName ? "Please Add Name !" : false}
                id="outlined-basic"
                label="Name"
                name="Name"
                variant="outlined"
                value={Name}
                onChange={this.handleChange}
              />{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: "40px 0 40px 30px",
                width: "100%",
              }}
            >
              {console.log(this.state.singltemslist, "nameeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")}
              <Button
                style={{ background: primary, color: "white", width: "200px" }}
                onClick={() => {
                  if (Name) {
                    if (addNew) {
                      const addData = {
                        name: Name,
                        group: 2,
                        is_active: Status,
                      };
                      this.props.addTransportersGroups(addData, this.props.user.user.uid).then(() => {
                        this.props.fetchTransportersGroups(this.props.user.user.uid).then((res) => {
                          if (res) {
                            this.setState(
                              {
                                success: "Added successfully !",
                                openMessage: true,
                                editTranspoters: false,
                                transpoterslist: res,
                              },
                              () => {
                                this.handleClearFields();
                              }
                            );
                          }
                        });
                      });
                    } else {
                      const addData = {
                        name: Name,
                        group: 2,
                        is_active: Status,
                      };
                      this.props
                        .transpoterEdit(addData, this.state.singletranspoterslist.id, this.props.user.user.uid)
                        .then(() => {
                          this.props.fetchTransportersGroups(this.props.user.user.uid).then((res) => {
                            if (res) {
                              this.setState({
                                success: "Edited successfully !",
                                openMessage: false,
                                editTranspoters: false,
                                //   transpoterslist: res,
                              });
                            }
                          });
                          this.handleClearFields();
                        });
                    }
                  } else {
                    this.setState({ errorMsgName: true });
                  }
                }}
              >
                {addNew ? "add " : "edit"}
              </Button>
            </div>
          </Dialog>
        ) : (
          ""
        )}
        {this.state.openMessage == false ? (
          <>
            {(transpoterslist.length > 0 && singletranspoterslist) || addNew ? (
              <Paper style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 100px",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <span style={{ color: "black" }}>TranspotersGroup Detail</span>
                  {/* <div>
                <Toggle
                  status={Status}
                  handleChange={() => {
                    this.setState({ Status: !Status });
                  }}
                />
              </div> */}
                </div>
                <Paper
                  style={{
                    border: "1px solid #8080802b",
                    padding: "20px 0",
                    margin: " 0 100px",
                    borderRadius: "10px",
                    boxShadow: "3px 3px #8080805c",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title="Edit Transpoter"
                      aria-label="add"
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <EditIcon
                        onClick={() => {
                          this.setState({
                            editGrades: true,
                            editTranspoters: true,
                          });
                        }}
                        style={{
                          width: 50,
                          marginRight: "3%",
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0 20px 30px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <div style={{ borderRadius: "10px" }}>
                          <span style={styles.contentStyle}>
                            Name :<span style={{ marginLeft: "10px", color: "grey" }}> {Name}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Status :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {Status ? "Active" : "Inactive"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Created At :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}>
                              {" "}
                              {Moment(createdAt).format("MMM DD YYYY, h:mm a")}
                            </span>
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>{" "}
              </Paper>
            ) : (
              <div
                style={{
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoData />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default withRouter(TransportersGroup);
