import React, { Component } from "react";
import PropTypes from "prop-types";
import { lightGrey, primary } from "../styles/colors";
import { Divider } from "@material-ui/core";
import QRCode from "qrcode.react";
import moment from "moment";
import { ArrowBack } from "@material-ui/icons";
const styles = {
    mainContainer: (fromPreview) => ({
        border: `2px solid ${primary}`,
        margin: fromPreview ? "0" : "40px",
        minHeight: fromPreview ? "100%" : "calc(100% - 84px)",
        width: fromPreview ? "100%" : "calc(100% - 84px)",
        height: fromPreview ? "100%" : "calc(100% - 84px)",
    }),
    widthWithDivider: { width: "calc(50% - 0.5px)" },
    widthWithDivider25: { width: "calc(25% - 0.5px)" },
    widthWithDivider75: { width: "calc(75% - 0.5px)" },
    widthWithDivider40: { width: "calc(40% - 0.5px)" },

    widthWithDivider60: { width: "calc(60% - 0.5px)" },
    widthWithDivider33: { width: "calc(33% - 0.5px)" },
    widthWithDivider67: { width: "calc(67% - 0.5px)" },
    widthWithDivider30: { width: "calc(30% - 0.5px)" },
    widthWithDivider70: { width: "calc(70% - 0.5px)" }
};
class Invoice extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { user, singleTrip, fromPreview } = this.props;
        const { } = this.state;
        return (
            <div
                className="d-flex height-100 d-flex-column"
                style={{
                    fontSize: "0.7rem", textAlign: "left", width: "100%", margin: fromPreview ? "0 0 20px 0" : "0",
                }}
            >   <div className="d-flex d-flex-column postionR" style={styles.mainContainer(fromPreview)}>
                    {fromPreview ? "" : <div className="width-100 justifyC d-flex positionAB alignC" style={{ height: "40px", top: "-41px" }}>Tax Invoice</div>}
                    {fromPreview ? "" : <div className="width-100 justifyC d-flex positionAB alignC" style={{ height: "40px", bottom: "-41px" }}>This is a Computer Generated Invoice</div>}

                    <div className="width-100 d-flex" style={{ minHeight: "300px", height: "300px" }}>

                        <div className="d-flex d-flex-column" style={styles.widthWithDivider40}>
                            <div className="padding5 d-flex d-flex-column" >
                                <span className="fontWeightBold">    Radhika Industrial Corporation</span>
                                Kh.No. 393.8,, India Timber Market ,Bhanpuri
                                Raipur ,Chhattisgarh, 402008.
                                GSTIN:22AAZFR1716R1ZG
                                State Name: Chhattisgarh,Code : 22
                                Contact : 0771-2434811
                                W-Mail : info@ricorporation.com</div>
                            <Divider className="width-100" />
                            <div className="padding5 d-flex d-flex-column" >
                                <span style={{ fontWeight: "bold" }}>   Consignee (Ship to)</span>

                                Sharda Industrial Private Limited
                                Kh.No. 393.8,, India Timber Market ,Bhanpuri
                                Raipur ,Chhattisgarh, 402008.
                                GSTIN:22AAZFR1716R1ZG
                                State Name: Chhattisgarh,Code : 22</div>
                            <Divider className="width-100" />
                            <div className="padding5 d-flex d-flex-column" >
                                <span style={{ fontWeight: "bold" }}>   Buyyer (Bill to)</span>
                                Kh.No. 393.8,, India Timber Market ,Bhanpuri
                                Raipur ,Chhattisgarh, 402008.
                                GSTIN/UIN:22AAZFR1716R1ZG
                                State Name: Chhattisgarh,Code : 22
                                Place of Supply : Chhattisgarh</div>
                        </div>
                        <Divider variant="vertical" className="height-100" />

                        <div className="d-flex d-flex-column" style={styles.widthWithDivider60}>
                            <div className="d-flex width-100">
                                <div style={styles.widthWithDivider} className="d-flex d-flex-column">
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC maxHeight16">Invoice No.</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16">GST/21-22/1367</span>

                                    </div>
                                    <Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100 minHeight16 d-flex alignC  maxHeight16">Delivery Note</span>
                                        <span className="width-100 fontWeightBold minHeight16 d-flex alignC  maxHeight16"></span>

                                    </div>
                                    <Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Dispatche Doc No.</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16"></span>

                                    </div><Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Dispatched Through</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16">Road</span>

                                    </div><Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Bill of Lading/LR-RR No.</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16">PLIP/1366 dt. 8-OCT-21</span>

                                    </div>
                                </div>
                                <Divider variant="vertical" className="height-100" />
                                <div style={styles.widthWithDivider} className="d-flex d-flex-column">
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Dated</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16">8-OCT-21</span>

                                    </div>
                                    <Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100   minHeight16 d-flex alignC  maxHeight16">MOde/Terms of Payment</span>
                                        <span className="width-100  fontWeightBold  minHeight16 d-flex alignC  maxHeight16"></span>

                                    </div>
                                    <Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Delivery Note Date</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16"></span>

                                    </div><Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Destination</span>
                                        <span className="width-100  fontWeightBold minHeight16 d-flex alignC  maxHeight16">GPILSiltara, Raipur</span>

                                    </div><Divider className="width-100" />
                                    <div className="d-flex d-flex-column padding5">
                                        <span className="width-100  minHeight16 d-flex alignC  maxHeight16">Motor Vehicle No</span>
                                        <span className="width-100  fontWeightBold minHeight26 d-flex alignC  maxHeight16"> {singleTrip.VehicleINo}</span>

                                    </div>
                                </div>
                            </div>
                            <Divider className="width-100" />
                            <div className="d-flex width-100 d-flex-column padding5">
                                Terms of Delivery
                                <span className="fontWeightBold">For DElivery</span>
                                <span className="fontWeightBold">From Aridongri Iron Ore Mines</span>
                                <span className="fontWeightBold">To GPIL Siltara, Raipur</span>
                            </div>
                        </div>


                    </div>  <Divider className="width-100" />
                    <div className="d-flex width-100 d-flex-column" style={{ minHeight: "155px", height: "155px" }}>

                        <div className="d-flex width-100 backgroundColorGrey">

                            <div
                                style={{ minWidth: "48.34px", maxWidth: "48.34px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">SL No.</div>

                            <Divider variant="vertical" className="height-100" />
                            <div
                                style={{ minWidth: "calc(100%(100% - 410.99px)", maxWidth: "calc(100%(100% - 410.99px)" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">Description</div>

                            <Divider variant="vertical" className="height-100" />
                            <div
                                style={{ minWidth: "80px", maxWidth: "80px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">HSN/SAC</div>

                            <Divider variant="vertical" className="height-100" />
                            <div style={{ minWidth: "80px", maxWidth: "80px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">Quantity</div>

                            <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "48.33px", maxWidth: "48.33px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">Rate</div>

                            <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "48.33px", maxWidth: "48.33px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">Per</div>

                            <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "100px", maxWidth: "100px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">Amount</div>

                        </div>
                        <Divider className="width-100" />
                        <div style={{ minHeight: "100px" }} className="d-flex width-100 d-flex-column">
                            <div className="d-flex width-100 height-100">

                                <div
                                    style={{ minWidth: "48.34px", maxWidth: "48.34px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">01</div>

                                <Divider variant="vertical" className="height-100" />
                                <div
                                    style={{ minWidth: "calc(100%(100% - 410.99px)", maxWidth: "calc(100%(100% - 410.99px)" }} className="width-100 padding5 minHeight26 d-flex alignC maxHeight26">Iron Ore Fines</div>

                                <Divider variant="vertical" className="height-100" />
                                <div
                                    style={{ minWidth: "80px", maxWidth: "80px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">HSN/SAC</div>

                                <Divider variant="vertical" className="height-100" />
                                <div style={{ minWidth: "80px", maxWidth: "80px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">Quantity</div>

                                <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "48.33px", maxWidth: "48.33px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">{ }</div>

                                <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "48.33px", maxWidth: "48.33px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">MT</div>

                                <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "100px", maxWidth: "100px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26"></div>

                            </div>
                        </div>
                        <Divider className="width-100" />
                        <div className="d-flex width-100 ">

                            <div
                                style={{ minWidth: "48.34px", maxWidth: "48.34px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26"></div>

                            <Divider variant="vertical" className="height-100" />
                            <div
                                style={{ minWidth: "calc(100%(100% - 410.99px)", maxWidth: "calc(100%(100% - 410.99px)" }} className="width-100 padding5 minHeight26 d-flex alignC justifyFE maxHeight26">Total</div>

                            <Divider variant="vertical" className="height-100" />
                            <div
                                style={{ minWidth: "80px", maxWidth: "80px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26"></div>

                            <Divider variant="vertical" className="height-100" />
                            <div style={{ minWidth: "80px", maxWidth: "80px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26"></div>

                            <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "48.33px", maxWidth: "48.33px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26">{ }</div>

                            <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "48.33px", maxWidth: "48.33px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26"></div>

                            <Divider variant="vertical" className="height-100" /> <div style={{ minWidth: "100px", maxWidth: "100px" }} className="width-100 padding5 minHeight26 d-flex alignC justifyC maxHeight26"></div>

                        </div>
                        <Divider className="width-100" />

                    </div>
                    <div className="d-flex d-flex-column width-100">
                        <span className="width-100 padding5 minHeight26 d-flex alignC maxHeight26">Amount Chargeable (in words)</span>
                        <span className="width-100 padding5 fontWeightBold minHeight26 d-flex alignC maxHeight26">Indian Rupees One Lakh Fifty Two THousand Nine Hundred Seventy Only</span>

                    </div>
                    <Divider className="width-100" />
                    <div className="d-flex width-100" style={{ height: "107px", minHeight: "107px" }}>
                        <div className="d-flex d-flex-column width-100"
                            style={styles.widthWithDivider}>
                            <div className="d-flex d-flex-column width-100">
                                <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyFE">Taxable</span>

                                <Divider className="width-100" style={{ color: "white", background: "white" }} /> <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">Value</span>
                            </div>
                            <Divider className="width-100" />
                            <div className="d-flex d-flex-column width-100">
                                <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyFE">2342355.454</span>
                            </div>
                            <Divider className="width-100" />
                            <div className="d-flex d-flex-column width-100">
                                <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">Total :   1,23,333.00</span>
                            </div>
                        </div>
                        <Divider variant="vertical" className="height-100" />
                        <div className="d-flex width-100 height-100"
                            style={styles.widthWithDivider}>
                            <div className="d-flex d-flex-column width-100 height-100"
                                style={styles.widthWithDivider40}>
                                <div className="d-flex d-flex-column width-100 height-100">
                                    <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyC backgroundColorGrey fontWeightBold">Centeral Tax</span>

                                    <Divider className="width-100" />
                                    <div className="d-flex width-100">   <div className="d-flex d-flex-column" style={styles.widthWithDivider}>
                                        <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 
                                        justifyC backgroundColorGrey fontWeightBold">Rate</span>
                                        <Divider className="width-100" />
                                        <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">9%</span>
                                        <Divider className="width-100" />
                                        <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE"></span>


                                    </div>
                                        <Divider variant="vertical" className="height-100" />
                                        <div className="d-flex d-flex-column" style={styles.widthWithDivider}>
                                            <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 
                                        justifyC backgroundColorGrey fontWeightBold">Amount</span>
                                            <Divider className="width-100" />
                                            <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyFE">11,667.24</span>
                                            <Divider className="width-100" />
                                            <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">11,667.24</span>


                                        </div>  </div></div>
                            </div>

                            <Divider variant="vertical" className="height-100" />
                            <div className="d-flex d-flex-column width-100 height-100">
                                <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyC backgroundColorGrey fontWeightBold">State Tax</span>

                                <Divider className="width-100" />
                                <div className="d-flex width-100">   <div className="d-flex d-flex-column" style={styles.widthWithDivider}>
                                    <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 
                                        justifyC backgroundColorGrey fontWeightBold">Rate</span>
                                    <Divider className="width-100" />
                                    <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">9%</span>
                                    <Divider className="width-100" />
                                    <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE"></span>


                                </div>
                                    <Divider variant="vertical" className="height-100" />
                                    <div className="d-flex d-flex-column" style={styles.widthWithDivider}>
                                        <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 
                                        justifyC backgroundColorGrey fontWeightBold">Amount</span>
                                        <Divider className="width-100" />
                                        <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyFE">11,667.24</span>
                                        <Divider className="width-100" />
                                        <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">11,667.24</span>


                                    </div>  </div></div>
                            <Divider variant="vertical" className="height-100" />
                            <div className="d-flex d-flex-column width-100"
                                style={{ width: "calc(10% - 1px)" }}>
                                <div className="d-flex d-flex-column width-100 height-100">
                                    <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyC backgroundColorGrey fontWeightBold"> Total</span>

                                    <Divider className="width-100" />
                                    <div className="d-flex width-100">
                                        <div className="d-flex d-flex-column width-100" >
                                            <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 
                                        justifyC backgroundColorGrey fontWeightBold">Tax Amount</span>
                                            <Divider className="width-100" />
                                            <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26 justifyFE">23,334.48</span>
                                            <Divider className="width-100" />
                                            <span className="width-100 padding3 fontWeightBold minHeight26 d-flex alignC maxHeight26 justifyFE">23,334.48</span>


                                        </div>  </div></div>
                            </div>
                        </div>
                    </div>
                    <Divider className="width-100" />
                    <div className="d-flex width-100">
                        <div className="padding3 minHeight26 maxHeight26 d-flex alignC" style={{ maxWidth: "143px" }}>Tax Amount (in words) : </div>
                        <span style={{ maxWidth: "calc(100% - 143px)", width: "calc(100% - 143px)" }} className="padding3 fontWeightBold minHeight26 d-flex alignC">Indian Rupees Twenty Three Thousand Three Hundred Thirty Four and Forty Eight paise Only</span>

                    </div>
                    <Divider className="width-100" />
                    <div className="d-flex width-100" style={{ height: "134px", minHeight: "134px" }}>
                        <div className="" style={{ width: "calc(100% - 485px)", minWidth: "calc(20% - 0.5px)" }}>

                        </div>
                        <Divider variant="vertical" className="height-100" />
                        <div className="d-flex d-flex-column" style={{ width: "485px", maxWidth: "calc(80% - 0.5px)" }}>
                            <span className="width-100 padding3 minHeight26 d-flex alignC maxHeight26">Company's Bank Details</span>  <Divider className="width-100" />
                            <div className="width-100 padding3 minHeight26 d-flex alignC ">
                                A/c Holders's Name :<div style={{ minWidth: "3px", width: "3px" }}></div>
                                <span className="fontWeightBold"> Radhika Industrial Corporation</span>

                            </div>
                            <Divider className="width-100" /> <div className="width-100 padding3 minHeight26 d-flex alignC ">
                                Bank Name :<div style={{ minWidth: "3px", width: "3px" }}></div>
                                <span className="fontWeightBold">Kotak Mahindra Bank Ltd</span>

                            </div>
                            <Divider className="width-100" /> <div className="width-100 padding3 minHeight26 d-flex alignC ">
                                A/c No. :<div style={{ minWidth: "3px", width: "3px" }}></div>
                                <span className="fontWeightBold">5512944767</span>

                            </div>
                            <Divider className="width-100" /><div className="width-100 padding3 minHeight26 d-flex alignC ">
                                Brnach and IFSE Code :<div style={{ minWidth: "3px", width: "3px" }}></div>
                                <span className="fontWeightBold" style={{ width: "calc(100% - 140px)" }}>Ground Floor,Dhand Compound, Civil Line Branch & KKBK0000132   </span>

                            </div>

                        </div>


                    </div>
                    <Divider className="width-100" />

                    <div className="d-flex width-100" style={{ height: "calc(100% - 779px)", minHeight: "120px", }}>
                        <div className="d-flex d-flex-column" style={styles.widthWithDivider}>
                            <div className="width-100 minHeight26 d-flex alignC ">
                                <span className="width-100 padding5 minHeight26 d-flex alignC maxHeight26
                                        justifyC backgroundColorGrey" style={{ maxWidth: "105px" }}> Company's PAN </span>
                                <Divider variant="vertical" className="height-100" />
                                <span className="fontWeightBold padding5" style={{ maxWidth: "calc(100% - 105px)" }}> GSYUGJHKGSRR</span>

                            </div>  <Divider className="width-100" />

                            <span className="width-100 padding3 textDecorU padding5" >Declaration</span>
                            <span className="width-100 padding3 wordBreak padding5" >We declare that this invoice shoes the actual price of the goods described and that all particulars are true and correct.</span>

                        </div>
                        <Divider variant="vertical" className="height-100" />
                        <div className="height-100 d-flex d-flex-column justifySB alignFE padding5" style={styles.widthWithDivider}>

                            <span className="width-100 padding3  d-flex justifyFE" >For Radhika Industrial Corporation</span>
                            <span className="width-100 padding3   d-flex justifyFE" >Authorised Signatory</span>

                        </div>

                    </div>
                </div>
            </div >
        );
    }
}

export default Invoice;
