import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white } from "../styles/colors";
import Button from "@material-ui/core/Button";
import { Paper, Dialog, Slide, Tooltip } from "@material-ui/core";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import NoData from "../styles/Illustrations/NoData";
import Toggle from "./Toggle";
import SnackbarComponent from "./SnackbarComponent";
import EditIcon from "@material-ui/icons/Create";
import ReactDOM from "react-dom";
import Moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  contentStyle: {
    display: "flex",
    marginBottom: "10px",
    color: "black",
    fontSize: "1rem",
  },
  withDivider50: { width: "calc(50% - 5px)" },
  withDivider25: { width: "25%" },
};
const templateTabs = [
  {
    label: "Source Cities",
    tooltip: "SourceCities List",
    data: [],
    route: "/sourceCities/sourceCitiesEdit",
    primaryField: "city",
    primarySecondValue: "date2",
    primaryFieldFallback: "phone", // Optional
    secondaryField: "is_active",
    avatarField: "src",

    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class SourceCities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabs: templateTabs,
      user: null,
      singleSourceCitieslist: null,
      SalesOrder: "",
      sourceCities: [],
      addNewSourceCities: false,
      users: [],
      Name: "",
      colorArr: [white, lightGrey],
      error: "",
      success: "",
      status: false,
      editSourceCities: false,
      openMessage: true,
      errorMsg: { Name: false },
    };
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "sourceCitiesEdit" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singleSourceCitieslist: this.state.tabs[0].data[next.match.params["id"]],
            Name: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].city
              : "",
            addNewSourceCities: false,
            status: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].is_active
              : "",
            openMessage: false,
          },
          () => {
            console.log(this.state.singleSourceCitieslist, "singleSourceCitieslistyyyyyyyyyy");
          }
        );
      } else if (tab === "new") {
        this.setState({
          singleSourceCitieslist: null,
          Name: "",
          addNewSourceCities: true,
        });
      }
    } else this.setState({ singleSourceCitieslist: null });
  }

  listClickHandler = (value) => {
    console.log(value, "annuu11");
    this.setState({
      addNewSourceCities: false,
      singleSourceCitieslist: true,
      Name: value.city,
      Status: value.status,
      CreadedAt: value.created_at,
      openMessage: false,
    });
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;

      tab.data = props.sourceCities && props.sourceCities.length > 0 ? props.sourceCities : [];
      return tab;
    });
    console.log(tabs, "tabs");
    this.setState({ sourceCities: props.sourceCities, tabs });
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg.Name = false;
    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  handleClearFields = () => {
    this.setState({ Name: "" });
  };
  render() {
    const {
      tabs,
      sourceCities,
      addNewSourceCities,
      singleSourceCitieslist,
      Name,
      error,
      success,
      status,
      CreadedAt,
      city,
      errorMsg,
    } = this.state;
    return (
      <Layout
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          { data: city, hintText: "Search City", labelField: "city" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.props.history.push("/sourceCities/new");
          this.setState({
            addNewSourceCities: true,
            singleSourceCitieslist: false,
            status: true,
            editSourceCities: true,
          });
          this.handleClearFields();
        }}
      >
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        {this.state.openMessage ? (
          <div
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoData />
          </div>
        ) : (
          ""
        )}
        {this.state.editSourceCities ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "20px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "fit-content";
                target.style.minWidth = "45%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({
                editSourceCities: false,
                openMessage: true,
                errorMsg: {
                  Name: false,
                },
              });
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 30px",
                fontSize: "1.5rem",
              }}
            >
              <span style={{ color: "black" }}>
                {addNewSourceCities ? " Add SourceCities Detail" : "Edit SourceCities Detail"}
              </span>

              <div>
                <Toggle
                  status={status}
                  handleChange={() => {
                    this.setState({ status: !status });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                fontSize: "1rem",
                color: "grey",
                background: "white",
                margin: "20px 0 20px 30px",
              }}
            >
              <CssTextField
                autoComplete="off"
                style={
                  styles.withDivider50 //disabled={tp}
                }
                error={errorMsg.Name ? true : false}
                helperText={errorMsg.Name ? "Please Add City Name !" : false}
                id="outlined-basic"
                label="City"
                name="Name"
                variant="outlined"
                value={Name}
                onChange={this.handleChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: "40px 0 40px 30px",
                width: "100%",
              }}
            >
              {console.log(this.state.singltemslist, "nameeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")}
              <Button
                style={{ background: primary, color: "white", width: "200px" }}
                onClick={() => {
                  if (Name) {
                    if (addNewSourceCities) {
                      const addData = { city: Name, is_active: status };
                      this.props.sourcesAdd(addData, this.props.user.user.uid).then(() => {
                        this.props.fetchSourceCities(this.props.user.user.uid).then((res) => {
                          if (res) {
                            this.setState(
                              {
                                success: " Added successfully !",
                                editSourceCities: false,
                                sourceCities: res,
                                openMessage: true,
                              },
                              () => {
                                this.handleClearFields();
                              }
                            );
                          }
                        });
                      });
                    } else {
                      this.props
                        .sourcesEdit(Name, status, this.state.singleSourceCitieslist.id, this.props.user.user.uid)
                        .then(() => {
                          this.props.fetchSourceCities(this.props.user.user.uid).then((res) => {
                            if (res) {
                              this.setState({
                                success: "Edited successfully !",
                                editSourceCities: false,
                                sourceCities: res,
                              });
                            }
                          });
                        });
                    }
                  } else {
                    var errorMsg = this.state.errorMsg;
                    errorMsg.Name = true;
                    this.setState({ errorMsg });
                  }
                }}
              >
                {addNewSourceCities ? "add " : "edit"}
              </Button>
            </div>
          </Dialog>
        ) : (
          ""
        )}
        {this.state.openMessage == false ? (
          <>
            {(sourceCities.length > 0 && singleSourceCitieslist) || addNewSourceCities ? (
              <Paper style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 100px",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <span style={{ color: "black" }}>SourceCities Detail</span>
                </div>
                <Paper
                  style={{
                    border: "1px solid #8080802b",
                    padding: "20px 0",
                    margin: " 0 100px",
                    borderRadius: "10px",
                    boxShadow: "3px 3px #8080805c",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title="Edit SourceCities"
                      aria-label="add"
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <EditIcon
                        onClick={() => {
                          this.setState({ editSourceCities: true });
                        }}
                        style={{
                          width: 50,
                          marginRight: "3%",
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0 20px 30px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <div style={{ borderRadius: "10px" }}>
                          <span style={styles.contentStyle}>
                            Name :<span style={{ marginLeft: "10px", color: "grey" }}> {Name}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Status :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {status ? "Active" : "Inactive"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Created At :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}>
                              {" "}
                              {Moment(CreadedAt).format("MMM DD YYYY, h:mm a")}
                            </span>
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>{" "}
              </Paper>
            ) : (
              <div
                style={{
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoData />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default withRouter(SourceCities);
