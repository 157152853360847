import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import TranspotersGroup from "../components/TranspotersGroup";
import Consignor from "../components/Consignor";
import { fetchConsignors, consignorEdit, consignorAdd } from "../redux/index";
class ConsignorsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
    console.log("aaaaaaaaaaaaa");
  }
  componentWillReceiveProps(next) {
    this.setState({ consignors: next.consignors });
  }

  fetchUsers = () => {
    console.log(
      "this.props.user.uidaaaaaaaaaaaaaaaa",
      this.props.user.user.uid
    );
    if (this.props.user.user.uid) {
      this.props.fetchConsignors(this.props.user.user.uid).then((res) => {
        if (res) {
          this.setState({ consignors: res });
        }
      });
    }
  };

  render() {
    return (
      <Route
        exact
        path={["/consignors", "/consignors/:tab/:id", "/consignors/new"]}
        render={(props) => <Consignor {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  consignors: state.masters.consignors,
});
const mapDispatchToProps = {
  fetchConsignors,
  consignorEdit,
  consignorAdd,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsignorsContainer);
