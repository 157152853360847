import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import axios from "axios";
import {
  darkestGray,
  green,
  lightblue,
  lightGreen,
  lightGrey,
  primary,
  purple,
  red,
  white,
  yellow,
} from "../styles/colors";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  AppBar,
  ListItem,
  List,
  Box,
  Divider,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  ClickAwayListener,
  Popover,
  Backdrop,
  MenuItem,
  Tooltip,
  Dialog,
  Slide,
  DialogActions,
  DialogContent,
  IconButton,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { CssTextField10, StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import { AddIcon, BottomArrow, EditIcon, TopArrow } from "../styles/Icons";
import { withStyles } from "@material-ui/styles";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import { Paper, InputBase, Avatar } from "@material-ui/core";
import { CancelIcon, SearchIcon, PlusIcon, Download, CrossIcon } from "../styles/Icons";
import { CheckCircle, MoreHorizOutlined } from "@material-ui/icons";
import TableComponent from "./TableComponent";
import moment from "moment";
import SnackbarComponent from "./SnackbarComponent";
import PaymentVoucherPDF from "./PaymentVoucherPDF";
const CssTextFieldNew = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      // padding: "10px 5px 10px 14px",
    },
    // "& .MuiOutlinedInput-adornedEnd": { fontSize: "0.9rem" },
  },
})(CssTextField10);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": { color: "black" },
    "& .MuiFormControl-root": {
      backgroundColor: "rgb(242, 242, 242)",
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiFormControl-root": {
        background: "rgb(242, 242, 242)",
      },
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "rgb(242, 242, 242)",
        padding: "10px 14px",
      },
    },
  },
})(KeyboardDatePicker);
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
};
export default class PaymentVoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      added: false,
      colorArr: [white, lightGrey],
      loading: {},
      error: false,
      success: false,
      loadingDialog: false,
      paymentDialog: false,
      LRNumber: "",
      lrDetails: false,
      paymentAmount: "",
      paymentvoucher: [],
      ShortageDeducation: "",
      shortageRate: 0,
      ShortageQuantity: 0,
      transpoterNameId: "",
      TranspoterName: "",
      paymentvoucherDetail: false,
      voucher: [],
      errorMsg: { LRNumber: false, TranspoterName: false },
      paymentVoucherlist: ["incomplete", "draft", "paid", "unpaid", "cancelled"],
      lrHeader: [
        "Sr.No.",
        "LR #",
        "Date",
        "Truck",
        "Payment Rate",
        "Net Weight",
        "Shoratge Quantity",
        "Deduction Rate",
        "Deduction Amount",
        "Total Amount",
        "Due Date",
        "Action",
      ],
      pvHeader: ["Sr.No.", "Date", "Transporter", "PV#", "LR Count", "Net Weight", "Total Amount", "PDF", "Status"],
      paymentStatus: "incomplete",
      transporter: [],
      addedLr: [],
      pvEdit: false,
      UTRNumber: "",
      selectedDate: new Date(),
      widthList: {},
      LR_ARRAY: [],
      downloadPdf: false,
      selectedLR: false,
    };
  }
  prevent = (fn, defaultOnly) => {
    return (e, ...params) => {
      e && e.preventDefault();
      !defaultOnly && e && e.stopPropagation();
      fn(e, ...params);
    };
  };
  fetchNewPv = (res) => {
    if (res && res.length > 0) {
      this.setState({ voucher: res }, () => {
        const { voucher } = this.state;
        if (voucher && voucher.length > 0) {
          const newArr = [];
          voucher.map((sV) => {
            sV["Date"] = sV.date ? moment(sV.date).format("Do MMM, ddd") : "-";
            sV["Transporter"] = sV.transporter_details?.name ? sV.transporter_details?.name : null;
            sV["PV#"] = sV.payment_voucher_number;
            sV["LR Count"] = sV.trips_list ? sV.trips_list.length : "0";
            sV["Net Weight"] = Number(sV.net_total).toFixed(3);
            sV["Total Amount"] = sV.sub_total ? Number(sV.sub_total).toFixed(2) : 0;
            sV["Status"] = sV.status;
            sV.PDF = (
              <div
                className="cursorP"
                style={{ color: green }}
                onClick={this.prevent(() => {
                  this.setState({ downloadPdf: sV });
                })}
              >
                Print
              </div>
            );
            const newSUbArr = [];

            if (sV.trips_details && sV.trips_details.length > 0) {
              sV.trips_details.map((sTD, i) => {
                console.log(sTD, "sTDsTD");
                sTD["LR #"] = sTD.lr_number;
                sTD["Date"] = sTD.trip_date ? moment(sTD.trip_date).format("Do MMM, ddd") : "-";
                sTD["Truck"] = sTD.vehicle_no;
                sTD["Payment Rate"] = Number(sTD.payment_rate).toFixed(2);
                sTD["Net Weight"] = Number(sTD.net_weight).toFixed(3);
                sTD["Shoratge Quantity"] = Number(sTD.shortage_quantity).toFixed(3);
                sTD["Deduction Rate"] = Number(sTD.shortage_rate).toFixed(2);
                sTD["Deduction Amount"] = Math.floor(sTD.shortage_quantity * sTD.shortage_rate).toFixed(2);
                sTD["Total Amount"] = Math.floor(
                  Math.floor(sTD.payment_rate * sTD.net_weight) - Math.floor(sTD.shortage_quantity * sTD.shortage_rate)
                ).toFixed(2);
                sTD["SR.NO."] = i + 1 > 9 ? i + 1 : `0${i + 1}`;
                sTD["Due Date"] =
                  sTD.due_days &&
                  (typeof sTD.due_days !== "string" || sTD.due_days !== "Rate Matrix not available for this date.")
                    ? sTD.due_days
                    : "";

                sTD["BILTY NO"] = sTD.lr_number;
                sTD["VEHICLE NO."] = sTD.vehicle_no;
                sTD["QTY(MT)"] = Number(sTD.net_weight).toFixed(3);
                sTD["SHORTAGE"] = Number(sTD.shortage_quantity).toFixed(3);
                sTD["NET QTY"] = Number(
                  Math.floor(Number(sTD.net_weight).toFixed(3) - Number(sTD.shortage_quantity).toFixed(3))
                ).toFixed(3);
                sTD["RATE"] = Number(sTD.payment_rate).toFixed(2);
                sTD["AMT"] = Math.floor(
                  Math.floor(Number(sTD.payment_rate) * Number(sTD.net_weight)) -
                    Math.floor(Number(sTD.shortage_quantity) * Number(sTD.shortage_rate))
                ).toFixed(2);
                newSUbArr.push(sTD);
              });
            }
            sV.subList = newSUbArr.length > 0 ? newSUbArr : [];
            newArr.push(sV);
          });

          this.setState({
            voucher: newArr.sort((a, b) => {
              var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            }),
          });
        }
      });
    }
  };
  componentWillMount() {
    this.props.fetchPaymentVoucher(this.props.user.user.uid, this.props.selectedId).then((res) => {
      this.fetchNewPv(res);
    });
  }
  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  setInitialValues(props) {
    this.setState(
      {
        selectedId: props.selectedId,
        loading: props.loading,
        voucher: props.voucher
          ? props.voucher.sort((a, b) => {
            var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
            var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
            return new Date(dateA) - new Date(dateB);
          })
          : [],
        transporter: props.transporter,
      },

      () => {
        const { voucher } = this.state;
        if (voucher && voucher.length > 0) {
          const newArr = [];
          voucher.map((sV) => {
            sV["Date"] = sV.date ? moment(sV.date).format("Do MMM, ddd") : "-";
            sV["Transporter"] = sV.transporter_details?.name ? sV.transporter_details?.name : null;
            sV["PV#"] = sV.payment_voucher_number;
            sV["LR Count"] = sV.trips_list ? sV.trips_list.lenght : "0";
            sV["Net Weight"] = Number(sV.net_total).toFixed(3);
            sV["Total Amount"] = sV.sub_total ? Number(sV.sub_total).toFixed(2) : 0;
            sV["Status"] = sV.status;
            sV.PDF = (
              <div
                className="cursorP"
                style={{ color: green }}
                onClick={this.prevent(() => {
                  this.setState({ downloadPdf: sV });
                })}
              >
                Print
              </div>
            );
            const newSUbArr = [];
            if (sV.trips_details && sV.trips_details.length > 0) {
              sV.trips_details.map((sTD, i) => {
                console.log(sTD, "sTDsTD");
                sTD["LR #"] = sTD.lr_number;
                sTD["Date"] = sTD.trip_date ? moment(sTD.trip_date).format("Do MMM, ddd") : "-";
                sTD["Truck"] = sTD.vehicle_no;
                sTD["Payment Rate"] = Number(sTD.payment_rate).toFixed(2);
                sTD["Net Weight"] = Number(sTD.net_weight).toFixed(3);
                sTD["Shoratge Quantity"] = Number(sTD.shortage_quantity).toFixed(3);
                sTD["Deduction Rate"] = Number(sTD.shortage_rate).toFixed(2);
                sTD["Deduction Amount"] = Math.floor(sTD.shortage_quantity * sTD.shortage_rate).toFixed(2);
                sTD["Total Amount"] = Math.floor(
                  Math.floor(Number(sTD.payment_rate) * Number(sTD.net_weight)) -
                    Math.floor(Number(sTD.shortage_quantity) * Number(sTD.shortage_rate))
                ).toFixed(2);
                sTD["SR.NO."] = i + 1 > 9 ? i + 1 : `0${i + 1}`;
                sTD["Due Date"] =
                  sTD.due_days &&
                  (typeof sTD.due_days !== "string" || sTD.due_days !== "Rate Matrix not available for this date.")
                    ? sTD.due_days
                    : "";
                sTD["BILTY NO"] = sTD.lr_number;
                sTD["VEHICLE NO."] = sTD.vehicle_no;
                sTD["QTY(MT)"] = Number(sTD.net_weight).toFixed(3);
                sTD["SHORTAGE"] = Number(sTD.shortage_quantity).toFixed(3);
                sTD["NET QTY"] = Number(Math.floor(Number(sTD.net_weight) - Number(sTD.shortage_quantity))).toFixed(3);
                sTD["RATE"] = Number(sTD.payment_rate).toFixed(2);
                sTD["AMT"] = Math.floor(
                  Math.floor(Number(sTD.payment_rate) * Number(sTD.net_weight)) -
                    Math.floor(Number(sTD.shortage_quantity) * Number(sTD.shortage_rate))
                ).toFixed(2);
                newSUbArr.push(sTD);
              });
            }
            sV.subList = newSUbArr.length > 0 ? newSUbArr : [];
            newArr.push(sV);
          });

          this.setState({
            voucher: newArr.sort((a, b) => {
              var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
              var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
              return new Date(dateA) - new Date(dateB);
            }),
          });
        }
      }
    );
  }
  handleClearFields = () => {
    this.setState({
      paymentDialog: false,
      errorMsg: { TranspoterName: false },
      LRNumber: "",
    });
  };
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    this.setState({
      [event.target.name]: event.target.value,
      errorMsg,
    });
  };
  handleDateChange = (selectedDate) => {
    this.setState({
      selectedDate: selectedDate,
    });
  };
  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].props?.title
            ? it[key].props?.title.toString().length
            : it[key].props?.children
            ? it[key].props?.children.toString().length
            : it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  setWidth = (lrHeader, list) => {
    if (list && list.length > 0) {
      if (list[0]) {
        const widthList = {};
        const array = lrHeader ? lrHeader : Object.keys(list[0]);
        console.log(array, "array");
        if (array && array.length > 0) {
          array.map((single, i) => {
            if (single) {
              return (widthList[lrHeader[i]] = `${
                Math.floor(
                  10 *
                    (single.toString().length > this.longest(single, list)
                      ? single.toString().length
                      : this.longest(single, list)
                      ? this.longest(single, list)
                      : 1)
                ) < 110
                  ? 110
                  : Math.floor(
                      10 *
                        (single.toString().length > this.longest(single, list)
                          ? single.toString().length
                          : this.longest(single, list)
                          ? this.longest(single, list)
                          : 1)
                    )
              }px`);
            }
          });
        }
        this.setState({ widthList });
      }
    }
  };
  clearSelected = () => {
    this.setState({
      shortage_rate: 0,
      selectedLR: false,
      shortage_quantity: 0,
      deduction_amount: 0,
      due_days: 0,
      payment_rate: 0,

      errorMsg: { LRNumber: false, TranspoterName: false },
    });
  };
  createRM = (singleDate) => {
    const {
      selectedLR,
      shortage_quantity,
      shortage_rate,
      deduction_amount,
      payment_rate,
      total_amount,
      transpoterNameId,
      paymentvoucherDetail,
      lrHeader,
      LR_ARRAY,
      LRNumber,
    } = this.state;
    const { selectedId } = this.props;
    if (payment_rate && payment_rate > 0) {
      if (total_amount) {
        this.setState({ loadingDialog: true });
        var addData = {
          shortage_rate: shortage_rate,
          shortage_quantity: shortage_quantity,
          payment_voucher: paymentvoucherDetail && paymentvoucherDetail.id,
        };
        if (payment_rate && !singleDate["Payment Rate"]) {
          addData["special_rate"] = true;
          addData["payment_rate"] = payment_rate;
        }
        if (payment_rate && payment_rate !== singleDate["Payment Rate"]) {
          addData["payment_rate"] = payment_rate;
          addData["old_payment_rate"] = singleDate["Payment Rate"];
        }
        if (singleDate["transporter"] === null) {
          addData["transporter"] = transpoterNameId;
        }
        if (singleDate["transporter"] !== null && singleDate["transporter"] !== transpoterNameId) {
          addData["old_transporter"] = singleDate["transporter"];
          addData["new_transporter"] = true;
          addData["transporter"] = transpoterNameId;
        }

        this.props.fetchLRdetails(singleDate["LR #"], this.props.user.user.uid, addData).then((res) => {
          if (res.error) {
            this.setState(
              {
                error: res.data,
                loadingDialog: false,
              },
              () => {
                this.handleClearFields();
              }
            );
          } else {
            const LRNumberNews = this.state.addedLr;
            console.log(res, "resres");
            const newArr = [];
            LRNumberNews.map((sLR, i3) => {
              const newObj = { ...sLR };
              if (sLR["LR #"] === singleDate["LR #"]) {
                newObj["Payment Rate"] = Number(payment_rate).toFixed(2);
                newObj["Shoratge Quantity"] = Number(shortage_quantity).toFixed(3);
                newObj["Deduction Rate"] = Number(shortage_rate).toFixed(2);
                newObj["Deduction Amount"] = Math.floor(deduction_amount).toFixed(2);
                newObj["Total Amount"] = Math.floor(total_amount).toFixed(2);
              }
              return newArr.push(newObj);
            });
            const LRNumbers = LR_ARRAY;
            if (LRNumber && !LRNumbers.includes(LRNumber)) {
              LRNumbers.push(LRNumber);
            }
            this.setState(
              {
                addedLr: newArr,
                loadingDialog: false,
                success: "LR Updated!",
              },
              () => {
                this.clearSelected();
              }
            );
          }
        });
      } else {
        var errorMsg = this.state.errorMsg;
        errorMsg["total_amount"] = true;
        this.setState({ errorMsg });
      }
    } else {
      var errorMsg = this.state.errorMsg;
      errorMsg["payment_rate"] = true;
      this.setState({ errorMsg });
    }
  };
  render() {
    const {
      added,
      loading,
      error,
      success,
      loadingDialog,
      paymentDialog,
      lrDetails,
      LRNumber,
      PaymentAmount,
      ShortageDeducation,
      shortageRate,
      ShortageQuantity,
      transpoterNameId,
      TranspoterName,
      errorMsg,
      paymentvoucherDetail,
      voucher,
      paymentVoucherlist,
      paymentStatus,
      payment,
      ChequeNumber,
      UTRNumber,
      transporter,
      addedLr,
      pvEdit,
      selectedList,
      calenderOpen,
      selectedDate,
      lrHeader,
      colorArr,
      widthList,
      LR_ARRAY,
      pvHeader,
      selectedId,
      downloadPdf,
      selectedLR,
      shortage_quantity,
      shortage_rate,
      deduction_amount,
      payment_rate,
      total_amount,
    } = this.state;
    const { transpoterslist, Name } = this.props;
    return (
      <div
        className="height-100 width-100"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          {console.log(downloadPdf, "downloadPdf")}
          {this.state.downloadPdf !== false && (
            <PaymentVoucherPDF
              onClose={() => {
                this.setState({ downloadPdf: false });
              }}
              {...this.state}
              {...this.props}
              tableList={
                downloadPdf?.trips_details && downloadPdf?.trips_details.length > 0
                  ? downloadPdf?.trips_details.map((sL) => {
                      return sL;
                    })
                  : []
              }
              excelHeader={[
                "SR.NO.",
                "Date",
                "BILTY NO",
                "VEHICLE NO.",
                "QTY(MT)",
                "SHORTAGE",
                "NET QTY",
                "RATE",
                "AMT",
              ]}
              sPA={{
                bank: downloadPdf?.transporter_details?.bank_name ? downloadPdf?.transporter_details?.bank_name : "-",
                acc: downloadPdf?.transporter_details?.bank_acc_no
                  ? downloadPdf?.transporter_details?.bank_acc_no
                  : "-",
                ifsc: downloadPdf?.transporter_details?.ifsc ? downloadPdf?.transporter_details?.ifsc : "-",
                pan: downloadPdf?.transporter_details?.pan ? downloadPdf?.transporter_details?.pan : "-",
                date: downloadPdf?.Date ? moment(downloadPdf.Date).format("Do MMM, ddd") : "-",
                cheque_number: downloadPdf?.transporter_details?.cheque_number
                  ? downloadPdf?.transporter_details?.cheque_number
                  : "-",
                total_amount: "2345.00",
                itemName: "Iron Ore",
                from: "CMDC (Kachche Mines)",
                to: "Raipur",
                transporter: downloadPdf?.transporter_details?.name ? downloadPdf?.transporter_details?.name : "-",
                transporter_group: Name,
                deduction_amount: "200",
                rounded_off:
                  downloadPdf?.trips_details && downloadPdf?.trips_details.length > 0
                    ? parseInt(
                        downloadPdf?.trips_details.reduce(
                          (previousValue, currentValue) => Number(previousValue) + Number(currentValue["AMT"]),
                          0
                        )
                      ).toFixed(2)
                    : 0,
                total_NET_QTY:
                  downloadPdf?.trips_details && downloadPdf?.trips_details.length > 0
                    ? downloadPdf?.trips_details
                        .reduce(
                          (previousValue, currentValue) => Number(previousValue) + Number(currentValue["NET QTY"]),
                          0
                        )
                        .toFixed(3)
                    : 0,
                total_QTY:
                  downloadPdf?.trips_details && downloadPdf?.trips_details.length > 0
                    ? downloadPdf?.trips_details
                        .reduce(
                          (previousValue, currentValue) => Number(previousValue) + Number(currentValue["QTY(MT)"]),
                          0
                        )
                        .toFixed(3)
                    : 0,
                total_off:
                  downloadPdf?.trips_details && downloadPdf?.trips_details.length > 0
                    ? downloadPdf?.trips_details
                        .reduce((previousValue, currentValue) => Number(previousValue) + Number(currentValue["AMT"]), 0)
                        .toFixed(2)
                    : 0,
              }}
            />
          )}
          <TableComponent
            setSelectedListVal={(selectedList) => {
              const LRNumbers = selectedList.trips_list ? selectedList.trips_list : [];

              const newaddedLr = selectedList.trips_details ? selectedList.trips_details : [];
              newaddedLr.map((sNLR) => {
                sNLR[lrHeader[0]] = newaddedLr.length + 1 < 10 ? `0${newaddedLr.length + 1}` : newaddedLr.length + 1;

                sNLR[lrHeader[1]] = sNLR.lr_number;
                sNLR[lrHeader[2]] = sNLR.trip_date ? moment(sNLR.trip_date).format("Do MMM, ddd") : "-";
                sNLR[lrHeader[3]] = sNLR.vehicle_number;
                sNLR[lrHeader[4]] = Number(sNLR.payment_rate).toFixed(2);
                sNLR[lrHeader[5]] = Number(sNLR.net_weight).toFixed(3);
                sNLR[lrHeader[6]] = Number(sNLR.shortage_quantity).toFixed(3);
                sNLR[lrHeader[7]] = Number(sNLR.shortage_rate).toFixed(2);
                sNLR[lrHeader[8]] = sNLR.deduction_amount
                  ? sNLR.deduction_amount
                  : Math.floor(
                      Number(sNLR.shortage_rate ? sNLR.shortage_rate : 0) *
                        Number(sNLR.shortage_quantity ? sNLR.shortage_quantity : 0)
                    ).toFixed(2);
                sNLR[lrHeader[9]] = sNLR.total_amount
                  ? Number(sNLR.total_amount).toFixed(2)
                  : Math.floor(Number(sNLR.payment_rate ? sNLR.payment_rate : 0) * Number(sNLR.net_weight)).toFixed(2);
                sNLR[lrHeader[10]] = sNLR.rate_matrix?.due_days;
                sNLR[lrHeader[11]] = (
                  <Tooltip title="Add" placement="top" disableFocusListener disableTouchListener arrow>
                    <StyledButton
                      name={<EditIcon />}
                      width="60px"
                      onClick={() => {
                        this.setState({
                          shortage_rate: Number(sNLR.shortage_rate ? sNLR.shortage_rate : 0).toFixed(2),
                          selectedLR: sNLR,
                          shortage_quantity: Number(sNLR.shortage_quantity ? sNLR.shortage_quantity : 0).toFixed(3),
                          deduction_amount: sNLR.deduction_amount
                            ? sNLR.deduction_amount
                            : Math.floor(
                                Number(sNLR.shortage_rate ? sNLR.shortage_rate : 0) *
                                  Number(sNLR.shortage_quantity ? sNLR.shortage_quantity : 0)
                              ).toFixed(2),
                          due_days: sNLR.rate_matrix?.due_days,
                          payment_rate: sNLR.payment_rate ? sNLR.payment_rate : 0,
                        });
                      }}
                    />
                  </Tooltip>
                );
              });

              this.setState(
                {
                  addedLr: newaddedLr,
                  LRNumber: "",
                  LR_ARRAY: LRNumbers,
                  added: false,
                  transpoterNameId: selectedList?.transporter_details?.id ? selectedList?.transporter_details?.id : "",
                  TranspoterName: selectedList?.transporter_details?.name
                    ? selectedList?.transporter_details?.name
                    : "",
                  selectedList,
                  paymentvoucherDetail: selectedList,
                },
                () => {
                  this.setWidth(lrHeader, this.state.addedLr);
                }
              );
            }}
            module={"Payment Vouchers"}
            onEdit={() => {
              this.setState({
                paymentDialog: true,
              });
            }}
            onAdd={() => {
              this.setState({
                paymentDialog: true,
                pvEdit: false,
                addedLr: [],
                LRNumber: "",
                LR_ARRAY: [],
                paymentvoucherDetail: false,
                transpoterNameId: "",
                TranspoterName: "",
                added: false,
                selectedList: false,
              });
            }}
            {...this.state}
            {...this.props}
            subMenu={true}
            header={pvHeader}
            apiHeader={pvHeader}
            subMenuheader={lrHeader.slice(0, 11)}
            tableList={
              voucher && voucher
                ? voucher.sort((a, b) => {
                    var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
                    var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
                    return new Date(dateA) - new Date(dateB);
                  })
                : []
            }
            actions={[
              {
                name: "edit",
                onClick: () => {
                  this.setState({
                    paymentDialog: true,
                    pvEdit: true,
                  });
                },
              },
            ]}
            subMenuAction={[]}
          />
        </Paper>
        {paymentDialog == true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "5px";
                target.style.maxHeight = "calc(100vh - 62px)";
                // target.style.minHeight = "650px";
                target.style.height = "650px";
                target.style.minWidth = "90%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={true}
            onClose={() => {
              this.setState({ paymentDialog: false, success: "", pvEdit: false });
              this.handleClearFields();
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.3rem",
                background: "rgb(30 53 89)",
                padding: "10px 24px",
              }}
            >
              {" "}
              <DialogActions
                classname="d-flex justifySB"
                style={{
                  background: primary,
                  color: "white",
                  padding: "10px 24px",
                }}
                onClick={() => {
                  this.setState({ paymentDialog: false });
                  this.handleClearFields();
                }}
              >
                <h3 style={styles.heading}>
                  Payment Voucher <div style={{ margin: "0 10px 0 0" }} />( Transporter Group :-
                  <div style={{ margin: "0 10px 0 0" }} /> {Name} )
                </h3>

                <Tooltip title="close" arrow placement="top">
                  <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                    <CrossIcon strokeWidth="2" />
                  </IconButton>
                </Tooltip>
              </DialogActions>{" "}
            </div>{" "}
            <DialogContent className="dashedDividerBottom" style={{ height: "100%" }}>
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  success={success}
                  snackbarClose={() => this.setState({ error: "", success: "" })}
                />
              ) : (
                ""
              )}
              {loadingDialog === true ? (
                <Backdrop style={{ zIndex: 1, color: "#fff" }} open={true}>
                  <StyledCircularProgress color="inherit" />
                </Backdrop>
              ) : (
                ""
              )}
              {added ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
                  <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                    {added}
                  </span>
                </div>
              ) : (
                <div className="height-100 width-100 d-flex padding20 d-flex-column ">
                  {paymentvoucherDetail !== false ? (
                    <>
                      {" "}
                      <div className="width-100 d-flex">
                        <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                          <CssKeyboardDatePicker
                            variant="inline"
                            disabled={true}
                            onOpen={() => {
                              this.setState({ calenderOpen: true });
                            }}
                            onClose={() => {
                              this.setState({ calenderOpen: false });
                            }}
                            keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                            maxDate={new Date()}
                            disableFuture
                            minDate={"2022-01-22"}
                            error={false}
                            invalidLabel=""
                            onKeyPress={(ev) => {
                              ev.preventDefault();
                            }}
                            allowKeyboardControl={false}
                            clearable
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            label={selectedDate ? "" : "Date"}
                            format="do MMM, yyyy"
                            style={{
                              maxWidth: "200px",
                              minWidth: "200px",
                              cursor: "none",
                              fontSize: "0.5rem",
                              height: "38px",
                              background: "rgb(242, 242, 242)",
                            }}
                            className="width-100"
                            value={selectedDate ? selectedDate : ""}
                            InputAdornmentProps={{ position: "end" }}
                            onChange={(date) => this.handleDateChange(date)}
                          />{" "}
                        </MuiPickersUtilsProvider>{" "}
                        <StyledButton
                          onlyTextColor={primary}
                          name={`( Tranporter :- ${TranspoterName} )`}
                          disabled={true}
                          margin="0 0 0 20px"
                        />
                      </div>{" "}
                      {selectedList && (selectedList.status === "paid" || selectedList.status === "unpaid") ? (
                        ""
                      ) : (
                        <div className="width-100 d-flex marginT20">
                          <CssTextFieldNew
                            autoComplete="off"
                            type="number"
                            InputProps={{
                              inputProps: {
                                min: 1,
                              },
                            }}
                            required={true}
                            onKeyDown={(evt) =>
                              (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") &&
                              evt.preventDefault()
                            }
                            style={{ width: "calc(50% - 15px)", maxWidth: "350px" }}
                            id="outlined-basic"
                            label="LR #"
                            disabled={Object.keys(lrDetails).length > 0}
                            name="LRNumber"
                            variant="outlined"
                            value={LRNumber}
                            error={errorMsg.LRNumber ? true : false}
                            helperText={errorMsg.LRNumber ? errorMsg.LRNumber : ""}
                            InputProps={{
                              endAdornment: (
                                <StyledButton
                                  name={"Add LR"}
                                  onlyTextColor={primary}
                                  onClick={() => {
                                    if (LRNumber.length > 0) {
                                      if (!LR_ARRAY.includes(LRNumber)) {
                                        this.setState({ loadingDialog: true });
                                        this.props.fetchLRdetails(LRNumber, this.props.user.user.uid).then((res) => {
                                          if (Object.keys(res).length === 0) {
                                            var errorMsg = this.state.errorMsg;
                                            errorMsg.LRNumber = "No trip available of this LR number!";
                                            this.setState({
                                              lrDetails: false,
                                              errorMsg,
                                              loadingDialog: false,
                                            });
                                          } else if (Object.keys(res).length > 0) {
                                            console.log(res, "resres");
                                            if (res.transporter_group === selectedId) {
                                              if (!res.payment_voucher) {
                                                const LRNumberNew = this.state.addedLr;
                                                const sLR = res;
                                                const newObj = { ...sLR };
                                                newObj[lrHeader[0]] =
                                                  LRNumberNew.length + 1 < 10
                                                    ? `0${LRNumberNew.length + 1}`
                                                    : LRNumberNew.length + 1;

                                                newObj[lrHeader[1]] = sLR.lr_number;
                                                newObj[lrHeader[2]] = sLR.trip_date
                                                  ? moment(sLR.trip_date).format("Do MMM, ddd")
                                                  : "-";
                                                newObj[lrHeader[3]] = sLR.vehicle_number;
                                                newObj[lrHeader[4]] = Number(sLR.payment_rate).toFixed(2);
                                                newObj[lrHeader[5]] = Number(sLR.net_weight).toFixed(3);
                                                newObj[lrHeader[6]] = Number(sLR.shortage_quantity).toFixed(3);
                                                newObj[lrHeader[7]] = Number(sLR.shortage_rate).toFixed(2);
                                                newObj[lrHeader[8]] = sLR.deduction_amount
                                                  ? sLR.deduction_amount
                                                  : Math.floor(
                                                      Number(sLR.shortage_rate ? sLR.shortage_rate : 0) *
                                                        Number(sLR.shortage_quantity ? sLR.shortage_quantity : 0)
                                                    ).toFixed(2);
                                                newObj[lrHeader[9]] = sLR.total_amount
                                                  ? Number(sLR.total_amount).toFixed(2)
                                                  : Math.floor(
                                                      Number(sLR.payment_rate ? sLR.payment_rate : 0) *
                                                        Number(sLR.net_weight)
                                                    ).toFixed(2);
                                                newObj[lrHeader[10]] = sLR.rate_matrix?.due_days;
                                                newObj[lrHeader[11]] = (
                                                  <Tooltip
                                                    title="Add"
                                                    placement="top"
                                                    disableFocusListener
                                                    disableTouchListener
                                                    arrow
                                                  >
                                                    <StyledButton
                                                      name={<EditIcon />}
                                                      width="60px"
                                                      onClick={() => {
                                                        this.setState({
                                                          shortage_rate: Number(
                                                            sLR.shortage_rate ? sLR.shortage_rate : 0
                                                          ).toFixed(2),
                                                          selectedLR: sLR,
                                                          shortage_quantity: Number(
                                                            sLR.shortage_quantity ? sLR.shortage_quantity : 0
                                                          ).toFixed(3),
                                                          deduction_amount: sLR.deduction_amount
                                                            ? sLR.deduction_amount
                                                            : Math.floor(
                                                                Number(sLR.shortage_rate ? sLR.shortage_rate : 0) *
                                                                  Number(
                                                                    sLR.shortage_quantity ? sLR.shortage_quantity : 0
                                                                  )
                                                              ).toFixed(2),
                                                          due_days: sLR.rate_matrix?.due_days,
                                                          payment_rate: sLR.payment_rate ? sLR.payment_rate : 0,
                                                        });
                                                      }}
                                                    />
                                                  </Tooltip>
                                                );

                                                LRNumberNew.push(newObj);
                                                const LRNumbers = LR_ARRAY;
                                                if (LRNumber && !LRNumbers.includes(LRNumber)) {
                                                  LRNumbers.push(LRNumber);
                                                }
                                                console.log(LRNumbers, "LRNumbersLRNumbers");
                                                this.setState(
                                                  {
                                                    addedLr: LRNumberNew,
                                                    LRNumber: "",
                                                    LR_ARRAY: LRNumbers,
                                                    loadingDialog: false,
                                                  },
                                                  () => {
                                                    this.setWidth(lrHeader, addedLr);
                                                  }
                                                );
                                              } else {
                                                var errorMsg = this.state.errorMsg;
                                                errorMsg.LRNumber = "LR Number already added to a payment voucher!";
                                                this.setState({ errorMsg, loadingDialog: false });
                                              }
                                            } else {
                                              var errorMsg = this.state.errorMsg;
                                              errorMsg.LRNumber = "LR Number of another transporter group!";
                                              this.setState({ errorMsg, loadingDialog: false });
                                            }
                                          } else {
                                            this.setState({
                                              loadingDialog: false,
                                            });
                                          }
                                        });
                                      } else {
                                        var errorMsg = this.state.errorMsg;
                                        errorMsg.LRNumber = "LR Number already added!";
                                        this.setState({ errorMsg });
                                      }
                                    }
                                  }}
                                  width="70px"
                                  minWidth="70px"
                                />
                              ),
                            }}
                            onChange={this.handleChange}
                          />
                        </div>
                      )}
                      <div
                        className="width-100"
                        style={{
                          margin: "20px 0 0 0",
                          height: "calc(100% - 58px)",
                        }}
                      >
                        <Paper
                          className="d-flex d-flex-column scrollBluePrimary overflowXA positionR"
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "7px",
                            // border: `1px solid ${primary}`,
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "rgb(158 158 158 / 48%)",
                              color: primary,
                              borderColor: primary,
                              width: "fit-content",
                              minWidth: "100%",
                              tableLayout: "fixed",
                              display: "flex",
                              alignItems: "center",
                              height: "50px",
                              minHeight: "50px",
                              overflow: "hidden",
                              borderRadius: "7px 7px 0 0",
                            }}
                          >
                            <div className="d-flex alignC justifySE width-100">
                              {lrHeader &&
                                lrHeader.map((name, i) =>
                                  selectedList &&
                                  (selectedList.status === "paid" || selectedList.status === "unpaid") &&
                                  name === "Action" ? (
                                    ""
                                  ) : (
                                    <div
                                      className="justifyC d-flex alignC"
                                      style={{
                                        margin: "12px 10px",
                                        width:
                                          i === 11
                                            ? "130px"
                                            : widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        minWidth:
                                          i === 11
                                            ? "130px"
                                            : widthList && widthList[name]
                                            ? widthList[name]
                                            : "fit-content",
                                        display: "flex",
                                        alignItems: "center",
                                        color: "#100f0f",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {name}
                                    </div>
                                  )
                                )}
                            </div>
                          </div>{" "}
                          {addedLr && addedLr.length > 0 ? (
                            <div
                              className="scrollBluePrimary"
                              style={{
                                height: "calc(100% - 84px)",
                                overflowY: "auto",
                                width: "fit-content",
                                minWidth: "100%",
                                position: "relative",
                              }}
                            >
                              {addedLr.map((singleDate, i2) => (
                                <>
                                  <div className="d-flex width-100 d-flex-column justifySE">
                                    <div
                                      className="d-flex width-100 justifySE"
                                      style={{
                                        margin: "1px 0 0 0",
                                        backgroundColor: colorArr[i2 % colorArr.length],
                                        height: "54px",
                                      }}
                                    >
                                      {" "}
                                      {selectedLR && singleDate.lr_number === selectedLR.lr_number ? (
                                        <>
                                          {lrHeader &&
                                            lrHeader.map((name, i) => (
                                              <div
                                                className="d-flex alignC justifyC minWidthFC"
                                                style={{
                                                  margin: "12px 10px",
                                                  width: widthList && widthList[name] ? widthList[name] : "fit-content",
                                                  minWidth:
                                                    widthList && widthList[name] ? widthList[name] : "fit-content",
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {i === lrHeader.length - 1 ? (
                                                  <>
                                                    {" "}
                                                    <StyledButton
                                                      name={"Save"}
                                                      width="60px"
                                                      onClick={() => {
                                                        this.createRM(singleDate);
                                                      }}
                                                    />{" "}
                                                    <Tooltip
                                                      title="Clear"
                                                      placement="top"
                                                      disableFocusListener
                                                      disableTouchListener
                                                      arrow
                                                    >
                                                      <StyledButton
                                                        name={<CrossIcon color={primary} />}
                                                        width="60px"
                                                        onClick={() => {
                                                          this.clearSelected();
                                                        }}
                                                      />
                                                    </Tooltip>
                                                  </>
                                                ) : i === 0 || i === 1 || i === 2 || i === 3 || i === 5 || i === 10 ? (
                                                  singleDate[name] ? (
                                                    singleDate[name]
                                                  ) : (
                                                    "-"
                                                  )
                                                ) : i === 9 ? (
                                                  Number(total_amount).toFixed(2)
                                                ) : (
                                                  <>
                                                    <CssTextFieldNew
                                                      autoComplete="off"
                                                      type="number"
                                                      autoFocus={[name].toString() === "Payment Rate"}
                                                      required={true}
                                                      onKeyDown={(evt) =>
                                                        (evt.key === "E" || evt.key === "e" || evt.key === "-") &&
                                                        evt.preventDefault()
                                                      }
                                                      style={{
                                                        width: widthList && widthList[name] ? widthList[name] : "110px",
                                                        maxWidth: "110px",
                                                      }}
                                                      id="outlined-basic"
                                                      name={[name]}
                                                      variant="outlined"
                                                      value={
                                                        [name].toString() === "Shoratge Quantity"
                                                          ? shortage_quantity
                                                          : [name].toString() === "Deduction Amount"
                                                          ? deduction_amount
                                                          : [name].toString() === "Deduction Rate"
                                                          ? shortage_rate
                                                          : [name].toString() === "Payment Rate"
                                                          ? payment_rate
                                                          : ""
                                                      }
                                                      error={errorMsg[name] ? true : false}
                                                      onChange={(event) => {
                                                        var errorMsg = this.state.errorMsg;
                                                        errorMsg[event.target.name] = false;
                                                        const TYPE = [event.target.name].toString();
                                                        console.log(TYPE, "TYPETYPE");
                                                        if (TYPE === "Deduction Amount") {
                                                          this.setState({
                                                            deduction_amount: Number(event.target.value),
                                                            errorMsg,
                                                          });
                                                        }
                                                        if (TYPE === "Payment Rate") {
                                                          this.setState({
                                                            payment_rate: Number(event.target.value),
                                                            errorMsg,
                                                            total_amount: Number(
                                                              Math.floor(
                                                                Number(
                                                                  Math.floor(
                                                                    Number(event.target.value) *
                                                                      Number(
                                                                        singleDate["Net Weight"]
                                                                          ? singleDate["Net Weight"]
                                                                          : 0
                                                                      )
                                                                  )
                                                                ) - Number(deduction_amount ? deduction_amount : 0)
                                                              )
                                                            ),
                                                          });
                                                        }
                                                        if (TYPE === "Shoratge Quantity") {
                                                          this.setState({
                                                            shortage_quantity: Number(event.target.value),
                                                            deduction_amount: Number(
                                                              Math.floor(
                                                                Number(event.target.value) *
                                                                  Number(shortage_rate ? shortage_rate : 0)
                                                              )
                                                            ),
                                                            errorMsg,
                                                          });
                                                        }
                                                        if (TYPE === "Deduction Rate") {
                                                          this.setState({
                                                            shortage_rate: Number(event.target.value),
                                                            errorMsg,
                                                            deduction_amount: Number(
                                                              Math.floor(
                                                                Number(event.target.value) *
                                                                  Number(shortage_quantity ? shortage_quantity : 0)
                                                              )
                                                            ),
                                                          });
                                                        }
                                                      }}
                                                      InputProps={{
                                                        endAdornment:
                                                          [name].toString() === "Shoratge Quantity"
                                                            ? shortage_quantity
                                                              ? "MT"
                                                              : ""
                                                            : "",
                                                      }}
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            ))}
                                        </>
                                      ) : (
                                        lrHeader &&
                                        lrHeader.map((name) => (
                                          <div
                                            className="d-flex alignC justifyC minWidthFC"
                                            style={{
                                              margin: "12px 10px",
                                              width: widthList && widthList[name] ? widthList[name] : "fit-content",
                                              minWidth: widthList && widthList[name] ? widthList[name] : "fit-content",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {singleDate[name] ? singleDate[name] : "-"}
                                          </div>
                                        ))
                                      )}
                                    </div>

                                    <Divider className="width-100" />
                                  </div>

                                  <Divider />
                                </>
                              ))}{" "}
                              <div className="d-flex width-100 d-flex-column justifySE">
                                <div
                                  className="d-flex width-100 justifySE"
                                  style={{
                                    margin: "1px 0 0 0",
                                    backgroundColor: colorArr[(addedLr.length + 1) % colorArr.length],
                                    height: "54px",
                                  }}
                                >
                                  {lrHeader &&
                                    lrHeader.map((name, i) => (
                                      <div
                                        className="d-flex alignC justifyC minWidthFC"
                                        style={{
                                          margin: "12px 10px",
                                          width: widthList && widthList[name] ? widthList[name] : "fit-content",
                                          minWidth: widthList && widthList[name] ? widthList[name] : "fit-content",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {i === 0
                                          ? "Total"
                                          : i === 5
                                          ? addedLr
                                              .reduce(
                                                (previousValue, currentValue) =>
                                                  Number(previousValue) +
                                                  Number(currentValue[lrHeader[5]] ? currentValue[lrHeader[5]] : 0),
                                                0
                                              )
                                              .toFixed(3)
                                          : i === 6
                                          ? addedLr
                                              .reduce(
                                                (previousValue, currentValue) =>
                                                  Number(previousValue) +
                                                  Number(currentValue[lrHeader[6]] ? currentValue[lrHeader[6]] : 0),
                                                0
                                              )
                                              .toFixed(3)
                                          : i === 8
                                          ? addedLr
                                              .reduce(
                                                (previousValue, currentValue) =>
                                                  Number(previousValue) +
                                                  Number(currentValue[lrHeader[8]] ? currentValue[lrHeader[8]] : 0),
                                                0
                                              )
                                              .toFixed(2)
                                          : i === 9
                                          ? Number(
                                              addedLr
                                                .reduce(
                                                  (previousValue, currentValue) =>
                                                    Number(previousValue) +
                                                    Number(currentValue[lrHeader[9]] ? currentValue[lrHeader[9]] : 0),
                                                  0
                                                )
                                                .toFixed(2)
                                            ).toFixed(2)
                                          : ""}
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                              <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>0 LR added Yet!</span>
                            </div>
                          )}
                        </Paper>
                      </div>
                    </>
                  ) : (
                    <div className="width-100 d-flex">
                      <CssTextFieldNew
                        required={true}
                        autoComplete="off"
                        value={
                          TranspoterName ? TranspoterName : "" //
                        }
                        onChange={(e) => {
                          var errorMsg = this.state.errorMsg;
                          errorMsg["TranspoterName"] = false;
                          this.setState({
                            errorMsg,
                            transpoterNameId: e.currentTarget.id,
                            TranspoterName: e.target.value,
                          });
                        }}
                        style={{ width: "calc(50% - 15px)", margin: "0 30px 0 0", maxWidth: "400px" }}
                        id="outlined-select-currency-native"
                        select
                        error={errorMsg.TranspoterName ? true : false}
                        helperText={errorMsg.TranspoterName !== false ? errorMsg.TranspoterName : ""}
                        label="Transpoter"
                        variant="outlined"
                      >
                        {transporter
                          .filter((sT) => sT.group === selectedId)
                          .map((option) => (
                            <MenuItem key={option.name} value={option.name} id={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                      </CssTextFieldNew>{" "}
                      <StyledButton
                        onlyTextColor={lightblue}
                        name="Next"
                        onClick={() => {
                          if (TranspoterName) {
                            this.setState({ loadingDialog: true });

                            const addData = {
                              date: moment(new Date()).format("YYYY-MM-DD"),
                              utr_number: "",
                              status: paymentStatus,
                              transporter_group: selectedId,
                              transporter: transpoterNameId,
                              net_total: "",
                              // ? transporter.filter((sT) => sT.id === transpoterNameId)[0]
                              // : {},
                            };
                            console.log(addData, "addData");
                            this.props
                              .PaymentVoucherAdd(addData, this.props.user.user.uid, pvEdit ? selectedList?.id : null)
                              .then((addedPv) => {
                                this.props
                                  .fetchPaymentVoucher(this.props.user.user.uid, this.props.selectedId)
                                  .then((res) => {
                                    console.log(res, "res");
                                    if (!res || res.error) {
                                      this.setState(
                                        {
                                          error: res.data,
                                          loadingDialog: false,
                                        },
                                        () => {
                                          this.handleClearFields();
                                        }
                                      );
                                    } else {
                                      this.setState(
                                        {
                                          paymentvoucherDetail: addedPv,
                                          loadingDialog: false,
                                          success: "Added successfully !",
                                        },
                                        () => {
                                          this.fetchNewPv(res);
                                        }
                                      );
                                    }
                                  });
                              });
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.TranspoterName = "Please select a Transporter!";
                            this.setState({ errorMsg });
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              )}

              {/* <CssTextFieldNew
                //       required={true}
                //       autoComplete="off"
                //       value={
                //         paymentStatus ? paymentStatus : "" //
                //       }
                //       disabled={pvEdit ? false : true}
                //       onChange={(e) => {
                //         this.setState({ paymentStatus: e.target.value });
                //       }}
                //       style={
                //         {
                //           width: "calc(50% - 15px)",
                //           margin: "0 0 0 30px",
                //           textTransform: "capitalize",
                //         } //disabled={tp}
                //       }
                //       id="outlined-select-currency-native"
                //       select
                //       label="Status"
                //       variant="outlined"
                //       InputLabelProps={{}}
                //     >
                //       {paymentVoucherlist.map((option) => (
                //         <MenuItem key={option} value={option} id={option}>
                //           {option}
                //         </MenuItem>
                //       ))}
                //     </CssTextFieldNew> */}
            </DialogContent>
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                minHeight: "52.5px",
              }}
            >
              {added !== false ? (
                ""
              ) : selectedList && selectedList.status === "paid" ? (
                ""
              ) : (selectedList && selectedList.status === "unpaid") || added !== false ? (
                <Button
                  style={{
                    color: red,
                    minWidth: "150px",
                  }}
                  onClick={() => {
                    this.setState({ loadingDialog: true });
                    const addData = {
                      status: "cancelled",
                    };
                    this.props
                      .PaymentVoucherAdd(addData, this.props.user.user.uid, paymentvoucherDetail.id)
                      .then((res) => {
                        if (res.error) {
                          this.setState(
                            {
                              error: res.data,
                              loadingDialog: false,
                            },
                            () => {
                              this.handleClearFields();
                            }
                          );
                        } else {
                          this.setState(
                            {
                              loadingDialog: false,
                              added: "Payment Voucher updated!",
                            },
                            () => {
                              this.props
                                .fetchPaymentVoucher(this.props.user.user.uid, this.props.selectedId)
                                .then((res) => {
                                  this.fetchNewPv(res);
                                });
                            }
                          );
                        }
                      });
                  }}
                >
                  {"Cancel"}
                </Button>
              ) : (
                <>
                  {selectedList && selectedList.status === "draft" && (
                    <Button
                      style={{
                        color: red,
                        minWidth: "150px",
                      }}
                      onClick={() => {
                        this.setState({ loadingDialog: true });
                        const addData = {
                          status: "cancelled",
                        };
                        this.props
                          .PaymentVoucherAdd(addData, this.props.user.user.uid, paymentvoucherDetail.id)
                          .then((res) => {
                            if (res.error) {
                              this.setState(
                                {
                                  error: res.data,
                                  loadingDialog: false,
                                },
                                () => {
                                  this.handleClearFields();
                                }
                              );
                            } else {
                              this.setState(
                                {
                                  loadingDialog: false,
                                  added: "Payment Voucher updated!",
                                },
                                () => {
                                  this.props
                                    .fetchPaymentVoucher(this.props.user.user.uid, this.props.selectedId)
                                    .then((res) => {
                                      this.fetchNewPv(res);
                                    });
                                }
                              );
                            }
                          });
                      }}
                    >
                      {selectedList && selectedList.status === "draft" ? "Reject" : ""}
                    </Button>
                  )}
                  {selectedList && selectedList.status === "draft" && (
                    <Button
                      style={{
                        background: green,
                        color: "white",
                        minWidth: "150px",
                      }}
                      onClick={() => {
                        this.setState({ loadingDialog: true });
                        const addData = {
                          status: "unpaid",
                        };
                        this.props
                          .PaymentVoucherAdd(addData, this.props.user.user.uid, paymentvoucherDetail.id)
                          .then((res) => {
                            if (res.error) {
                              this.setState(
                                {
                                  error: res.data,
                                  loadingDialog: false,
                                },
                                () => {
                                  this.handleClearFields();
                                }
                              );
                            } else {
                              this.setState(
                                {
                                  loadingDialog: false,
                                  added: "Payment Voucher updated!",
                                },
                                () => {
                                  this.props
                                    .fetchPaymentVoucher(this.props.user.user.uid, this.props.selectedId)
                                    .then((res) => {
                                      this.fetchNewPv(res);
                                    });
                                }
                              );
                            }
                          });
                      }}
                    >
                      Approve
                    </Button>
                  )}{" "}
                  {((selectedList && selectedList.status === "draft") || paymentvoucherDetail !== false) && (
                    <Button
                      style={{
                        background: primary,
                        color: "white",
                        minWidth: "150px",
                      }}
                      onClick={() => {
                        if (LR_ARRAY.length > 0) {
                          this.setState({ loadingDialog: true });
                          const addData = {
                            status: "draft",
                            trips_list: LR_ARRAY,
                            sub_total: Number(
                              addedLr
                                .reduce(
                                  (previousValue, currentValue) =>
                                    Number(previousValue) +
                                    Number(currentValue["Total Amount"] ? currentValue["Total Amount"] : 0),
                                  0
                                )
                                .toFixed(2)
                            ).toFixed(2),
                            net_total: Number(
                              addedLr
                                .reduce(
                                  (previousValue, currentValue) =>
                                    Number(previousValue) +
                                    Number(currentValue["NET QTY"] ? currentValue["NET QTY"] : 0),
                                  0
                                )
                                .toFixed(3)
                            ).toFixed(3),
                          };
                          this.props
                            .PaymentVoucherAdd(addData, this.props.user.user.uid, paymentvoucherDetail.id)
                            .then((res) => {
                              if (res.error) {
                                this.setState(
                                  {
                                    error: res.data,
                                    loadingDialog: false,
                                  },
                                  () => {
                                    this.handleClearFields();
                                  }
                                );
                              } else {
                                this.setState(
                                  {
                                    loadingDialog: false,

                                    added:
                                      selectedList && selectedList.status === "draft"
                                        ? "Payment Voucher updated!"
                                        : "Payment Voucher created!",
                                  },
                                  () => {
                                    this.props
                                      .fetchPaymentVoucher(this.props.user.user.uid, this.props.selectedId)
                                      .then((res) => {
                                        this.fetchNewPv(res);
                                      });
                                  }
                                );
                              }
                            });
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.LRNumber = "Please add atleast one Lr number!";
                          this.setState({ errorMsg });
                        }
                      }}
                    >
                      {selectedList && selectedList.status === "draft" ? "Edit" : "Save as Draft"}
                    </Button>
                  )}
                </>
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
      </div>
    );
  }
}
