import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Layout from '../layouts/Layout'
import { primary, lightGrey, white } from '../styles/colors'
import Button from '@material-ui/core/Button'
import { Paper, Dialog, Slide, Tooltip, Divider } from '@material-ui/core'
import { CssTextField, StyledButton } from '../styles/StyledComponents'
import NoData from '../styles/Illustrations/NoData'
import Toggle from './Toggle'
// import Rhombus from "../styles/Rhombus.gif";

import { MenuItem } from '@material-ui/core'
import SnackbarComponent from './SnackbarComponent'
import EditIcon from '@material-ui/icons/Create'
import ReactDOM from 'react-dom'
import Moment from 'moment'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
const styles = {
  paperStyle: {
    borderRadius: '7px',
    display: 'flex',
    width: '210px',
    height: '30px',
    fontSize: '0.5rem',
    backgroundColor: 'white',
    marginLeft: '20px',
  },
  paperStyleDynamic: {
    borderRadius: '7px',
    display: 'flex',
    width: '100%',
    height: '40px',
    fontSize: '0.5rem',
    // minWidth: "350px",
    backgroundColor: 'white',
    padding: '0 0 0 10px',
    position: 'relative',
  },
  contentStyle: {
    display: 'flex',
    marginBottom: '10px',
    color: 'black',
    fontSize: '1rem',
  },
  withDivider50: { width: 'calc(25% - 5px)' },
  withDivider25: { width: '25%' },
}
const templateTabs = [
  {
    label: 'Vehicles',
    tooltip: 'Vehicles List',
    data: [],
    route: '/vehicles/vehiclesEdit',
    primaryField: 'vehicle_no',
    primarySecondValue: 'date2',
    primaryFieldFallback: 'phone', // Optional
    secondaryField: 'is_active',
    //  secondaryField: "transporter_name",
    avatarField: 'src',

    decorators: {
      conditionField: 'status',
      options: ['pending', 'approved', 'unidentified'],
      colors: ['yellow', 'green', 'red'],
    },
  },
]
class Vehicles extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tabs: templateTabs,
      user: null,
      singleVehicleslist: null,
      SalesOrder: '',
      vehicles: [],
      addNewvehicles: false,
      users: [],
      VehicleNo: '',
      TpIdentifier: '',
      Transporter: '',
      TransporterGroup: '',
      TransporterName: '',
      TransporterGroupName: '',
      colorArr: [white, lightGrey],
      selectedTransporterId: '',
      error: '',
      success: '',
      status: false,
      openMessage: true,
      editVehicles: false,
      fetched: false,
      errorMsg: {
        VehicleNo: false,
        TransporterName: false,
        Transporter: false,

        TransporterName: false,
        TransporterGroupName: false,
        TpIdentifier: false,
        TransporterGroup: false,
      },
    }
  }

  componentDidMount() {
    this.setInitialValues(this.props)
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next)
    if (next.match.params.hasOwnProperty('tab')) {
      let tab = next.match.params['tab']
      if (tab === 'vehiclesEdit' && next.match.params.hasOwnProperty('id')) {
        let name = this.state.tabs[0].data[next.match.params['id']]
          ? this.props.transpoterslist.filter(
              (data) =>
                data.id ===
                this.state.tabs[0].data[next.match.params['id']].transporter,
            )
          : []
        this.setState(
          {
            singleVehicleslist: this.state.tabs[0].data[
              next.match.params['id']
            ],
            VehicleNo: this.state.tabs[0].data[next.match.params['id']]
              ? this.state.tabs[0].data[next.match.params['id']].vehicle_no
              : '',
            TransporterName: this.state.tabs[0].data[next.match.params['id']]
              ? name.length > 0
                ? name[0].name
                  ? name[0].name
                  : ''
                : this.state.tabs[0].data[next.match.params['id']]
                    .transporter_name
              : '',
            Transporter: this.state.tabs[0].data[next.match.params['id']]
              ? this.state.tabs[0].data[next.match.params['id']].transporter
              : '',
            TransporterGroupName: this.state.tabs[0].data[
              next.match.params['id']
            ]
              ? this.state.tabs[0].data[next.match.params['id']]
                  .transporter_group_name
              : '',
            TpIdentifier: this.state.tabs[0].data[next.match.params['id']]
              ? this.state.tabs[0].data[next.match.params['id']].tp_identifier
              : '',
            status: this.state.tabs[0].data[next.match.params['id']]
              ? this.state.tabs[0].data[next.match.params['id']]
                  .transporter_group
              : '',
            TransporterGroup: this.state.tabs[0].data[next.match.params['id']]
              ? this.state.tabs[0].data[next.match.params['id']]
                  .transporter_group
              : '',
            addNewvehicles: false,
            openMessage: false,
          },
          () => {
            console.log(this.state.singleVehicleslist, 'singleVehicleslist')
          },
        )
      } else if (tab === 'new') {
      }
    } else this.setState({ singleVehicleslist: null })
  }

  listClickHandler = (value) => {
    console.log(value, 'value')
    let name = value.transporter
      ? this.props.transpoterslist.filter(
          (data) => data.id === value.transporter,
        )
      : ''
    this.setState({
      addNewvehicles: false,
      singleVehicleslist: true,
      VehicleNo: value.vehicle_no,
      Transporter: value.transporter,
      status: value.is_active,

      TransporterName:
        name.length > 0
          ? name[0].name
            ? name[0].name
            : ''
          : value.transporter_name,
      TransporterGroupName: value.transporter_group_name,
      TpIdentifier: value.tp_identifier,
      TransporterGroup: value.transporter_group,
      openMessage: false,
      fetched: false,
    })
    // this.props.fetchVehicles(value.id);
  }
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e

      tab.data =
        props.vehicles && props.vehicles.length > 0 ? props.vehicles : []
      return tab
    })
    this.setState({ vehicles: props.vehicles, tabs })
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg

    errorMsg.VehicleNo = false
    errorMsg.TransporterName = false
    errorMsg.Transporter = false
    errorMsg.TransporterName = false
    errorMsg.TransporterGroupName = false
    errorMsg.TpIdentifier = false
    errorMsg.TransporterGroup = false
    this.setState({ [event.target.name]: event.target.value, errorMsg })
    //this.setState({ [event.target.name]: event.target.value });
  }
  handleClearFields = () => {
    this.setState({
      VehicleNo: '',
      Transporter: '',
      TransporterName: '',
      TransporterGroupName: '',
      TpIdentifier: '',
      TransporterGroup: '',
    })
  }
  render() {
    const {
      tabs,
      vehicles,
      addNewvehicles,
      singleVehicleslist,
      VehicleNo,
      Transporter,
      TransporterName,
      TransporterGroupName,
      TpIdentifier,
      TransporterGroup,
      error,
      success,
      status,
      vehicle_no,
      errorMsg,
    } = this.state
    const { transpoterslist, transpotersGroupslist } = this.props
    return (
      <Layout
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          {
            data: vehicle_no,
            hintText: 'Search VehiclesNo',
            labelField: 'vehicle_no',
          } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.props.history.push('/vehicles/new')
          this.setState({
            addNewvehicles: true,
            singleVehicleslist: false,
            status: true,
            editVehicles: true,
          })
          this.handleClearFields()
        }}
      >
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: '', success: '' })}
          />
        ) : (
          ''
        )}
        {this.state.openMessage ? (
          <div
            style={{
              height: 500,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <NoData />
          </div>
        ) : (
          ''
        )}
        {this.state.editVehicles ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0]
                target.style.borderRadius = '20px'
                target.style.maxHeight = 'calc(100% - 62px)'
                target.style.minHeight = 'fit-content'
                target.style.minWidth = '70%'
                target.style.maxWidth = 'calc(100% - 150px)'
                target.style.margin = '32px 75px'
                target.style.padding = '0 50px'
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({
                editVehicles: false,
                openMessage: true,
                errorMsg: {
                  VehicleNo: false,
                  TransporterName: false,
                  Transporter: false,

                  TransporterName: false,
                  TransporterGroupName: false,
                  TpIdentifier: false,
                  TransporterGroup: false,
                },
              })
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '20px 0px',
                fontSize: '1.5rem',
              }}
            >
              {' '}
              <span style={{ color: 'black' }}>
                {addNewvehicles
                  ? ' Add Vehicles Detail'
                  : 'Edit Vehicles Detail'}
              </span>
              <div>
                <Toggle
                  status={status}
                  handleChange={() => {
                    this.setState({ status: !status })
                  }}
                />
              </div>
            </div>
            {/* <Paper
              style={{
                border: "1px solid #8080802b",
                padding: "20px 50px",
                margin: " 0 80px",
              }}
            > */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontSize: '1rem',
                color: 'grey',
                background: 'white',
                margin: '30px 0',
              }}
            >
              <CssTextField
                autoComplete="off"
                style={
                  styles.withDivider50 //disabled={tp}
                }
                error={errorMsg.VehicleNo ? true : false}
                helperText={
                  errorMsg.VehicleNo ? 'Please Add VehicleNo!' : false
                }
                id="outlined-basic"
                label="VehicleNo"
                name="VehicleNo"
                variant="outlined"
                value={VehicleNo}
                onChange={this.handleChange}
              />

              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsg.TpIdentifier ? true : false}
                helperText={
                  errorMsg.TpIdentifier ? 'Please Add TpIdentifier!' : false
                }
                id="outlined-basic"
                label="TpIdentifier"
                name="TpIdentifier"
                variant="outlined"
                value={TpIdentifier}
                onChange={this.handleChange}
              />
            </div>
            <div
              style={
                {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: '1rem',
                  color: 'grey',
                  background: 'white',
                  margin: '20px 0',
                } //height: "100%",
              }
            >
              <CssTextField
                autoComplete="off"
                value={TransporterName ? TransporterName : TransporterName}
                onChange={(e) => {
                  this.setState({
                    Transporter: e.currentTarget.id,
                    TransporterName: e.target.value,
                  })
                }}
                style={
                  styles.withDivider50 //disabled={tp}
                }
                error={errorMsg.Transporter ? true : false}
                helperText={
                  errorMsg.Transporter ? 'Please Add TransporterName!' : false
                }
                id="outlined-select-currency-native"
                select
                label="Transporter"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {transpoterslist.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    id={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </CssTextField>

              <CssTextField
                autoComplete="off"
                value={TransporterGroupName ? TransporterGroupName : ''}
                onChange={(e) => {
                  this.setState({
                    TransporterGroup: e.currentTarget.id,
                    TransporterGroupName: e.target.value,
                  })
                }}
                style={
                  styles.withDivider50 // } //   // TransporterGroupName ? TransporterGroupName : "" // defaultValue={"hikuj" //disabled={tp}
                }
                error={errorMsg.TransporterGroup ? true : false}
                helperText={
                  errorMsg.TransporterGroup
                    ? 'Please Add TransporterGroupName!'
                    : false
                }
                id="outlined-select-currency-native"
                select
                label="TransporterGroup"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              >
                {' '}
                {transpotersGroupslist.map((option) => (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    id={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </CssTextField>
            </div>
            {/* <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", fontSize: "1rem", color: "grey", background: "white", margin: "30px 0" }}>
              <CssTextField autoComplete="off" style={styles.withDivider50} id="outlined-basic" label="TpIdentifier" variant="outlined" name="TpIdentifier" variant="outlined" value={TpIdentifier} onChange={this.handleChange} />
            </div> */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                padding: '40px 0',
                width: '100%',
              }}
            >
              <Button
                style={{ background: primary, color: 'white', width: '200px' }}
                onClick={() => {
                  // disabled={VehicleNo && Transporter && TpIdentifier && TransporterGroup ? false : true}
                  if (VehicleNo) {
                    if (Transporter) {
                      if (TpIdentifier) {
                        if (TransporterGroup) {
                          if (addNewvehicles) {
                            const addData = {
                              vehicle_no: VehicleNo,
                              transporter: Transporter,
                              tp_identifier: TpIdentifier,
                              transporter_group: TransporterGroup,
                              is_active: status,
                            }
                            this.props
                              .vehicleAdd(addData, this.props.user.user.uid)
                              .then(() => {
                                this.props
                                  .fetchVehicles(this.props.user.user.uid)
                                  .then((res) => {
                                    if (res) {
                                      this.setState(
                                        {
                                          success: 'Added successfully !',
                                          vehicles: res,
                                          editVehicles: false,
                                          openMessage: true,
                                        },
                                        () => {
                                          this.handleClearFields()
                                        },
                                      )
                                    }
                                  })
                              })
                          } else {
                            const editData = {
                              vehicle_no: VehicleNo,
                              transporter: Transporter,
                              tp_identifier: TpIdentifier,
                              transporter_group: TransporterGroup,
                              status: status,
                            }
                            this.props
                              .vehicleEdit(
                                editData,
                                this.state.singleVehicleslist.id,
                                this.props.user.user.uid,
                              )
                              .then(() => {
                                this.props
                                  .fetchVehicles(this.props.user.user.uid)
                                  .then((res) => {
                                    if (res) {
                                      this.setState({
                                        success: 'Edited successfully !',
                                        vehicles: res,
                                        editVehicles: false,
                                        openMessage: false,
                                      })
                                    }
                                  })
                              })
                          }
                        } else {
                          var errorMsg = this.state.errorMsg
                          errorMsg.TransporterGroup = true
                          this.setState({ errorMsg })
                        }
                      } else {
                        var errorMsg = this.state.errorMsg
                        errorMsg.TpIdentifier = true
                        this.setState({ errorMsg })
                      }
                    } else {
                      var errorMsg = this.state.errorMsg
                      errorMsg.Transporter = true
                      this.setState({ errorMsg })
                    }
                  } else {
                    var errorMsg = this.state.errorMsg
                    errorMsg.VehicleNo = true
                    this.setState({ errorMsg })
                  }
                }}
              >
                {addNewvehicles ? 'add ' : 'edit'}
              </Button>
            </div>
          </Dialog>
        ) : (
          ''
        )}
        {this.state.openMessage == false ? (
          <>
            {(vehicles.length > 0 && singleVehicleslist) || addNewvehicles ? (
              <Paper style={{ width: '100%', height: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '20px 100px',
                    fontSize: '1.5rem',
                  }}
                >
                  {' '}
                  <span style={{ color: 'black' }}>Vehicles Detail</span>
                  <Divider />
                </div>
                <Paper
                  style={{
                    border: '1px solid #8080802b',
                    padding: '20px 0',
                    margin: ' 0 100px',
                    borderRadius: '10px',
                    boxShadow: '3px 3px #8080805c',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title="Edit Vehicles"
                      aria-label="add"
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <EditIcon
                        onClick={() => {
                          this.setState({ editVehicles: true })
                        }}
                        style={{
                          width: 50,
                          marginRight: '3%',
                          color: 'black',
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                      fontSize: '1rem',
                      color: 'grey',
                      background: 'white',
                      margin: '20px 0 20px 30px',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div style={{ width: '90%' }}>
                        <div style={{ borderRadius: '10px' }}>
                          <span style={styles.contentStyle}>
                            VehicleNo :
                            <span style={{ marginLeft: '10px', color: 'grey' }}>
                              {' '}
                              {VehicleNo ? VehicleNo : 'Null'}
                            </span>
                          </span>
                          <span style={styles.contentStyle}>
                            Transporter :{' '}
                            <span style={{ marginLeft: '10px', color: 'grey' }}>
                              {' '}
                              {Transporter ? Transporter : 'Null'}
                            </span>
                          </span>
                          <span style={styles.contentStyle}>
                            TpIdentifier :{' '}
                            <span style={{ marginLeft: '10px', color: 'grey' }}>
                              {' '}
                              {TpIdentifier ? TpIdentifier : 'Null'}
                            </span>
                          </span>
                          <span style={styles.contentStyle}>
                            TransporterGroup :{' '}
                            <span style={{ marginLeft: '10px', color: 'grey' }}>
                              {' '}
                              {TransporterGroup ? TransporterGroup : 'Null'}
                            </span>
                          </span>

                          <span style={styles.contentStyle}>
                            Status :{' '}
                            <span style={{ marginLeft: '10px', color: 'grey' }}>
                              {' '}
                              {status ? 'Active' : 'Inactive'}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>{' '}
              </Paper>
            ) : (
              <div
                style={{
                  height: 500,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <NoData />
              </div>
            )}
          </>
        ) : (
          ''
        )}
      </Layout>
    )
  }
}

export default withRouter(Vehicles)
