import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "./ListItem";
import SearchBar from "./SearchBar";
import "./styles.css";
import { primary } from "../../styles/colors";
import RaisedSearchBar from "./RaisedSearchBar";
import { StyledCircularProgress } from "../../styles/StyledComponents";

const styles = {
  fab: { position: "absolute", bottom: 16, right: 16 },
  tab: (isMobile, length) => ({
    minWidth: isMobile ? `${100 / length}%` : 291.47 / length,
  }),
  paper: { color: primary, backgroundColor: "#f8f8f8" },
  container: (isMobile, search, RaisedSearchBar) => ({
    height: isMobile
      ? search || RaisedSearchBar
        ? "calc(100% - 56px)"
        : "100%"
      : search || RaisedSearchBar
      ? "calc(100% - 72px)"
      : "100%",
    overflow: "auto",
    // unicodeBidi: "bidi-override",
    // direction: "rtl",
    margin: "0 2px",
    width: "calc(100% - 4px)",
  }),
};

function TabPanel(props) {
  const { children, value, index } = props;
  if (value === index) return children;
  else return null;
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function SubMenuList(props) {
  const [selectedIndex, setIndex] = useState(-1);
  const [tabIndex, setTab] = useState(0);
  const [searchedData, setSearchedData] = useState(null);

  const handleSearch = (e, newValue) => {
    console.log(newValue, "newValue");
    if (props?.tabs?.[tabIndex]?.searchArr) {
      setIndex(-1);

      setSearchedData(newValue);

      if (typeof props?.serchClick === "function" && props?.tabs?.[tabIndex]) {
        props.serchClick(newValue);
      }
      props.history.push(`${props?.tabs?.[tabIndex]?.goBackRoute}`);
    } else {
      const index = props?.tabs?.[tabIndex]?.data?.findIndex((e) => e === newValue);
      if (index > -1) {
        setSearchedData(newValue);
        setIndex(index);
        if (props.setOpen) props.setOpen(false);
        if (props.setIndex) props.setIndex(index);

        if (props.setSwipeableIndex) {
          setTimeout(() => {
            props.setSwipeableIndex(1);
            if (typeof props?.serchClick === "function" && props?.tabs?.[tabIndex]) {
              props.serchClick(props?.tabs?.[tabIndex]?.data?.[index]);
            }
            props.history.push(`${props?.tabs?.[tabIndex]?.route}/${index}`);
          }, 350);
        } else {
          if (typeof props?.serchClick === "function" && props?.tabs?.[tabIndex]) {
            props.serchClick(props?.tabs?.[tabIndex]?.data?.[index]);
          }
          props.history.push(`${props?.tabs?.[tabIndex]?.route}/${index}`);
        }
      }
    }
  };

  const handleTabsChange = (e, value) => {
    setTab(value);
    setIndex(-1);
    setSearchedData(null);
    if (props.setTab) props.setTab(value);
    if (props.setIndex) props.setIndex(-1);
  };

  const listClickHandler = (index) => {
    setIndex(index);
    if (props.setOpen) props.setOpen(false);
    if (props.setIndex) props.setIndex(index);
    if (props.setSwipeableIndex) props.setSwipeableIndex(1);
    if (typeof props?.listClickHandler === "function" && props?.tabs?.[tabIndex]) {
      console.log(
        index,
        "qwdddddddddd",
        tabIndex,
        props?.tabs?.[tabIndex]?.data,
        props?.tabs?.[tabIndex]?.data?.[index]
      );
      props.listClickHandler(props?.tabs?.[tabIndex]?.data?.[index]);
    }
    console.log(index, "qwdddddddddd wdff");

    props.history.push(`${props?.tabs?.[tabIndex]?.route}/${index}`);
  };

  useEffect(
    () => {
      if (props.swipeableIndex === 0) {
        setIndex(-1);
        setSearchedData(null);
      }
    },
    [props.swipeableIndex]
  );
  // useEffect(
  //   () => {

  if (
    selectedIndex === -1 &&
    props.match.params.hasOwnProperty("id") &&
    typeof props?.listClickHandler === "function" &&
    props?.tabs?.[tabIndex]?.data?.length > 0
  ) {
    listClickHandler(Number(props.match.params["id"]));
    console.log(props.match.params.hasOwnProperty("id"), props.match.params["id"], "idddddddddddddddddddd");
  }
  //   }, []
  // )

  console.log(selectedIndex, props?.tabs?.[tabIndex], "idddddddddddddddddddd");
  return (
    <>
      {props.RaisedSearchBar ? (
        <RaisedSearchBar
          hintText={props.RaisedSearchBar?.hintText || "Search"}
          labelField={props.RaisedSearchBar?.labelField}
          handleSearch={handleSearch}
          searchedData={searchedData}
          data={props.RaisedSearchBar.data || props?.tabs?.[tabIndex]?.data}
        />
      ) : (
        props.search && (
          <SearchBar
            hintText={props.search?.hintText || "Search"}
            labelField={props.search?.labelField}
            handleSearch={handleSearch}
            searchedData={searchedData}
            data={props.search.data || props?.tabs?.[tabIndex]?.data}
          />
        )
      )}
      {}
      <div className="noTrack" style={styles.container(props.isMobile, props.search, props.RaisedSearchBar)}>
        {props.noTab ? (
          ""
        ) : (
          <Paper square style={styles.paper}>
            <Tabs value={tabIndex} indicatorColor="primary" color="white" onChange={handleTabsChange}>
              {props?.tabs?.map((tab, index) => (
                <Tooltip key={`${"Tooltip"}-${index}`} title={tab.tooltip} placement="top">
                  <Tab
                    {...a11yProps(index)}
                    key={`TAB-${tab}-${index}`}
                    label={tab.label}
                    style={styles.tab(props.isMobile, props.tabs.length)}
                  />
                </Tooltip>
              ))}
            </Tabs>
          </Paper>
        )}
        {props.loading ? (
          <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
            <StyledCircularProgress color="inherit" />
          </div>
        ) : props.noList ? (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "60%",
              color: "#998f8f",
              fontSize: "2rem",
            }}
          >
            <span> Coming Soon</span>
          </div>
        ) : (
          <>
            {props?.tabs?.map((tab, i) => (
              <TabPanel key={`${"TabPanel"}-${i}`} value={tabIndex} index={i}>
                <List key={`${"List"}-${i}`} disablePadding>
                  {props?.tabs?.[i]?.data?.map((menu, index) => (
                    <ListItem
                      primarySecondValue={tab.dispatchList ? menu[tab.primarySecondValue] : ""}
                      key={`ListItem-${i}-${index}`}
                      selectedIndex={selectedIndex}
                      index={index}
                      is_finalised={tab.is_finalised ? menu[tab.is_finalised] : false}
                      dispatchList={tab.dispatchList}
                      salesList={tab.salesList}
                      listClickHandler={listClickHandler}
                      primary={menu[tab.primaryField] || menu[tab.primaryFieldFallback]}
                      primaryFieldThirdFallback={
                        tab.salesList
                          ? menu[tab.primaryFieldThirdFallback]
                          : tab.dispatchList
                          ? menu[tab.secondaryField]
                          : ""
                      }
                      secondary={
                        tab.secondaryField === "is_active"
                          ? menu[tab.secondaryField] === true
                            ? "Acitve"
                            : menu[tab.secondaryField] === false
                            ? "Inactive"
                            : ""
                          : menu[tab.secondaryField]
                      }
                      avatar={menu[tab.avatarField]}
                      color={
                        tab?.decorators?.colors?.[
                          tab?.decorators?.options.findIndex((e) => e === menu?.[tab?.decorators?.conditionField])
                        ]
                      }
                    />
                  ))}
                </List>
              </TabPanel>
            ))}
          </>
        )}
      </div>
    </>
  );
}
