// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// import Layout from "../layouts/Layout";
// import Paper from "@material-ui/core/Paper";
// import NoData from "../styles/Illustrations/NoData";
// class DemoFullWidthPaper extends Component {
//   render() {
//     const paperStyle = { width: "100%", height: "100%", padding: "16px 0px" };
//     return (
//       <Layout noList={true} tabs={{}} noTab={true}>
//         <Paper style={{ width: "100%", height: "100%" }}>
//           <div
//             style={{
//               height: 500,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <NoData />
//             {/* <img src={Rhombus} alt="loading..." />; */}
//           </div>
//         </Paper>
//       </Layout>
//     );
//   }
// }

// export default withRouter(DemoFullWidthPaper);

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary } from "../styles/colors";
import { Divider, TablePagination } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { Paper, InputBase, Avatar } from "@material-ui/core";

import NoData from "../styles/Illustrations/NoData";
const CssTablePagination = withStyles({
  root: {
    "& .MuiSelect-icon": {
      color: primary,
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: primary,
      padding: "0",
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "30px",
    },
  },
})(TablePagination);
const templateTabs = [
  {
    label: "User and Permmision",
    tooltip: "Click to approve pending users",
    data: [],
    route: "/userandpermmision/new",
    primaryField: "date",
    primarySecondValue: "date2",
    primaryFieldFallback: "phone", // Optional
    secondaryField: "date",
    avatarField: "src",
    dispatchList: true,
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
};
class DemoFullWidthPaper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: templateTabs,
      user: null,
      users: [],
      page: 0,
      rowsPerPage: 3,

      contentArea: null,
      pdfExportComponent: null,
    };
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Dates" && next.match.params.hasOwnProperty("id")) {
        this.setState({
          user: this.state.tabs[0].data[next.match.params["id"]],
        });
      } else if (tab === "new") {
        this.setState({ user: null });
      }
    } else this.setState({ user: null });
    // if (next.match.path === "/dashboard/new") alert("NEW");
  }

  listClickHandler = (value) => {
    console.log(value);
  };

  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.users;
      return tab;
    });
    console.log(tabs, "tabs");
    this.setState({ users: props.users, tabs });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  render() {
    const {
      tabs,
      users,
      user,
      tripList,
      page,
      rowsPerPage,
      contentArea,
      pdfExportComponent,
    } = this.state;
    return (
      <Layout
        tabs={tabs}
        noList={true}
        // RaisedSearchBar={
        //   { data: users, hintText: "Search Dates", labelField: "date" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        // }
        listClickHandler={
          this.listClickHandler // }} //   this.props.history.push("/dashboard/new"); // fabClickHandler={() => {
        }
      >
        <Paper style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "1rem",
              color: "grey",
              background: "#d3d3d35c",
            }}
          >
            <NoData />
          </div>
        </Paper>{" "}
      </Layout>
    );
  }
}

export default withRouter(DemoFullWidthPaper);
