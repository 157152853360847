import React, { Component } from "react";

export class NoData extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="259.952"
        height="166.368"
        viewBox="0 0 259.952 166.368"
      >
        <g
          id="No_data-cuate_1_"
          data-name="No data-cuate (1)"
          transform="translate(-68.96 -67.542)"
        >
          <g
            id="freepik--Floor--inject-275"
            transform="translate(68.96 201.835)"
          >
            <path
              id="Path_121361"
              data-name="Path 121361"
              d="M68.96,381.894l32.494-.1,32.494-.038,64.988-.073,64.988.073,32.494.038,32.494.1L296.418,382l-32.494.034-64.988.073-64.988-.073L101.454,382Z"
              transform="translate(-68.96 -381.68)"
              fill="#263238"
            />
          </g>
          <g
            id="freepik--Shadow--inject-275"
            transform="translate(77.211 207.046)"
          >
            <ellipse
              id="Ellipse_415"
              data-name="Ellipse 415"
              cx="122.58"
              cy="13.432"
              rx="122.58"
              ry="13.432"
              transform="translate(0 0)"
              fill="#ebebeb"
            />
          </g>
          <g
            id="freepik--Character--inject-275"
            transform="translate(87.555 75.661)"
          >
            <path
              id="Path_121362"
              data-name="Path 121362"
              d="M262.879,382.047c-1,.338-29.557.428-30.651-.248-.427-.257-.671-4.416-.855-9.452-.038-1.086-.068-2.21-.094-3.347L229,353.764l19.1-.124,1.8,15.24-.073,3.352s10.952,4.852,12.073,5.733S263.875,381.709,262.879,382.047Z"
              transform="translate(-179.178 -239.453)"
              fill="#eb9481"
            />
            <path
              id="Path_121363"
              data-name="Path 121363"
              d="M265.953,402.457c-1,.338-29.557.427-30.651-.248-.5-.316-.778-6.391-.932-12.8l18.579-.12-.073,3.352s10.952,4.852,12.072,5.733S266.95,402.119,265.953,402.457Z"
              transform="translate(-182.253 -259.862)"
              fill="#fff"
            />
            <path
              id="Path_121364"
              data-name="Path 121364"
              d="M266.057,406.945c-1,.338-29.557.428-30.652-.248-.427-.257-.671-4.416-.855-9.452l18.412-.115s10.952,4.852,12.073,5.733S267.053,406.608,266.057,406.945Z"
              transform="translate(-182.356 -264.351)"
              fill="#a6a6a6"
            />
            <path
              id="Path_121365"
              data-name="Path 121365"
              d="M268.6,417.353c-4.788-.1-24.111-.056-28.852.209-.038,0-.038.026,0,.03,4.745.2,24.068,0,28.852-.162C268.706,417.425,268.706,417.353,268.6,417.353Z"
              transform="translate(-185.317 -275.899)"
              fill="#263238"
            />
            <path
              id="Path_121366"
              data-name="Path 121366"
              d="M296.34,408.149a3.848,3.848,0,0,0-2.86,1.18,3.7,3.7,0,0,0-1.06,2.565c0,.03.051.03.056,0a4.446,4.446,0,0,1,3.847-3.676.035.035,0,0,0,.017-.068Z"
              transform="translate(-215.487 -270.658)"
              fill="#263238"
            />
            <path
              id="Path_121367"
              data-name="Path 121367"
              d="M274.857,398.229a6.67,6.67,0,0,0-5.322,2.236c-.043.06.043.124.1.094a26.608,26.608,0,0,1,5.258-2.095.12.12,0,0,0-.034-.235Z"
              transform="translate(-202.378 -264.98)"
              fill="#263238"
            />
            <path
              id="Path_121368"
              data-name="Path 121368"
              d="M278.334,399.56a6.741,6.741,0,0,0-5.322,2.236c-.047.064.038.128.1.1a26.33,26.33,0,0,1,5.254-2.1.12.12,0,0,0-.03-.235Z"
              transform="translate(-204.368 -265.742)"
              fill="#263238"
            />
            <path
              id="Path_121369"
              data-name="Path 121369"
              d="M281.8,400.9a6.739,6.739,0,0,0-5.322,2.236c-.043.06.043.124.1.094a26.606,26.606,0,0,1,5.258-2.095.12.12,0,0,0-.034-.235Z"
              transform="translate(-206.351 -266.509)"
              fill="#263238"
            />
            <path
              id="Path_121370"
              data-name="Path 121370"
              d="M285.265,402.239a6.659,6.659,0,0,0-5.322,2.236c-.047.06.043.124.1.094a26.5,26.5,0,0,1,5.258-2.095.12.12,0,0,0-.034-.235Z"
              transform="translate(-208.336 -267.276)"
              fill="#263238"
            />
            <path
              id="Path_121371"
              data-name="Path 121371"
              d="M240.463,407.26a1.673,1.673,0,0,0,0,3.343C242.6,410.59,242.618,407.247,240.463,407.26Z"
              transform="translate(-184.823 -270.15)"
              fill="#263238"
            />
            <path
              id="Path_121372"
              data-name="Path 121372"
              d="M280.787,385.8c-.97-.8-2.193,0-2.787.825a10.08,10.08,0,0,0-1.71,4.908.055.055,0,0,0,.06.056.231.231,0,0,0,.346.15,16.673,16.673,0,0,0,3.963-3.129C281.245,387.836,281.723,386.553,280.787,385.8Zm-2.334,4.078c-.658.483-1.338.932-1.979,1.441.342-.958.637-1.924,1.073-2.847a10.709,10.709,0,0,1,.693-1.282c.427-.637,2.291-2.244,2.488-.162C280.839,388.173,279.235,389.31,278.453,389.883Z"
              transform="translate(-206.252 -257.677)"
              fill="#263238"
            />
            <path
              id="Path_121373"
              data-name="Path 121373"
              d="M262.381,396.918a16.8,16.8,0,0,0,5.027-.492.235.235,0,0,0,.141-.351.051.051,0,0,0,0-.081,10.046,10.046,0,0,0-4.643-2.338c-1.009-.192-2.437.086-2.565,1.338C260.188,396.174,261.407,396.764,262.381,396.918Zm-1.163-1.086c-1.282-1.642,1.167-1.783,1.9-1.612a10.936,10.936,0,0,1,1.4.453,29.738,29.738,0,0,1,2.74,1.3c-.812.081-1.612.227-2.42.338C263.878,396.443,261.937,396.734,261.219,395.832Z"
              transform="translate(-197.114 -262.33)"
              fill="#263238"
            />
            <path
              id="Path_121374"
              data-name="Path 121374"
              d="M163.32,189.2s22.174,37.722,22.768,40.373c.911,4.07,5.737,44.259,5.737,44.259l23.085-.427s-3.976-35.435-7.742-47.11c-2.71-8.409-22.281-36.3-22.281-36.3Z"
              transform="translate(-141.576 -145.31)"
              fill="#37474f"
            />
            <path
              id="Path_121375"
              data-name="Path 121375"
              d="M252.986,377.875c-3.313-.026-15.056-.158-19.468.252-.034,0-.03.06,0,.064,1.518.218,16.151-.12,19.464-.227a.047.047,0,1,0,0-.09Z"
              transform="translate(-181.751 -253.304)"
              fill="#263238"
            />
            <path
              id="Path_121376"
              data-name="Path 121376"
              d="M172.064,197.275c1.453,2.279,2.992,4.51,4.472,6.772s2.967,4.523,4.433,6.78q4.386,6.84,8.55,13.77a42.162,42.162,0,0,1,3.463,6.75,41.361,41.361,0,0,1,1.945,7.242c.453,2.475.752,4.976,1.03,7.477q.427,3.989.893,7.981.911,8.169,1.825,16.339c.073.667.145,1.334.214,2a.09.09,0,0,1-.18,0c-.68-5.408-1.244-10.837-1.86-16.245q-.462-4.048-.919-8.092c-.286-2.509-.56-5.023-.949-7.515a49.165,49.165,0,0,0-1.624-7.267,34.9,34.9,0,0,0-3.189-7.054c-2.672-4.613-5.5-9.144-8.332-13.68s-5.737-9.11-8.721-13.59c-.368-.551-.74-1.1-1.124-1.646C171.961,197.27,172.038,197.232,172.064,197.275Z"
              transform="translate(-146.537 -149.922)"
              fill="#263238"
            />
            <path
              id="Path_121377"
              data-name="Path 121377"
              d="M141.468,375.929c-1.052-.064-28.215-11.068-28.967-12.111a.359.359,0,0,1-.043-.2c0-.936,1.885-4.2,3.458-8.494.1-.274.8-1.548,1.77-3.262,2.7-4.7,7.4-12.791,7.4-12.791l17.754,7.054-8.5,12.718-1.321,3.078s8.332,8.614,9.033,9.832S142.516,375.989,141.468,375.929Z"
              transform="translate(-112.457 -231.111)"
              fill="#eb9481"
            />
            <path
              id="Path_121378"
              data-name="Path 121378"
              d="M141.468,393.058c-1.052-.064-28.215-11.068-28.967-12.111a.359.359,0,0,1-.043-.2c0-.936,1.885-4.2,3.458-8.494.1-.274.8-1.548,1.77-3.262l16.672,6.981-1.321,3.078s8.332,8.614,9.033,9.832S142.516,393.118,141.468,393.058Z"
              transform="translate(-112.457 -248.24)"
              fill="#fff"
            />
            <path
              id="Path_121379"
              data-name="Path 121379"
              d="M141.47,397.426c-1.052-.064-28.215-11.068-28.967-12.111-.291-.4.923-4.1,3.42-8.7l17.1,6.8s8.332,8.605,9.037,9.832S142.518,397.486,141.47,397.426Z"
              transform="translate(-112.459 -252.609)"
              fill="#a6a6a6"
            />
            <path
              id="Path_121380"
              data-name="Path 121380"
              d="M144.458,408.218c-4.4-1.89-22.328-9.1-26.821-10.628-.038,0-.047,0,0,.026,4.322,1.966,22.307,9.029,26.8,10.687C144.526,408.324,144.552,408.256,144.458,408.218Z"
              transform="translate(-115.404 -264.614)"
              fill="#263238"
            />
            <path
              id="Path_121381"
              data-name="Path 121381"
              d="M171.876,411.285a3.848,3.848,0,0,0-3.1,0,3.719,3.719,0,0,0-1.941,1.975c0,.026.038.047.051.021a4.45,4.45,0,0,1,4.963-1.962.03.03,0,0,0,.021-.034Z"
              transform="translate(-143.592 -272.268)"
              fill="#263238"
            />
            <path
              id="Path_121382"
              data-name="Path 121382"
              d="M156.425,394.35a6.661,6.661,0,0,0-5.771.077c-.064.038,0,.128.056.124a26.649,26.649,0,0,1,5.66.03.12.12,0,0,0,.056-.231Z"
              transform="translate(-134.31 -262.405)"
              fill="#263238"
            />
            <path
              id="Path_121383"
              data-name="Path 121383"
              d="M159.135,396.89a6.661,6.661,0,0,0-5.771.077c-.064.038,0,.128.056.124a26.373,26.373,0,0,1,5.66.03.12.12,0,0,0,.056-.231Z"
              transform="translate(-135.861 -263.859)"
              fill="#263238"
            />
            <path
              id="Path_121384"
              data-name="Path 121384"
              d="M161.853,399.43a6.66,6.66,0,0,0-5.771.077c-.068.038,0,.128.051.124a26.371,26.371,0,0,1,5.66.03.12.12,0,0,0,.06-.231Z"
              transform="translate(-137.416 -265.313)"
              fill="#263238"
            />
            <path
              id="Path_121385"
              data-name="Path 121385"
              d="M164.563,401.969a6.675,6.675,0,0,0-5.771.073c-.068.043,0,.133.051.128a26.372,26.372,0,0,1,5.66.03.12.12,0,0,0,.06-.231Z"
              transform="translate(-138.967 -266.766)"
              fill="#263238"
            />
            <path
              id="Path_121386"
              data-name="Path 121386"
              d="M121.265,388.6a1.672,1.672,0,0,0-1.231,3.108A1.672,1.672,0,0,0,121.265,388.6Z"
              transform="translate(-116.211 -259.392)"
              fill="#263238"
            />
            <path
              id="Path_121387"
              data-name="Path 121387"
              d="M164.677,384.858c-.6-1.107-2.026-.834-2.894-.282a10.054,10.054,0,0,0-3.42,3.9.051.051,0,0,0,.034.073c0,.141.094.308.261.274a16.758,16.758,0,0,0,4.848-1.419C164.339,386.91,165.246,385.905,164.677,384.858Zm-3.7,2.9c-.791.2-1.586.363-2.368.594a29.9,29.9,0,0,1,2.056-2.236,10.839,10.839,0,0,1,1.133-.94c.62-.427,2.963-1.218,2.364.787C163.835,387.073,161.924,387.521,160.979,387.761Z"
              transform="translate(-138.735 -256.888)"
              fill="#263238"
            />
            <path
              id="Path_121388"
              data-name="Path 121388"
              d="M145.54,387.313a16.926,16.926,0,0,0,4.844,1.432c.167.038.278-.128.265-.274a.047.047,0,0,0,.034-.073,10.055,10.055,0,0,0-3.42-3.912c-.855-.556-2.287-.834-2.894.269S144.689,386.813,145.54,387.313Zm-.667-1.445c-.594-2,1.744-1.21,2.364-.778a10.4,10.4,0,0,1,1.129.945,29.926,29.926,0,0,1,2.052,2.24c-.782-.231-1.577-.4-2.368-.6C147.092,387.42,145.2,386.992,144.873,385.868Z"
              transform="translate(-130.615 -256.834)"
              fill="#263238"
            />
            <path
              id="Path_121389"
              data-name="Path 121389"
              d="M140.264,261.9l-19.874-8.024s24.641-36.23,24.82-38.928c.162-2.385-10.662-23.17-11.085-29.228a18.079,18.079,0,0,1,6.147-14.74l20.5,8.717a17.918,17.918,0,0,1-5.557,5.386s10.512,24.025,9.914,31.412S140.264,261.9,140.264,261.9Z"
              transform="translate(-116.999 -134.879)"
              fill="#37474f"
            />
            <path
              id="Path_121390"
              data-name="Path 121390"
              d="M146.078,365.568c-2.873-1.235-2.031-.923-4.92-2.137-1.411-.59-10.687-4.45-12.115-4.771-.03,0-.051.043-.021.06,1.235.757,10.589,4.455,12.013,5.019,2.911,1.146,2.086.787,5.01,1.9a.043.043,0,1,0,.034-.068Z"
              transform="translate(-121.932 -242.327)"
              fill="#263238"
            />
            <path
              id="Path_121391"
              data-name="Path 121391"
              d="M201.292,202.935a38.69,38.69,0,0,0-6.241,2.026.066.066,0,1,0,.043.124c2.095-.611,4.224-1.184,6.284-1.907C201.536,203.123,201.455,202.9,201.292,202.935Z"
              transform="translate(-159.717 -153.172)"
              fill="#263238"
            />
            <path
              id="Path_121392"
              data-name="Path 121392"
              d="M202.935,200.851a9.252,9.252,0,0,0-.526,1.1,2.527,2.527,0,0,0-.269.919c0,.064.086.077.124.038a3.21,3.21,0,0,0,.513-.855c.175-.338.355-.671.522-1.017A.2.2,0,1,0,202.935,200.851Z"
              transform="translate(-163.801 -151.917)"
              fill="#263238"
            />
            <path
              id="Path_121393"
              data-name="Path 121393"
              d="M203.766,193.861c.128-.316.252-.628.385-.945s.3-.641.427-.975c0-.051.1,0,.081.038-.128.316-.209.65-.308.975s-.209.65-.316.975a15.816,15.816,0,0,1-.705,1.787,2.266,2.266,0,0,0,.983-.171,1.766,1.766,0,0,0,.7-.855c.18-.351.346-.71.53-1.06a7.084,7.084,0,0,0,.569-1.193c0-.051.1,0,.081.038A13.872,13.872,0,0,1,205.1,195c-.192.312-.342.641-.693.761a2.9,2.9,0,0,1-1.167.081c-.051,0-.111,0-.094-.077q.263-.968.62-1.907Z"
              transform="translate(-164.375 -146.867)"
              fill="#263238"
            />
            <path
              id="Path_121394"
              data-name="Path 121394"
              d="M202.457,205.77c.731,1.543,1.372,3.082,2.052,4.621s1.282,3.027,1.894,4.557c1.223,3.087,2.317,6.233,3.279,9.4.475,1.573.915,3.159,1.308,4.758a34.2,34.2,0,0,1,.949,4.856,12.825,12.825,0,0,1-.427,4.873,28.505,28.505,0,0,1-1.808,4.544c-.449.923-.6.8-.385.333.611-1.325,2.214-5.339,2.1-8.712a25.145,25.145,0,0,0-.744-4.784q-.56-2.424-1.261-4.809c-.911-3.138-1.992-6.212-3.1-9.281-.624-1.71-1.282-3.42-1.924-5.13s-1.282-3.283-2-5.173C202.363,205.753,202.436,205.723,202.457,205.77Z"
              transform="translate(-163.939 -154.784)"
              fill="#263238"
            />
            <path
              id="Path_121395"
              data-name="Path 121395"
              d="M167.2,188.139c.355-.133.855.086,1.214.145a10.379,10.379,0,0,0,1.406.141,10.17,10.17,0,0,0,1.4-.047,2.993,2.993,0,0,0,1.338-.355,6.81,6.81,0,0,0,1.71-1.808,10.521,10.521,0,0,0,1.33-2.334c0-.051.09,0,.073.034-.15.475-.261.953-.427,1.419a8.617,8.617,0,0,1-.658,1.261,5.391,5.391,0,0,1-1.77,2.009,5.267,5.267,0,0,1-2.8.389,10.956,10.956,0,0,1-1.586-.209c-.376-.081-1.009-.141-1.248-.47a.115.115,0,0,1,.026-.175Z"
              transform="translate(-143.764 -142.252)"
              fill="#263238"
            />
            <path
              id="Path_121396"
              data-name="Path 121396"
              d="M133.642,252.388q4.9-7.421,9.619-14.962t9.311-15.2a21.131,21.131,0,0,0,2.043-3.783,6.6,6.6,0,0,0-.239-4.326,73.371,73.371,0,0,0-3.052-7.695c-1.146-2.527-2.381-5.01-3.6-7.5a77.172,77.172,0,0,1-3.42-7.554,13.081,13.081,0,0,1-.534-7.631,22.134,22.134,0,0,1,3.6-6.99c.393-.564.8-1.124,1.2-1.71.03-.043.094,0,.064.051A29.812,29.812,0,0,0,144.4,182.3a12.461,12.461,0,0,0-.821,4.04,13.077,13.077,0,0,0,.646,4.134,54.428,54.428,0,0,0,3.258,7.5c1.184,2.445,2.45,4.976,3.617,7.494a80.222,80.222,0,0,1,3.206,7.776,10.784,10.784,0,0,1,.821,3.668,7.978,7.978,0,0,1-1.372,3.788q-4.489,7.6-9.178,15.048c-3.121,4.976-6.331,9.952-9.58,14.877l-1.231,1.86C133.7,252.567,133.591,252.478,133.642,252.388Z"
              transform="translate(-124.578 -137.191)"
              fill="#263238"
            />
            <path
              id="Path_121397"
              data-name="Path 121397"
              d="M216.829,133.139c1.851,4.061,11.2,9.657,15.578,10.26a92.013,92.013,0,0,0,15.993.081c3.847-.59.906-10.957-1.364-10.687-6.241.727-11.542,1.539-13.21,1.539-2.035,0-13.043-3.531-14.368-4.031C216.209,129.091,215.781,130.835,216.829,133.139Z"
              transform="translate(-171.919 -111.364)"
              fill="#eb9481"
            />
            <path
              id="Path_121398"
              data-name="Path 121398"
              d="M215.9,133.712c2.343,3.467,10.127,9.136,10.127,9.136l1.082-2.394,3.578-7.917s-7.152-2.244-11.859-3.27a10.312,10.312,0,0,0-1.684-.239C214.14,128.864,213.879,130.72,215.9,133.712Z"
              transform="translate(-170.932 -110.855)"
              fill="#e4e6f2"
            />
            <path
              id="Path_121399"
              data-name="Path 121399"
              d="M215.9,133.722c2.343,3.467,10.127,9.136,10.127,9.136l1.082-2.394c-2.9-3.373-7.387-8.482-9.965-11.418C214.14,128.874,213.879,130.73,215.9,133.722Z"
              transform="translate(-170.932 -110.865)"
              fill="#263238"
            />
            <path
              id="Path_121400"
              data-name="Path 121400"
              d="M238.052,146.078c.594-1.458,3.236-7.327,3.894-8.55.043-.085.111-.077.073,0a86.236,86.236,0,0,1-3.907,8.55C238.1,146.13,238.027,146.112,238.052,146.078Z"
              transform="translate(-184.358 -115.693)"
              fill="#263238"
            />
            <path
              id="Path_121401"
              data-name="Path 121401"
              d="M285.49,136.028c3.8-.5,8.494-1.347,12.474.342a2.138,2.138,0,0,1,1.064.911c1.8.727,1.992,1.791,1.992,1.791a3.557,3.557,0,0,1,1.518,2.308s2.1.855,1.783,2.232c-.351,1.522-3.232,0-5.327.124-4.809.3-11.542,2.065-12.932,2.287Z"
              transform="translate(-211.519 -114.492)"
              fill="#eb9481"
            />
            <path
              id="Path_121402"
              data-name="Path 121402"
              d="M304.711,147.009a22.143,22.143,0,0,1,8.755.919.039.039,0,1,0,.026-.073,16.369,16.369,0,0,0-8.781-.889C304.643,146.958,304.643,147.013,304.711,147.009Z"
              transform="translate(-222.494 -120.993)"
              fill="#263238"
            />
            <path
              id="Path_121403"
              data-name="Path 121403"
              d="M303.288,140.507a22.349,22.349,0,0,1,7.87,1.483v-.026a14.71,14.71,0,0,0-7.879-1.526C303.2,140.448,303.194,140.5,303.288,140.507Z"
              transform="translate(-221.67 -117.364)"
              fill="#263238"
            />
            <path
              id="Path_121404"
              data-name="Path 121404"
              d="M302.6,136.863a22.974,22.974,0,0,1,6.173,1.206v-.026a14.087,14.087,0,0,0-6.186-1.282C302.5,136.794,302.506,136.863,302.6,136.863Z"
              transform="translate(-221.272 -115.288)"
              fill="#263238"
            />
            <path
              id="Path_121405"
              data-name="Path 121405"
              d="M293.991,156.64c-.611.286-.462.607.705.876s6.019.351,5.848,2.043-7.015,1.479-8.926.881a32.1,32.1,0,0,1-4.788-2.24C288.185,157.974,291.366,157.115,293.991,156.64Z"
              transform="translate(-212.286 -126.669)"
              fill="#eb9481"
            />
            <path
              id="Path_121406"
              data-name="Path 121406"
              d="M227.476,152.574c-.03.731-.1,1.475-.2,2.214a.09.09,0,0,1,.03-.081c.141-.385.282-.778.453-1.15h.038a6.165,6.165,0,0,1-.368,1.188c-.077.184-.15.363-.231.543-.051.329-.111.654-.175.979-.128.611-.705,3.21-.855,3.651-.026.09-.137.068-.128-.034.03-.479.513-3.185.611-3.8a33.22,33.22,0,0,1,.765-3.527C227.437,152.535,227.48,152.553,227.476,152.574Z"
              transform="translate(-177.483 -124.326)"
              fill="#263238"
            />
            <path
              id="Path_121407"
              data-name="Path 121407"
              d="M186.092,156.556c-1.141.586-2.612,1.5-9.238.192-.428-.085-.829-.188-1.248-.312-6.212-1.864-13.68-9.17-14.086-9.687,0,0,9.871-16,22.495-21.033,1.56-.62,4.142.577,5.557,2.069.509.534,1.616,4.463,1.193,7.644S187.23,155.97,186.092,156.556Z"
              transform="translate(-140.546 -108.87)"
              fill="#e4e6f2"
            />
            <path
              id="Path_121408"
              data-name="Path 121408"
              d="M216.528,148.32a4.848,4.848,0,0,1-.278,1.992,4.638,4.638,0,0,1,.278-1.992Z"
              transform="translate(-171.871 -121.906)"
              fill="#fff"
            />
            <path
              id="Path_121409"
              data-name="Path 121409"
              d="M206.457,173.81a4.416,4.416,0,0,1-.034,1.009,4.191,4.191,0,0,1-.244.983,4.211,4.211,0,0,1,.034-1.013A4.432,4.432,0,0,1,206.457,173.81Z"
              transform="translate(-166.104 -136.499)"
              fill="#fff"
            />
            <path
              id="Path_121410"
              data-name="Path 121410"
              d="M189.348,154.54a4.852,4.852,0,0,1-.278,2A4.673,4.673,0,0,1,189.348,154.54Z"
              transform="translate(-156.31 -125.467)"
              fill="#fff"
            />
            <path
              id="Path_121411"
              data-name="Path 121411"
              d="M223.117,172.51a4.442,4.442,0,0,1-.034,1.013,4.19,4.19,0,0,1-.244.983,4.211,4.211,0,0,1,.034-1.013A4.411,4.411,0,0,1,223.117,172.51Z"
              transform="translate(-175.642 -135.755)"
              fill="#fff"
            />
            <path
              id="Path_121412"
              data-name="Path 121412"
              d="M201.54,151.219a4.415,4.415,0,0,1,1.009.03,4.2,4.2,0,0,1,.983.248A4.818,4.818,0,0,1,201.54,151.219Z"
              transform="translate(-163.457 -123.557)"
              fill="#fff"
            />
            <path
              id="Path_121413"
              data-name="Path 121413"
              d="M182.12,169.634a4.848,4.848,0,0,1,1.992.278,4.638,4.638,0,0,1-1.992-.278Z"
              transform="translate(-152.339 -134.102)"
              fill="#fff"
            />
            <path
              id="Path_121414"
              data-name="Path 121414"
              d="M205,189.563a4.818,4.818,0,0,1,1.992.278A4.664,4.664,0,0,1,205,189.563Z"
              transform="translate(-165.438 -145.511)"
              fill="#fff"
            />
            <path
              id="Path_121415"
              data-name="Path 121415"
              d="M176.13,182.324a4.848,4.848,0,0,1,1.992.278A4.664,4.664,0,0,1,176.13,182.324Z"
              transform="translate(-148.91 -141.367)"
              fill="#fff"
            />
            <path
              id="Path_121416"
              data-name="Path 121416"
              d="M216.77,130.384a4.848,4.848,0,0,1,1.992.278A4.848,4.848,0,0,1,216.77,130.384Z"
              transform="translate(-172.177 -111.631)"
              fill="#fff"
            />
            <path
              id="Path_121417"
              data-name="Path 121417"
              d="M208.99,136.78a4.169,4.169,0,0,1-.735.693,4.54,4.54,0,0,1-.855.522,4.349,4.349,0,0,1,.727-.693A4.506,4.506,0,0,1,208.99,136.78Z"
              transform="translate(-166.812 -115.299)"
              fill="#fff"
            />
            <path
              id="Path_121418"
              data-name="Path 121418"
              d="M178.195,154.91a4.322,4.322,0,0,1-.74.688,4.031,4.031,0,0,1-.855.522,4.5,4.5,0,0,1,.74-.688A4.139,4.139,0,0,1,178.195,154.91Z"
              transform="translate(-149.179 -125.679)"
              fill="#fff"
            />
            <path
              id="Path_121419"
              data-name="Path 121419"
              d="M216.645,163.09a4.347,4.347,0,0,1-.74.693,4.139,4.139,0,0,1-.855.522,4.348,4.348,0,0,1,.74-.693,4.411,4.411,0,0,1,.855-.522Z"
              transform="translate(-171.192 -130.362)"
              fill="#fff"
            />
            <path
              id="Path_121420"
              data-name="Path 121420"
              d="M191.745,178.77a4.347,4.347,0,0,1-.74.693,4.215,4.215,0,0,1-.855.522,4.348,4.348,0,0,1,.74-.693A4.413,4.413,0,0,1,191.745,178.77Z"
              transform="translate(-156.937 -139.339)"
              fill="#fff"
            />
            <path
              id="Path_121421"
              data-name="Path 121421"
              d="M226.185,141.67a4.348,4.348,0,0,1-.74.693,4.541,4.541,0,0,1-.855.522,4.348,4.348,0,0,1,.74-.693A4.215,4.215,0,0,1,226.185,141.67Z"
              transform="translate(-176.654 -118.099)"
              fill="#fff"
            />
            <path
              id="Path_121422"
              data-name="Path 121422"
              d="M226.174,157.7a4.348,4.348,0,0,1-.693-.74,4.108,4.108,0,0,1-.522-.855,4.168,4.168,0,0,1,.693.735,4.671,4.671,0,0,1,.522.859Z"
              transform="translate(-176.865 -126.366)"
              fill="#fff"
            />
            <path
              id="Path_121423"
              data-name="Path 121423"
              d="M170.674,170.225a4.348,4.348,0,0,1-.693-.74,4.411,4.411,0,0,1-.522-.855,4.347,4.347,0,0,1,.693.74,4.216,4.216,0,0,1,.522.855Z"
              transform="translate(-145.092 -133.534)"
              fill="#fff"
            />
            <path
              id="Path_121424"
              data-name="Path 121424"
              d="M219.424,185.12a4.168,4.168,0,0,1-.693-.735,4.541,4.541,0,0,1-.522-.855,4.348,4.348,0,0,1,.693.74,4.3,4.3,0,0,1,.522.851Z"
              transform="translate(-173.001 -142.064)"
              fill="#fff"
            />
            <path
              id="Path_121425"
              data-name="Path 121425"
              d="M194.994,142.67a4.321,4.321,0,0,1-.693-.735,4.673,4.673,0,0,1-.522-.855,4.168,4.168,0,0,1,.693.735,4.674,4.674,0,0,1,.522.855Z"
              transform="translate(-159.015 -117.761)"
              fill="#fff"
            />
            <path
              id="Path_121426"
              data-name="Path 121426"
              d="M195.424,193.595a4.348,4.348,0,0,1-.693-.74,4.507,4.507,0,0,1-.522-.855,4.169,4.169,0,0,1,.693.735,4.455,4.455,0,0,1,.522.859Z"
              transform="translate(-159.261 -146.913)"
              fill="#fff"
            />
            <path
              id="Path_121427"
              data-name="Path 121427"
              d="M199.58,163.555a4.5,4.5,0,0,1-.688-.74,4.032,4.032,0,0,1-.522-.855,4.323,4.323,0,0,1,.688.74A4.138,4.138,0,0,1,199.58,163.555Z"
              transform="translate(-161.643 -129.715)"
              fill="#fff"
            />
            <path
              id="Path_121428"
              data-name="Path 121428"
              d="M217.287,124.985c-1.612-.3-3.125-3.629-3.317-5.87,0-.154.752-1.372,1.612-2.9.522-.928,1.073-1.975,1.513-2.967.09-.2.616.479.616.479l1.146.855,3.783,2.9a17.245,17.245,0,0,0-2.279,3.535,2.138,2.138,0,0,0-.137.427.52.52,0,0,0,0,.073C219.989,122.369,218.839,125.271,217.287,124.985Z"
              transform="translate(-170.574 -101.807)"
              fill="#eb9481"
            />
            <path
              id="Path_121429"
              data-name="Path 121429"
              d="M224.085,122.259a.525.525,0,0,0,0,.073,4.236,4.236,0,0,1-.654-.252c-4.142-1.941-2.317-7.49-2.317-7.49l1.624.8,3.783,2.9a17.245,17.245,0,0,0-2.279,3.535A2.138,2.138,0,0,0,224.085,122.259Z"
              transform="translate(-174.434 -102.596)"
              fill="#263238"
            />
            <path
              id="Path_121430"
              data-name="Path 121430"
              d="M244.42,95.58a4.32,4.32,0,0,1,1.9,3.339,8.906,8.906,0,0,1-1.035,4.3C245.108,103.3,244.42,95.58,244.42,95.58Z"
              transform="translate(-188.006 -91.712)"
              fill="#263238"
            />
            <path
              id="Path_121431"
              data-name="Path 121431"
              d="M221.377,94.225c-1.137,2.244-1.077,9.319.342,11.141,2.061,2.642,6.028,3.552,8.426.94,2.321-2.531,2.082-11.748.427-13.466C228.166,90.3,223.062,90.9,221.377,94.225Z"
              transform="translate(-174.36 -89.246)"
              fill="#eb9481"
            />
            <path
              id="Path_121432"
              data-name="Path 121432"
              d="M222.87,124h0"
              transform="translate(-175.669 -107.983)"
              fill="none"
              stroke="#f5a79e"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="0"
            />
            <path
              id="Path_121433"
              data-name="Path 121433"
              d="M235.9,111.43s-.038.026-.034.043c.026.483-.026,1.039-.457,1.21v.026C235.944,112.623,236,111.883,235.9,111.43Z"
              transform="translate(-182.848 -100.786)"
              fill="#263238"
            />
            <path
              id="Path_121434"
              data-name="Path 121434"
              d="M234.721,110.279c-.778-.043-.8,1.513-.081,1.552S235.371,110.313,234.721,110.279Z"
              transform="translate(-182.108 -100.127)"
              fill="#263238"
            />
            <path
              id="Path_121435"
              data-name="Path 121435"
              d="M242.679,111.39a.039.039,0,0,1,.038.043c-.026.483.03,1.039.466,1.206v.026C242.644,112.583,242.58,111.843,242.679,111.39Z"
              transform="translate(-186.987 -100.764)"
              fill="#263238"
            />
            <path
              id="Path_121436"
              data-name="Path 121436"
              d="M243.135,110.229c.774-.047.812,1.509.094,1.552S242.485,110.267,243.135,110.229Z"
              transform="translate(-187.003 -100.098)"
              fill="#263238"
            />
            <path
              id="Path_121437"
              data-name="Path 121437"
              d="M233.817,106.045a6.263,6.263,0,0,0,.6-.205,1.026,1.026,0,0,0,.581-.325.329.329,0,0,0-.047-.4.816.816,0,0,0-.821-.1,1.21,1.21,0,0,0-.7.509.355.355,0,0,0,.385.517Z"
              transform="translate(-181.691 -97.077)"
              fill="#263238"
            />
            <path
              id="Path_121438"
              data-name="Path 121438"
              d="M243.3,107.7a6.262,6.262,0,0,1-.616-.171,1.026,1.026,0,0,1-.594-.295.321.321,0,0,1,.026-.4.808.808,0,0,1,.812-.141,1.2,1.2,0,0,1,.727.47.351.351,0,0,1-.355.534Z"
              transform="translate(-186.642 -98.048)"
              fill="#263238"
            />
            <path
              id="Path_121439"
              data-name="Path 121439"
              d="M233.905,122.088c.115.115.227.269.4.286a1.282,1.282,0,0,0,.522-.111s.03,0,0,.026a.646.646,0,0,1-.616.252.517.517,0,0,1-.376-.427.037.037,0,0,1,.068-.026Z"
              transform="translate(-181.946 -106.875)"
              fill="#263238"
            />
            <path
              id="Path_121440"
              data-name="Path 121440"
              d="M237.76,111.061s.021.718,0,1.056a7.045,7.045,0,0,0-1.971.068c-.154.034-.137-.09.038-.171a2.89,2.89,0,0,1,1.676-.222c.034-.09-.056-1.111,0-1.107a2.856,2.856,0,0,1,.744.257c0-1.5-.239-2.992-.218-4.5a.045.045,0,0,1,.09,0,24.215,24.215,0,0,1,.427,4.831C238.551,111.459,237.888,111.129,237.76,111.061Z"
              transform="translate(-183.005 -97.908)"
              fill="#263238"
            />
            <path
              id="Path_121441"
              data-name="Path 121441"
              d="M236.283,119.162a2.137,2.137,0,0,1-.9,1.073c-.646.261-.855-.325-.205-.855A2.086,2.086,0,0,1,236.283,119.162Z"
              transform="translate(-182.49 -105.209)"
              fill="#263238"
            />
            <path
              id="Path_121442"
              data-name="Path 121442"
              d="M235.6,120.591a.855.855,0,0,1-.227.133c-.594.239-.829-.235-.351-.714C235.352,120.036,235.724,120.155,235.6,120.591Z"
              transform="translate(-182.482 -105.699)"
              fill="#ff99ba"
            />
            <path
              id="Path_121443"
              data-name="Path 121443"
              d="M220.069,97.561c.782.239,1.68-2.112,1.937-2.992.222-.8.479-3.322.517-3.5s3.42,2.236,5.378,1.663,3.287-2.347,3.317-3.172-2.42-2.92-4.536-3.027S221.7,89.1,221.7,89.1a9.833,9.833,0,0,0,.94-1.257,2.326,2.326,0,0,0-1.654,1.428s.274-1.227.171-1.248-1.167,1.026-.928,1.821a4.164,4.164,0,0,0-1.342,2.232C218.773,93.243,219.15,97.279,220.069,97.561Z"
              transform="translate(-173.376 -86.533)"
              fill="#263238"
            />
            <path
              id="Path_121444"
              data-name="Path 121444"
              d="M233.179,97.247a6.5,6.5,0,0,1-1.86.667,4.416,4.416,0,0,1-2.137-.342,6.566,6.566,0,0,1-1.864-1.1c-.492-.4-.932-.893-1.458-1.253-.133-.094-.282.1-.2.218a7.062,7.062,0,0,0,3.574,2.633,4.074,4.074,0,0,0,3.942-.8S233.187,97.247,233.179,97.247Z"
              transform="translate(-177.253 -91.49)"
              fill="#263238"
            />
            <path
              id="Path_121445"
              data-name="Path 121445"
              d="M219.751,109.017s-1.214-2.462-2.308-2.065-.428,3.8.7,4.429a1.218,1.218,0,0,0,1.71-.4Z"
              transform="translate(-172.242 -98.198)"
              fill="#eb9481"
            />
            <path
              id="Path_121446"
              data-name="Path 121446"
              d="M218.685,109.28s-.026,0,0,.034a3.057,3.057,0,0,1,1.283,2.137.671.671,0,0,0-.966-.333c-.026,0,0,.047,0,.047a.748.748,0,0,1,.774.385,3.612,3.612,0,0,1,.291.718c.026.081.167.06.154-.03h0C220.4,111.174,219.8,109.562,218.685,109.28Z"
              transform="translate(-173.266 -99.556)"
              fill="#263238"
            />
            <path
              id="Path_121447"
              data-name="Path 121447"
              d="M204.716,135.228c-.855,4.844,4.395,21.161,7.759,24.209,5.006,4.531,14.261,7.511,17.617,8.482,1.462.427,5.506-9.862,4.027-10.542-3.168-1.441-13.009-4.519-13.945-5.288s-6.579-8.922-9.093-13.616C207.717,132.154,205.139,132.778,204.716,135.228Z"
              transform="translate(-165.222 -113.414)"
              fill="#eb9481"
            />
            <path
              id="Path_121448"
              data-name="Path 121448"
              d="M259.937,187.25s4.369,1.116,7.186,1.975a18.2,18.2,0,0,1,5.194,2.428c1.488,1.321,6.866,7.785,5.327,9.123-1.394,1.214-5.31-4.929-5.31-4.929s4.5,5.848,2.565,7-5.468-6.378-5.468-6.378,4.484,6.113,2.291,6.981c-1.659.654-5.215-5.985-5.215-5.985s3.822,5.28,2.137,6.028c-1.855.829-5.519-5.1-5.519-5.1-4.583,1.184-7.267-.128-9.033-1.282C253.679,196.83,259.937,187.25,259.937,187.25Z"
              transform="translate(-193.533 -144.194)"
              fill="#eb9481"
            />
            <path
              id="Path_121449"
              data-name="Path 121449"
              d="M293.411,202.381c.556.667,1.12,1.342,1.612,2.056s.958,1.449,1.381,2.206a14.8,14.8,0,0,1,1.368,3.1,31.815,31.815,0,0,0-2.95-5.181c-.492-.714-.949-1.453-1.436-2.167C293.372,202.381,293.4,202.363,293.411,202.381Z"
              transform="translate(-216.037 -152.852)"
              fill="#263238"
            />
            <path
              id="Path_121450"
              data-name="Path 121450"
              d="M287.306,206.433a18.291,18.291,0,0,1,3.7,5.425c.034.077,0,.073-.056,0-.6-.928-3.027-4.519-3.668-5.412C287.272,206.433,287.293,206.42,287.306,206.433Z"
              transform="translate(-212.544 -155.173)"
              fill="#263238"
            />
            <path
              id="Path_121451"
              data-name="Path 121451"
              d="M281.4,208.979a13.393,13.393,0,0,1,2.745,4.275c.03.077-.03.064-.077,0-.885-1.441-1.569-2.565-2.715-4.236C281.337,208.979,281.376,208.94,281.4,208.979Z"
              transform="translate(-209.149 -156.624)"
              fill="#263238"
            />
            <path
              id="Path_121452"
              data-name="Path 121452"
              d="M208.493,134.072c-3.9-5.519-6.237-3.476-5.455,2.518a96.709,96.709,0,0,0,2.7,12.282l9.832-4.771S210.977,137.607,208.493,134.072Z"
              transform="translate(-164.229 -111.903)"
              fill="#e4e6f2"
            />
            <path
              id="Path_121453"
              data-name="Path 121453"
              d="M221.542,143.47a4.394,4.394,0,0,1,.278.97,4.441,4.441,0,0,1,.064,1.013,4.42,4.42,0,0,1-.274-.975A4.176,4.176,0,0,1,221.542,143.47Z"
              transform="translate(-174.905 -119.13)"
              fill="#fff"
            />
            <path
              id="Path_121454"
              data-name="Path 121454"
              d="M207.92,150.856a4.7,4.7,0,0,1,1.979-.342,4.385,4.385,0,0,1-.97.274A4.189,4.189,0,0,1,207.92,150.856Z"
              transform="translate(-167.11 -123.16)"
              fill="#fff"
            />
            <path
              id="Path_121455"
              data-name="Path 121455"
              d="M206.959,131.24a4.77,4.77,0,0,1-1.159,1.646A4.771,4.771,0,0,1,206.959,131.24Z"
              transform="translate(-165.896 -112.128)"
              fill="#fff"
            />
            <path
              id="Path_121456"
              data-name="Path 121456"
              d="M226.449,157.35a4.463,4.463,0,0,1-.492.885,4.322,4.322,0,0,1-.667.761,4.771,4.771,0,0,1,1.159-1.646Z"
              transform="translate(-177.054 -127.076)"
              fill="#fff"
            />
            <path
              id="Path_121457"
              data-name="Path 121457"
              d="M209.8,163.629a4.771,4.771,0,0,1-1.646-1.159A4.771,4.771,0,0,1,209.8,163.629Z"
              transform="translate(-167.242 -130.007)"
              fill="#fff"
            />
            <path
              id="Path_121458"
              data-name="Path 121458"
              d="M218.96,142.28c.526.722,1.069,1.428,1.607,2.137q-.205-.539-.359-1.09h.026c.107.282.231.56.333.855l.154.428a.763.763,0,0,1,.034.09c.427.551.855,1.1,1.248,1.667.483.675.949,1.359,1.419,2.043s.945,1.535,1.453,2.223c.047.06-.855.428-.953.475s-.15-.034-.056-.064.812-.47.821-.479c-.47-.693-1.013-1.33-1.5-2s-.979-1.342-1.453-2.022c-.962-1.385-1.843-2.821-2.8-4.211Z"
              transform="translate(-173.413 -118.448)"
              fill="#263238"
            />
            <path
              id="Path_121459"
              data-name="Path 121459"
              d="M203.683,150.944a9.194,9.194,0,0,1,.427.876l.162.372c-.227-1.064-.427-2.137-.663-3.2h0c.453,1.71.911,3.458,1.33,5.194.4,1.659.881,3.334,1.111,5.027a5.579,5.579,0,0,1,.923-.312,4.558,4.558,0,0,1-1.017.492,53.357,53.357,0,0,1-1.282-5.233c-.107-.479-.205-.958-.308-1.436-.111-.291-.244-.569-.376-.855s-.252-.607-.372-.911C203.644,150.939,203.674,150.922,203.683,150.944Z"
              transform="translate(-164.643 -122.29)"
              fill="#263238"
            />
            <path
              id="Path_121460"
              data-name="Path 121460"
              d="M209.867,163.4c1.368-.769,7.084-3.728,8.362-4.275.09-.038.137,0,.047.06a86.168,86.168,0,0,1-8.379,4.275C209.863,163.473,209.829,163.426,209.867,163.4Z"
              transform="translate(-168.215 -128.086)"
              fill="#263238"
            />
          </g>
          <g
            id="freepik--Folder--inject-275"
            transform="translate(136.598 105.495)"
          >
            <path
              id="Path_121461"
              data-name="Path 121461"
              d="M401.89,289.01H271.64L290.471,182.2h130.25Z"
              transform="translate(-252.634 -171.136)"
              fill="#e4e6f2"
            />
            <path
              id="Path_121462"
              data-name="Path 121462"
              d="M360.269,156.32H317.644l-1.954,11.064h45.618Z"
              transform="translate(-277.852 -156.32)"
              fill="#e4e6f2"
            />
            <g
              id="Group_11100"
              data-name="Group 11100"
              transform="translate(19.007)"
              opacity="0.1"
            >
              <path
                id="Path_121463"
                data-name="Path 121463"
                d="M401.89,289.01H271.64L290.471,182.2h130.25Z"
                transform="translate(-271.64 -171.136)"
              />
              <path
                id="Path_121464"
                data-name="Path 121464"
                d="M360.269,156.32H317.644l-1.954,11.064h45.618Z"
                transform="translate(-296.859 -156.32)"
              />
            </g>
            <path
              id="Path_121465"
              data-name="Path 121465"
              d="M309.214,161.9a8.194,8.194,0,0,1,0-3.719A8.2,8.2,0,0,1,309.214,161.9Z"
              transform="translate(-274.022 -157.385)"
              fill="#263238"
            />
            <path
              id="Path_121466"
              data-name="Path 121466"
              d="M298.144,165.135a7.269,7.269,0,0,1-1.116-1.5,7.418,7.418,0,0,1-.748-1.71,7.089,7.089,0,0,1,1.116,1.5A7.53,7.53,0,0,1,298.144,165.135Z"
              transform="translate(-266.74 -159.526)"
              fill="#263238"
            />
            <path
              id="Path_121467"
              data-name="Path 121467"
              d="M289.929,173.6a8.2,8.2,0,0,1-3.219-1.86,7.336,7.336,0,0,1,1.71.744,7.516,7.516,0,0,1,1.509,1.116Z"
              transform="translate(-261.261 -165.148)"
              fill="#263238"
            />
            <path
              id="Path_121468"
              data-name="Path 121468"
              d="M287.019,184.764a8.2,8.2,0,0,1-3.719,0A8.2,8.2,0,0,1,287.019,184.764Z"
              transform="translate(-259.309 -172.482)"
              fill="#263238"
            />
            <path
              id="Path_121469"
              data-name="Path 121469"
              d="M290.295,193.91a7.542,7.542,0,0,1-1.5,1.116,7.336,7.336,0,0,1-1.71.744,7.267,7.267,0,0,1,1.5-1.116A7.182,7.182,0,0,1,290.295,193.91Z"
              transform="translate(-261.473 -177.84)"
              fill="#263238"
            />
            <path
              id="Path_121470"
              data-name="Path 121470"
              d="M298.75,200.3a8.178,8.178,0,0,1-1.86,3.223,7.2,7.2,0,0,1,.748-1.71A7.4,7.4,0,0,1,298.75,200.3Z"
              transform="translate(-267.089 -181.499)"
              fill="#263238"
            />
            <path
              id="Path_121471"
              data-name="Path 121471"
              d="M309.912,202.51a7.458,7.458,0,0,1,.214,1.86,7.268,7.268,0,0,1-.214,1.86,7.084,7.084,0,0,1-.214-1.86A7.46,7.46,0,0,1,309.912,202.51Z"
              transform="translate(-274.421 -182.764)"
              fill="#263238"
            />
            <path
              id="Path_121472"
              data-name="Path 121472"
              d="M319.06,199.989a7.266,7.266,0,0,1,1.116,1.5,7.181,7.181,0,0,1,.744,1.71,8.178,8.178,0,0,1-1.86-3.223Z"
              transform="translate(-279.782 -181.316)"
              fill="#263238"
            />
            <path
              id="Path_121473"
              data-name="Path 121473"
              d="M325.45,193.3a7.181,7.181,0,0,1,1.71.744,7.267,7.267,0,0,1,1.5,1.116,7.183,7.183,0,0,1-1.71-.744,7.268,7.268,0,0,1-1.5-1.116Z"
              transform="translate(-283.44 -177.491)"
              fill="#263238"
            />
            <path
              id="Path_121474"
              data-name="Path 121474"
              d="M327.66,184.054a8.217,8.217,0,0,1,3.724,0A8.216,8.216,0,0,1,327.66,184.054Z"
              transform="translate(-284.705 -172.075)"
              fill="#263238"
            />
            <path
              id="Path_121475"
              data-name="Path 121475"
              d="M325.089,172.99a7.4,7.4,0,0,1,1.5-1.111,7.027,7.027,0,0,1,1.71-.748,8.178,8.178,0,0,1-3.223,1.86Z"
              transform="translate(-283.228 -164.799)"
              fill="#263238"
            />
            <path
              id="Path_121476"
              data-name="Path 121476"
              d="M318.45,164.785a7.515,7.515,0,0,1,.744-1.71,7.267,7.267,0,0,1,1.116-1.5,7.183,7.183,0,0,1-.744,1.71A7.268,7.268,0,0,1,318.45,164.785Z"
              transform="translate(-279.432 -159.326)"
              fill="#263238"
            />
            <path
              id="Path_121477"
              data-name="Path 121477"
              d="M376.244,294.2H245.716L227.18,191.27H357.712Z"
              transform="translate(-227.18 -176.329)"
              fill="#e4e6f2"
            />
            <circle
              id="Ellipse_416"
              data-name="Ellipse 416"
              cx="5.425"
              cy="5.425"
              r="5.425"
              transform="translate(39.808 67.014)"
              fill="#263238"
            />
            <circle
              id="Ellipse_417"
              data-name="Ellipse 417"
              cx="5.425"
              cy="5.425"
              r="5.425"
              transform="translate(82.695 67.014)"
              fill="#263238"
            />
            <path
              id="Path_121478"
              data-name="Path 121478"
              d="M379.132,323.92l-.3.569c-.026.051-2.83,5.232-8.469,5.232-5.13,0-6.412-4.878-6.447-5.087l-.154-.62-1.244.3.15.624c0,.06,1.53,6.066,7.695,6.066,6.412,0,9.478-5.677,9.606-5.921l.3-.564Z"
              transform="translate(-304.663 -242.271)"
              fill="#263238"
            />
          </g>
          <g
            id="freepik--speech-bubble--inject-275"
            transform="translate(150.372 67.542)"
          >
            <path
              id="Path_121495"
              data-name="Path 121495"
              d="M283.332,79.581a10.653,10.653,0,0,1-17.8,6.742l-5.331.782,2.668-4.608a10.653,10.653,0,1,1,20.464-2.916Z"
              transform="translate(-259.858 -67.706)"
              fill="#fff"
            />
            <path
              id="Path_121496"
              data-name="Path 121496"
              d="M282.874,79.417a10.687,10.687,0,0,1-2.138,5.4,9.45,9.45,0,0,1-2.09,2.065,10.371,10.371,0,0,1-2.565,1.432,10.486,10.486,0,0,1-2.868.684,10.688,10.688,0,0,1-1.479.03,9.483,9.483,0,0,1-1.475-.154,10.756,10.756,0,0,1-5.271-2.6l.124.038-5.322.817-.389.06.2-.342,2.65-4.617v.184a10.764,10.764,0,0,1,.658-9.495A10.707,10.707,0,0,1,279.715,70.6,10.687,10.687,0,0,1,282.874,79.417Zm0,0a10.538,10.538,0,0,0-12.081-11.542,10.422,10.422,0,0,0-4.4,1.736,10.982,10.982,0,0,0-3.189,3.48A10.525,10.525,0,0,0,261.85,77.6a10.632,10.632,0,0,0,.752,4.677l.039.094-.051.09-2.685,4.6-.188-.282,5.335-.748h.073l.051.047a10.5,10.5,0,0,0,5.1,2.612,9.076,9.076,0,0,0,1.445.175,10.37,10.37,0,0,0,1.458,0,10.687,10.687,0,0,0,2.847-.628,10.02,10.02,0,0,0,2.565-1.385,9.739,9.739,0,0,0,2.095-2.039A10.688,10.688,0,0,0,282.874,79.417Z"
              transform="translate(-259.4 -67.542)"
              fill="#263238"
            />
            <path
              id="Path_121497"
              data-name="Path 121497"
              d="M277.338,89.608a1.035,1.035,0,0,1,1.043-1.094,1.094,1.094,0,0,1,0,2.189,1.035,1.035,0,0,1-1.043-1.094Zm.427-2.526-.248-8.482h1.71l-.248,8.482Z"
              transform="translate(-269.668 -73.873)"
              fill="#e4e6f2"
            />
            <path
              id="Path_121498"
              data-name="Path 121498"
              d="M286.848,89.608a1.035,1.035,0,0,1,1.039-1.094,1.094,1.094,0,0,1,0,2.189,1.035,1.035,0,0,1-1.039-1.094Zm.428-2.527L287.04,78.6h1.71l-.248,8.482Z"
              transform="translate(-275.113 -73.873)"
              fill="#e4e6f2"
            />
            <path
              id="Path_121499"
              data-name="Path 121499"
              d="M296.348,89.608a1.035,1.035,0,0,1,1.043-1.094,1.094,1.094,0,1,1,0,2.189,1.035,1.035,0,0,1-1.043-1.094Zm.428-2.526-.248-8.482h1.71l-.244,8.482Z"
              transform="translate(-280.552 -73.873)"
              fill="#e4e6f2"
            />
          </g>
        </g>
      </svg>
    );
  }
}

export default NoData;
