import React, { Component } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";
import { Button } from "@material-ui/core";
import { Download } from "../styles/Icons";
import SnackbarComponent from "./SnackbarComponent";
import moment from "moment";
const styles = {
  exportButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100px",
    boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
    borderRadius: "0.25rem",
    margin: "0 5px 0 0",
    backgroundColor: "white",
    textColor: "#bfbfbf",
  },
  exportText: {
    padding: "5px",
    color: "#bfbfbf",
  },
  notificationStyle: {
    position: "absolute",
    right: "15px",
  },
};

export class ExportToExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      fileExtension: ".xlsx",
      error: "",
      success: "",
    };
  }
  exportToCSV = () => {
    const { apiData, apiData1, apiData2, fileName, wscols, wscols1, wscols2, Heading, Heading1, Heading2 } = this.props;
    console.log(apiData, "apiData");
    const { fileType, fileExtension } = this.state;
    //sheet 1
    const ws = XLSX.utils.json_to_sheet(apiData, {
      skipHeader: Heading ? true : false,
      origin: "A4",
    });

    ws["!merges"] = [{ s: { c: 0, r: 0 }, e: { c: 13, r: 0 } }, { s: { c: 0, r: 1 }, e: { c: 13, r: 1 } }];
    if (Heading) {
      XLSX.utils.sheet_add_aoa(ws, [
        ["Transporting Report", "", "", "", "", "", "", "", "", "", "", "", "", ""],
        [
          moment(new Date().toISOString()).format("Do MMM, ddd, YYYY"),
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ],
        Heading,
      ]);
    }
    ws["!cols"] = wscols;
    var wsrows = [{ hpx: 25 }, { hpx: 25 }, { hpx: 25 }];
    if (Heading) {
      wscols.map((data, i) => {
        ws[`${(i + 10).toString(36).toUpperCase()}1`].s = {
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "00A36C" },
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
          },
          height: { hpx: 16 },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: { rgb: "000000" },
            },
            left: {
              style: "thin",
              color: { rgb: "FFFFFF" },
            },
          },
        };
      });
      wscols.map((data, i) => {
        ws[`${(i + 10).toString(36).toUpperCase()}2`].s = {
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "00A36C" },
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
          },
          height: { hpx: 16 },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: { rgb: "000000" },
            },
            left: {
              style: "thin",
              color: { rgb: "000000" },
            },
          },
        };
      });
      wscols.map((data, i) => {
        ws[`${(i + 10).toString(36).toUpperCase()}3`].s = {
          // set the style for target cell
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "000000" }, //add background color
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
            // ve: "Center"
          },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "FFFFFF" },
            },
            top: {
              style: "thin",
              color: { rgb: "FFFFFF" },
            },
            right: {
              style: "thin",
              color: wscols.length === i + 1 ? { rgb: "000000" } : { rgb: "FFFFFF" },
            },
            left: {
              style: "thin",
              color: { rgb: "FFFFFF" },
            },
          },
        };
      });
    }
    ws["!rows"] = wsrows;
    if (Heading) {
      apiData.map((data, i) => {
        if (data.SNo === "Total in MT") {
          wsrows.push({ hpx: 25 });
          ws["!merges"].push({ s: { c: 0, r: i + 3 }, e: { c: 4, r: i + 3 } });
          wscols.map((data, i2) => {
            ws[`${(i2 + 10).toString(36).toUpperCase()}${i + 4}`].s = {
              font: {
                name: "Calibri",
                sz: 12,
                color: {
                  rgb: "000000",
                },
              },
              fill: {
                fgColor: { rgb: "F1ECB9" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
              },
              height: { hpx: 20 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
              },
            };
          });
        } else {
          wsrows.push({ hpx: 20 });
          wscols.map((data, i2) => {
            ws[`${(i2 + 10).toString(36).toUpperCase()}${i + 4}`].s = {
              font: {
                name: "Calibri",
                sz: 11,
                color: {
                  rgb: "000000",
                },
              },
              fill: {
                fgColor: { rgb: "ECFFDC" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
              },
              height: { hpx: 16 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
              },
            };
          });
        }
      });
    }

    //sheet 22222
    const ws2 = XLSX.utils.json_to_sheet(apiData1, {
      skipHeader: Heading1 ? true : false,
      origin: "A3",
    });
    if (Heading1) {
      XLSX.utils.sheet_add_aoa(ws2, [
        ["Transporting Report", "", "", "", "", "", "", "", "", "", ""],
        [moment(new Date().toISOString()).format("Do MMM, ddd, YYYY"), "", "", "", "", "", "", "", "", "", ""],
      ]);
    }
    ws2["!cols"] = wscols1;
    ws2["!merges"] = [{ s: { c: 0, r: 0 }, e: { c: 10, r: 0 } }, { s: { c: 0, r: 1 }, e: { c: 10, r: 1 } }];
    var wsrows = [{ hpx: 25 }, { hpx: 25 }];
    if (Heading1) {
      wscols1.map((data, i2) => {
        ws2[`${(i2 + 10).toString(36).toUpperCase()}${1}`].s = {
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "00A36C" },
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
          },
          height: { hpx: 20 },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: { rgb: "000000" },
            },
            left: {
              style: "thin",
              color: { rgb: "000000" },
            },
          },
        };
      });
      wscols1.map((data, i2) => {
        ws2[`${(i2 + 10).toString(36).toUpperCase()}${2}`].s = {
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "00A36C" },
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
          },
          height: { hpx: 20 },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: { rgb: "000000" },
            },
            left: {
              style: "thin",
              color: { rgb: "000000" },
            },
          },
        };
      });
    }
    ws2["!rows"] = wsrows;
    if (Heading1) {
      console.log(apiData1, wscols1, "apiData1apiData1");
      apiData1.map((data, i) => {
        // console.log(apiData1[i - 1].SNo === "string", "vvvvvvvvvvvvvvvvvvvvvvvv");
        if (typeof data.SNo === "string" && data.SNo !== "#") {
          wsrows.push({ hpx: 25 });
          if (data.SNo === "Total in MT") {
            ws2["!merges"].push({ s: { c: 0, r: i + 2 }, e: { c: 2, r: i + 2 } });
            wscols1.map((data, i2) => {
              ws2[`${(i2 + 10).toString(36).toUpperCase()}${i + 3}`].s = {
                font: {
                  name: "Calibri",
                  sz: 12,
                  color: {
                    rgb: "000000",
                  },
                },
                fill: {
                  fgColor: { rgb: "F1ECB9" }, //add background color
                },
                alignment: {
                  horizontal: "center",
                  wrapText: true,
                  vertical: "center",
                },
                height: { hpx: 20 },
                border: {
                  bottom: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                  top: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                  right: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                  left: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                },
              };
            });
          } else {
            ws2["!merges"].push({ s: { c: 0, r: i + 2 }, e: { c: 10, r: i + 2 } });
            wscols1.map((datas, i2) => {
              ws2[`${(i2 + 10).toString(36).toUpperCase()}${i + 3}`].s = {
                font: {
                  name: "Calibri",
                  sz: 12,
                  color: {
                    rgb: "FFFFFF",
                  },
                },
                fill: {
                  fgColor: { rgb: data.Vehicle_Number }, //add background color
                },
                alignment: {
                  horizontal: "center",
                  wrapText: true,
                  vertical: "center",
                },
                height: { hpx: 20 },
                border: {
                  bottom: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                  top: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                  right: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                  left: {
                    style: "thin",
                    color: { rgb: "000000" },
                  },
                },
              };
            });
          }
        } else if (data.SNo === "#") {
          wsrows.push({ hpx: 25 });

          // ws2["!merges"].push({ s: { c: 0, r: i + 3 }, e: { c: 10, r: i + 3 } });
          wscols1.map((data, i2) => {
            ws2[`${(i2 + 10).toString(36).toUpperCase()}${i + 3}`].s = {
              // set the style for target cell
              font: {
                name: "Calibri",
                sz: 12,
                bold: true,
                color: {
                  rgb: "FFFFFF",
                },
              },
              fill: {
                fgColor: { rgb: "000000" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
                // ve: "Center"
              },
              height: { hpx: 20 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",

                  color: wscols1.length === i2 + 1 ? { rgb: "000000" } : { rgb: "FFFFFF" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "FFFFFF" },
                },
              },
            };
          });
        } else {
          wsrows.push({ hpx: 20 });

          wscols1.map((data, i2) => {
            ws2[`${(i2 + 10).toString(36).toUpperCase()}${i + 3}`].s = {
              font: {
                name: "Calibri",
                sz: 11,
                color: {
                  rgb: "000000",
                },
              },
              fill: {
                fgColor: { rgb: "ECFFDC" }, //add background color
              },
              alignment: {
                horizontal: "center",
                wrapText: true,
                vertical: "center",
              },
              height: { hpx: 16 },
              border: {
                bottom: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                top: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                right: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
                left: {
                  style: "thin",
                  color: { rgb: "000000" },
                },
              },
            };
          });
        }
      });
    }

    //sheet 33333
    const ws3 = XLSX.utils.json_to_sheet(apiData2, {
      skipHeader: Heading2 ? true : false,
      origin: "A4",
    });
    ws3["!merges"] = [{ s: { c: 0, r: 0 }, e: { c: 2, r: 0 } }, { s: { c: 0, r: 1 }, e: { c: 2, r: 1 } }];

    if (Heading2) {
      XLSX.utils.sheet_add_aoa(ws3, [
        ["Summary", "", ""],
        [moment(new Date().toISOString()).format("Do MMM, ddd, YYYY"), "", ""],
        Heading2,
      ]);
    }
    ws3["!cols"] = wscols2;
    var wsrows = [{ hpx: 50 }, { hpx: 50 }, { hpx: 50 }];
    if (Heading2) {
      wscols2.map((data, i2) => {
        ws3[`${(i2 + 10).toString(36).toUpperCase()}1`].s = {
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "00A36C" },
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
          },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: { rgb: "000000" },
            },
            left: {
              style: "thin",
              color: { rgb: "000000" },
            },
          },
        };
      });
      wscols2.map((data, i2) => {
        ws3[`${(i2 + 10).toString(36).toUpperCase()}2`].s = {
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "00A36C" },
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
          },
          height: { hpx: 16 },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: { rgb: "000000" },
            },
            left: {
              style: "thin",
              color: { rgb: "000000" },
            },
          },
        };
      });
      wscols2.map((data, i) => {
        ws3[`${(i + 10).toString(36).toUpperCase()}3`].s = {
          // set the style for target cell
          font: {
            name: "Calibri",
            sz: 12,
            bold: true,
            color: {
              rgb: "FFFFFF",
            },
          },
          fill: {
            fgColor: { rgb: "000000" }, //add background color
          },
          alignment: {
            horizontal: "center",
            wrapText: true,
            vertical: "center",
            // ve: "Center"
          },
          height: { hpx: 16 },
          border: {
            bottom: {
              style: "thin",
              color: { rgb: "000000" },
            },
            top: {
              style: "thin",
              color: { rgb: "000000" },
            },
            right: {
              style: "thin",
              color: wscols2.length === i + 1 ? { rgb: "000000" } : { rgb: "FFFFFF" },
            },
            left: {
              style: "thin",
              color: { rgb: "FFFFFF" },
            },
          },
        };
      });
    }
    ws3["!rows"] = wsrows;
    if (Heading2) {
      apiData2.map((data, i) => {
        wsrows.push({ hpx: 50 });

        wscols2.map((data, i2) => {
          ws3[`${(i2 + 10).toString(36).toUpperCase()}${i + 4}`].s = {
            font: {
              name: "Calibri",
              sz: 11,
              color: {
                rgb: "000000",
              },
            },
            fill: {
              fgColor: { rgb: i + 1 === apiData2.length ? "F1ECB9" : "ECFFDC" }, //add background color
            },
            alignment: {
              horizontal: "center",
              wrapText: true,
              vertical: "center",
            },
            height: { hpx: 16 },
            border: {
              bottom: {
                style: "thin",
                color: "000000",
              },
              top: {
                style: "thin",
                color: "000000",
              },
              right: {
                style: "thin",
                color: "000000",
              },
              left: {
                style: "thin",
                color: "000000",
              },
            },
          };
        });
      });
    }
    const wb = {
      Sheets: { ["Transporting Report"]: ws, ["Transporter Wise Report"]: ws2, ["Summary"]: ws3 },
      SheetNames: ["Transporting Report", "Transporter Wise Report", "Summary"],
    };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({
      success: "Xlsx file downloaded successfully!",
    });
  };

  render() {
    const { width } = this.props;
    const { downloadExcel, error, success } = this.state;
    return (
      <>
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        <Button
          onClick={(e) => {
            // this.setState({
            //     downloadExcel: true,
            // });

            this.exportToCSV();
          }}
          style={{
            background: "#1e3559",
            color: "white",
            textTransform: "capitalize",
            borderRadius: "5px",
            margin: "0 10px 0 0",
            minWidth: width ? width : "",
          }}
        >
          <div
            style={{
              marginRight: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Download height="20" width="20" />
          </div>
          Export
        </Button>
        {/* <button
                    style={styles.exportButton}
                    onClick={(e) => {
                        this.setState({
                            downloadExcel: true,
                        });
                    }}
                >
                    <FileIcon />
                    <span style={styles.exportText}> Export</span>
                </button> */}
      </>
    );
  }
}

export default ExportToExcel;
