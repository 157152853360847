import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary } from "../styles/colors";
import { Backdrop, Divider, TablePagination } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import BiltiPrint from "./BiltiPrint";
import PDFPrint from "./PDFPrint";
import Button from "@material-ui/core/Button";
import { Paper, InputBase, Avatar } from "@material-ui/core";

import domtoimage from "dom-to-image";
import { CancelIcon, SearchIcon, FliterIcon, ThreeDotIcon } from "../styles/Icons";
import ExportToExcel from "./ExportToExcel";
import NoData from "../styles/Illustrations/NoData";
import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import moment from "moment";
import SummaryTable from "./SummaryTable";
const CssTablePagination = withStyles({
  root: {
    "& .MuiSelect-icon": {
      color: primary,
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: primary,
      padding: "0",
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "30px",
    },
  },
})(TablePagination);
const templateTabs = [
  {
    label: "Dashboard",
    tooltip: "Dashboard List",
    data: [],
    route: "/dashboard/Dates",
    goBackRoute: "/dashboard",
    primaryField: "formattedDate",
    primarySecondValue: "total_net_weight",
    primaryFieldFallback: "total_trips", // Optional
    secondaryField: "dispatch_details",
    primaryFieldThirdFallback: "total_net_weight_pending_trips",
    avatarField: "src",
    dispatchList: true,
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const months = [
      {
        month: "Jan",
        monthId: 1,
        yearId: date.getFullYear(),
      },

      {
        month: "Feb",
        monthId: 2,
        yearId: date.getFullYear(),
      },
      {
        month: "Mar",
        monthId: 3,
        yearId: date.getFullYear(),
      },
      {
        month: "Apr",
        monthId: 4,
        yearId: date.getFullYear(),
      },
      {
        month: "May",
        monthId: 5,
        yearId: date.getFullYear(),
      },
      {
        month: "Jun",
        monthId: 6,
        yearId: date.getFullYear(),
      },
      {
        month: "Jul",
        monthId: 7,
        yearId: date.getFullYear(),
      },
      {
        month: "Aug",
        monthId: 8,
        yearId: date.getFullYear(),
      },
      {
        month: "Sep",
        monthId: 9,
        yearId: date.getFullYear(),
      },
      {
        month: "Oct",
        monthId: 10,
        yearId: date.getFullYear(),
      },
      {
        month: "Nov",
        monthId: 11,
        yearId: date.getFullYear(),
      },
      {
        month: "Dec",
        monthId: 12,
        yearId: date.getFullYear(),
      },
    ];
    const MonthArr = months.splice(0, date.getMonth() + 1);
    this.state = {
      MonthArr,
      selectedMonth: {
        month: date.toLocaleString("en-us", { month: "short" }),
        monthId: date.getMonth() + 1,
        yearId: date.getFullYear(),
      },
      tabs: templateTabs,
      singleDate: null,
      dates: [],
      page: 0,
      rowsPerPage: 3,
      liveParty: [],
      copyLiveParty: [],
      total_weight: 0,
      total_trips: 0,
      fetched: false,
      openMessage: true,
      fetched: false,
      SearchBarValue: "",
      fieldName: ["transporter_group"],
      singleDateTripsCopyExport: [],
      toPrint: false,
      loading: {},
      wscols: [
        { wch: 6 },
        { wch: 12 },
        { wch: 9 },
        { wch: 34 },
        { wch: 34 },
        { wch: 34 },

        { wch: 34 },
        { wch: 34 },
        { wch: 34 },
        { wch: 34 },
        { wch: 34 },

        { wch: 34 },

        { wch: 34 },
        { wch: 34 },
      ],
    };
  }

  componentWillMount() {
    const next = this.props;
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Dates" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singleDate: this.state.tabs[0].data[next.match.params["id"]],
          },
          () => {
            if (this.state.singleDate) {
              localStorage.setItem("selectedDate", JSON.stringify(this.state.singleDate.date));
            }
            if (this.state.fetched === false) {
              this.props.fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid);
              this.setState({ fetched: true, openMessage: false });
            }
          }
        );
      } else if (tab === "new") {
        this.setState({ singleDate: null });
      }
    } else {
      this.setState({ singleDate: null });
    }
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  listClickHandler = (value) => {
    localStorage.setItem("selectedDate", JSON.stringify(value.date));
    console.log(value, "valuevaluevalue");
    this.setState(
      {
        singleDate: value,
      },
      () => {
        this.props.fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid);
      }
    );
  };

  setInitialValues(props) {
    if (props.dates && props.dates.length > 0) {
      props.dates.sort((a, b) => {
        var dateA = b.date; // moment(b.date).format("Do MMM");
        var dateB = a.date; //moment(a.date).format("Do MMM");
        return dateA > dateB ? 1 : -1;
      });
      props.dates.map((data, index) => {
        data.formattedDate = moment(data.date).format("Do MMM, ddd");
      });
    }

    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.dates && props.dates.length > 0 ? props.dates : [];
      tab.searchArr = this.state.MonthArr;
      return tab;
    });
    this.setState(
      {
        loading: props.loading,
        dates: props.dates,
        tabs,
        liveParty: props.liveParty ? props.liveParty : [],
        copyLiveParty:
          props.liveParty && props.liveParty.total_per_transporter ? props.liveParty.total_per_transporter : [],
      },
      () => {
        if (this.state.copyLiveParty.length > 0) {
          var total_weight = 0;
          var total_trips = 0;
          this.state.copyLiveParty.map((data, i) => {
            total_trips = total_trips + data.total_trips;
            total_weight = total_weight + data.total_net_weight;
            if (i === this.state.copyLiveParty.length - 1) {
              this.setState({
                total_weight: Number(total_weight).toFixed(3),
                total_trips,
              });
            }
          });
        }
        const allTrips = props.livePartyCopy ? props.livePartyCopy : {};
        const newArr = [];
        if (allTrips) {
          var newData = {};

          newData.SNo = allTrips.id;
          newData.date = allTrips.date;
          newData.is_active = allTrips.is_active ? "Active" : "Inactive";
          if (allTrips.total_per_consignee) {
            allTrips.total_per_consignee.map((data, i) => {
              newData[`consignee${i + 1}`] = data.consignee;
              newData[`consignee${i + 1}total_net_weight`] = Number(data.total_net_weight).toFixed(3);
              newData[`consignee${i + 1}total_trips`] = data.total_trips;
            });
          }
          if (allTrips.total_per_transporter) {
            allTrips.total_per_transporter.map((data, i) => {
              newData[`transporter_group${i + 1}`] = data.transporter_group;
              newData[`transporter_group${i + 1}total_net_weight`] = Number(data.total_net_weight).toFixed(3);
              newData[`transporter_group${i + 1}total_trips`] = data.total_trips;
            });
          }
          if (allTrips.dispatch_details) {
            allTrips.dispatch_details.map((data, i) => {
              newData[`dispatch_details${i + 1}consignee`] = data.consignee;
              newData[`dispatch_details${i + 1}transporter_group_name`] = data.transporter_group_name;

              newData[`dispatch_details${i + 1}total_net_weight`] = Number(data.total_net_weight).toFixed(3);
              newData[`dispatch_details${i + 1}total_trips`] = data.total_trips;
            });
          }
        }
        this.setState({
          singleDateTripsCopyExport: [newData],
        });
      }
    );
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };
  searchHandler = (value) => {
    if (value && value.monthId) {
      this.setState({ selectedMonth: value, singleDate: null });
      this.props.fetchDates(this.props.user.user.uid, value.monthId, value.yearId).then((dateRR) => {
        this.props.setDates(dateRR);
      });
    }
  };
  render() {
    const {
      tabs,
      dates,
      liveParty,
      copyLiveParty,
      fieldName,
      total_weight,
      total_trips,
      singleDateTripsCopyExport,
      singleDate,
      formattedDate,
      toPrint,
      MonthArr,
      loading,
    } = this.state;
    console.log(singleDate, "singleDatesingleDate");
    return (
      <Layout
        loading={loading.dates}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: MonthArr.sort((a, b) => b.monthId - a.monthId),
          hintText: "Search Months",
          labelField: "month",
        }}
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="521.46px"
        closeWidth="287px"
        listClickHandler={this.listClickHandler}
      >
        {" "}
        {!this.state.singleDate ? (
          <div
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoData />
          </div>
        ) : (
          <Paper style={{ width: "100%", height: "100%", position: "relative" }}>
            {this.props.loading.liveParty ? (
              <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
                <StyledCircularProgress color="inherit" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "1rem",
                    color: "grey",
                    background: "white",
                  }}
                >
                  <div
                    style={
                      {
                        width: "100%",
                        display: "flex",
                        padding: "20px 0 20px 30px",
                      } //height: "100%",
                    }
                  >
                    <div style={{ width: "calc(100% - 160px)" }}>
                      {copyLiveParty && copyLiveParty.length > 0 ? (
                        <Paper style={styles.paperStyle} elevation={3} component="form">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0 12px",
                            }}
                          >
                            <SearchIcon color="rgb(16, 15, 15)" />
                          </div>
                          <InputBase
                            onKeyPress={(ev) => {
                              if (ev.key === "Enter") {
                                ev.preventDefault();
                              }
                            }}
                            style={{
                              fontSize: "0.9rem",
                              width: "100%",
                              color: "rgb(16, 15, 15)",
                            }}
                            placeholder="Search"
                            inputProps={{ "aria-label": "search google maps" }}
                            onChange={(event) => {
                              this.setState(
                                {
                                  SearchBarValue: event.target.value,
                                },
                                () => {
                                  const filterData = [];

                                  if (this.state.SearchBarValue && copyLiveParty && fieldName) {
                                    copyLiveParty.map((data) => {
                                      fieldName.map((singleField) => {
                                        if (
                                          data[singleField]
                                            .toString()
                                            .toLowerCase()
                                            .includes(this.state.SearchBarValue.toString().toLowerCase())
                                        ) {
                                          if (!filterData.includes(data)) {
                                            filterData.push(data);
                                          }
                                        }
                                      });
                                    });
                                    const newData = liveParty;
                                    newData.total_per_transporter = filterData;
                                    this.setState({
                                      liveParty: newData,
                                    });
                                  } else {
                                    const newData = liveParty;
                                    newData.total_per_transporter = copyLiveParty;
                                    this.setState({
                                      liveParty: newData,
                                    });
                                  }
                                }
                              );
                            }}
                            value={this.state.SearchBarValue}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0 9.5px",
                              cursor: "pointer",
                            }}
                          >
                            <Avatar
                              onClick={(event) => {
                                const newData = liveParty;
                                newData.total_per_transporter = copyLiveParty;
                                this.setState({
                                  liveParty: newData,
                                  SearchBarValue: "",
                                });
                              }}
                              style={{
                                color: "white",
                                backgroundColor: "#BEBCBC",
                                borderRadius: "50%",
                                width: "18px",
                                height: "18px",
                                fontSize: "0.7rem",
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <CancelIcon />
                            </Avatar>
                          </div>
                        </Paper>
                      ) : (
                        ""
                      )}
                    </div>

                    {copyLiveParty && copyLiveParty.length > 0 ? (
                      <div
                        style={{
                          width: "130px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "1px solid #1e3559",
                        borderRadius: "5px",
                      }}
                    >
                      <Button
                        style={{
                          background: "none",
                          color: "#1e3559",
                          textTransform: "capitalize",
                          borderRadius: "5px",
                          minWidth: "100px",
                        }}
                      >
                        {" "}
                        <FliterIcon />
                        <div
                          style={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        />
                        FLITERS
                      </Button>
                    </div> */}
                        {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 30px",
                        }}
                      >  <StyledButton name={"Download"} withBg={true} width="90px" onClick={() => { this.downloadImage(`PLIPL-${singleDate.formattedDate}-Details`) }} />
                      </div> */}
                        {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0 30px",
                        }}
                      >
                        <ExportToExcel

                          wscols={this.state.wscols}
                          apiData={
                            singleDateTripsCopyExport
                              ? singleDateTripsCopyExport
                              : liveParty.dispatch_details
                          }
                          fileName={`PLIPL-DETAILS-${singleDate.formattedDate
                            }`}
                          width="100px"
                        />
                      </div> */}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div style={{ height: "calc(100% - 77.99px)", maxHeight: "calc(100% - 77.99px)", padding: "20px" }}>
                  {singleDate && (
                    <SummaryTable
                      fromDashBoard={true}
                      fileName={`PLIPL-${singleDate.formattedDate && singleDate.formattedDate}-Summary`}
                      {...this.props}
                      {...this.state}
                    />
                  )}
                </div>
              </>
            )}
          </Paper>
        )}
        {toPrint ? (
          <Backdrop style={{ zIndex: 1, color: "#fff" }} open={toPrint}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default withRouter(Dashboard);
