import React, { Component } from "react";
import validator from "validator";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { CssTextField, CssTextFieldOrange, StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import { green, lightblue, mostLightGrey, orange, primary, red } from "../styles/colors";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { CancelIcon, CrossIcon, UploadIcon } from "../styles/Icons";
import { Cancel, CheckCircle } from "@material-ui/icons";
import SnackbarComponent from "./SnackbarComponent";
import moment from "moment";

const styles = {
  withDivider50: { width: "calc(50% - 5px)" },
  withDivider5043: { width: "calc(50% - 21.515px)" },
  withDivider25: { width: "25%" },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  bottomHeading: {
    margin: "0 10px 0 0",
    width: "390px",
    // color: dark,
    // overflow: "hidden",
    // textOverflow: "ellipsis",
    // textAlign: "left",
    // display: "flex",
  },
};
export class DialogDispatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scroll: "paper",
      skip: false,
      transitPass: "",
      varifiedTp: false,
      varifiedVehicle: false,
      ExistVehicle: false,
      selectedDate: "",
      successfullyFetched: false,
      image: false,
      error: "",
      success: "",
      tare_mt: "",
      net_mt: "",
      gross_mt: "",
      driver_name: "",
      driver_number: "",
      vehicleNumber: "",
      partyId: "",
      partyName: "",
      TransporterGroupName: "",
      TransporterGroupId: "",
      selectedalesOrderName: "",
      selectedSalesOrderId: "",
      selectedSalesItemId: "",
      pendingQuantity: "",
      salesOrders: [],
      selectedItemId: "",
      selectedItem: "",
      loading: false,
      addVehicle: false,
      Transporter: "",
      consignorName: "",
      consignorId: "",
      transitPassId: "",
      clearTp: false,
      tripAdded: false,
      comment: "",
      isRemoved: false,
      editComment: false,
      editingVehicle: false,
      lastTrip: false,
      errorMsg: {
        tare_mt: false,
        net_mt: false,
        gross_mt: false,
        driver_name: false,
        driver_number: false,
        vehicleNumber: false,
        partyName: false,
        TransporterGroupName: false,
        selectedalesOrderName: false,
        selectedItem: false,
        transitPass: false,
        image: false,
        comment: false,
      },
    };
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    if (event.target.name === "gross_mt" || event.target.name === "tare_mt") {
      this.setState({ [event.target.name]: event.target.value }, () => {
        // if (this.state.tare_mt > this.state.gross_mt) {
        //   errorMsg.gross_mt = "Please add gross mt!";
        //   this.setState({ errorMsg });
        // } else {
        this.setState({
          net_mt: Number(this.state.gross_mt - this.state.tare_mt).toFixed(3),

          errorMsg,
        });
        // }
      });
    } else {
      this.setState({
        clearTp: false,
        [event.target.name]: event.target.value,
        errorMsg,
      });
    }
  };
  reset = () => {
    const { image, skip, transitPass, varifiedTp, varifiedVehicle, successfullyFetched } = this.state;
    if (!image && !skip && transitPass === "" && !varifiedTp && !varifiedVehicle && !successfullyFetched) {
      this.setState({ error: "Nothing to reset !" });
    } else {
      this.setState(
        {
          tripAdded: false,
          image: false,
          skip: false,
          transitPass: "",
          varifiedTp: false,
          varifiedVehicle: false,
          ExistVehicle: false,
          bulk_permit_id: false,
          bulk_permit_name: "",
          source_id: false,
          transit_pass_date: false,
          successfullyFetched: false,
          tare_mt: "",
          net_mt: "",
          gross_mt: "",

          editingVehicle: false,
          driver_number: "",
          driver_name: "",
          vehicleNumber: "",
          partyId: "",
          partyName: "",
          TransporterGroupName: "",
          TransporterGroupId: "",
          selectedalesOrderName: "",
          selectedItemId: "",
          selectedItem: "",
          consignorName: "",
          consignorId: "",
          selectedSalesOrderId: "",
          bulkpermit: [],
          bulk_permit_name: "",
          pendingQuantity: "",
          selectedSalesItemId: "",
          salesVerified: false,
          vehicleId: "",
          transitPassId: "",
          clearTp: false,
          errorMsg: {
            tare_mt: false,
            net_mt: false,
            gross_mt: false,
            driver_name: false,
            driver_number: false,
            vehicleNumber: false,
            partyName: false,
            TransporterGroupName: false,
            selectedalesOrderName: false,
            selectedItem: false,
            transitPass: false,
            image: false,
            comment: "",
            editComment: false,
            isRemoved: false,
          },
        },
        () => {
          this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };
  componentWillReceiveProps(next) {
    const { tp } = next;
    if (tp) {
      console.log(tp, "tp");
      this.setState({
        bulkpermit: next.bulkpermit,
        lastTrip: next.lastTrip,
        successfullyFetched: true,
        selectedDate: next.selectedDate ? next.selectedDate : next.dates[0].date,
        transitPass: tp.transit_pass.TransitPassId,
        vehicleNumber: tp.vehicle.vehicle_no,
        vehicleId: tp.vehicle.id,
        image: { url: tp.transit_pass_file },
        tare_mt: tp.tare_weight,
        net_mt: tp.net_weight,
        gross_mt: tp.gross_weight,
        driver_name: tp.driver_name,
        driver_number: tp.driver_number,
        partyId: tp.consignee.id,
        partyName: tp.consignee.name,
        TransporterGroupName: tp.transporter_group.name,
        TransporterGroupId: tp.transporter_group.id,
        selectedalesOrderName: tp.sales_order.sales_order_number,
        selectedSalesOrderId: tp.sales_order.id,
        salesOrders: [tp.sales_order],
        transpoterslist: [tp.transporter_group],
        pendingQuantity: tp.sales_order.pending_quantity,
        selectedSalesItemId: tp.sales_order.item,
        selectedItemId: tp.item_grade.id,
        selectedItem: tp.item_grade.name,
        Transporter: tp.transporter === null ? "" : tp.transporter.id,
        consignorName: tp.consignor.name,
        consignorId: tp.consignor.id,
        transitPassId: tp.transit_pass.id,
        comment: tp.dispatch_remark ? tp.dispatch_remark : "",
      });
    } else {
      this.setState({
        lastTrip: next.lastTrip,
        bulkpermit: next.bulkpermit,
        // salesOrders: next.salesOrders,
        selectedDate: next.selectedDate ? next.selectedDate : next.dates[0].formattedDate,
        transpoterslist: next.transpoterslist,
      });
    }
  }
  componentWillMount() {
    const { tp } = this.props;
    console.log(tp, "tp");
    if (tp) {
      this.setState({
        lastTrip: this.props.lastTrip,
        successfullyFetched: true,
        selectedDate: this.props.selectedDate ? this.props.selectedDate : this.props.dates[0].date,
        transitPass: tp.transit_pass.TransitPassId,
        vehicleNumber: tp.vehicle.vehicle_no,
        vehicleId: tp.vehicle.id,
        image: { url: tp.transit_pass_file },
        tare_mt: tp.tare_weight,
        net_mt: tp.net_weight,
        gross_mt: tp.gross_weight,
        driver_name: tp.driver_name,
        driver_number: tp.driver_number,
        partyId: tp.consignee.id,
        partyName: tp.consignee.name,
        TransporterGroupName: tp.transporter_group.name,
        TransporterGroupId: tp.transporter_group.id,
        selectedalesOrderName: tp.sales_order.sales_order_number,
        selectedSalesOrderId: tp.sales_order.id,
        salesOrders: [tp.sales_order],
        transpoterslist: [tp.transporter_group],
        pendingQuantity: tp.sales_order.pending_quantity,
        selectedSalesItemId: tp.sales_order.item,
        selectedItemId: tp.item_grade.id,
        selectedItem: tp.item_grade.name,
        Transporter: tp.transporter === null ? "" : tp.transporter.id,
        consignorName: tp.consignor.name,
        consignorId: tp.consignor.id,
        transitPassId: tp.transit_pass.id,
        comment: tp.dispatch_remark ? tp.dispatch_remark : "",
      });
    } else {
      this.setState({
        lastTrip: this.props.lastTrip,
        // salesOrders: this.props.salesOrders,
        selectedDate: this.props.selectedDate ? this.props.selectedDate : this.props.dates[0].formattedDate,
        transpoterslist: this.props.transpoterslist,
      });
    }
  }

  handleUpdate = (e) => {
    this.setState({ cancel: false });
    e.preventDefault();
    let image = {};

    if (e.target.files[0]) {
      const kb = (e.target.files[0].size / 1024).toFixed(2);
      const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

      if (kb <= 1024) {
        const size = `${kb}${" "}kb`;
        image.mainSize = size;
        this.setState({ size });
      }
      if (1024 < kb) {
        const size = `${mb}${" "}mb`;
        image.mainSize = size;
        this.setState({ size });
      }

      image.img = e.target.files[0];
      image.url = URL.createObjectURL(e.target.files[0]);
      var errorMsg = this.state.errorMsg;
      errorMsg.tpUpload = false;
      this.setState({ image, errorMsg });
    }
  };
  render() {
    const MenuProps = {
      PaperProps: {
        style: {
          borderRadius: "12px",
          maxWidth: "2px",
          margin: "42px 0 0 0",
        },
      },
    };
    const {
      bulk_permit_id,
      scroll,
      clearTp,
      tripAdded,
      skip,
      editComment,
      transitPass,
      varifiedTp,
      varifiedVehicle,
      ExistVehicle,
      salesVerified,
      transitPassId,
      comment,
      selectedDate,
      addVehicle,
      Transporter,
      consignorName,
      consignorId,
      selectedSalesOrderId,
      selectedSalesItemId,
      errorMsg,
      successfullyFetched,
      image,
      error,
      success,
      tare_mt,
      net_mt,
      gross_mt,
      driver_number,
      loading,
      driver_name,
      vehicleNumber,
      vehicleId,
      partyId,
      partyName,
      pendingQuantity,
      TransporterGroupName,
      TransporterGroupId,
      selectedalesOrderName,
      selectedItemId,
      selectedItem,
      editingVehicle,
      salesOrders,
      transpoterslist,
      lastTrip,
      bulk_permit_name,
      bulkpermit,
    } = this.state;
    const { openDialogDispatch, tp, editTrip, changeTransporterGroup, consignees, items_grade, vehicles } = this.props;
    console.log(transpoterslist, "transpoterslist");
    const verifyTp = () => {
      this.setState({ loading: true });
      if (transitPass !== "") {
        this.props.fetchConsigneeGradeUsingTp(transitPass, this.props.user.user.uid).then((res) => {
          if (res.status === true) {
            const val = res.data;
            const msg = res.message;
            this.setState(
              {
                transitPassId: res.transit_pass_id,
                varifiedTp: true,
                items_grade: res.item_grades,
                consignees: res.consignees,
              },
              () => this.setState({ loading: false })
            );
          } else {
            var errorMsg = this.state.errorMsg;
            errorMsg.transitPass = res.msg;
            this.setState({
              errorMsg,
              loading: false,
              clearTp: true,
            });
          }
        });
      } else {
        var errorMsg = this.state.errorMsg;
        errorMsg.transitPass = "Please add transit pass number !";
        this.setState({ errorMsg, loading: false });
      }
    };
    const fetchDetails = () => {
      if (transitPass !== "") {
        this.setState({ loading: true });
        this.props.fetchDetailsOfTp(transitPass, this.props.user.user.uid).then((res) => {
          if (res.status === true) {
            const val = res.data;
            const msg = res.message;
            if (val.sales_order[0]) {
              this.props
                .fetchSalesOrders(val.grade_id, val.consignee_id, this.props.user.user.uid, "onlyfilt")
                .then((res) => {
                  if (res) {
                    {
                      console.log(res, "<========res LIST3 ======>");
                    }
                    this.setState(
                      {
                        salesOrders: res,
                      },
                      () => {
                        if (res.length > 0) {
                          this.setState({
                            selectedSalesOrderId: res[0].id,

                            pendingQuantity: res[0].pending_quantity,
                            selectedalesOrderName: res[0].sales_order_number,
                            selectedSalesItemId: res[0].item,
                          });
                        } else {
                          this.setState({
                            selectedSalesOrderId: "",
                            pendingQuantity: "",
                            selectedalesOrderName: "",
                            selectedSalesItemId: "",
                          });
                        }
                      }
                    );
                  }
                });
            }
            if (msg.VehicleNumber) {
              this.props.fetchVehicles(this.props.user.user.uid, msg.VehicleNumber).then((res5) => {
                console.log(res5, "res5res5res5res5res5");
                if (res5.data && res5.data.length > 0) {
                  var transportersGroup = res5.data;
                  var TransporterGroupName = transportersGroup[0].transporter_group_name;
                  var TransporterGroupId = transportersGroup[0].transporter_group;
                  this.setState(
                    {
                      bulk_permit_id: val.bulk_permit_id,

                      source_id: val.source_id,

                      transit_pass_date: val.transit_pass_date,
                      successfullyFetched: val,
                      tare_mt: val.tare_mt,
                      net_mt: Number(val.net_mt).toFixed(3),
                      gross_mt: val.gross_mt,
                      driver_number: val.driver_number,
                      driver_name: val.driver_name,
                      vehicleNumber: msg.VehicleNumber,
                      partyId: val.consignee_id,
                      partyName: val.consignee_name,
                      vehicleId: transportersGroup[0].id,
                      TransporterGroupName: TransporterGroupName,
                      TransporterGroupId: TransporterGroupId,
                      Transporter: val.transporter_id,
                      consignorId: val.consignor_id,
                      transitPassId: val.transit_pass_id,
                      selectedItemId: val.grade_id,
                      selectedItem: val.grade_name,
                    },
                    () => {
                      this.setState({
                        loading: false,
                      });
                    }
                  );
                } else {
                  // var newItemId = val.sales_order[0]
                  //   ? salesOrders.filter(
                  //     data =>
                  //       data.id ===
                  //       val.sales_order[0].id
                  //   )[0]
                  //   : false;

                  this.setState(
                    {
                      transitPassId: val.transit_pass_id,
                      Transporter: val.transporter_id,
                      addVehicle: true,
                      successfullyFetched: val,

                      transit_pass_date: val.transit_pass_date,
                      tare_mt: val.tare_mt,
                      net_mt: Number(val.net_mt).toFixed(3),
                      gross_mt: val.gross_mt,
                      driver_number: val.driver_number,
                      driver_name: val.driver_name,
                      vehicleNumber: msg.VehicleNumber,
                      partyId: val.consignee_id,
                      partyName: val.consignee_name,
                      consignorId: val.consignor_id,
                      // TransporterGroupName: TransporterGroupName,
                      bulk_permit_id: val.bulk_permit_id,

                      source_id: val.source_id,
                      // TransporterGroupId: TransporterGroupId,
                      selectedItemId: val.grade_id,
                      selectedItem: val.grade_name,
                    },
                    () => {
                      this.setState({
                        loading: false,
                      });
                    }
                  );
                }
              });
            }
          } else {
            var errorMsg = this.state.errorMsg;
            errorMsg.transitPass = res.message;
            this.setState({
              errorMsg,
              loading: false,
              clearTp: true,
            });
          }
        });
      } else {
        var errorMsg = this.state.errorMsg;
        errorMsg.transitPass = "Please add transit pass number!";
        this.setState({ errorMsg });
      }
    };
    const verifyVehicle = () => {
      if (vehicleNumber) {
        this.props.fetchVehicles(this.props.user.user.uid, vehicleNumber).then((res5) => {
          if (res5.data.length > 0) {
            this.setState(
              {
                varifiedVehicle: true,
                ExistVehicle: true,
                addVehicle: false,
                TransporterGroupName: res5.data[0].transporter_group_name,
                TransporterGroupId: res5.data[0].transporter_group,
                vehicleId: res5.data[0].id,
              },
              () => {}
            );
          } else {
            // var errorMsg = this.state.errorMsg;
            // errorMsg.vehicleNumber = "Vehicle number is not added!";
            this.setState({
              // errorMsg,
              addVehicle: true,
            });
          }
        });
      } else {
        var errorMsg = this.state.errorMsg;
        errorMsg.vehicleNumber = "Please add vehicle number!";
        this.setState({ errorMsg });
      }
    };
    const verifyVehicleFlow1 = () => {
      if (vehicleNumber) {
        this.props.fetchVehicles(this.props.user.user.uid, vehicleNumber).then((res5) => {
          if (res5.data.length > 0) {
            this.setState(
              {
                editingVehicle: false,
                varifiedVehicle: true,
                ExistVehicle: true,
                addVehicle: false,
                TransporterGroupName: res5.data[0].transporter_group_name,
                TransporterGroupId: res5.data[0].transporter_group,
                vehicleId: res5.data[0].id,
              },
              () => {}
            );
          } else {
            // var errorMsg = this.state.errorMsg;
            // errorMsg.vehicleNumber = "Vehicle number is not added!";
            this.setState({
              // errorMsg,

              editingVehicle: false,
              addVehicle: true,
            });
          }
        });
      } else {
        var errorMsg = this.state.errorMsg;
        errorMsg.vehicleNumber = "Please add vehicle number!";
        this.setState({ errorMsg });
      }
    };
    const editVehicle = (flow1) => {
      if (flow1) {
        this.setState({
          editingVehicle: true,
        });
      } else {
        this.setState({ addVehicle: false });
      }
    };
    return (
      <Dialog
        open={openDialogDispatch}
        onClose={this.props.handleClose}
        scroll={scroll}
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children ? ReactDOM.findDOMNode(e).children[2].children[0] : null;
            target.style.borderRadius = 0;
            target.style.width = "90%";
            target.style.maxWidth = "100%";
            target.style.height = "calc(100vh - 40px)";
            target.style.minHeight = "calc(100vh - 40px)";

            target.style.maxHeight = "calc(100vh - 40px)";
            target.style.margin = "20px 0";
            target.style.position = "relative";
            target.style.overflow = "hidden";
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {loading ? (
          <Backdrop style={{ zIndex: 1, color: "#fff", position: "absolute" }} open={loading}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        <DialogActions
          classname="d-flex justifySB"
          style={{ background: primary, color: "white", padding: "10px 24px" }}
          onClick={() => this.props.handleClose()}
        >
          <h3 style={styles.heading}>
            {" "}
            {tp.tP === "check tp"
              ? "Check TP"
              : changeTransporterGroup
              ? "Change Transporters Group"
              : editTrip || tp
              ? "Edit"
              : "Add"}{" "}
            A Trip
            <div style={{ margin: "0 10px 0 0" }} />( {selectedDate})
          </h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <DialogContent dividers={scroll === "paper"} className="dashedDividerBottom" style={{ height: "100%" }}>
          {tripAdded ? (
            <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
              <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
              <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                {tripAdded}
              </span>
            </div>
          ) : (
            <div className="height-100 width-100 d-flex">
              <div style={{ width: "calc(60% - 0.5px)", padding: "0 24px 0 0" }}>
                <div style={{ minWidth: "10px", width: "10px" }} />
                <div className="d-flex width-100" style={{ margin: "15px 0" }}>
                  <div className="d-flex alignC" style={styles.withDivider50}>
                    <CssTextField
                      required={true}
                      autoComplete="off"
                      error={errorMsg.transitPass ? true : false}
                      helperText={errorMsg.transitPass ? errorMsg.transitPass : false}
                      disabled={tp ? true : successfullyFetched || varifiedTp}
                      className="width-100"
                      id="outlined-select-currency-native"
                      label="Transit pass #"
                      SelectProps={{
                        disableUnderline: true,
                      }}
                      onChange={this.handleChange}
                      value={transitPass}
                      name="transitPass"
                      variant="outlined"
                      type="number"
                      onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                      inputProps={{
                        autoFocus: (skip && !varifiedTp) || (!skip && !successfullyFetched),
                        maxLength: 8,
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 8);
                      }}
                      onKeyPress={(ev) => {
                        console.log(`Pressed keyCode ${ev.key}`);
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                          if (skip) {
                            verifyTp();
                          } else {
                            fetchDetails();
                          }
                        }
                      }}
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: transitPass ? "TP_NO:" : "",
                        endAdornment: clearTp ? (
                          <StyledButton
                            minWidth="107px"
                            margin="0"
                            onClick={() => {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.transitPass = false;
                              this.setState({
                                transitPass: "",
                                clearTp: false,
                                errorMsg,
                              });
                            }}
                            name={<CancelOutlinedIcon style={{ color: "red" }} />}
                          />
                        ) : successfullyFetched || varifiedTp ? (
                          <CheckCircle style={{ color: "green" }} />
                        ) : skip ? (
                          <StyledButton
                            minWidth="107px"
                            margin="0"
                            onClick={() => {
                              verifyTp();
                            }}
                            onlyTextColor={lightblue}
                            name="Verify"
                          />
                        ) : (
                          <StyledButton
                            minWidth="107px"
                            margin="0 10px 0 0"
                            onClick={() => {
                              fetchDetails();
                            }}
                            name="Fetch Details"
                            onlyTextColor={lightblue}
                          />
                        ),
                      }}
                    />
                  </div>
                  {skip || successfullyFetched ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <div
                        className="d-flex justifySB d-flex-column alignC"
                        style={{
                          margin: "0 15px",
                          fontSize: "1.4rem",
                          color: "rgb(0 0 0 / 0.6)",
                        }}
                      >
                        <span>|</span>
                        <span style={{ fontSize: "1.1rem" }}>OR</span>
                        <span>|</span>
                      </div>{" "}
                      <div className="d-flex justifyC alignC" style={styles.withDivider50}>
                        <StyledButton
                          onlyTextColor={lightblue}
                          name="Skip"
                          onClick={() => {
                            this.setState({ skip: true });
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>{" "}
                {successfullyFetched || (skip && varifiedTp) ? (
                  <>
                    <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                      {!skip || (skip && varifiedTp) ? (
                        <CssTextField
                          required={true}
                          error={errorMsg.vehicleNumber ? true : false}
                          helperText={
                            errorMsg.vehicleNumber
                              ? errorMsg.vehicleNumber
                              : addVehicle
                              ? "New Vehicle"
                              : ExistVehicle
                              ? "Existing Vehicle"
                              : false
                          }
                          disabled={
                            tp
                              ? true
                              : editingVehicle
                              ? false
                              : addVehicle
                              ? true
                              : successfullyFetched
                              ? true
                              : varifiedVehicle
                              ? true
                              : false
                          }
                          autoComplete="off"
                          style={styles.withDivider50}
                          id="outlined-basic"
                          label="Vehicle No. #"
                          variant="outlined"
                          onChange={this.handleChange}
                          // focused={addVehicle ? true : false}
                          value={vehicleNumber}
                          onKeyPress={(ev) => {
                            console.log(`Pressed keyCode ${ev.key}`);
                            if (ev.key === "Enter") {
                              ev.preventDefault();
                              if (skip) {
                                if (addVehicle) {
                                  editVehicle();
                                } else {
                                  verifyVehicle();
                                }
                              } else {
                                editVehicle("flow1");
                              }
                            }
                          }}
                          inputProps={{
                            autoFocus: skip && varifiedTp && !varifiedVehicle,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: tp ? (
                              ""
                            ) : editingVehicle ? (
                              <StyledButton
                                minWidth="107px"
                                margin="0"
                                onClick={() => {
                                  verifyVehicleFlow1();
                                }}
                                onlyTextColor={lightblue}
                                name="verify"
                              />
                            ) : successfullyFetched || (skip && addVehicle) ? (
                              <StyledButton
                                onlyTextColor={lightblue}
                                name="Edit"
                                onClick={() => {
                                  if (skip) {
                                    editVehicle();
                                  } else {
                                    editVehicle("flow1");
                                    // this.setState({ editVehicle })
                                  }
                                }}
                              />
                            ) : addVehicle ? (
                              ""
                            ) : varifiedVehicle ? (
                              <CheckCircle style={{ color: "green" }} />
                            ) : (
                              <StyledButton
                                minWidth="107px"
                                margin="0"
                                onClick={() => {
                                  verifyVehicle();
                                }}
                                onlyTextColor={lightblue}
                                name="verify"
                              />
                            ),
                          }}
                          name="vehicleNumber"
                        />
                      ) : (
                        ""
                      )}
                      <div style={{ minWidth: "10px", width: "10px" }} />
                      {!skip || (skip && varifiedVehicle) || addVehicle ? (
                        <CssTextField
                          error={errorMsg.TransporterGroupName ? true : false}
                          helperText={errorMsg.TransporterGroupName ? errorMsg.TransporterGroupName : false}
                          value={TransporterGroupName}
                          autoComplete="off"
                          disabled={
                            !tp
                              ? editingVehicle
                                ? false
                                : vehicleNumber && !addVehicle
                                ? true
                                : addVehicle
                                ? false
                                : successfullyFetched
                                ? true
                                : true
                              : tp
                          }
                          style={styles.withDivider50}
                          id="outlined-select-currency-native"
                          select
                          label="Transpoters Group"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          SelectProps={
                            {
                              // native: true,
                              disableUnderline: true,
                            } //onChange={this.handleChange} //value={currency}
                          }
                          variant="outlined"
                          onChange={(e) => {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.TransporterGroupName = false;
                            this.setState({
                              errorMsg,
                              TransporterGroupId: e.currentTarget.id,
                              TransporterGroupName: e.target.value,
                            });
                          }}
                          required={true}
                        >
                          {transpoterslist &&
                            transpoterslist.map((option) => (
                              <MenuItem key={option.name} value={option.name} id={option.id}>
                                {option.name}
                              </MenuItem>
                            ))}
                        </CssTextField>
                      ) : (
                        ""
                      )}
                    </div>{" "}
                    {addVehicle ? (
                      <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                        <StyledButton
                          color={lightblue}
                          minWidth="107px"
                          margin="0"
                          withBg={true}
                          onClick={() => {
                            if (vehicleNumber) {
                              if (TransporterGroupId) {
                                this.setState({ loading: true });
                                const addData = {
                                  vehicle_no: vehicleNumber,
                                  transporter: null,
                                  tp_identifier: vehicleNumber,
                                  transporter_group: TransporterGroupId,
                                  is_active: true,
                                };
                                this.props.vehicleAdd(addData, this.props.user.user.uid).then((res1) => {
                                  console.log(res1, "res1res1res1res1res1");
                                  if (res1.error) {
                                    var errorMsg = this.state.errorMsg;
                                    errorMsg.vehicleNumber = res1.msg.vehicle_no ? res1.msg.vehicle_no[0] : res1.msg;
                                    this.setState({
                                      errorMsg,
                                      loading: false,
                                    });
                                  } else {
                                    this.props.fetchVehicles(this.props.user.user.uid).then((res) => {
                                      if (res) {
                                        var errorMsg = this.state.errorMsg;
                                        errorMsg.vehicleNumber = false;
                                        this.setState({
                                          errorMsg,
                                          loading: false,
                                          addVehicle: false,
                                          vehicles: res.data,
                                          varifiedVehicle: true,
                                          vehicleId: res1.id,
                                        });
                                      } else {
                                        var errorMsg = this.state.errorMsg;
                                        errorMsg.vehicleNumber = "Vehicle number not add!";
                                        this.setState({
                                          errorMsg,
                                          loading: false,
                                        });
                                      }
                                    });
                                  }
                                });
                              } else {
                                var errorMsg = this.state.errorMsg;
                                errorMsg.TransporterGroupName = "Add transporter group!";
                                this.setState({ errorMsg });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.vehicleNumber = "add vehicle group!";
                              this.setState({ errorMsg });
                            }
                          }}
                          name="Add Vehicle"
                        />{" "}
                      </div>
                    ) : editingVehicle ? (
                      ""
                    ) : (
                      <>
                        <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                          {" "}
                          {!skip || (skip && varifiedVehicle) ? (
                            <CssTextField
                              required={true}
                              error={errorMsg.partyName ? true : false}
                              helperText={errorMsg.partyName ? errorMsg.partyName : false}
                              autoComplete="off"
                              value={partyName}
                              disabled={tp ? true : successfullyFetched ? true : tp}
                              style={styles.withDivider50}
                              id="outlined-select-currency-native"
                              select
                              label="Party"
                              SelectProps={{ disableUnderline: true }}
                              variant="outlined"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    partyId: e.currentTarget.id,
                                    partyName: e.target.value,
                                  },
                                  () => {
                                    this.props
                                      .fetchSalesOrders(
                                        this.state.selectedItemId,
                                        partyId,
                                        this.props.user.user.uid,
                                        "onlyfilt"
                                      )
                                      .then((res) => {
                                        if (res) {
                                          {
                                            console.log(res, "<========res LIST ======>");
                                          }

                                          this.setState(
                                            {
                                              salesOrders: res.length > 0 ? res : [],
                                            },
                                            () => {
                                              if (res.length > 0) {
                                                this.setState({
                                                  selectedSalesOrderId: res[0].id,
                                                  selectedalesOrderName: res[0].sales_order_number,

                                                  pendingQuantity: res[0].pending_quantity,
                                                  selectedSalesItemId: res[0].item,
                                                });
                                              } else {
                                                this.setState({
                                                  selectedSalesOrderId: "",
                                                  pendingQuantity: "",
                                                  selectedalesOrderName: "",
                                                  selectedSalesItemId: "",
                                                });
                                              }
                                            }
                                          );
                                        }
                                        //  else {
                                        //   this.setState({ error: "Vehicle number not add!" });
                                        // }
                                      });
                                  }
                                );
                              }}
                            >
                              {consignees &&
                                consignees.map((option) => (
                                  <MenuItem key={option.name} value={option.name} id={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </CssTextField>
                          ) : (
                            ""
                          )}
                          <div style={{ minWidth: "10px", width: "10px" }} />
                          {!skip || (skip && partyName) ? (
                            <CssTextField
                              required={true}
                              error={errorMsg.selectedItem ? true : false}
                              helperText={errorMsg.selectedItem ? errorMsg.selectedItem : false}
                              autoComplete="off"
                              disabled={tp ? true : successfullyFetched ? true : tp}
                              style={styles.withDivider50}
                              id="outlined-select-currency-native"
                              select
                              label="Grade"
                              SelectProps={{ disableUnderline: true }}
                              variant="outlined"
                              onChange={(e) => {
                                this.setState(
                                  {
                                    selectedItemId: e.currentTarget.id,
                                    selectedItem: e.target.value,
                                  },
                                  () => {
                                    this.props
                                      .fetchSalesOrders(
                                        this.state.selectedItemId,
                                        partyId,
                                        this.props.user.user.uid,
                                        "onlyfilt"
                                      )
                                      .then((res) => {
                                        if (res) {
                                          this.setState(
                                            {
                                              salesOrders: res.length > 0 ? res : [],
                                            },
                                            () => {
                                              if (res.length > 0) {
                                                this.setState({
                                                  selectedSalesOrderId: res[0].id,
                                                  selectedalesOrderName: res[0].sales_order_number,
                                                  pendingQuantity: res[0].pending_quantity,
                                                  selectedSalesItemId: res[0].item,
                                                  salesVerified: true,
                                                });
                                              } else {
                                                this.setState({
                                                  selectedSalesOrderId: "",
                                                  pendingQuantity: "",
                                                  selectedalesOrderName: "",
                                                  selectedSalesItemId: "",
                                                });
                                              }
                                              if (skip) {
                                                this.props.fetchSalesOrderBulkPermit(
                                                  this.state.selectedItem,
                                                  this.props.user.user.uid
                                                );
                                              }
                                            }
                                          );
                                        }
                                        //  else {
                                        //   this.setState({ error: "Vehicle number not add!" });
                                        // }
                                      });
                                  }
                                );
                              }}
                              value={selectedItem}
                            >
                              {" "}
                              {items_grade &&
                                items_grade.map((option) => (
                                  <MenuItem key={option.name} value={option.name} id={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </CssTextField>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                          {!skip || (skip && selectedItem) ? (
                            <CssTextField
                              required={true}
                              error={
                                tp
                                  ? false
                                  : errorMsg.selectedalesOrderName
                                  ? true
                                  : salesOrders.length === 0
                                  ? true
                                  : (pendingQuantity !== null && pendingQuantity < 150 && pendingQuantity > 0) ||
                                    (pendingQuantity !== null && pendingQuantity < 0)
                                  ? true
                                  : false
                              }
                              helperText={
                                tp
                                  ? false
                                  : errorMsg.selectedalesOrderName
                                  ? errorMsg.selectedalesOrderName
                                  : salesOrders.length === 0
                                  ? "No sales orders available for selected party and grade!"
                                  : pendingQuantity !== null && pendingQuantity < 150 && pendingQuantity > 0
                                  ? `${pendingQuantity} MT Remaining!`
                                  : pendingQuantity !== null && pendingQuantity < 0
                                  ? "All quantity in this sales order has been dispatched. No new trips can be created!!"
                                  : false
                              }
                              autoComplete="off"
                              disabled={tp ? true : successfullyFetched ? (salesOrders.length > 1 ? false : true) : tp}
                              style={styles.withDivider50}
                              id="outlined-select-currency-native"
                              select
                              label={"Sales Order"}
                              SelectProps={{ disableUnderline: true }}
                              variant="outlined"
                              value={selectedalesOrderName ? selectedalesOrderName : ""}
                            >
                              {salesOrders &&
                                salesOrders.map((option) => (
                                  <MenuItem
                                    onClick={(e) => {
                                      this.setState({
                                        selectedSalesOrderId: option.id,
                                        pendingQuantity: option.pending_quantity,
                                        selectedalesOrderName: option.sales_order_number,
                                      });
                                    }}
                                    // style={{
                                    //   background: option.pending_quantity !== null &&
                                    //     option.pending_quantity <= 0 ? "red" : ""
                                    // }}
                                    disabled={
                                      option.pending_quantity !== null && option.pending_quantity <= 0 ? true : false
                                    }
                                    key={option.sales_order_number}
                                    value={option.sales_order_number}
                                    id={option.id}
                                  >
                                    {`${option.sales_order_number}-${moment(option.start_date).format("Do MMM")}`}
                                  </MenuItem>
                                ))}
                            </CssTextField>
                          ) : (
                            ""
                          )}
                          {}
                          <div style={{ minWidth: "10px", width: "10px" }} />
                          {selectedalesOrderName && skip && (
                            <CssTextField
                              required={true}
                              error={errorMsg.bulk_permit_name ? errorMsg.bulk_permit_name : false}
                              helperText={errorMsg.bulk_permit_name ? errorMsg.bulk_permit_name : ""}
                              autoComplete="off"
                              disabled={tp ? true : false}
                              style={styles.withDivider50}
                              id="outlined-select-currency-native"
                              select
                              label={"Bulk Permit"}
                              SelectProps={{ disableUnderline: true }}
                              variant="outlined"
                              value={bulk_permit_name ? bulk_permit_name : ""}
                            >
                              {bulkpermit &&
                                bulkpermit.map((option) => (
                                  <MenuItem
                                    onClick={(e) => {
                                      this.setState({
                                        bulk_permit_id: option.id,
                                        bulk_permit_name: option.bulk_permit_no,
                                      });
                                    }}
                                    key={option.bulk_permit_no}
                                    value={option.bulk_permit_no}
                                    id={option.id}
                                  >
                                    {`${option.bulk_permit_no}-${moment(option.issue_date).format("Do MMM")}`}
                                  </MenuItem>
                                ))}
                            </CssTextField>
                          )}
                        </div>

                        {!tp && salesOrders.length === 0 ? (
                          ""
                        ) : (!tp &&
                            salesOrders.length === 1 &&
                            salesOrders[0].pending_quantity !== null &&
                            !tp &&
                            salesOrders[0].pending_quantity <= 0) ||
                          (!tp && pendingQuantity && pendingQuantity <= 0) ? (
                          ""
                        ) : (
                          <>
                            <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                              {!skip || (skip && salesVerified) ? (
                                <CssTextField
                                  required={true}
                                  error={errorMsg.gross_mt ? true : false}
                                  helperText={errorMsg.gross_mt ? errorMsg.gross_mt : false}
                                  autoComplete="off"
                                  disabled={tp ? true : successfullyFetched ? true : tp}
                                  style={styles.withDivider25}
                                  id="outlined-basic"
                                  label="Gross MT"
                                  type="number"
                                  value={gross_mt}
                                  name="gross_mt"
                                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                  onChange={this.handleChange}
                                  variant="outlined"
                                />
                              ) : (
                                ""
                              )}
                              {!skip || (skip && salesVerified) ? (
                                <div
                                  className="d-flex justifyC alignC"
                                  style={{
                                    fontSize: "2rem",
                                    color: "black",
                                    fontWeight: "600",
                                    margin: "0 15px",
                                    height: "59px",
                                  }}
                                >
                                  -
                                </div>
                              ) : (
                                ""
                              )}
                              {!skip || (skip && gross_mt) ? (
                                <CssTextField
                                  required={true}
                                  error={errorMsg.tare_mt ? true : false}
                                  helperText={errorMsg.tare_mt ? errorMsg.tare_mt : false}
                                  autoComplete="off"
                                  disabled={tp ? true : successfullyFetched ? true : tp}
                                  style={styles.withDivider25}
                                  id="outlined-basic"
                                  label="TARE MT"
                                  type="number"
                                  variant="outlined"
                                  value={tare_mt}
                                  name="tare_mt"
                                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                  onChange={this.handleChange}
                                />
                              ) : (
                                ""
                              )}
                              {!skip || (skip && gross_mt) ? (
                                <div
                                  className="d-flex justifyC alignC"
                                  style={{
                                    fontSize: "2rem",
                                    color: "black",
                                    fontWeight: "600",
                                    margin: "0 15px",
                                    height: "59px",
                                  }}
                                >
                                  =
                                </div>
                              ) : (
                                ""
                              )}
                              {!skip || (skip && tare_mt) ? (
                                <CssTextField
                                  required={true}
                                  autoComplete="off"
                                  disabled={tp ? true : successfullyFetched ? true : tp}
                                  style={{ width: "calc(50% - 88.72px)" }}
                                  id="outlined-basic"
                                  label="Net MT"
                                  type="number"
                                  variant="outlined"
                                  value={net_mt}
                                  name="net_mt"
                                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                  // onChange={this.handleChange}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                              {" "}
                              {!skip || (skip && tare_mt) ? (
                                <CssTextField
                                  required={true}
                                  error={errorMsg.driver_name ? true : false}
                                  helperText={errorMsg.driver_name ? errorMsg.driver_name : false}
                                  autoComplete="off"
                                  disabled={tp ? true : successfullyFetched ? true : tp}
                                  style={styles.withDivider50}
                                  id="outlined-basic"
                                  label="Driver Name"
                                  variant="outlined"
                                  value={driver_name}
                                  name="driver_name"
                                  onChange={this.handleChange}
                                />
                              ) : (
                                ""
                              )}
                              <div style={{ minWidth: "10px", width: "10px" }} />
                              {!skip || (skip && driver_name) ? (
                                <CssTextField
                                  required={true}
                                  error={errorMsg.driver_number ? true : false}
                                  helperText={errorMsg.driver_number ? errorMsg.driver_number : false}
                                  autoComplete="off"
                                  disabled={tp ? true : successfullyFetched ? true : tp}
                                  style={styles.withDivider50}
                                  id="outlined-basic"
                                  label="Phone Number"
                                  variant="outlined"
                                  value={driver_number}
                                  onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                                  type="number"
                                  onInput={(e) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                      .toString()
                                      .slice(0, 10);
                                  }}
                                  name="driver_number"
                                  onChange={this.handleChange}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {(tp && this.props.user.user.roles.includes("admin")) ||
                    this.props.user.user.roles.includes("management") ||
                    this.props.user.user.roles.includes("officeStaff") ||
                    this.props.user.user.roles.includes("superAdmin") ||
                    this.props.user.user.roles.includes("transporterManagement") ? (
                      <div className="d-flex width-100" style={{ margin: "0 0 15px 0" }}>
                        <CssTextFieldOrange
                          disabled={tp.open ? true : false}
                          InputProps={{
                            disableUnderline: true,
                          }}
                          error={errorMsg.comment ? true : false}
                          helperText={errorMsg.comment ? errorMsg.comment : false}
                          style={{
                            width: "75%",
                          }}
                          autoComplete="off"
                          id="outlined-basic"
                          label="Add Comment"
                          variant="outlined"
                          value={comment ? (comment !== "null" && comment !== null ? comment : "") : ""}
                          multiline
                          rowsMax="3"
                          rows="3"
                          name="comment"
                          onChange={this.handleChange}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <Divider
                variant="vertical"
                className="dashedDividerRight"
                style={{ margin: "-16px 0", height: "calc(100% + 32px)" }}
              />

              <div
                style={{
                  width: "calc(40% - 0.5px)",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                {!image ? (
                  <>
                    <input
                      accept="application/pdf, .pdf"
                      ref={(ref) => (this.fileInput = ref)}
                      id="files"
                      style={{
                        visibility: "hidden",
                        height: 0,
                        width: 0,
                      }}
                      type="file"
                      onChange={this.handleUpdate}
                    />
                    <label htmlFor="files" class="btn">
                      <div
                        className="d-flex justifyC alignC"
                        style={{
                          background: errorMsg.tpUpload ? red : primary,
                          width: "120px",
                          borderRadius: "7px",
                          color: "white",
                          padding: "10px",
                          marginLeft: "24px",
                        }}
                      >
                        <UploadIcon color="white" height={"20"} width={"20"} />
                        <div style={{ margin: "0 3px 0 0" }} />
                        Upload Tp
                      </div>
                      <span
                        style={{
                          color: red,
                          margin: "10px 0 0 0",
                          width: "100%",
                        }}
                        className="d-flex justifyC"
                      >
                        {errorMsg.tpUpload ? errorMsg.tpUpload : ""}
                      </span>
                    </label>
                  </>
                ) : (
                  <div className="d-flex d-flex-column height-100 width-100">
                    {/* <StyledButton name="ded" onClick={() => {
                      let headers = new Headers();

                      headers.append('Content-Type', 'application/json');
                      headers.append('Accept', 'application/json');
                      headers.append('Authorization', `JWT ${this.props.user.user.uid}`);

                      headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
                      headers.append('Access-Control-Allow-Credentials', 'true');
                      fetch("http://www.africau.edu/images/default/sample.pdf", {

                        method: "GET",
                        // mode: "no-cors",
                        headers
                        // headers: {
                        //   Authorization: `JWT ${this.props.user.user.uid}`,
                        //   "Content-Type": "application/json;charset=UTF-8",
                        //   'Access-Control-Allow-Origin', 'http://localhost:3000'
                        // },
                      })
                        .then(async (response) => {
                          console.log(response, "responseresponse")
                          await response.arrayBuffer().then(function (buffer) {
                            const url = window.URL.createObjectURL(new Blob([buffer]));
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute(
                              "download",
                              "sample.pdf");
                            document.body.appendChild(link);
                            link.click();
                          });

                        })
                        .catch((err) => {
                          console.log(err);
                        });


                      // var frame = document.getElementById('frame');
                      // frame.contentWindow.focus();
                      // frame.contentWindow.print();
                      // document.getElementById("myFrame").contentWindow.print();
                      // var frm = document.getElementById("myFrame").contentWindow;
                      // frm.focus();// focus on contentWindow is needed on some ie versions
                      // frm.print();
                      // return false;
                    }} /> */}
                    <iframe
                      id="frame"
                      src={
                        tp
                          ? this.state.isRemoved
                            ? image.url
                            : `https://app.pitambarainfra.com/${image.url}`
                          : image.url
                      }
                      style={{
                        margin: "0 0 0 24px",
                        width: "calc(100% - 24px)",
                        height: tp ? "100%" : "calc(100% - 36px)",
                      }}
                    />
                    {/* : ""} */}
                    {tp.dispatch_status === "CORRECTION" || tp.dispatch_status === "NEW" || !tp ? (
                      <div
                        className="d-flex alignC justifyC "
                        style={{
                          margin: "0 0 0 24px",
                          width: "calc(100% - 24px)",
                        }}
                      >
                        <StyledButton
                          name="Remove"
                          onlyTextColor={red}
                          onClick={() => {
                            this.setState({
                              image: false,
                              isRemoved: true,
                            });
                          }}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
                {/* <Button
                style={{
                  height: "50px",
                  background: "#a7da29",
                  color: "white",
                  width: "130px",
                  boxShadow: "3px 3px rgb(167 218 41 / 49%)",
                  textTransform: "capitalize",
                }}
                variant="contained"
              >
                Upload TP
              </Button> */}
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: lastTrip ? "space-between" : "flex-end",
          }}
        >
          {lastTrip && (
            <h3 style={styles.bottomHeading}>
              {`Last added trip: ${lastTrip.transit_pass.TransitPassId} - ${lastTrip.vehicle.vehicle_no} - ${
                lastTrip.consignee.short_name
              }`}
            </h3>
          )}
          <div className="d-flex justifyFE" style={{ width: "calc(100% - 400px)" }}>
            {/* <Button style={{
            padding: "3px",
            maxWidth: "100px",
            width: "70px",
            justifyContent: "center",
            color: primary,
            textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }} onClick={this.props.handleClose}>Cancel</Button> */}
            {this.props.user.user.accessibleWidgets &&
            this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips") &&
            this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips").length > 0 &&
            this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips")[0]
              .has_update_permission === true &&
            tp &&
            !tripAdded ? (
              tp.open ? (
                ""
              ) : changeTransporterGroup || editTrip ? ( // || this.props.user.user.roles.includes("dispatchStaff")
                <Button
                  style={{
                    borderRadius: "5px",
                    padding: "3px 10px",
                    background: red,
                    justifyContent: "center",
                    color: "white",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "capitalize",
                    margin: "0 10px 0 0",
                  }}
                  onClick={() => {
                    // if (comment) {
                    this.setState({ loading: true });
                    var newData = {};
                    if (changeTransporterGroup) {
                      if (TransporterGroupId !== "") {
                        newData = {
                          new_vehicle_no: vehicleId,
                          transporter_group: TransporterGroupId,
                        };
                        this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                          if (res.error) {
                            this.setState({
                              error: res.data,
                              loading: false,
                            });
                          } else {
                            this.props
                              .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                              .then(() => {
                                this.props
                                  .fetchDates(
                                    this.props.user.user.uid,
                                    this.props.selectedMonth.monthId,
                                    this.props.selectedMonth.yearId
                                  )
                                  .then((resres) => {
                                    if (resres) {
                                      this.props.setsingleDate(
                                        resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                        resres
                                      );
                                    }
                                    this.setState({
                                      tripAdded: "Trip edited ! ",
                                      loading: false,
                                    });
                                  });
                              });
                          }
                        });
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.TransporterGroupName = "Please select any one transporter group !";
                        this.setState({ errorMsg, loading: false });
                      }
                    } else {
                      newData = {
                        new_vehicle_no: vehicleId,
                        tare_weight: tare_mt,
                        gross_weight: gross_mt,
                        net_weight: net_mt,
                        driver_name: driver_name,
                        driver_number: driver_number,
                        dispatch_status: tp.dispatch_status,

                        dispatch_remark: comment,
                        lr_recieving_status: "",
                        lr_shortage: 0,
                        lr_shortage_deducted: 0,
                        lr_remark: null,
                        payment_rate: 0,
                        is_active: true,
                      };
                      this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                        if (res.error) {
                          this.setState({ error: res.data, loading: false });
                        } else {
                          this.props.fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid).then(() => {
                            this.props
                              .fetchDates(
                                this.props.user.user.uid,
                                this.props.selectedMonth.monthId,
                                this.props.selectedMonth.yearId
                              )
                              .then((resres) => {
                                if (resres) {
                                  this.props.setsingleDate(
                                    resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                    resres
                                  );
                                }
                                this.setState({
                                  tripAdded: "Trip edited ! ",
                                  loading: false,
                                });
                              });
                          });
                        }
                      });
                    }

                    // } else {
                    //   var errorMsg = this.state.errorMsg;
                    //   errorMsg.comment = "Please add comment !";
                    //   this.setState({ errorMsg, });
                    // }
                  }}
                >
                  EDIT
                </Button>
              ) : this.props.user.user.roles.includes("admin") ||
                this.props.user.user.roles.includes("management") ||
                this.props.user.user.roles.includes("officeStaff") ||
                this.props.user.user.roles.includes("superAdmin") ||
                this.props.user.user.roles.includes("transporterManagement") ? (
                <>
                  {tp.tP === "CORRECTION" ? (
                    ""
                  ) : (
                    <Button
                      onClick={() => {
                        if (comment) {
                          this.setState({ loading: true });
                          if (this.state.isRemoved) {
                            if (image) {
                              let newData = new FormData();

                              newData.append("transit_pass_file", image.img);
                              newData.append("dispatch_status", "BILLING");
                              newData.append("dispatch_remark", comment);

                              this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                                if (res.error) {
                                  this.setState({
                                    error: res.data,
                                    loading: false,
                                  });
                                } else {
                                  this.props
                                    .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                                    .then(() => {
                                      this.props
                                        .fetchDates(
                                          this.props.user.user.uid,
                                          this.props.selectedMonth.monthId,
                                          this.props.selectedMonth.yearId
                                        )
                                        .then((resres) => {
                                          if (resres) {
                                            this.props.setsingleDate(
                                              resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                              resres
                                            );
                                          }
                                          this.setState({
                                            tripAdded: "Trip is approved ! ",
                                            loading: false,
                                          });
                                        });
                                    });
                                }
                              });
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.tpUpload = "Please upload a transit pass !";
                              this.setState({
                                errorMsg,
                                loading: false,
                              });
                            }
                          } else {
                            const newData = {
                              dispatch_status: "BILLING",
                              dispatch_remark: comment,
                            };
                            this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                              if (res.error) {
                                this.setState({
                                  error: res.data,
                                  loading: false,
                                });
                              } else {
                                console.log(res, "res");
                                this.setState({
                                  image: res.transit_pass_file,
                                });
                                this.props
                                  .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                                  .then(() => {
                                    this.props
                                      .fetchDates(
                                        this.props.user.user.uid,
                                        this.props.selectedMonth.monthId,
                                        this.props.selectedMonth.yearId
                                      )
                                      .then((resres) => {
                                        if (resres) {
                                          this.props.setsingleDate(
                                            resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                            resres
                                          );
                                        }
                                        this.setState({
                                          tripAdded: "Trip is approved ! ",
                                          loading: false,
                                        });
                                      });
                                  });
                              }
                            });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.comment = "Please add comment !";
                          this.setState({ errorMsg });
                        }
                      }}
                      style={{
                        borderRadius: "5px",
                        padding: "3px 10px",
                        background: "green",
                        justifyContent: "center",
                        color: "white",
                        textTransform: "capitalize",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "capitalize",
                        margin: "0 10px 0 0",
                      }}
                    >
                      Approve for billing{" "}
                    </Button>
                  )}
                  <Button
                    style={{
                      borderRadius: "5px",
                      padding: "3px 10px",
                      background: red,
                      justifyContent: "center",
                      color: "white",
                      textTransform: "capitalize",
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      if (comment) {
                        this.setState({ loading: true });
                        //comment, tp,
                        if (this.state.isRemoved) {
                          if (image) {
                            var newData = new FormData();

                            newData.append("transit_pass_file", image.img);
                            newData.append("dispatch_status", "CORRECTION");
                            newData.append("dispatch_remark", comment);
                            this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                              if (res.error) {
                                this.setState({
                                  error: res.data,
                                  loading: false,
                                });
                              } else {
                                this.props
                                  .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                                  .then(() => {
                                    this.props
                                      .fetchDates(
                                        this.props.user.user.uid,
                                        this.props.selectedMonth.monthId,
                                        this.props.selectedMonth.yearId
                                      )
                                      .then((resres) => {
                                        if (resres) {
                                          this.props.setsingleDate(
                                            resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                            resres
                                          );
                                        }
                                        this.setState({
                                          tripAdded: "Trip sent for correction ! ",
                                          loading: false,
                                        });
                                      });
                                  });
                              }
                            });
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.tpUpload = "Please upload a transit pass !";
                            this.setState({
                              errorMsg,
                              loading: false,
                            });
                          }
                        } else {
                          const newData = {
                            // "transit_pass_file": image.url,
                            // "new_vehicle_no": vehicleId,
                            // "tare_weight": tare_mt,
                            // "gross_weight": gross_mt,
                            // "net_weight": net_mt,
                            // "driver_name": driver_name,
                            // "driver_number": driver_number,
                            dispatch_status: "CORRECTION",
                            dispatch_remark: comment,
                          };
                          // "lr_recieving_status": "RECEIVED",
                          // "lr_shortage": 0,
                          // "lr_shortage_deducted": 0,
                          // "lr_remark": null,
                          // "payment_rate": 0,
                          // "is_active": true
                          this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                            if (res.error) {
                              this.setState({
                                error: res.data,
                                loading: false,
                              });
                            } else {
                              this.props
                                .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                                .then(() => {
                                  this.props
                                    .fetchDates(
                                      this.props.user.user.uid,
                                      this.props.selectedMonth.monthId,
                                      this.props.selectedMonth.yearId
                                    )
                                    .then((resres) => {
                                      if (resres) {
                                        this.props.setsingleDate(
                                          resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                          resres
                                        );
                                      }
                                      this.setState({
                                        tripAdded: "Trip sent for correction ! ",
                                        loading: false,
                                      });
                                    });
                                });
                            }
                          });
                        }
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.comment = "Please add comment !";
                        this.setState({ errorMsg });
                      }
                    }}
                  >
                    {tp.dispatch_status === "CORRECTION" ? "Edit" : "Send for correction"}
                  </Button>
                </>
              ) : (
                ""
              )
            ) : tripAdded && !tp ? (
              <StyledButton
                onClick={() => {
                  this.reset();
                }}
                name={"Add Another"}
                withBg={true}
                background={"green"}
                width="120px"
              />
            ) : (
              !tp && (
                <>
                  {" "}
                  <StyledButton
                    onClick={() => {
                      this.reset();
                    }}
                    name={"Reset"}
                    width="60px"
                    margin="0 10px 0 0"
                  />
                  <StyledButton
                    onClick={() => {
                      if (transitPass !== "") {
                        if (vehicleNumber !== "") {
                          if (TransporterGroupId !== "") {
                            if (partyName !== "") {
                              if (selectedItem !== "") {
                                if (selectedSalesOrderId !== "") {
                                  if (gross_mt !== "") {
                                    if (tare_mt !== "") {
                                      if (driver_name !== "") {
                                        if (driver_number !== "") {
                                          if (validator.isMobilePhone(driver_number)) {
                                            if (image) {
                                              this.setState({ loading: true });

                                              var newItemId =
                                                salesOrders.length > 0 &&
                                                salesOrders.filter(
                                                  (data) => data.id === this.state.selectedSalesOrderId
                                                )[0].item;

                                              console.log(newItemId, "newItemId");
                                              let tripData = new FormData();

                                              tripData.append("transit_pass_file", image.img);
                                              tripData.append("transit_pass", transitPassId);

                                              tripData.append("transporter_group", TransporterGroupId);
                                              tripData.append("transporter", Transporter ? Transporter : "");
                                              tripData.append("vehicle", vehicleId);
                                              tripData.append("consignor", consignorId);
                                              tripData.append("source", skip ? 7 : this.state.source_id);
                                              tripData.append("consignee", partyId);
                                              tripData.append("item", newItemId ? newItemId : selectedSalesItemId);
                                              tripData.append("item_grade", selectedItemId);
                                              tripData.append("tare_weight", tare_mt);
                                              tripData.append("gross_weight", gross_mt);
                                              tripData.append(
                                                "transit_pass_date",
                                                skip
                                                  ? ""
                                                  : moment(this.state.transit_pass_date).format("YYYY-MM-DDTHH:MM:SS")
                                              );
                                              tripData.append("created_using_tp", skip ? false : true);
                                              tripData.append("net_weight", net_mt);
                                              tripData.append("driver_name", driver_name);
                                              tripData.append("driver_number", driver_number);
                                              tripData.append("dispatch_status", "NEW");
                                              tripData.append("dispatch_remark", null);
                                              tripData.append("lr_recieving_status", "");
                                              tripData.append("lr_shortage", 0);
                                              tripData.append("lr_remark", null);
                                              tripData.append("payment_rate", 0);
                                              tripData.append("is_active", true);
                                              tripData.append("sales_order", selectedSalesOrderId);
                                              tripData.append(
                                                "bulk_permit",
                                                this.state.bulk_permit_id ? this.state.bulk_permit_id : ""
                                              );

                                              this.props.postTrip(this.props.user.user.uid, tripData).then((res) => {
                                                if (res.error) {
                                                  this.setState({
                                                    error: res.data,
                                                    loading: false,
                                                  });
                                                } else {
                                                  this.props
                                                    .fetchSingleDateTrips(
                                                      this.props.singleDate,
                                                      this.props.user.user.uid
                                                    )
                                                    .then(() => {
                                                      this.props
                                                        .fetchDates(
                                                          this.props.user.user.uid,
                                                          this.props.selectedMonth.monthId,
                                                          this.props.selectedMonth.yearId
                                                        )
                                                        .then((resres) => {
                                                          if (resres) {
                                                            this.props.setsingleDate(
                                                              resres.filter(
                                                                (data) => data.id === this.props.singleDate.id
                                                              )[0],
                                                              resres
                                                            );
                                                          }
                                                          this.props
                                                            .fetchSingleDateDetails(
                                                              this.state.singleDate,
                                                              this.props.user.user.uid
                                                            )
                                                            .then(() => {
                                                              this.props
                                                                .fetchDates(
                                                                  this.props.user.user.uid,
                                                                  this.props.selectedMonth.monthId,
                                                                  this.props.selectedMonth.yearId
                                                                )
                                                                .then((resres) => {
                                                                  if (resres) {
                                                                    this.props.setsingleDate(
                                                                      resres.filter(
                                                                        (data) => data.id === this.props.singleDate.id
                                                                      )[0],
                                                                      resres
                                                                    );
                                                                  }
                                                                  this.setState({
                                                                    tripAdded: " New Trip Added",
                                                                    loading: false,
                                                                  });
                                                                });
                                                            });
                                                        });
                                                    });
                                                }
                                              });
                                            } else {
                                              var errorMsg = this.state.errorMsg;
                                              errorMsg.tpUpload = "Please upload a transit pass !";
                                              this.setState({ errorMsg });
                                            }
                                          } else {
                                            var errorMsg = this.state.errorMsg;
                                            errorMsg.driver_number = "Incorrect driver number !";
                                            this.setState({ errorMsg });
                                          }
                                        } else {
                                          var errorMsg = this.state.errorMsg;
                                          errorMsg.driver_number = "Please add driver number !";
                                          this.setState({ errorMsg });
                                        }
                                      } else {
                                        var errorMsg = this.state.errorMsg;
                                        errorMsg.driver_name = "Please add driver name !";
                                        this.setState({ errorMsg });
                                      }
                                    } else {
                                      var errorMsg = this.state.errorMsg;
                                      errorMsg.tare_mt = "Please add tare mt!";
                                      this.setState({ errorMsg });
                                    }
                                  } else {
                                    var errorMsg = this.state.errorMsg;
                                    errorMsg.gross_mt = "Please add gross mt!";
                                    this.setState({ errorMsg });
                                  }
                                } else {
                                  var errorMsg = this.state.errorMsg;
                                  errorMsg.selectedalesOrderName = "Please select any one!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                var errorMsg = this.state.errorMsg;
                                errorMsg.selectedItem = "Please select any one item !";
                                this.setState({ errorMsg });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.partyName = "Please select any one party !";
                              this.setState({ errorMsg });
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.TransporterGroupName = "Please select any one transporter group !";
                            this.setState({ errorMsg });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.vehicleNumber = "Please vehicle number!";
                          this.setState({ errorMsg });
                        }
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.transitPass = "Please add transit pass number!";
                        this.setState({ errorMsg });
                      }
                    }}
                    name={"Submit"}
                    withBg={true}
                    background={"green"}
                    width="120px"
                  />
                </>
              )
            )}

            {/* <Button
            style={{
              borderRadius: "5px",
              padding: "3px",
              maxWidth: "100px",
              width: "70px",
              background: primary,
              justifyContent: "center",
              color: "white",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              textTransform: "capitalize",
            }}
            onClick={this.props.onAdd}>{editTrip || tp ? "Edit" : "Add"}</Button> */}
          </div>{" "}
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogDispatch;
