import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import TranspotersGroup from "../components/TranspotersGroup";
import Destination from "../components/Destination";
import {
  fetchDestinationCities,
  addDestinationCities,
  destinationEdit,
} from "../redux/index";
class DestinationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ destinationCities: next.destinationCities });
  }
  // fetchUsers = () => {
  //   console.log("this.props.user.uid", this.props.user.user.uid);
  //   if (this.props.user.user.uid) {
  //     this.props
  //       .const fetchDestinationCities = () => async (dispatch, getState) => {
  //         (this.props.user.user.uid)
  //           .then((res) => {
  //             if (res) {
  //               this.setState({ destinationCities: res });
  //             }
  //           });
  //       }
  //   };
  fetchUsers = () => {
    if (this.props.user.user.uid) {
      this.props
        .fetchDestinationCities(this.props.user.user.uid)
        .then((res) => {
          if (res) {
            console.log("this.props.user.uidaaaaaaaaaaaaaaaa", res);
            this.setState({ destinationCities: res });
          }
        });
    }
  };
  render() {
    return (
      <Route
        exact
        path={[
          "/destinationcities",
          "/destinationcities/:tab/:id",
          "/destinationcities/new",
        ]}
        render={(props) => <Destination {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  destinationCities: state.masters.destinationCities,
});
const mapDispatchToProps = {
  fetchDestinationCities,
  addDestinationCities,
  destinationEdit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DestinationContainer);
