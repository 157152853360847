import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { CheckCircle } from "@material-ui/icons";

function ListItemElement(props) {
  const [selectedIndex, setIndex] = useState(props.selectedIndex);
  const {
    avatar,
    color,
    index,
    listClickHandler,
    primary,
    secondary,
    dispatchList,
    is_finalised,
    primarySecondValue,
    secondaryField,
    primaryFieldThirdFallback,
    salesList,
  } = props;
  // if (selectedIndex === -1 && props.match.params.hasOwnProperty("id")) {
  //   setIndex( props.match.params.hasOwnProperty("id"));
  // }
  useEffect(
    () => {
      console.log(props.selectedIndex, index, selectedIndex === index, "selectedIndexss");
      setIndex(props.selectedIndex);
    },
    [props.selectedIndex]
  );
  console.log(selectedIndex, selectedIndex === index, "selectedIndex");
  return (
    <div>
      <ListItem
        className="cursorP"
        alignItems="flex-start"
        style={{
          padding: "20px 10px",
          position: "relative",
          backgroundColor: selectedIndex === index ? "rgb(30 53 89 / 9%)" : "",
          borderBottom: "1px solid #1e3559",
        }}
        onClick={(e) => {
          e.preventDefault();
          listClickHandler(index);
        }}
      >
        {salesList ? (
          ""
        ) : dispatchList ? (
          is_finalised ? (
            <Avatar
              style={{
                color: "white",
                backgroundColor: primary,
                borderRadius: "20px",
                width: "25px",
                height: "25px",
                fontSize: "0.7rem",
                textAlign: "center",
                justifyContent: "center",
                background: "#00800075",
                position: "absolute",
                right: "16px",
                top: "5px",
              }}
            >
              <CheckCircle />{" "}
            </Avatar>
          ) : primaryFieldThirdFallback &&
            primaryFieldThirdFallback.total_pending_trips &&
            primaryFieldThirdFallback.total_pending_trips > 0 ? (
            <Avatar
              style={{
                color: "white",
                backgroundColor: primary,
                borderRadius: "20px",
                width: "25px",
                height: "25px",
                fontSize: "0.7rem",
                textAlign: "center",
                justifyContent: "center",
                background: "#00800075",
                position: "absolute",
                right: "16px",
                top: "5px",
              }}
            >
              {primaryFieldThirdFallback.total_pending_trips}
            </Avatar>
          ) : (
            ""
          )
        ) : (
          <ListItemAvatar>
            <Avatar
              alt={primary}
              src={avatar}
              style={{
                background: dispatchList ? "#1e3559" : "",
                boxShadow:
                  "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
              }}
            >
              {dispatchList ? primary?.substring(8, 10) : primary?.substring(0, 1)}
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          primary={
            dispatchList ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: dispatchList ? "center" : "space-between",
                }}
                className={dispatchList ? "d-flex width-100" : ""}
              >
                <Typography
                  component={"span"}
                  style={
                    {
                      display: "inline-flex",
                      color: "#000000DE",
                      width: "auto",
                      maxWidth: "calc(100% - 58px)",
                      maxHeight: "38px",
                      fontWeight: "bold",
                      height: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: selectedIndex === index ? "#1e3559" : "",
                      fontWeight: selectedIndex === index ? "800" : "500",
                    }
                    // textTransform: "capitalize",
                  }
                >
                  {primary}
                </Typography>{" "}
                {/* <Typography
                  style={{
                    // fontSize: "0.8rem",
                    flexDirection: "column",
                    display: "flex",
                    height: "auto",
                    alignItems: "center",
                    fontSize: "0.7rem",
                    borderRadius: "5px",
                    padding: "0 2Px",
                    justifyContent: "center",
                    width: "100%", textAlign: "center"
                  }}
                  component={"span"}
                >
                  {primary === moment(new Date()).format("YYYY-MM-DD")
                    ? "Today"
                    : primary ===
                      moment(new Date()) //firebase.firestore.Timestamp.now()
                        .subtract(1, "days")
                        .format("YYYY-MM-DD")
                      ? "Yesterday"
                      : ""}
                </Typography>{" "} */}
              </div>
            ) : (
              <span
                style={{
                  color: selectedIndex === index ? "rgb(30 53 89)" : "black",
                  fontWeight: selectedIndex === index ? "800" : "500",
                }}
              >
                {primary}
              </span>
            )
          }
          secondary={
            dispatchList ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: dispatchList ? "center" : "space-between",
                }}
                className={dispatchList ? "d-flex width-100" : ""}
              >
                <Typography
                  style={{
                    marginTop: "0",
                    maxHeight: "48px",
                    fontSize: "0.8rem",
                    width: "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    fontWeight: selectedIndex === index ? "800" : "500",
                  }}
                  component={"div"}
                >
                  {salesList ? (
                    <>
                      {` Pending ${primarySecondValue ? Number(primarySecondValue).toFixed(0) : ""} MT | Open
                      ${primaryFieldThirdFallback ? primaryFieldThirdFallback : ""}`}
                    </>
                  ) : (
                    <>
                      {" "}
                      {primaryFieldThirdFallback && primaryFieldThirdFallback.total_trips
                        ? primaryFieldThirdFallback.total_trips
                        : ""}{" "}
                      trips |{" "}
                      {primaryFieldThirdFallback && primaryFieldThirdFallback.total_net_weight
                        ? primaryFieldThirdFallback.total_net_weight
                        : ""}{" "}
                      MT
                    </>
                  )}
                </Typography>
              </div>
            ) : (
              <span
                style={{
                  color: "grey",
                  fontWeight: selectedIndex === index ? "600" : "500",
                }}
              >
                {" "}
                {secondary}
              </span>
            )
          }
        />
      </ListItem>
      <Divider />
    </div>
  );
}

export default ListItemElement;
