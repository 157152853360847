import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import Grades from "../components/Grades";
import { fetchItems, gradesAdd, gradesEdit } from "../redux/index";
class GradesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ items: next.items });
  }

  fetchUsers = () => {
    if (this.props.user.user.uid) {
      this.props.fetchItems(this.props.user.user.uid).then((res) => {
        if (res) {
          console.log("this.props.user.uidaaaaaaaaaaaaaaaa", res);
          this.setState({ items: res });
        }
      });
    }
  };
  render() {
    return (
      <Route
        exact
        path={["/items", "/items/:tab/:id", "/items/new"]}
        render={(props) => <Grades {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  items: state.masters.items,
});
const mapDispatchToProps = {
  fetchItems,
  gradesAdd,
  gradesEdit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GradesContainer);
