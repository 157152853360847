import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white } from "../styles/colors";
import Button from "@material-ui/core/Button";
import { Paper, Dialog, Tooltip, Slide } from "@material-ui/core";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import NoData from "../styles/Illustrations/NoData";
import Toggle from "./Toggle";
import SnackbarComponent from "./SnackbarComponent";
import EditIcon from "@material-ui/icons/Create";
import ReactDOM from "react-dom";
import Moment from "moment";
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  contentStyle: {
    display: "flex",
    marginBottom: "10px",
    color: "black",
    fontSize: "1rem",
  },
  withDivider50: { width: "calc(50% - 5px)",margin:"0 20px 0 10px" },
  withDivider25: { width: "25%" },
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const templateTabs = [
  {
    label: "ItemsGrade",
    tooltip: "Items List",
    data: [],
    route: "/itemsgrades/editItemsgrades",
    primaryField: "name",
    secondaryField: "is_active",
    avatarField: "src",
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class ItemsGrade extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: templateTabs,
      user: null,
      singltemsGradelist: null,
      SalesOrder: "",
      items_grade: [],
      addNewItemGrade: false,
      users: [],
      Name: "",
      Tpidentifier:"",
      colorArr: [white, lightGrey],
      success: "",
      error: "",
      errorMsg: {
        Name: false,
        Tpidentifier:false
      },
      status: false,
      editGrades: false,
      openMessage: false,
    };
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      console.log(next.match.params["tab"], "singltemsGradelistyyyyyyyyyy");
      let tab = next.match.params["tab"];
      if (tab === "editItemsgrades" && next.match.params.hasOwnProperty("id")) {
        console.log(this.state.tabs[0].data[next.match.params["id"]], "singltemsGradelistyyyyyyyyyy");
        this.setState(
          {
            singltemsGradelist: this.state.tabs[0].data[next.match.params["id"]],
            Name: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].name
              : "",
              Tpidentifier :this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].tp_identifier
              : "",
            addNewItemGrade: false,
            status: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].is_active
              : "",
          },
          () => {
            console.log(this.state.singltemsGradelist, "singltemsGradelistyyyyyyyyyy");
          }
        );
      } else if (tab === "new") {
        this.setState({
          // singltemsGradelist: null,
          // Name: "",
          addNewItemGrade: true,
        });
      }
    } else this.setState({ singltemsGradelist: null });
  }

  listClickHandler = (value) => {
    console.log(value, "annuu11");
    this.setState({
      addNewItemGrade: false,
      singltemsGradelist: true,
      Name: value.name,
      Tpidentifier:value.tp_identifier,
      Status: value.is_active,
      createdAt: value.created_at,
      openMessage: false,
    });
  };
  setInitialValues(props) {
    console.log(props.items_grade, " props.drivers");
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.items_grade && props.items_grade.length > 0 ? props.items_grade : [];

      return tab;
    });
    console.log(tabs, "tabs");
    this.setState({ items_grade: props.items_grade, tabs });
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg.Name = false;
    errorMsg.Tpidentifier=false;
    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };
  handleClearFields = () => {
    this.setState({ Name: "",Tpidentifier:"" });
  };
  render() {
    const {
      tabs,
      items_grade,
      addNewItemGrade,
      singltemsGradelist,
      Name,
      status,
      success,
      error,
      Status,
      createdAt,
      errorMsg,
      name,Tpidentifier
    } = this.state;

    return (
      <Layout
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          { data: name, hintText: "Search ItemName", labelField: "name" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={this.listClickHandler}
        fabClickHandler={() => {
          this.props.history.push("/itemsgrades/new");
          this.setState({
            addNewItemGrade: true, // singltemsGradelist: false,
            status: true,
            editGrades: true,
          });
          this.handleClearFields();
        }}
      >
        
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        {this.state.editGrades ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "20px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "fit-content";
                target.style.minWidth = "60%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({
                editGrades: false,
                openMessage: true,
                errorMsg: {
                  Name: false,
                  Tpidentifier:false
                },
              });
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 30px",
                fontSize: "1.5rem",
              }}
            >
              {" "}
              <span style={{ color: "black" }}>{addNewItemGrade ? " Add ItemsGrade Detail" : 
              "Edit ItemsGrade Detail"}</span>
              <div>
                <Toggle
                  status={status}
                  handleChange={() => {
                    this.setState({ status: !status });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                fontSize: "1rem",
                color: "grey",
                background: "white",
                margin: "20px 20px",
              }}
            >
              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsg.Name ? true : false}
                helperText={errorMsg.Name ? "Please Add Name!" : false}
                id="outlined-basic"
                label="ItemsGrade Name"
                name="Name"
                variant="outlined"
                value={Name}
                onChange={this.handleChange}
              />

     
                     
               <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsg.Tpidentifier ? true : false}
                helperText={errorMsg.Tpidentifier ? "Please Add Name!" : false}
                id="outlined-basic"
                label="TP Identifier"
                name="Tpidentifier"
                variant="outlined"
                    // InputProps={{
                    //   startAdornment: Tpidentifier ? "Fines- " : "",
                    // }}
                value={Tpidentifier}
                onChange={this.handleChange}
              />
            </div>
             
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: "40px 0 40px 30px",
                width: "100%",
              }}
            >
              {console.log(this.state.singltemsGradelist, "nameeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")}
              <Button
                style={{ background: primary, color: "white", width: "200px" }}
                onClick={() => {
                  if (Name) {
                    if(Tpidentifier){
                    if (addNewItemGrade) {
                      const addData = { name: Name, tp_identifier : Tpidentifier, is_active: status };
                      this.props.items_gradeAdd(addData, this.props.user.user.uid).then(() => {
                        this.props.fetchitems_grade(this.props.user.user.uid).then((res) => {
                          if (res) {
                            this.setState(
                              {
                                success: "Added successfully !",
                                editGrades: false,
                                items: res,
                                openMessage: true,
                              },
                              () => {
                                this.handleClearFields();
                              }
                            );
                          }
                        });
                      });
                    } else {
                      const addData = { name: Name, tp_identifier : Tpidentifier };
                     
                      this.props
                        .items_gradeEdit(addData,
                             this.props.user.user.uid, this.state.singltemsGradelist.id)
                        .then(() => {
                          this.props.fetchitems_grade(this.props.user.user.uid).then((res) => {
                            if (res) {
                              this.setState({
                                success: "Edited successfully !",
                                editGrades: false,
                                items: res,
                                openMessage: false,
                              });
                            }
                          });
                        });
                    }
                  } else {
                    var errorMsg = this.state.errorMsg;
                    errorMsg.Name = true;
                    this.setState({ errorMsg });
                  }
                }}}
              >
                {addNewItemGrade ? "add " : "edit"}
              </Button>
            </div>
          </Dialog>
        ) : (
          ""
        )}

        {this.state.openMessage ? (
          <div
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoData />
          </div>
        ) : (
          ""
        )}
        {this.state.openMessage == false ? (
          <>
            {(items_grade.length > 0 && singltemsGradelist) || addNewItemGrade ? (
              <Paper style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 100px",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <span style={{ color: "black" }}>Items Detail</span>
                </div>
                <Paper
                  style={{
                    border: "1px solid #8080802b",
                    padding: "20px 0",
                    margin: " 0 100px",
                    borderRadius: "10px",
                    boxShadow: "3px 3px #8080805c",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title="Edit Grades"
                      aria-label="add"
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <EditIcon
                        onClick={() => {
                          this.setState({ editGrades: true });
                        }}
                        style={{
                          width: 50,
                          marginRight: "3%",
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0 20px 30px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ width: "50%" }}>
                        <div style={{ borderRadius: "10px" }}>
                          <span style={styles.contentStyle}>
                            Name :<span style={{ marginLeft: "10px", color: "grey" }}> {Name}</span>
                          </span>
                              <span style={styles.contentStyle}>
                            TpIdentifier :<span style={{ marginLeft: "10px", color: "grey" }}> {Name}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Status :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {Status ? "Active" : "Inactive"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Created At :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}>
                              {" "}
                              {Moment(createdAt).format("MMM DD YYYY, h:mm a")}
                            </span>
                          </span>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>{" "}
              </Paper>
            ) : (
              <div
                style={{
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoData />
              </div>
            )}
          </>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default withRouter(ItemsGrade);
