import React, { Component } from "react";

import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import moment from "moment";
import { Paper, Divider, Backdrop } from "@material-ui/core";
import { darkestGray, primary, white } from "../styles/colors";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { withStyles } from "@material-ui/styles";
import Moment from "moment";
import domtoimage from "dom-to-image";
import { BottomArrow, Reload, TopArrow } from "../styles/Icons";
import Layout from "../layouts/Layout";
// import { Share } from "@capacitor/share";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#424242",
      borderColor: white,

      // background: "#F4F4FA",
    },
    "& .MuiInputBase-input": {
      padding: "14px 0",
      borderRadius: "2px",
      color: "transparent",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      // background: white,
      background: primary,
      borderRadius: "13px",
      color: white,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(13.5px, 13.5px) scale(1)",
      color: white,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: darkestGray,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInput-underline:before": { borderBottom: "none" },
    "& .MuiFormHelperText-root": {
      margin: "0",
      backgroundColor: white,
      color: white,
      height: "0",
      display: "none",
    },
    "& .MuiPickersBasePicker-container": {
      maxWidth: "350px",
      width: "100%",
      margin: "0 0 0 -43px",
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
    "& .MuiOutlinedInput-root": {
      "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
      "& .MuiOutlinedInput-input ": {
        padding: "13.5px  0 13.5px 14px",
        caretColor: "transparent",
        color: white,
      },
      "& .MuiIconButton-root": {
        padding: "2px 0 2px 0",
        margin: "10px 0 10px 12px",
        borderRadius: "2px",
        color: white,
      },
      "& fieldset": {
        // borderColor: darkestGray,
      },
      "&:hover fieldset": { borderColor: darkestGray },
      "&.Mui-focused fieldset": {
        // borderColor: darkestGray,
      },
    },
  },
})(KeyboardDatePicker);

const styles = {
  chip: {
    padding: "5px",
    borderRadius: "13px",
    background: primary,
    minWidth: "77px",
  },
};
export class SalesOrderMobileView extends Component {
  constructor(props) {
    super(props);
    const loadedAt = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : new Date();
    this.state = {
      dates: [],
      props,
      selectedDateData: {},
      selectedDate: new Date(), //).format("Do MMM, ddd"),
      consignees: [],
      salesOrders: {},
      noData: false,
      total_weight: 0,
      total_trips: 0,
      calenderOpen: false,
      loading: {},
      loadFirst: false,
    };
  }

  handleDateChange = (selectedDate) => {
    localStorage.setItem("selectedDate", JSON.stringify(moment(selectedDate).format("YYYY-MM-DD")));
    this.setState(
      {
        selectedDate: selectedDate,
      },
      () => {
        const { dates, selectedDate } = this.state;
        const selectedDateData = dates.filter(
          (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
        )[0];
        this.props.fetchSalesOrdersDaySummary(
          selectedDateData ? selectedDateData.date : moment(selectedDate).format("YYYY-MM-DD"),
          this.props.user.user.uid
        );
        localStorage.setItem("loadedAt", new Date());

        this.setState({
          selectedDateData,
          noData: false,
        });
      }
    );
  };
  setInitialValues = (props, componentDidMount) => {
    const loadedAt = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : new Date();
    if (props) {
      if (props.dates && props.dates.length > 0) {
        props.dates.sort((a, b) => {
          var dateA = b.date; // moment(b.date).format("Do MMM");
          var dateB = a.date; //moment(a.date).format("Do MMM");
          return dateA > dateB ? 1 : -1;
        });
        props.dates.map((data, index) => {
          data.formattedDate = moment(data.date).format("Do MMM, ddd");
        });
      }
      if (!this.state.loadFirst && props.loading.dates === false && props.dates.length > 0) {
        this.setState(
          {
            loading: props.loading,
            loadedAt,
            loadFirst: true,
            dates: props.dates && props.dates.length > 0 ? props.dates : [],
            consignees: props.consignees && props.consignees.length > 0 ? props.consignees : [],
            salesOrders: props.salesOrders && props.salesOrders,
          },
          () => {
            this.handleDateChange(new Date());
          }
        );
      } else {
        this.setState({
          loading: props.loading,
          loadedAt,
          dates: props.dates && props.dates.length > 0 ? props.dates : [],
          consignees: props.consignees && props.consignees.length > 0 ? props.consignees : [],
          salesOrders: props.salesOrders && props.salesOrders,
        });
      }
    }
  };
  UNSAFE_componentWillMount() {
    localStorage.setItem("loadedAt", new Date());
    this.setInitialValues(this.props);
  }
  componentDidMount() {
    this.setInitialValues(this.props, "componentDidMount");
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  // shareImageFunction = () => {
  //   Share.share({
  //     title: "See cool stuff",
  //     text: "Really awesome thing you need to see right meow",
  //     url: "http://ionicframework.com/",
  //     dialogTitle: "Share with buddies",
  //   });
  // };

  downloadImageFunction = () => {
    const { selectedDate } = this.state;
    const fileName = `PLIPL-${Moment(selectedDate).format("Do MMM, ddd")}`;
    this.setState({ toPrint: true }, () => {
      domtoimage
        .toJpeg(document.getElementById("mycanvas"), { quality: 0.95 })
        .then(function(dataUrl) {
          var link = document.createElement("a");
          link.download = fileName ? `${fileName}.jpeg` : "image.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          this.setState({ toPrint: false });
        });
    });
  };
  render() {
    const {
      loading,
      selectedDate,

      noData,
      salesOrders,
      calenderOpen,
      toPrint,
      loadedAt,
    } = this.state;
    const dateTimeAgo = moment(loadedAt).fromNow();
    return (
      <Layout
        title={"Sales Orders"}
        subTitle={`Last updated ${dateTimeAgo}!`}
        // ShareImage={() => {
        //   this.shareImageFunction();
        // }}
        DownloadImage={() => {
          this.downloadImageFunction();
        }}
      >
        <div className="d-flex width-100 height-100 postionR d-flex-column padding20 ">
          {loading.dates === true ? (
            <div
              className="alignC justiyC d-flex"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div className="d-flex width-100 justifyC" style={{ margin: "0 0 20px 0" }}>
                <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                  <CssKeyboardDatePicker
                    variant="inline"
                    onOpen={() => {
                      this.setState({ calenderOpen: true });
                    }}
                    onClose={() => {
                      this.setState({ calenderOpen: false });
                    }}
                    keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                    maxDate={new Date()}
                    disableFuture
                    minDate={"2022-01-22"}
                    error={false}
                    invalidLabel=""
                    onKeyPress={(ev) => {
                      ev.preventDefault();
                    }}
                    allowKeyboardControl={false}
                    clearable
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label={selectedDate ? "" : "Date"}
                    format="do MMM, yyyy"
                    style={{ maxWidth: "350px", cursor: "none" }}
                    className="width-100"
                    value={selectedDate ? selectedDate : ""}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => this.handleDateChange(date)}
                  />{" "}
                </MuiPickersUtilsProvider>{" "}
              </div>
              {loading.salesOrders === true ? (
                <div
                  className="alignC justiyC d-flex"
                  style={{
                    height: "calc(100% - 66px)",
                    width: "100%",
                  }}
                >
                  <StyledCircularProgress color="inherit" />
                </div>
              ) : noData ? (
                <div className="width-100 d-flex justifyC alignC height-100"> No trips on this date.</div>
              ) : (
                <div
                  className=" d-flex d-flex-column alignC overflowYA"
                  style={{
                    height: "calc(100% - 66px)",
                    width: "calc(100% + 40px)",
                    padding: "0 20px",
                    margin: "0 -20px",
                  }}
                >
                  {" "}
                  {salesOrders && salesOrders.length > 0 ? (
                    <div
                      id="mycanvas"
                      style={{
                        width: toPrint ? "fit-content" : "100%",
                        height: "fit-content",
                        minWidth: toPrint ? "fit-content" : "100%",
                        minHeight: toPrint ? "fit-content" : "100%",
                        padding: toPrint && "20px",
                        borderRadius: toPrint && "7px",
                        backgroundColor: toPrint && "#98AFC7",
                      }}
                    >
                      {" "}
                      {toPrint && (
                        <div
                          style={{
                            padding: "0 0 30px 0",
                            width: "100%",

                            height: "50px",
                            width: "fit-content",
                            minWidth: "100%",
                            color: primary,
                            fontWeight: "400",
                          }}
                        >{`Sales Orders till :- ${moment(selectedDate).format("MMMM Do YYYY")}`}</div>
                      )}
                      <div
                        className="d-flex width-100"
                        style={{ margin: "0 0 10px 0", minHeight: "277px", height: "277px" }}
                      >
                        <Paper className="height-100 d-flex paddingT20 borderRadius13 d-flex-column width-100">
                          {" "}
                          <div
                            className="d-flex  d-flex-column minWidthFC paddingRL10"
                            style={{ minWidth: "fit-content" }}
                          >
                            <div className="d-flex  justifyC textC fontWeightBold">Total MT</div>
                            <div
                              className="d-flex justifyC textColorprimary  textC textColorprimary"
                              style={{
                                fontSize: "0.9rem",
                                minWidth: "fit-content",
                                maxHeight: "20.59px",
                                display: "flex",
                              }}
                            >
                              {`till ${moment(selectedDate)
                                .subtract(1, "days")
                                .format("Do MMM, yyyy")}`}
                            </div>
                          </div>{" "}
                          <Divider className=" " style={{ margin: "10px 15px 0", width: "calc(100% - 30px)" }} />
                          <div
                            className={`width-100 d-flex textC padding20 ${
                              toPrint ? "minWidthFC" : "overflowXA"
                            } scrollBluePrimarythin  ${salesOrders && salesOrders.length > 2 ? "" : "justifySA"} `}
                          >
                            {salesOrders.map((sSo, i) => (
                              <>
                                <div
                                  className="d-flex  d-flex-column minWidthFC paddingRL10"
                                  style={{ minWidth: "fit-content" }}
                                >
                                  <div className="d-flex  justifyC textC marginB5 fontWeightBold">{sSo.short_name}</div>
                                  <div
                                    className="d-flex justifyC textColorprimary  textC textColorprimary"
                                    style={{
                                      fontSize: "0.9rem",
                                      minWidth: "fit-content",
                                      maxHeight: "20.59px",
                                      display: "flex",
                                    }}
                                  >
                                    {`${sSo.consignee_data &&
                                      sSo.consignee_data.length > 0 &&
                                      sSo.consignee_data
                                        .reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) + Number(currentValue.yesterday_pending),
                                          0
                                        )
                                        .toFixed(0)}MT`}
                                  </div>
                                </div>{" "}
                                {i !== salesOrders.length - 1 && (
                                  <Divider className="height-100" variant="vertical" style={{ minHeight: "62px" }} />
                                )}{" "}
                              </>
                            ))}
                          </div>
                          <div
                            className="d-flex  d-flex-column minWidthFC paddingRL10 backgroundColorPrimary padding20"
                            style={{ minWidth: "fit-content", borderRadius: "0 0 13px 13px" }}
                          >
                            <div className="d-flex  justifyC textC marginTB10 fontWeightBold textColorW">
                              Pending MT
                            </div>
                            <div
                              className="d-flex justifyC textColorW  textC"
                              style={{
                                fontSize: "0.9rem",
                                minWidth: "fit-content",
                                maxHeight: "20.59px",
                                display: "flex",
                              }}
                            >
                              {`${salesOrders
                                .reduce(
                                  (previousValue, currentValue) =>
                                    Number(previousValue) +
                                    Number(
                                      currentValue.consignee_data &&
                                        currentValue.consignee_data.length > 0 &&
                                        currentValue.consignee_data.reduce(
                                          (previousValue2, currentValue1) =>
                                            Number(previousValue2) + Number(currentValue1.yesterday_pending),
                                          0
                                        )
                                        ? currentValue.consignee_data.reduce(
                                            (previousValue2, currentValue1) =>
                                              Number(previousValue2) + Number(currentValue1.yesterday_pending),
                                            0
                                          )
                                        : 0
                                    ),
                                  0
                                )
                                .toFixed(0)}MT`}
                            </div>
                          </div>{" "}
                        </Paper>
                      </div>{" "}
                      {toPrint ? (
                        <Backdrop style={{ zIndex: 1, color: "#fff" }} open={toPrint}>
                          <StyledCircularProgress color="inherit" />
                        </Backdrop>
                      ) : (
                        ""
                      )}{" "}
                      {salesOrders &&
                        salesOrders.map((sSo) => (
                          <>
                            {sSo.consignee_data &&
                              sSo.consignee_data.map((sGrade) => (
                                <div
                                  className="d-flex width-100 d-flex-column"
                                  style={{ margin: "0 0 10px 0", minHeight: "207px", height: "207px" }}
                                >
                                  <Paper
                                    className="height-100 d-flex borderRadius13 d-flex-column width-100"
                                    style={{ padding: "0 20px" }}
                                  >
                                    <div className={`d-flex width-100 textC padding20 scrollBluePrimarythin justifyC`}>
                                      <div
                                        className="d-flex minWidthFC paddingRL10 fontSize1"
                                        style={{ minWidth: "fit-content", margin: "2px 0" }}
                                      >
                                        <div className="d-flex fontWeightBold">{sSo.short_name} </div>
                                        <div style={{ minWidth: "3px" }} />
                                        <div className="textColorprimary"> {`(${sGrade.grade})`}</div>
                                      </div>{" "}
                                    </div>{" "}
                                    <div
                                      className="d-flex  d-flex-column minWidthFC paddingRL10"
                                      style={{ minWidth: "fit-content" }}
                                    >
                                      <div className="d-flex width-100 justifySB marginTB10">
                                        {" "}
                                        <div className="d-flex justifyC fontWeightBold">Yesterday</div>
                                        <div className="d-flex justifyC textColorprimary">
                                          {sGrade.yesterday_pending
                                            ? `${Number(sGrade.yesterday_pending).toFixed(0)}MT`
                                            : "-"}
                                        </div>
                                      </div>{" "}
                                      <div className="d-flex width-100 justifySB marginTB10">
                                        {" "}
                                        <div className="d-flex justifyC fontWeightBold">Today</div>
                                        <div className="d-flex justifyC textColorprimary">
                                          {sGrade.today_dispatch
                                            ? `${Number(sGrade.today_dispatch).toFixed(0)}MT`
                                            : "-"}
                                        </div>
                                      </div>{" "}
                                      <div className="d-flex width-100 justifySB marginTB10">
                                        {" "}
                                        <div className="d-flex justifyC fontWeightBold ">Remaining</div>
                                        <div className="d-flex justifyC textColorprimary">
                                          {sGrade.remaining ? `${Number(sGrade.remaining).toFixed(0)}MT` : "-"}
                                        </div>
                                      </div>
                                    </div>{" "}
                                  </Paper>
                                </div>
                              ))}
                          </>
                        ))}{" "}
                      {toPrint && (
                        <div
                          className="d-flex justifyFE"
                          style={{
                            height: "50px",
                            width: "100%",
                            padding: "30px 0 0 0",
                            minWidth: "100%",
                            color: primary,
                            fontWeight: "400",
                          }}
                        >{`Summary generated at :- ${moment(new Date()).format("MMMM Do YYYY, h:mm A")}`}</div>
                      )}
                    </div>
                  ) : (
                    <div id="mycanvas" className="width-100 d-flex justifyC alignC height-100">
                      {" "}
                      No Sales Orders yet.
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    );
  }
}

export default SalesOrderMobileView;
