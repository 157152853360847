import { Button } from "@material-ui/core";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import BiltiPrint from "./BiltiPrint";
import { primary } from "../styles/colors";
import Invoice from "./Invoice";
function PDFPrint(props) {
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);

  // const handleExportWithFunction = (event) => {
  //   savePDF(contentArea.current, { paperSize: "A4" });
  // }
  const handleExportWithFunction1 = (url, fileName) => {
    fetch(url, {
      method: "GET",
      // mode: "no-cors",
      headers: {},
    })
      .then(async (response) => {
        await response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "er.pdf");
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });

    // savePDF(url, { paperSize: "A2" });
  };
  const handleExportWithFunction = useReactToPrint({
    content: () => contentArea.current,
  });
  useEffect(
    () => {
      if (props.invoicDownload) {
        console.log(props.url, "props.url");
        handleExportWithFunction();
        // document.getElementById("myFrame").contentWindow.print();
        // let objFra = document.getElementById('myFrame');
        // objFra.contentWindow.focus();
        // objFra.contentWindow.print();
        // fetch(props.url, {
        //   method: "GET",
        //   // mode: "no-cors",
        //   headers: {
        //     Authorization: `JWT ${props.token}`,
        //     "Content-Type": "application/json;charset=UTF-8",
        //   },
        // })
        //   .then(async (response) => {
        //     await response.arrayBuffer().then(function (buffer) {
        //       const url = window.URL.createObjectURL(new Blob([buffer]));
        //       console.log(url, "urlurlurlurlurlurlurlurlurlurl")
        //       // const link = document.createElement("iframe");
        //       // link.href = url;
        //       // link.setAttribute(
        //       //   "download",
        //       //   "nkw.pdf"
        //       // );
        //       // document.body.appendChild(link);
        //       // link.click();
        //     });

        //   })
        //   .catch((err) => {
        //     console.log(err.response);
        //   });
      }
    },
    [props.invoicDownload]
  );
  const { singleTrip, invoicDownload, status } = props;
  return (
    <>
      {/* <Invoice
              {...props}
              singleDate={new Date()}
              singleTrip={{
                ownerName: "Vaibhav Agrawal",
                DName: "Ramu Verma",
                DlNo: "9826009764",
                weight: "24.93 mt",
                quantity: "2H.93MT",
                descriptiomOfGood: " IRON ORE FINES",
                from: "Kanker",
                to: "Raipur",
                lr: " 211113001",
                VehicleINo: " CG04MK0762",
                party: "GPIL, Raipur",
                NetMt: "44.61",
                Group: "SAMITI",
                tP: "approved",
                Invoice: "UPLOAD",
                bilti: "Reprint",
                status: "correction",
              }} />  */}
      {invoicDownload ? (
        <div style={{ height: "0", width: "0", overflow: "hidden" }}>
          {/* <style type="text/css" media="print">
            .no-print {display: none; }
          </style> */}
          <div ref={contentArea} style={{ width: "100%", height: "100%" }}>
            <iframe
              src={"http://www.africau.edu/images/default/sample.pdf"}
              //</div>props.url}
              id="myFrame"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        </div>
      ) : (
        <>
          <Button
            style={{
              borderRadius: "5px",
              padding: "3px",
              maxWidth: "100px",
              width: "100%",
              background: status === "DISPATCHED" ? "" : singleTrip.bilti === "print" ? primary : "",
              justifyContent: "center",
              color: status === "DISPATCHED" ? "green" : "white",
              // margin: "0 calc(50% - 50px)",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              // setView(true)
              handleExportWithFunction();
            }}
          >
            {singleTrip.bilti}
          </Button>

          <div style={{ height: "0", width: "0", overflow: "hidden" }}>
            {/* <PDFExport ref={pdfExportComponent} paperSize="A4"> */}
            <div ref={contentArea} style={{ width: "100%", height: "100%" }}>
              <BiltiPrint {...props} />
            </div>
            {/* </PDFExport> */}
          </div>
        </>
      )}
    </>
  );
}

export default PDFPrint;
