import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import { connect } from "react-redux";
import { fetchDates, fetchSingleDateDetails } from "../redux";
class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: [],
    };
  }

  componentDidMount() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    this.fetchdates(month, year);
  }

  fetchdates = (month, year) => {
    // this.setState(
    //   {
    //     dates: arrTime.sort((a, b) => {
    //       var dateA = moment(b.date).format("Do MMM");
    //       var dateB = moment(a.date).format("Do MMM");
    //       return dateA > dateB ? 1 : -1;
    //     }),
    //   },

    //   })
    if (this.props.user.user.uid) {
      this.props.fetchDates(this.props.user.user.uid, month, year).then((res) => {
        if (res) {
          this.setState({ dates: res });
        }
      });
    }
  };
  render() {
    return (
      <Route
        exact
        path={["/dashboard", "/dashboard/:tab/:id", "/dashboard/new"]}
        render={(props) => (
          <Dashboard
            {...this.props}
            {...this.state}
            setDates={(dateRR) => {
              this.setState({ dates: dateRR });
            }}
          />
        )}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  dates: state.masters.dates,

  loading: state.masters.loading,
  liveParty: state.masters.liveParty,
  livePartyCopy: state.masters.liveParty,
});
const mapDispatchToProps = {
  fetchDates,
  fetchSingleDateDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);
