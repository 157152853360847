import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import TranspotersGroup from "../components/TranspotersGroup";
import {
  fetchTransportersGroups,
  transpoterEdit,
  addTransportersGroups,
} from "../redux/index";
class TranspotersGroupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      transpoterslist: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ transpoterslist: next.transpoterslist });
  }
  fetchUsers = () => {
    console.log("this.props.user.uid", this.props.user.user.uid);
    if (this.props.user.user.uid) {
      this.props
        .fetchTransportersGroups(this.props.user.user.uid)
        .then((res) => {
          if (res) {
            this.setState({ transpoterslist: res });
          }
        });
    }
  };

  render() {
    return (
      <Route
        exact
        path={[
          "/transpotersgroup",
          "/transpotersgroup/:tab/:id",
          "/transpotersgroup/new",
        ]}
        render={(props) => <TranspotersGroup {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  transpoterslist: state.masters.transpoterslist,
});
const mapDispatchToProps = {
  fetchTransportersGroups,
  transpoterEdit,
  addTransportersGroups,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TranspotersGroupContainer);
