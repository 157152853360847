import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import DrawerContents from "../components/layoutComponents/DrawerContents";
import ProjectName from "../components/layoutComponents/ProjectName";
import RightIcon from "../components/layoutComponents/RightIcon";
import { primary } from "../styles/colors";
import { withStyles } from "@material-ui/styles";

const drawerWidth = 291.47;
const CssDrawer = withStyles({
  root: {
    "& .MuiDrawer-paperAnchorDockedLeft": {
      border: "none"
    }
  }
})(Drawer);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: "100%",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap", border: "none"
  },
  drawerOpen: {
    zIndex: "10000",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background:
      "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
    color: "white",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background:
      "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
    color: "white",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  rightIcon: {
    position: "absolute",
    right: 24,
  },
}));

export default function WebViewAppBar(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {/* <Avatar
            variant="rounded"
            alt="Project Name"
            className={classes.logoAvatar}
            onClick={e => props.setOpen(!props.open)}
          // style={{ background: "transparent", border: "1px solid white" }}
          >
            AS
          </Avatar>
          <ProjectName {...props} /> */}
          {props.open ? "" : <ProjectName {...props} />}
          <div className={classes.rightIcon}>
            <RightIcon {...props} />
          </div>
        </Toolbar>
      </AppBar>
      <CssDrawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          }),
        }}
        open={props.open} style={{ border: "none" }}
      >
        <DrawerContents {...props} />
      </CssDrawer>
    </div>
  );
}
