const initialState = {
  loading: {
    transpoterslist: false,
    sourceCities: false,
    consignees: false,
    consignors: false,
    destinationCities: false,
    vehicles: false,
    transpoters: false,
    salesOrders: false,
    items: false,
    drivers: false,
    consigneesAndGrades: false,
    transporter: false,
    items_grade: false,
    liveParty: false,
    dates: false,
    bulkpermit: false,
    singleTransGroupsTrips: false,
    voucher: false,
    rateMatrixes: false,
    paymentAdvices: false,
  },
  transpoterslist: [],
  sourceCities: [],
  consignees: [],
  consignors: [],
  destinationCities: [],
  vehicles: [],
  transpoters: [],
  liveParty: {},
  salesOrders: [],
  items: [],
  dates: [],
  drivers: [],
  consigneesAndGrades: [],
  transporter: [],
  items_grade: [],
  monthArr: [],
  bulkpermit: [],
  data: [],
  singleTransGroupsTrips: [],
  voucher: [],

  rateMatrixes: [],
  paymentAdvices: [],
};
// let initialTime = new Date("2022-01-09Z08:00:00"),
//       endTime = new Date(),
//       arrTime = [],
//       dayMillisec = 24 * 60 * 60 * 1000;
//     for (
//       let q = initialTime;
//       q <= endTime;
//       q = new Date(q.getTime() + dayMillisec)
//     ) {
//       arrTime.push({
//         date: moment(q).format("Do MMM, ddd"),
//         date2: q,
//       });
//     }
const mastersReducer = (state = initialState, action) => {
  const load = state.loading;

  switch (action.type) {
    case "FETCH_REQUEST":
      load[action.payload] = true;
      state[action.payload] = [];
      state.loading = load;
      return { ...state };
    case `FETCH_${action.payload}_SUCCESS`:
      console.log(action.data, "action.data");
      load[action.payload] = false;
      state[action.payload] = action.data;
      state.loading = load;
      return { ...state };
    default:
      return { ...state };
  }
};

export default mastersReducer;
