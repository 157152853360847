import {
  Button,
  CircularProgress,
  withStyles,
  Snackbar,
  SnackbarContent,
  Divider,
  TextField,
  Avatar,
  InputBase,
  Paper,
} from "@material-ui/core";
import React from "react";
import {
  primary,
  green,
  white,
  mostLightGrey,
  disableColor,
  lightestGrey,
  darkGrey,
  primaryOpacity019,
  lightGrey,
  orange,
  lightblue,
} from "./colors";
import { CancelIcon, CrossIcon, SearchIcon } from "./Icons";
const CssButton = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "RGBA(0,134,126,0.1)",
      color: primary,
    },
  },
})(Button);
export const CssTextField = withStyles({
  "& .MuiMenu-paper": {
    margin: "42px 0 0 0",
  },
  "& .MuiFormHelperText-root": {
    color: lightblue,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#f44336",
  },
  root: {
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 20px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField);
export const CssTextField10 = withStyles({
  "& .MuiMenu-paper": {
    margin: "42px 0 0 0",
  },
  "& .MuiFormHelperText-root": {
    color: lightblue,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#f44336",
  },
  root: {
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "15px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(15px , 15px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField);
export const CssTextFieldMobile = withStyles({
  "& .MuiMenu-paper": {
    margin: "42px 0 0 0",
  },

  "& .MuiFormHelperText-root": {
    color: lightblue,
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "#f44336",
  },
  root: {
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(10px , 10px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: white,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiSelect-icon": {
        color: white,
      },
      background: primary,
      color: white,
      borderRadius: "13px",
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField);
export const CssFilledSelectTextField = withStyles({
  "& .MuiMenu-paper": {
    margin: "42px 0 0 0",
  },

  "& .MuiFormHelperText-root.Mui-error": {
    color: "#f44336",
  },
  root: {
    "& .MuiSelect-select": {
      padding: "5px 5px 5px 10px",
      minHeight: "26.5px",
      border: "none",
      display: "flex",
      alignItems: "center",
      textTransform: "capitalize",
      borderRadius: "7px",
      background: "rgba(158, 158, 158, 0.18)",
    },
    "& .MuiFilledInput-underline:after": { border: "none" },
    "& .MuiFilledInput-underline:before": { border: "none" },
    "& .MuiFormHelperText-root": {
      color: lightblue,
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
    },
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 20px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: primary,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: mostLightGrey,
      },
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField);
export const CssTextFieldOrange = withStyles({
  "& .MuiMenu-paper": {
    margin: "42px 0 0 0",
  },
  root: {
    "& .MuiMenu-paper": {
      margin: "42px 0 0 0",
    },
    "& .MuiInputBase-inputMultiline": {
      color: darkGrey,
    },
    "& .MuiOutlinedInput-input": {
      padding: "20px",

      color: darkGrey,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(20px , 20px) scale(1)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.Mui-focused": {
      color: orange,
    },

    "& .MuiTypography-colorTextSecondary": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: orange,
    },
    "& .MuiOutlinedInput-multiline": {
      display: "flex",
      alignItems: "flex-end",
      color: darkGrey,
    },

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: orange,
      },
      "&:hover fieldset": {
        borderColor: orange,
      },
      "&.Mui-focused fieldset": {
        borderColor: orange,
      },
    },
  },
})(TextField);
const CssInputBase = withStyles({
  root: {
    "& .MuiInputBase-input": { color: darkGrey },
    "& :hover": {
      // backgroundColor: "RGBA(0,134,126,0.1)",
      // color: primary,
      border: "none",
    },
  },
})(InputBase);
export const StyledButton = (props) => (
  <CssButton
    onClick={props.onClick}
    className={props.disabled ? "" : props.background && "boxShadow"}
    style={{
      background: props.onlyTextColor
        ? ""
        : props.background
        ? props.background
        : props.disabled
        ? disableColor
        : props.withBg
        ? primary
        : props.color
        ? props.color
        : "transparent",
      color: props.onlyTextColor
        ? props.onlyTextColor
        : props.disabled
        ? lightestGrey
        : props.withBg
        ? props.mostLightGrey
          ? darkGrey
          : white
        : props.color
        ? props.color
        : primary,
      fontFamily: props.fontFamily && props.fontFamily,
      textTransform: props.textTransform ? props.textTransform : "capitalize",
      border: props.border ? props.border : "",
      borderRadius: props.borderRadius ? props.borderRadius : props.withBg ? "6px" : "",
      margin: props.margin ? props.margin : "",
      width: props.width ? props.width : "fit-content",
      minWidth: props.minWidth ? props.minWidth : "",
      height: props.height ? props.height : "",
    }}
    disabled={props.disabled && props.disabled}
  >
    {props.icon} <div style={{ minWidth: "3px" }} /> {props.name}
  </CssButton>
);
export const StyledCircularProgress = (props) => (
  <div
    style={{
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress
      style={{
        height: props.height ? props.height : "65px",
        width: props.width ? props.width : "65px",
        color: props.color ? props.color : primary,
        margin: "0",
      }}
    />
  </div>
);
export const StyledSnackbar = (props) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: props.center ? "center" : "bottom",
        horizontal: props.center ? "center" : props.success || props.error ? "right" : "center",
      }}
      open={true}
      autoHideDuration={2000}
      onClose={() => props.handleCloseSnackbar()}
    >
      <SnackbarContent
        style={{
          backgroundColor: props.success ? green : props.error ? "#DB0011" : props.adminRequestPage ? "grey" : primary,
        }}
        message={
          <span id="message-id" style={{ color: "white" }}>
            {props.message ? props.message : "updated successfully"}
          </span>
        }
      />
    </Snackbar>
  );
};
export const StyledDivider = (props) => {
  return (
    <Divider
      style={{
        color: props.color ? props.color : mostLightGrey,
        opacity: props.opacity ? props.opacity : "0.8",
        margin: props.margin ? props.margin : "0",
        width: props.width ? props.width : "100%",
      }}
    />
  );
};
export const StyledSearchBar = (props) => {
  const [searchBarValue, setSearchBarValue] = React.useState("");
  return (
    <Paper
      className="d-flex width-100"
      style={{
        padding: "",
        maxWidth: "300px",
        width: "300px",
        borderRadius: "7px",
        height: "38px",
        fontSize: "0.5rem",
        background: props.bgC ? props.bgC : "rgba(158, 158, 158, 0.18)",
      }}
      elevation={props.bgC ? 0 : 3}
      component="form"
    >
      <div
        className="d-flex align-c justify-c"
        style={{
          padding: props.padding ? props.padding : "12px 12px",
        }}
      >
        <SearchIcon />
      </div>
      <CssInputBase
        disabled={props.copyData && props.copyData.length === 0}
        style={{ width: "100%", border: "none" }}
        placeholder={props.placeholder ? props.placeholder : "Search !!"}
        inputProps={{ "aria-label": "search google maps" }}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
          }
        }}
        onChange={(event) => {
          setSearchBarValue(event.target.value);
          const filterData = [];
          if (props.copyData && props.fieldName) {
            props.copyData.map((data) => {
              return props.fieldName.map((singleField) => {
                if (
                  singleField &&
                  data[singleField]
                    ?.toString()
                    .toLowerCase()
                    .includes(event.target.value.toString().toLowerCase())
                ) {
                  if (!filterData.includes(data)) {
                    return filterData.push(data);
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              });
            });
            props.filteredData(filterData);
          } else {
            props.onChange(event);
          }
        }}
        value={searchBarValue}
      />{" "}
      <div
        className="d-flex alignC justifyC"
        style={{
          padding: "0 9.5px",
        }}
      >
        <Avatar
          onClick={(event) => {
            setSearchBarValue("");
            props.onClear();
          }}
          style={{
            color: white,
            backgroundColor: primaryOpacity019,
            borderRadius: "50%",
            width: "18px",
            height: "18px",
            fontSize: "0.7rem",
            textAlign: "center",
            justifyContent: "center",
          }}
        >
          <CancelIcon />
        </Avatar>
      </div>
    </Paper>
  );
};
