import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import ItemsGrade from "../components/ItemsGrade";
import { fetchitems_grade,items_gradeAdd ,items_gradeEdit} from "../redux/index";
class ItemGradeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { items_grade: [] };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ items_grade: next.items_grade });
  }

  fetchUsers = () => {
    if (this.props.user.user.uid) {
      this.props.fetchitems_grade(this.props.user.user.uid).then((res) => {
        if (res) {
          console.log("this.props.user.uidaaaaaaaaaaaaaaaa", res);
          this.setState({ items_grade: res });
        }
      });
    }
  };
  render() {
    return (
      <Route
        exact
        path={["/itemsgrades", "/itemsgrades/:tab/:id", "/itemsgrades/new"]}
        render={(props) => <ItemsGrade {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  items_grade: state.masters.items_grade,
});
const mapDispatchToProps = {
  fetchitems_grade,
  items_gradeAdd,
  items_gradeEdit,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemGradeContainer);
