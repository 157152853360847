import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import Paper from "@material-ui/core/Paper";

class DemoFullWidthPaperSettings extends Component {
  render() {
    return (
      <Layout noList={true} tabs={{}}
        noTab={true}
      // listClickHandler={ }
      >
        <Paper style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "1rem",
              color: "grey",
              background: "#d3d3d35c",
            }}
          >
            <span>No data </span>
          </div>
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(DemoFullWidthPaperSettings);
