import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import MastersManagements from "../components/MastersManagements";

function MastersManagementsContainer() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    setUsers([{ name: "Transporter Group" }, { name: "Destination City" }, { name: "Item Category" }, { name: "Consignor" }, { name: "Consignee" }, { name: "Vehicles" }, { name: "Driver" }]
    );

  }, [])

  return (
    <Route
      exact
      path={["/masters", "/masters/user/:id", "/masters/new"]}
      render={props => <MastersManagements {...props} users={users} />}
    />
  );
}

export default MastersManagementsContainer;
