import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import SearchRoundedIcon from "@material-ui/icons/SearchRounded";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import { SearchIcon } from "../../styles/Icons";
const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      border: "none"
    },
    "&.MuiFormControl-marginNormal": {
      marginTop: "16px",
      marginBottom: " 8px",
      marginLeft: " 5px",
      marginRight: "5px",
    },
    "&.MuiFormControl-fullWidth": {
      width: "95%",
    },
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
})(TextField);
export default function RaisedSearchBar({
  data,
  labelField,
  searchedData,
  handleSearch,
  hintText,
}) {
  return (
    <Autocomplete
      // style={{ marginRight: "10px", marginLeft: "10px", width: "calc(100% - 20px)" }}
      // closeIcon={<SearchRoundedIcon />}
      options={data}
      getOptionLabel={(option) => option[labelField]}
      value={searchedData}
      onChange={handleSearch}

      renderInput={(params) => (
        <CssTextField
          style={{
            background: "white",
            boxShadow: "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
            borderRadius: "7px",
            padding: "5px 10px"
            // border: "InputBorder.none",
          }}
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (<InputAdornment position="start"> <SearchIcon />
            </InputAdornment>),
            disableUnderline: true
          }}
          placeholder={hintText}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}
