import { Divider } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
const styles = {
  mainContainer: (fullwidth) => ({
    border: `1px solid ${"rgba(0, 0, 0, 1)"}`,
    margin: "40px",
    minHeight: "calc(100% - 84px)",
    minWidth: fullwidth ? `calc(100px + ${fullwidth}px)` : "calc(100% - 82px)",
    width: fullwidth ? `calc(100px + ${fullwidth}px)` : "calc(100% - 82px)",
    height: "calc(100% - 84px)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  }),

  djac: { display: "flex", justifyContent: "center", alignItems: "center" },
};

function PaymentVoucherPDF(props) {
  const contentArea = useRef(null);
  const [widthList, setWidthList] = useState({});

  const longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].props?.title
            ? it[key].props?.title.toString().length
            : it[key].props?.children
            ? it[key].props?.children.toString().length
            : it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  const handleExportWithFunction = useReactToPrint({
    content: () => contentArea.current,
    onAfterPrint: () => props.onClose(),
  });
  useEffect(
    () => {
      const { sPA } = props;
      if (props.tableList && props.tableList.length > 0) {
        if (props.tableList[0] && !Array.isArray(props.tableList[0])) {
          const widthList = {};
          const array = props.excelHeader ? props.excelHeader : Object.keys(props.tableList[0]);
          array.map((single, i) => {
            return (widthList[props.excelHeader[i]] = Number(
              Math.floor(
                10 *
                  ((single.props?.title
                    ? single.props?.title.toString().length
                    : single.props?.children
                    ? single.props.children.toString().length
                    : single.toString().length) > longest(single, props.tableList)
                    ? single.props?.title
                      ? single.props?.title.toString().length
                      : single.props?.children
                      ? single.props?.children?.toString().length
                      : single.toString().length
                    : longest(single, props.tableList)
                    ? longest(single, props.tableList)
                    : 1)
              )
            ));
          });
          const wscols = [];
          const name = "Round Off (- /+)";
          props.excelHeader.map((sH, i) => {
            if (i === 0) {
              wscols.push("43px");
            } else {
              if (i + 1 === props.excelHeader.length) {
                if (sPA && Number(Math.floor(10 * sPA.transporter_group.toString().length)) > widthList[sH]) {
                  wscols.push(`${Number(Math.floor(10 * sPA.transporter_group.toString().length))}px`);
                } else {
                  wscols.push(`${widthList[sH]}px`);
                }
              } else {
                if (i === 7 && Number(Math.floor(10 * name.toString().length)) > widthList[sH]) {
                  wscols.push(`${Number(Math.floor(10 * name.toString().length))}px`);
                } else {
                  wscols.push(`${widthList[sH]}px`);
                }
              }
            }
          });
          setWidthList(wscols);
        }
      } else {
        const array = props.excelHeader;
        array.map((single, i) => {
          return (widthList[props.excelHeader[i]] = Number(Math.floor(10 * single.length)));
        });
        const wscols = [];
        const name = "Round Off (- /+)";
        props.excelHeader.map((sH, i) => {
          if (i === 0) {
            wscols.push("43px");
          } else {
            if (i + 1 === props.excelHeader.length) {
              if (sPA && Number(Math.floor(10 * sPA.transporter_group.toString().length)) > widthList[sH]) {
                wscols.push(`${Number(Math.floor(10 * sPA.transporter_group.toString().length))}px`);
              } else {
                wscols.push(`${widthList[sH]}px`);
              }
            } else {
              if (i === 7 && Number(Math.floor(10 * name.toString().length)) > widthList[sH]) {
                wscols.push(`${Number(Math.floor(10 * name.toString().length))}px`);
              } else {
                wscols.push(`${widthList[sH]}px`);
              }
            }
          }
        });
        setWidthList(wscols);
      }
    },
    [props.downloadPdf]
  );
  useEffect(
    () => {
      if (contentArea && widthList && widthList.length > 0) {
        handleExportWithFunction();
      }
    },
    [widthList]
  );
  const HDivider = <Divider style={{ minWidth: "100%", width: "100%", backgroundColor: "rgba(0, 0, 0, 1)" }} />;
  const VDivider = (
    <Divider variant="vertical" style={{ height: "100%", backgroundColor: "rgba(0, 0, 0, 1)", minHeight: "29.19px" }} />
  );
  const VDividerW = (
    <Divider variant="vertical" style={{ height: "100%", backgroundColor: "transparent", minHeight: "29.19px" }} />
  );
  const { user, sPA, excelHeader } = props;

  return (
    <>
      <div style={{ height: "0", width: "0", overflow: "hidden" }}>
        <div
          ref={contentArea}
          style={{
            display: "flex",
            justifyContent: "center",
            minHeight: "100%",
            height: "fit-content",
            fontSize: "0.8rem",
            textAlign: "left",
            minWidth: "100%",
            width: "100%",
            textTransform: "capitalize",
          }}
        >
          <div
            style={styles.mainContainer(
              widthList &&
                widthList.length > 0 &&
                widthList.reduce(
                  (previousValue, currentValue) => Number(previousValue) + Number(currentValue.split("px")[0]),
                  0
                )
            )}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                padding: "5px 0",
                fontSize: "1.1rem",
              }}
            >
              {user.companyName}
            </div>
            {HDivider}{" "}
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                padding: "5px 0",
                fontSize: "0.9rem",
              }}
            >
              {`Transportation Charges of ${sPA.itemName} Expenses`}
            </div>
            {HDivider}{" "}
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                padding: "5px 0",
                fontSize: "0.9rem",
              }}
            >
              {`${sPA.itemName} Transportation charges from ${sPA.from} to ${sPA.to}`}
            </div>
            {HDivider}{" "}
            <div
              style={Object.assign(
                {
                  width: "100%",
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                },
                styles.djac
              )}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `calc(100% - 11px - ${widthList[8]})`,
                  minWidth: `calc(100% - 11px - ${widthList[8]})`,
                }}
              >
                {`${sPA.transporter}`}
              </div>{" "}
              {VDivider}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: `${widthList[8]}`,
                  minWidth: `${widthList[8]}`,
                  margin: "0 5px",
                  textDecoration: "underline",
                }}
              >
                {`${sPA.transporter_group}`}
              </div>
            </div>
            {HDivider}{" "}
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                minHeight: "25px",
                display: "flex",
                alignItems: "center",
                padding: "0 5px",
                justifyContent: "space-evenly",
              }}
            >
              <div>{`BANK:- ${sPA.bank}`},</div>
              <div> {`AC NO:- ${sPA.acc}`},</div>
              <div>{`IFSC CODE:- ${sPA.ifsc}`},</div>
            </div>
            {HDivider}{" "}
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                minHeight: "25px",
                display: "flex",
                alignItems: "center",
                padding: "0 5px",
                justifyContent: "space-evenly",
              }}
            >
              <div>{`PAN:- ${sPA.pan},`}</div> <div>{`DATE:- ${sPA.date}`}</div>
            </div>
            {HDivider}
            <div
              style={{
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    {" "}
                    <div
                      style={Object.assign(
                        {
                          margin: "0 5px",
                          width: widthList[i] ? widthList[i] : "fit-content",
                          minWidth: widthList[i] ? widthList[i] : "fit-content",
                        },
                        styles.djac
                      )}
                    >
                      {sH}
                    </div>
                    {i + 1 !== excelHeader.length && VDivider}
                  </>
                ))}
            </div>
            {HDivider}{" "}
            {props.tableList &&
              props.tableList.map((sT) => (
                <>
                  <div
                    style={{
                      width: "100%",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {excelHeader &&
                      excelHeader.map((sH, i) => (
                        <>
                          {" "}
                          <div
                            style={Object.assign(
                              {
                                margin: "0 5px",
                                width: widthList[i] ? widthList[i] : "fit-content",
                                minWidth: widthList[i] ? widthList[i] : "fit-content",
                              },
                              styles.djac
                            )}
                          >
                            {sT[sH]}
                          </div>
                          {i + 1 !== excelHeader.length && VDivider}
                        </>
                      ))}
                  </div>
                  {HDivider}
                </>
              ))}
            <div
              style={{
                minWidth: "100%",
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "relative",
                background: "rgba(158, 158, 158, 0.48)",
              }}
            >
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    <div
                      style={Object.assign(
                        {
                          margin: "0 5px",
                          width: widthList[i] ? widthList[i] : "fit-content",
                          minWidth: widthList[i] ? widthList[i] : "fit-content",
                        },
                        styles.djac
                      )}
                    >
                      {i === 4 ? sPA.total_QTY : i === 6 ? sPA.total_NET_QTY : i === 8 ? sPA.total_off : ""}
                    </div>
                    {i === 7 || i === 6 || i === 3 || i === 4 || i === 5 || i === 0
                      ? VDivider
                      : i + 1 !== excelHeader.length && VDividerW}
                  </>
                ))}
            </div>{" "}
            {HDivider}{" "}
            <div
              style={{
                minWidth: "100%",
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    <div
                      style={Object.assign(
                        {
                          margin: "0 5px",
                          width: widthList[i] ? widthList[i] : "fit-content",
                          minWidth: widthList[i] ? widthList[i] : "fit-content",
                        },
                        styles.djac
                      )}
                    >
                      {i === 8 ? sPA.rounded_off : i === 7 ? "Round Off (- /+)" : ""}
                    </div>
                    {i === 7 || i === 6 || i === 0 ? VDivider : i + 1 !== excelHeader.length && VDividerW}
                  </>
                ))}
            </div>{" "}
            {HDivider}{" "}
            <div
              style={{
                minWidth: "100%",
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    <div
                      style={Object.assign(
                        {
                          margin: "0 5px",
                          width: widthList[i] ? widthList[i] : "fit-content",
                          minWidth: widthList[i] ? widthList[i] : "fit-content",
                        },
                        styles.djac
                      )}
                    >
                      {i === 8 ? sPA.deduction_amount : i === 7 ? "BILTY/200 (-)" : i === 6 ? "BILTY" : ""}
                    </div>
                    {i === 7 || i === 6 || i === 5 || i === 0 ? VDivider : i + 1 !== excelHeader.length && VDividerW}
                  </>
                ))}
            </div>{" "}
            {HDivider}{" "}
            <div
              style={{
                minWidth: "100%",
                width: "100%",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                position: "relative",
                background: "rgba(158, 158, 158, 0.48)",
              }}
            >
              <div
                style={{
                  margin: "0 5px",
                  width: widthList[0] ? `calc(${widthList[0]} + ${widthList[1]} + 12px)` : "fit-content",
                  minWidth: widthList[0] ? `calc(${widthList[0]} + ${widthList[1]} + 12px)` : "fit-content",
                }}
              >
                Prepared By...Murli
              </div>
              {excelHeader &&
                excelHeader.map((sH, i) => (
                  <>
                    {i === 0 || i === 1 ? (
                      ""
                    ) : (
                      <div
                        style={Object.assign(
                          {
                            margin: "0 5px",
                            width: widthList[i] ? widthList[i] : "fit-content",
                            minWidth: widthList[i] ? widthList[i] : "fit-content",
                          },
                          styles.djac
                        )}
                      >
                        {i === 6 ? sPA.total_NET_QTY : i === 8 ? Math.floor(sPA.total_off - sPA.deduction_amount) : ""}
                      </div>
                    )}
                    {i === 0 || i === 1
                      ? ""
                      : i === 7 || i === 6 || i === 5
                      ? VDivider
                      : i + 1 !== excelHeader.length && VDividerW}
                  </>
                ))}
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentVoucherPDF;
