import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import axios from "axios";
import { lightblue, lightGreen, lightGrey, primary, purple, red, white, yellow } from "../styles/colors";
import {
  AppBar,
  ListItem,
  List,
  Box,
  Divider,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  ClickAwayListener,
  Popover,
  Backdrop,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import BiltiPrint from "./BiltiPrint";
import PDFPrint from "./PDFPrint";
import Button from "@material-ui/core/Button";
import { Paper, InputBase, Avatar } from "@material-ui/core";
import { CancelIcon, SearchIcon, PlusIcon, Download } from "../styles/Icons";
import DialogDispatch from "./DialogDispatch";
import TripEditDialog from "./TripEditDialog";
import FilePrevie from "./FilePrevie";
import PropTypes from "prop-types";
import { MoreHorizOutlined } from "@material-ui/icons";
import ExportToExcel from "./ExportToExcel";
import NoData from "../styles/Illustrations/NoData";
import SnackbarComponent from "./SnackbarComponent";
import UploadFile from "./UploadFile";
import moment from "moment";
import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import SummaryTable from "./SummaryTable";
import MobileDispatches from "./MobileDispatched";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="height-100">
          <Typography
            style={{
              margin: "0 -24px -24px -24px",
              height: "calc(100% + 24px)",
              padding: "2px",
              overflowY: "auto",
            }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CssTab = withStyles({
  root: {
    "&.PrivateTabIndicator-colorSecondary-1003": {
      backgroundColor: "#1E32FA",
    },
  },
})(Tab);
const CssTabs = withStyles({
  root: {
    "& .PrivateTabIndicator-colorSecondary-5": {
      backgroundColor: "#00867E",
    },
    "& .MuiTabs-flexContainer": {
      display: " flex",
      height: "40px",
      // justifyContent: "",
    },
  },
})(Tabs);
const CssTablePagination = withStyles({
  root: {
    "& .MuiSelect-icon": {
      color: primary,
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: primary,
      padding: "0",
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "30px",
    },
  },
})(TablePagination);
const templateTabs = [
  {
    //label: "Dates",
    tooltip: "Click to approve pending dates",
    data: [],
    route: "/dispatches/Dates",
    goBackRoute: "/dispatches",
    primaryField: "formattedDate",
    primarySecondValue: "total_net_weight",
    primaryFieldFallback: "total_trips", // Optional
    secondaryField: "dispatch_details",
    primaryFieldThirdFallback: "total_net_weight_pending_trips",
    avatarField: "src",
    is_finalised: "is_finalised",
    dispatchList: true,
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
};
class Dispatches extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const months = [
      {
        month: "Jan",
        monthId: 1,
        yearId: date.getFullYear(),
      },

      {
        month: "Feb",
        monthId: 2,
        yearId: date.getFullYear(),
      },
      {
        month: "Mar",
        monthId: 3,
        yearId: date.getFullYear(),
      },
      {
        month: "Apr",
        monthId: 4,
        yearId: date.getFullYear(),
      },
      {
        month: "May",
        monthId: 5,
        yearId: date.getFullYear(),
      },
      {
        month: "Jun",
        monthId: 6,
        yearId: date.getFullYear(),
      },
      {
        month: "Jul",
        monthId: 7,
        yearId: date.getFullYear(),
      },
      {
        month: "Aug",
        monthId: 8,
        yearId: date.getFullYear(),
      },
      {
        month: "Sep",
        monthId: 9,
        yearId: date.getFullYear(),
      },
      {
        month: "Oct",
        monthId: 10,
        yearId: date.getFullYear(),
      },
      {
        month: "Nov",
        monthId: 11,
        yearId: date.getFullYear(),
      },
      {
        month: "Dec",
        monthId: 12,
        yearId: date.getFullYear(),
      },
    ];
    const MonthArr = months.splice(0, date.getMonth() + 1);
    this.state = {
      MonthArr,
      selectedMonth: {
        month: date.toLocaleString("en-us", { month: "short" }),
        monthId: date.getMonth() + 1,
        yearId: date.getFullYear(),
      },
      loading: false,
      tabs: templateTabs,
      singleDate: null,
      dates: [],
      page: 0,
      rowsPerPage: 3,
      SearchBarValue: "",
      tripList: [],
      copyTripList: [],
      printINvoice: false,
      pdfExportComponent: null,
      colorArr: [white, lightGrey],
      addTrip: false,
      openBilti: false,
      openInvoice: false,
      tabValue: "All Trips",
      tp: false,
      anchorEl: null,
      open: false,
      editTrip: false,
      changeTransporterGroup: false,
      destinationCities: [],
      changeSalesOrder: false,
      changeDate: false,
      cancelTrip: false,
      fetched: false,
      singleDateTrips: [],
      singleDateTripsCopy: [],
      openFinalisedDialog: false,
      singleDateTripsCopyExport: [],
      singleDateTripsCopyExport2: [],
      singleDateTripsCopyExport3: [],
      error: "",
      success: "",
      uploadInvoice: false,
      fieldName: [
        "net_weight",
        "dispatch_status",
        "driver_name",
        "driver_number",
        "gross_weight",
        "lr_number",
        "tare_weight",
        "vehicle",
        "consignee",
        "transporter_group",
      ],

      wscols: [
        { wch: 6 },
        { wch: 14 },
        { wch: 14 },

        { wch: 12 },
        { wch: 9 },
        { wch: 8 },

        { wch: 8 },
        { wch: 8 },

        { wch: 23 },
        { wch: 23 },
        { wch: 40 },
        { wch: 23 },

        { wch: 20 },
        { wch: 15 },
      ],
      wscols1: [
        { wch: 6 },
        { wch: 15 },
        { wch: 9 },
        { wch: 13 },
        { wch: 13 },
        { wch: 13 },

        { wch: 20 },
        { wch: 20 },
        { wch: 40 },
        { wch: 30 },
        { wch: 10 },
      ],
      wscols2: [{ wch: 25 }, { wch: 15 }, { wch: 15 }],
      exportColorArr: ["40B5AD", "C70039", "B4C424", "93C572", "7FFFD4"],
    };
  }
  handlePopoverClose = () => {
    this.handleRequestClose();
  };
  handleTouchTap = (event, id) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      tp: id,
    });
  };
  handleRequestClose = () => {
    this.setState({ open: false, anchorEl: null });
  };
  handleChange = (e) => {
    this.setState({ tabValue: e });
  };
  componentWillMount() {
    const next = this.props;
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];

      if (tab === "Dates" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singleDate: this.state.tabs[0].data[next.match.params["id"]],
          },
          () => {
            console.log(this.state.singleDate, "this.state.singleDate");
            if (this.state.singleDate) {
              localStorage.setItem("selectedDate", JSON.stringify(this.state.singleDate.date));
            }
            // if (this.state.fetched === false) {
            this.setState(
              {
                singleDateTrips: [],
                singleDateTripsCopy: [],
              },
              () => {
                this.props.fetchSingleDateTrips(this.state.singleDate, this.props.user.user.uid);
                this.props.fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid);
              }
            );
          }
        );
      } else if (tab === "NEW") {
        this.setState({ singleDate: null });
      }
    } else {
      this.setState({ singleDate: null });
    }
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  listClickHandler = (value) => {
    localStorage.setItem("selectedDate", JSON.stringify(value.date));
    this.setState(
      {
        singleDate: value,
        singleDateTrips: [],
        singleDateTripsCopy: [],
      },
      () => {
        this.props.fetchSingleDateTrips(this.state.singleDate, this.props.user.user.uid);

        this.props.fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid);

        this.setState({ fetched: true });
      }
    );
  };
  searchHandler = (value) => {
    console.log(value, "valuevalueeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
    if (value && value.monthId) {
      if (this.state.selectedMonth.monthId !== value.monthId) {
        this.setState({ selectedMonth: value, singleDate: null, singleDateTrips: [], singleDateTripsCopy: [] });
        this.props.fetchDates(this.props.user.user.uid, value.monthId, value.yearId).then((dateRR) => {
          this.props.setDates(dateRR);
        });
      }
    }
  };
  setInitialValues(props) {
    if (props.dates && props.dates.length > 0) {
      props.dates.sort((a, b) => {
        var dateA = b.date; // moment(b.date).format("Do MMM");
        var dateB = a.date; //moment(a.date).format("Do MMM");
        return dateA > dateB ? 1 : -1;
      });
      props.dates.map((data, index) => {
        data.formattedDate = moment(data.date).format("Do MMM, ddd");
      });
    }
    var tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.dates && props.dates.length > 0 ? props.dates : [];
      tab.searchArr = this.state.MonthArr;
      return tab;
    });
    const allTrips = props.dispatchesCopy.singleDateTrips
      ? props.dispatchesCopy.singleDateTrips.Trips
        ? props.dispatchesCopy.singleDateTrips.Trips
        : []
      : [];
    if (allTrips.length > 0) {
      // &&
      // (this.state.singleDate.date === props.dispatches.singleDateTrips.Trips[0].trip_date))
      this.setState(
        {
          destinationCities:
            props.destinationCities && props.destinationCities.length > 0 ? props.destinationCities : [],
          bulkpermit: props.bulkpermit,
          dates: props.dates,
          tabs,
          singleDateTrips: props.dispatches.singleDateTrips ? props.dispatches.singleDateTrips : [],
          singleDateTripsCopy: props.dispatches.singleDateTrips ? props.dispatches.singleDateTrips.Trips : [],
        },
        () => {
          const { exportColorArr } = this.state;
          const allTrips = props.dispatchesCopy.singleDateTrips ? props.dispatchesCopy.singleDateTrips.Trips : [];
          const newArr = [];
          const newArr2 = [];
          const newArr3 = [];
          if (allTrips && allTrips.length > 0) {
            allTrips
              .sort((a, b) => a.lr_number - b.lr_number)
              .map((data, i) => {
                var newData = {};
                newData.SNo = i + 1 > 9 ? i + 1 : `0${i + 1}`;
                newData.TP_Date = moment(data.transit_pass_date).format("Do MMM, ddd");
                newData.Vehicle_Number = data.vehicle ? data.vehicle.vehicle_no : "";
                newData.LR_Number = data.lr_number;
                newData.TP_Number = data.transit_pass.TransitPassId;
                // newData.Transpoter = data.transporter.name
                //   ? data.transporter.name
                //   : "-";
                // newData.consigneeGstin_no = data.consignee.gstin_no
                // newData.consigneeAddress = data.consignee.address
                newData.Gross = Number(data.gross_weight);
                newData.Tare = Number(data.tare_weight);
                newData.Net = Number(data.net_weight);
                newData.driver_name = data.driver_name;
                newData.driver_number = data.driver_number;
                newData.Party_Name = data.consignee.name;
                newData.Transporter_Group = data.transporter_group.name;

                // newData.Item = data.item.name;
                newData.Grade = data.item_grade.name;
                newData.Permit_Number = data.bulk_permit.bulk_permit_no ? data.bulk_permit.bulk_permit_no : "";

                // newData.dispatch_status = data.dispatch_status

                // newData.bulk_permit = data.bulk_permit

                // newData.payment_rate = data.payment_rate
                // newData.sales_order = data.sales_order.sales_order_number
                // newData.lr_recieving_status = data.lr_recieving_status
                // newData.lr_remark = data.lr_remark
                // newData.lr_shortage_deducted = data.lr_shortage_deducted

                // newData.consignor = data.consignor.name
                // newData.consignorGstin_no = data.consignor.gstin_no
                // newData.consignorAddress = data.consignor.address
                // newData.dispatch_remark = data.dispatch_remark

                // newData.is_active = data.is_active ? "Active" : "Inactive"
                // newData.transit_pass_file = data.transit_pass_file
                // newData.source = data.source

                newArr.push(newData);
              });
            newArr.push({
              SNo: "Total in MT",
              TP_Date: "",
              Vehicle_Number: "",
              LR_Number: "",
              TP_Number: "",
              Tare: Number(
                allTrips.reduce(
                  (previousValue, currentValue) => Number(previousValue) + Number(currentValue.tare_weight),
                  0
                )
              ),
              Gross: Number(
                allTrips.reduce(
                  (previousValue, currentValue) => Number(previousValue) + Number(currentValue.gross_weight),
                  0
                )
              ),
              Net: Number(
                allTrips.reduce(
                  (previousValue, currentValue) => Number(previousValue) + Number(currentValue.net_weight),
                  0
                )
              ),
              driver_name: "",
              driver_number: "",
              Party_Name: "",
              Transporter_Group: "",

              Grade: "",
              Permit_Number: "",
            });
          }
          if (allTrips && allTrips.length > 0 && this.props.liveParty && this.props.liveParty.total_per_transporter) {
            this.props.liveParty &&
              this.props.liveParty.total_per_transporter &&
              this.props.liveParty.total_per_transporter.map((singleTrans, i) => {
                newArr2.push({
                  SNo: singleTrans.transporter_group,
                  Vehicle_Number: exportColorArr[i % exportColorArr.length],
                  TP_Number: "",
                  Tare: "",
                  Gross: "",
                  Net: "",
                  driver_name: "",
                  driver_number: "",
                  Party_Name: "",
                  Transporter_Group: "",
                  remark: "",
                });
                newArr2.push({
                  SNo: "#",
                  Vehicle_Number: "VEHICLE NO",
                  TP_Number: "TP NO",
                  Tare: "Tare Weight",
                  Gross: "Gross weight",
                  Net: "Net Weight",
                  driver_name: "Driver Name",
                  driver_number: "Driver Mo. No.",
                  Party_Name: "Dispatch Party Name",
                  Transporter_Group: "Transporter",
                  remark: "Remark",
                });
                allTrips
                  .filter((a) => a.transporter_group.name === singleTrans.transporter_group)
                  .sort((a, b) => a.lr_number - b.lr_number)
                  .map((data, i) => {
                    const newData2 = {};
                    newData2.SNo = Number(i + 1 > 9 ? i + 1 : `0${i + 1}`);
                    newData2.Vehicle_Number = data.vehicle ? data.vehicle.vehicle_no : "";
                    newData2.TP_Number = data.transit_pass.TransitPassId;
                    newData2.Tare = Number(data.tare_weight);
                    newData2.Gross = Number(data.gross_weight);
                    newData2.Net = Number(data.net_weight);
                    newData2.driver_name = data.driver_name;
                    newData2.driver_number = data.driver_number;
                    newData2.Party_Name = data.consignee.name;
                    newData2.Transporter_Group = data.transporter_group.name;
                    newData2.remark = data.lr_remark ? data.lr_remark : "-";

                    newArr2.push(newData2);
                  });
                newArr2.push({
                  SNo: "Total in MT",
                  Vehicle_Number: "",
                  TP_Number: "",
                  Tare: Number(
                    allTrips
                      .filter((a) => a.transporter_group.name === singleTrans.transporter_group)
                      .reduce(
                        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.tare_weight),
                        0
                      )
                  ),
                  Gross: Number(
                    allTrips
                      .filter((a) => a.transporter_group.name === singleTrans.transporter_group)
                      .reduce(
                        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.gross_weight),
                        0
                      )
                  ),
                  Net: Number(
                    allTrips
                      .filter((a) => a.transporter_group.name === singleTrans.transporter_group)
                      .reduce(
                        (previousValue, currentValue) => Number(previousValue) + Number(currentValue.net_weight),
                        0
                      )
                  ),
                  driver_name: "",
                  driver_number: "",
                  Party_Name: "",
                  Transporter_Group: "",
                  remark: "",
                });
                var newData = {};
                newData.Transporter = singleTrans.transporter_group;
                newData.total_trips = singleTrans.total_trips;
                newData.total_net_weight = singleTrans.total_net_weight;
                newArr3.push(newData);
              });
            newArr3.push({
              Transporter: "Total",
              total_trips: Number(
                this.props.liveParty &&
                  this.props.liveParty.total_per_transporter &&
                  this.props.liveParty.total_per_transporter.reduce(
                    (previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_trips),
                    0
                  )
              ),
              total_net_weight: Number(
                this.props.liveParty &&
                  this.props.liveParty.total_per_transporter &&
                  this.props.liveParty.total_per_transporter.reduce(
                    (previousValue, currentValue) => Number(previousValue) + Number(currentValue.total_net_weight),
                    0
                  )
              ),
            });
          }
          console.log(newArr2, "newArr2");
          this.setState({
            singleDateTripsCopyExport: newArr,
            singleDateTripsCopyExport2: newArr2,
            singleDateTripsCopyExport3: newArr3,
          });
        }
      );
    } else {
      this.setState({
        destinationCities: props.destinationCities && props.destinationCities.length > 0 ? props.destinationCities : [],
        bulkpermit: props.bulkpermit,

        dates: props.dates,
        tabs,
        singleDateTrips: [],
        singleDateTripsCopy: [],
      });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  render() {
    const {
      tabs,
      dates,
      singleDate,
      tripList,
      page,
      rowsPerPage,
      contentArea,
      pdfExportComponent,
      colorArr,
      addTrip,
      openInvoice,
      openBilti,
      printINvoice,
      tabValue,
      tp,
      open,
      editTrip,
      changeTransporterGroup,
      changeSalesOrder,
      changeDate,
      cancelTrip,
      singleDateTrips,
      singleDateTripsCopy,
      singleDateTripsCopyExport,
      singleDateTripsCopyExport2,
      singleDateTripsCopyExport3,
      error,
      success,
      uploadInvoice,
      fieldName,
      loading,
      openFinalisedDialog,
      MonthArr,
      bulkpermit,
      destinationCities,
    } = this.state;
    const { isMobile } = this.props;
    const date1 = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : "";
    const date2 = new Date();

    console.log(moment(date1).format("L, LTS"), moment(date2).format("L, LTS"), "vvvvvvvs1");
    var duration = moment.duration(moment([moment(date1).format("L, LTS")]).diff([moment(date2).format("L, LTS")])); //moment.duration(date1.diff(date2));
    const data = duration._data;
    // const result = datetimeDifference(
    //   moment(date1).format("L, LTS"),
    //   moment(date2).format("L, LTS")
    // ); /* result is {
    //     "years": 0,
    //     "months": 2,
    //     "days": 3,
    //     "hours": 13,
    //     "minutes": 48,
    //     "seconds": 47,
    //     "milliseconds": 0
    // } */

    console.log(data.years, data.days, data.minutes, "vvvvvvvs");
    return isMobile ? (
      <MobileDispatches
        {...this.state}
        {...this.props}
        fetchSingleDateTrips={this.props.fetchSingleDateTrips}
        fetchSingleDateDetails={this.props.fetchSingleDateDetails}
      />
    ) : (
      <Layout
        loading={loading.dates}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          { data: MonthArr.sort((a, b) => b.monthId - a.monthId), hintText: "Search Months", labelField: "month" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={
          this.listClickHandler // }} //   this.props.history.push("/dashboard/new"); // fabClickHandler={() => {
        }
        serchClick={this.searchHandler}
        centerWidth="230px"
        openWidth="521.46px"
        closeWidth="287px"
      >
        {uploadInvoice || openInvoice ? (
          <UploadFile
            open={true}
            {...this.state}
            {...this.props}
            fetchDates={this.props.fetchDates}
            setsingleDate={(data, dateRR) => {
              data.formattedDate = moment(data.date).format("Do MMM, ddd");

              this.setState({ singleDate: data }, () => {
                this.props.setDates(dateRR);
              });
            }}
            selectedTrip={uploadInvoice || openInvoice}
            sendForCORRECTION={this.props.sendForCORRECTION}
            fetchSingleDateTrips={this.props.fetchSingleDateTrips}
            handleClose={() => this.setState({ uploadInvoice: false, openInvoice: false })}
          />
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() =>
              this.setState({
                error: "",
                success: "",
              })
            }
          />
        ) : (
          ""
        )}
        <Popover
          open={this.state.open}
          onRequestClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
          style={{ marginTop: "-2.5%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <ClickAwayListener onClickAway={() => this.handlePopoverClose()}>
            <List>
              {loading ? (
                <div className="alignC justiyC d-flex" style={{ width: "192.54px", height: "192.91px" }}>
                  <StyledCircularProgress color="inherit" />
                </div>
              ) : (
                <>
                  {tp.dispatch_status === "DISPATCHED" ? (
                    ""
                  ) : (
                    <ListItem
                      button
                      onClick={() => {
                        if (tp.dispatch_status === "READY") {
                          this.setState({ loading: true });
                          var newData = { dispatch_status: "DISPATCHED" };
                          this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                            if (res.error) {
                              this.setState({ error: res.data, loading: false }, () => {
                                this.handlePopoverClose();
                              });
                            } else {
                              tp.dispatch_status = "DISPATCHED";
                              this.props
                                .fetchDates(
                                  this.props.user.user.uid,
                                  this.state.selectedMonth.monthId,
                                  this.state.selectedMonth.yearId
                                )
                                .then((res) => {
                                  if (res && res.length > 0) {
                                    const data = res.filter((data) => data.id === singleDate.id)[0];
                                    data.formattedDate = moment(data.date).format("Do MMM, ddd");
                                    this.setState({ singleDate: data });
                                  }
                                });

                              // this.props.fetchSingleDateTrips(singleDate, this.props.user.user.uid).then(() => {
                              this.setState({ loading: false, success: "Trip marked as dispatched!" }, () => {
                                this.handlePopoverClose();
                              });
                              // });
                            }
                          });
                        } else {
                          this.setState(
                            {
                              error: `This trip is not ready to be dispatched ,Please${
                                tp.dispatch_status === "BILLING" ? " Upload Invoice!!" : " Check TP"
                              }`,
                            },
                            () => {
                              this.handlePopoverClose();
                            }
                          );
                        }
                      }}
                      style={styles.listItemsStyle}
                    >
                      <p
                        style={
                          { margin: "0", fontSize: "0.9rem", height: "24px", display: "flex", alignItems: "center" } // color: grey,
                        }
                      >
                        Mark as dispatched
                      </p>
                    </ListItem>
                  )}
                  {tp.dispatch_status === "DISPATCHED"
                    ? ""
                    : this.props.user.user.accessibleWidgets &&
                      this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips") &&
                      this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips").length >
                        0 &&
                      this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips")[0]
                        .has_update_permission === true && (
                        <Divider className="width-100" style={{ background: "rgba(0, 0, 0, 0.4)" }} />
                      )}{" "}
                  {/* <ListItem
                button
                onClick={() =>
                  this.setState({
                    addTrip: true,
                    editTrip: true,
                  })
                }
                style={styles.listItemsStyle}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "0.9rem", // color: grey,
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Edit
                </p>
              </ListItem> */}
                  {this.props.user.user.accessibleWidgets &&
                  this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips") &&
                  this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips").length >
                    0 &&
                  this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips")[0]
                    .has_update_permission === true ? (
                    <>
                      <ListItem
                        button
                        onClick={() =>
                          this.setState({
                            // addTrip: true,
                            // editTrip: true,
                            changeTransporterGroup: true,
                          })
                        }
                        style={styles.listItemsStyle}
                      >
                        <p
                          style={
                            { margin: "0", fontSize: "0.9rem", height: "24px", display: "flex", alignItems: "center" } // color: grey,
                          }
                        >
                          Change Transporter Group
                        </p>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() =>
                          this.setState({
                            // addTrip: true,
                            // editTrip: true,
                            changeSalesOrder: true,
                          })
                        }
                        style={styles.listItemsStyle}
                      >
                        <p
                          style={
                            { margin: "0", fontSize: "0.9rem", height: "24px", display: "flex", alignItems: "center" } // color: grey,
                          }
                        >
                          Change Sales Order
                        </p>
                      </ListItem>

                      <ListItem
                        button
                        onClick={() => {
                          this.setState({ changeDate: true });
                        }}
                        style={styles.listItemsStyle}
                      >
                        <p
                          style={
                            { margin: "0", fontSize: "0.9rem", height: "24px", display: "flex", alignItems: "center" } // color: grey,
                          }
                        >
                          Change Trip Date
                        </p>
                      </ListItem>
                      <ListItem
                        button
                        onClick={() =>
                          this.setState({
                            cancelTrip: true,
                          })
                        }
                        style={styles.listItemsStyle}
                      >
                        <p
                          style={
                            { margin: "0", fontSize: "0.9rem", height: "24px", display: "flex", alignItems: "center" } // color: grey,
                          }
                        >
                          Cancel
                        </p>
                      </ListItem>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </List>
          </ClickAwayListener>
        </Popover>

        {printINvoice ? (
          <PDFPrint
            invoicDownload={true}
            token={this.props.user.user.uid}
            url={`https://app.pitambarainfra.com/${printINvoice}`}
          />
        ) : (
          ""
        )}
        {openBilti ? (
          <FilePrevie
            title={openInvoice ? "Invoice" : openBilti.lr_number}
            {...this.state}
            {...this.props}
            handleClose={() => this.setState({ openInvoice: false, openBilti: false })}
          />
        ) : (
          ""
        )}
        {changeTransporterGroup || changeSalesOrder || changeDate || cancelTrip || openFinalisedDialog ? (
          <TripEditDialog
            setDate={this.props.setDates}
            setsingleDate={(data, dateRR) => {
              data.formattedDate = moment(data.date).format("Do MMM, ddd");
              data.is_finalised = true;
              this.setState({ singleDate: data }, () => {
                this.props.setDates(dateRR);
              });
            }}
            editDate={this.props.editDate}
            fetchDetailsOfTp={this.props.fetchDetailsOfTp}
            selectedDate={singleDate && singleDate.formattedDate ? singleDate.formattedDate : ""}
            openTripEditDialog={true}
            fetchSingleDateTrips={this.props.fetchSingleDateTrips}
            fetchSingleDateDetails={this.props.fetchSingleDateDetails}
            fetchDates={this.props.fetchDates}
            tp={tp}
            {...this.state}
            {...this.props}
            setInitialValues={this.setInitialValues}
            handleClose={() =>
              this.setState({
                changeTransporterGroup: false,
                changeSalesOrder: false,
                changeDate: false,
                cancelTrip: false,
                openFinalisedDialog: false,
              })
            }
            postTrip={this.props.postTrip}
            sendForCORRECTION={this.props.sendForCORRECTION}
            onEdit={() => {
              this.setState({
                changeTransporterGroup: false,
                changeSalesOrder: false,
                changeDate: false,
                cancelTrip: false,
                openFinalisedDialog: false,
              });
            }}
          />
        ) : (
          ""
        )}
        {addTrip ? (
          <DialogDispatch
            setsingleDate={(data, dateRR) => {
              data.formattedDate = moment(data.date).format("Do MMM, ddd");

              this.setState({ singleDate: data }, () => {
                this.props.setDates(dateRR);
              });
            }}
            lastTrip={
              singleDateTrips.Trips && singleDateTrips.Trips.length !== 0
                ? singleDateTrips.Trips.sort((a, b) => b.lr_number - a.lr_number)[0]
                : ""
            }
            fetchDetailsOfTp={this.props.fetchDetailsOfTp}
            vehicleAdd={this.props.vehicleAdd}
            selectedDate={singleDate.formattedDate}
            openDialogDispatch={true}
            fetchSingleDateTrips={this.props.fetchSingleDateTrips}
            fetchSingleDateDetails={this.props.fetchSingleDateDetails}
            fetchDates={this.props.fetchDates}
            tp={tp}
            {...this.state}
            {...this.props}
            handleClose={() =>
              this.setState({
                addTrip: false,
                editTrip: false,
                tp: false,
                changeTransporterGroup: false,
                changeSalesOrder: false,
              })
            }
            postTrip={this.props.postTrip}
            sendForCORRECTION={this.props.sendForCORRECTION}
            onEdit={() => {
              this.setState({
                addTrip: false,
                editTrip: false,
                tp: false,
                changeTransporterGroup: false,
                changeSalesOrder: false,
              });
            }}
          />
        ) : (
          ""
        )}

        <Paper style={{ width: "100%", height: "100%", padding: "15px", position: "relative" }}>
          {dates.length > 0 && singleDate ? (
            <>
              <AppBar
                style={{
                  boxShadow: "none",
                  borderBottom: "1px solid #0000000B",
                  width: "100%",
                  backgroundColor: "white",
                }}
                position="static"
              >
                <CssTabs
                  style={{ backgroundColor: white, color: "#00867E" }}
                  value={tabValue}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <CssTab
                    onClick={(event) => {
                      this.handleChange("All Trips");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"All Trips"}
                    label={"All Trips"}
                    wrapped
                    {...a11yProps("All Trips")}
                  />

                  <CssTab
                    onClick={(event) => {
                      this.handleChange("Summary");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"Summary"}
                    label={"Summary"}
                    wrapped
                    {...a11yProps("Summary")}
                  />
                </CssTabs>
              </AppBar>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"All Trips"}
              >
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Paper style={styles.paperStyle} elevation={3} component="form">
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "0 12px" }}>
                      <SearchIcon color="rgb(16, 15, 15)" />
                    </div>
                    <InputBase
                      disabled={singleDateTripsCopy && singleDateTripsCopy.length !== 0 ? false : true}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                        }
                      }}
                      style={{ fontSize: "0.9rem", width: "100%", color: "rgb(16, 15, 15)" }}
                      placeholder="Search Vehicle "
                      inputProps={{ "aria-label": "search google maps" }}
                      onChange={(event) => {
                        this.setState(
                          {
                            SearchBarValue: event.target.value,
                          },
                          () => {
                            const filterData = [];

                            if (this.state.SearchBarValue && singleDateTripsCopy && fieldName) {
                              singleDateTripsCopy.map((data) => {
                                fieldName.map((singleField) => {
                                  if (
                                    (singleField === "transporter_group" &&
                                      data[singleField] &&
                                      data[singleField].name &&
                                      data[singleField].name
                                        .toString()
                                        .toLowerCase()
                                        .includes(this.state.SearchBarValue.toString().toLowerCase())) ||
                                    (singleField === "consignee" &&
                                      data[singleField] &&
                                      data[singleField].short_name &&
                                      data[singleField].short_name
                                        .toString()
                                        .toLowerCase()
                                        .includes(this.state.SearchBarValue.toString().toLowerCase())) ||
                                    (singleField === "vehicle" &&
                                      data[singleField] &&
                                      data[singleField].vehicle_no &&
                                      data[singleField].vehicle_no
                                        .toString()
                                        .toLowerCase()
                                        .includes(this.state.SearchBarValue.toString().toLowerCase())) ||
                                    (data[singleField] &&
                                      data[singleField]
                                        .toString()
                                        .toLowerCase()
                                        .includes(this.state.SearchBarValue.toString().toLowerCase()))
                                  ) {
                                    if (!filterData.includes(data)) {
                                      filterData.push(data);
                                    }
                                  }
                                });
                                const arr = ["consignee", "vehicle", "transporter_group", "transit_pass"];
                                // arr.map((single) => {
                                //   if (data[single]) {
                                //     Object.keys(data[single]).forEach((key) => {
                                //       console.log(key, "keykey")
                                //       if (
                                //         data[single[key]]
                                //           .toString()
                                //           .toLowerCase()
                                //           .includes(event.target.value.toString().toLowerCase())
                                //       ) {
                                //         if (!filterData.includes(data)) {
                                //           filterData.push(data);
                                //         }
                                //       }

                                //     });
                                //   }
                                // }
                                // )
                              });
                              const newData = singleDateTrips;
                              newData.Trips = filterData;
                              this.setState({
                                singleDateTrips: newData,
                              });
                            } else {
                              const newData = singleDateTrips;
                              newData.Trips = singleDateTripsCopy;
                              this.setState({
                                singleDateTrips: newData,
                              });
                            }
                          }
                        );
                      }}
                      value={this.state.SearchBarValue}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0 9.5px",
                        cursor: "pointer",
                      }}
                    >
                      {this.state.SearchBarValue ? (
                        <Avatar
                          onClick={(event) => {
                            this.setState(
                              {
                                SearchBarValue: "",
                              },
                              () => {
                                const newData = singleDateTrips;
                                newData.Trips = singleDateTripsCopy;
                                this.setState({
                                  singleDateTrips: newData,
                                });
                              }
                            );
                          }}
                          style={{
                            color: "white",
                            backgroundColor: "#BEBCBC",
                            borderRadius: "50%",
                            width: "18px",
                            height: "18px",
                            fontSize: "0.7rem",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CancelIcon />
                        </Avatar>
                      ) : (
                        ""
                      )}
                    </div>
                  </Paper>

                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {this.props.user.user.accessibleWidgets &&
                    this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "tripAdmin") &&
                    this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "tripAdmin").length >
                      0 &&
                    this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "tripAdmin")[0]
                      .has_update_permission === true ? (
                      singleDateTrips &&
                      singleDateTrips.Trips &&
                      singleDateTrips.Trips.length !== 0 &&
                      singleDate.is_finalised !== true ? (
                        <StyledButton
                          onClick={() => {
                            this.setState({ openFinalisedDialog: true });
                          }}
                          name={"Finalise"}
                          withBg={true}
                          width="84.15px"
                          margin="0 10px 0 0"
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {singleDateTrips && singleDateTrips.Trips && singleDateTrips.Trips.length !== 0
                      ? this.props.user.user.accessibleWidgets &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "exportTrips") &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "exportTrips")
                          .length > 0 &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "exportTrips")[0]
                          .has_read_permission === true && (
                          <ExportToExcel
                            Heading={[
                              "#",
                              "DATE",
                              "VEHICLE NO",
                              "LR NO",
                              "TP NO",
                              "GROSS",
                              "TARE",
                              "NET",
                              "DRIVER NAME",
                              "DRIVER NUMBER",
                              "PARTY NAME",
                              "TRANSPORTER GROUP",
                              "GRADE",
                              "Permit Number",
                            ]}
                            Heading1={[
                              "#",
                              "VEHICLE NO",
                              "TP NO",
                              "Tare Weight",
                              "Gross weight",
                              "Net Weight",
                              "Driver Name",
                              "Driver Mo. No.",
                              "Dispatch Party Name",
                              "Transporter",
                              "Remark",
                            ]}
                            Heading2={["Transporter", "Total Trip", "Qty."]}
                            sheet2={[{ data: "Summary", bgColor: "40B5AD" }, { data: new Date(), bgColor: "40B5AD" }]}
                            sheet1={[{ data: "Pitambara Logistics & infra. Pvt. Ltd.", bgColor: "40B5AD" }]}
                            wscols={this.state.wscols}
                            wscols1={this.state.wscols1}
                            wscols2={this.state.wscols2}
                            apiData={
                              singleDateTripsCopyExport
                                ? singleDateTripsCopyExport
                                : singleDateTrips.Trips.sort((a, b) => a.lr_number - b.lr_number)
                            }
                            apiData1={
                              singleDateTripsCopyExport2
                                ? singleDateTripsCopyExport2
                                : singleDateTrips.Trips.sort((a, b) => a.lr_number - b.lr_number)
                            }
                            apiData2={
                              singleDateTripsCopyExport3
                                ? singleDateTripsCopyExport3
                                : singleDateTrips.Trips.sort((a, b) => a.lr_number - b.lr_number)
                            }
                            fileName={`PLIPL-${singleDate.formattedDate}`}
                          />
                        )
                      : ""}
                    {singleDate.date === moment(new Date()).format("YYYY-MM-DD")
                      ? this.props.user.user.accessibleWidgets &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips") &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips")
                          .length > 0 &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips")[0]
                          .has_update_permission === true &&
                        this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "editTrips")[0]
                          .has_create_permission === true
                        ? singleDate.is_finalised !== true && (
                            <Button
                              onClick={() => {
                                this.setState({ addTrip: true });
                              }}
                              style={{
                                background: "#1e3559",
                                color: "white",
                                textTransform: "capitalize",
                                borderRadius: "5px",
                              }}
                            >
                              <div style={{ marginRight: "5px", display: "flex", alignItems: "center" }}>
                                <PlusIcon />
                              </div>
                              Add New Trip
                            </Button>
                          )
                        : ""
                      : ""}
                  </div>
                </div>
                <Paper
                  className="d-flex d-flex-column scrollBluePrimary"
                  style={
                    {
                      margin: "20px 0 0 0",
                      width: "100%",
                      height: "calc(100% - 110px)",
                      borderRadius: "7px",
                      height: "calc(100% - 58px)",
                      overflowX: "auto",
                    }
                    // border: `1px solid ${primary}`,
                  }
                >
                  <div
                    style={{
                      backgroundColor: "rgb(158 158 158 / 48%)",
                      color: primary,
                      borderColor: primary,
                      width: "fit-content",
                      minWidth: "100%",
                      tableLayout: "fixed",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "50px",
                      overflow: "hidden",
                      borderRadius: "7px 7px 0 0",
                    }}
                  >
                    <div style={{ width: "421.19px" }} className="d-flex alignC">
                      <div
                        style={{
                          padding: "12px 10px",
                          width: "63.79px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                        }}
                      >
                        Sr.No.
                      </div>
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "12px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "111.33px",
                          maxWidth: "111.33px",
                          fontWeight: "500",
                        }}
                      >
                        LR #
                      </div>
                      <div
                        style={
                          {
                            color: "#100f0f",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            minWidth: "126.08px",
                            maxWidth: "126.08px",
                            fontWeight: "500",
                          }
                          // margin: "0 10px",
                        }
                      >
                        Vehicle #
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        Status
                      </div>
                    </div>
                    <div
                      style={{ width: "fit-content", minWidth: "calc(100% - 1035.07px)" }}
                      className="d-flex justifySA height-100 alignC"
                    >
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "66.78px",
                          width: "34%",
                          maxWidth: "66.78px",
                          fontWeight: "500",
                        }}
                        className="d-flex justifyC alignC"
                      >
                        Party
                      </div>{" "}
                      <div
                        className="d-flex justifyC alignC"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "66.78px",
                          width: "33%",
                          maxWidth: "66.78px",
                          fontWeight: "500",
                        }}
                      >
                        Group
                      </div>
                      <div
                        className="d-flex justifyC alignC"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "62.69px",
                          width: "33%",
                          maxWidth: "62.69px",
                          fontWeight: "500",
                        }}
                      >
                        Net MT
                      </div>{" "}
                    </div>
                    <div style={{ width: "543.90px" }} className="d-flex alignC">
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "91.95px",
                          maxWidth: "91.95px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        TP NO
                      </div>{" "}
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "91.95px",
                          maxWidth: "91.95px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        EP NO
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Transit Pass
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        Invoice
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        Bilti
                      </div>
                    </div>
                    {singleDate.is_finalised === true ? (
                      ""
                    ) : (
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "70px",
                          maxWidth: "70px",
                          fontWeight: "500",
                        }}
                      >
                        Action
                      </div>
                    )}
                  </div>
                  {/* <Divider style={{ margin: "3px 0 0 0" }} /> */}
                  <div
                    style={{
                      height: "calc(100% - 50px)",
                      overflowY: "auto", // overflowX: "hidden",
                      width: "fit-content",
                      minWidth: "100%",
                      position: "relative",
                    }}
                  >
                    {this.props.dispatches.loading.singleDateTrips ? (
                      <div
                        className="alignC justiyC d-flex"
                        style={{ position: "absolute", width: "100%", height: "100%" }}
                      >
                        <StyledCircularProgress color="inherit" />
                      </div>
                    ) : singleDateTrips && singleDateTrips.Trips && singleDateTrips.Trips.length !== 0 ? (
                      singleDateTrips &&
                      singleDateTrips.Trips.sort((a, b) => b.lr_number - a.lr_number).map((singleTrip, i) => (
                        <>
                          <div style={{ display: "none" }}>
                            {/* {singleTrip.dispatch_status = i === 0 ? "NEW" : i === 1 ? "CORRECTION" : i === 2 ? "BILLING" : i === 3 ? 'DISPATCHED' : i === 4 ? "DISPATCHED" : ""} */}
                            {/* {singleTrip.id = i} */}
                          </div>
                          <div
                            className="d-flex"
                            style={{
                              margin: "1px 0 0 0",
                              width: "100%",
                              backgroundColor: colorArr[i % colorArr.length],
                              height: "54px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ width: "421.19px" }} className="d-flex alignC">
                              <div
                                style={{
                                  padding: "12px 10px",
                                  width: "63.79px",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#808080d1",
                                }}
                              >
                                {singleDateTrips.Trips.length - i < 10
                                  ? `0${singleDateTrips.Trips.length - i}`
                                  : singleDateTrips.Trips.length - i}{" "}
                              </div>
                              <div
                                style={{
                                  padding: "12px 10px",
                                  width: "111.33px",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#808080d1",
                                }}
                              >
                                {singleTrip.lr_number}
                              </div>
                              <div
                                className="d-flex justifyC alignC"
                                style={{
                                  padding: "12px 4px",
                                  display: "flex",
                                  alignItems: "center",
                                  minWidth: "126.08px",
                                  maxWidth: "126.08px",
                                  color: "#808080d1",
                                  textTransform: "capitalize",
                                  // margin: "0 10px",
                                }}
                              >
                                {singleTrip.vehicle ? singleTrip.vehicle.vehicle_no : ""}
                              </div>{" "}
                              <div style={{ width: "120px", minWidth: "120px" }} className="d-flex justifyC alignC">
                                <div
                                  style={{
                                    padding: "3px",
                                    display: "flex",
                                    alignItems: "center",
                                    minWidth: "100px",
                                    maxWidth: "100px",
                                    justifyContent: "center",
                                    color:
                                      singleTrip.dispatch_status === "CORRECTION"
                                        ? red
                                        : singleTrip.dispatch_status === "DISPATCHED"
                                        ? lightGreen
                                        : "white",
                                    borderRadius: "5px",
                                    border:
                                      singleTrip.dispatch_status === "CORRECTION"
                                        ? `2px solid ${red}`
                                        : singleTrip.dispatch_status === "READY"
                                        ? `2px solid  ${lightGreen}`
                                        : singleTrip.dispatch_status === "DISPATCHED"
                                        ? `2px solid ${lightGreen}`
                                        : "",
                                    background:
                                      singleTrip.dispatch_status === "READY"
                                        ? lightGreen
                                        : singleTrip.dispatch_status === "NEW"
                                        ? purple
                                        : singleTrip.dispatch_status === "BILLING"
                                        ? yellow
                                        : "",
                                    textTransform: "capitalize",
                                    fontSize: "0.9rem",
                                  }}
                                  align="center"
                                >
                                  {singleTrip.dispatch_status === "READY"
                                    ? "READY"
                                    : singleTrip.dispatch_status === "DISPATCHED"
                                    ? "COMPLETE"
                                    : singleTrip.dispatch_status}{" "}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                width: "fit-content",
                                minWidth: "calc(100% - 1035.07px)",
                              }} // "calc(100% - 1045.88px)" }}
                              className="d-flex justifySA height-100 alignC"
                            >
                              <div
                                className="d-flex justifyC alignC"
                                style={{
                                  display: "flex",
                                  alignItems: "center",

                                  textTransform: "capitalize",
                                  padding: "12px 4px",

                                  width: "34%",
                                  minWidth: "66.78px",
                                  maxWidth: "66.78px",
                                  color: "#808080d1",
                                }}
                                className="justifyC"
                              >
                                <div
                                  className="textOEllips width-100 d-flex justifyC"
                                  style={{
                                    maxHeight: "23.75px",
                                  }}
                                >
                                  {" "}
                                  {singleTrip.consignee.short_name}
                                </div>
                              </div>{" "}
                              <div
                                className="d-flex justifyC alignC"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  textTransform: "capitalize",
                                  padding: "12px 4px",

                                  minWidth: "66.78px",
                                  width: "33%",
                                  maxWidth: "66.78px",
                                  color: "#808080d1",
                                  textTransform: "capitalize",
                                }}
                              >
                                {singleTrip.transporter_group.name ? singleTrip.transporter_group.name : "-"}
                              </div>
                              <div
                                className="d-flex justifyC alignC"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  textTransform: "capitalize",
                                  padding: "12px 4px",

                                  minWidth: "61.6px",
                                  width: "33%",
                                  maxWidth: "61.6px",
                                  color: "#808080d1",
                                  textTransform: "capitalize",
                                }}
                              >
                                <div
                                  className="textOEllips width-100 d-flex justifyC"
                                  style={{
                                    maxHeight: "23.75px",
                                  }}
                                >
                                  {" "}
                                  {singleTrip.net_weight ? Number(singleTrip.net_weight).toFixed(3) : "-"}
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "543.90px" }} className="d-flex alignC">
                              <div
                                align="center"
                                style={{
                                  padding: "12px 4px",
                                  minWidth: "91.95px",
                                  maxWidth: "91.95px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  height: "54px",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "100%",

                                    minWidth: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleTrip.transit_pass.TransitPassId}
                                </div>
                              </div>
                              <div
                                align="center"
                                style={{
                                  padding: "12px 4px",
                                  minWidth: "91.95px",
                                  maxWidth: "91.95px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  height: "54px",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "100%",

                                    minWidth: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#808080d1",
                                    justifyContent: "center",
                                  }}
                                >
                                  {singleTrip.bulk_permit.bulk_permit_no
                                    ? singleTrip.bulk_permit.bulk_permit_no.slice(8, 13)
                                    : "-"}
                                </div>
                              </div>
                              <div
                                align="center"
                                style={{
                                  padding: "12px 4px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {singleTrip.dispatch_status === "BILLING" ||
                                singleTrip.dispatch_status === "DISPATCHED" ||
                                singleTrip.dispatch_status === "READY" ? (
                                  // <div
                                  //   style={{
                                  //     color: "green",
                                  //     padding: "5px 4px",
                                  //     display: "flex",
                                  //     alignItems: "center",
                                  //     minWidth: "100px",
                                  //     maxWidth: "100px",
                                  //     justifyContent: "center",
                                  //     borderRadius: "15px",
                                  //     textTransform: "capitalize",
                                  //     textDecoration: "underline"
                                  //   }}
                                  // >
                                  //   Approved
                                  // </div>
                                  <div
                                    className="cursorP"
                                    onClick={() => {
                                      singleTrip.open = true;
                                      this.setState({
                                        addTrip: true,
                                        tp: singleTrip,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      padding: "5px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                      justifyContent: "center",
                                      borderRadius: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    open
                                  </div>
                                ) : this.props.user.user.roles.includes("admin") ||
                                  this.props.user.user.roles.includes("management") ||
                                  this.props.user.user.roles.includes("officeStaff") ||
                                  this.props.user.user.roles.includes("superAdmin") ||
                                  this.props.user.user.roles.includes("transporterManagement") ? (
                                  <Button
                                    onClick={() =>
                                      this.setState({
                                        addTrip: true,
                                        tp: singleTrip,
                                      })
                                    }
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      maxWidth: "100px",
                                      width: "100%",
                                      background: lightblue,
                                      justifyContent: "center",
                                      color: "white",
                                      // margin: "0 calc(50% - 50px)",
                                      // margin: "0 calc(50% - 50px)",
                                      textTransform: "capitalize",
                                      display: "flex",
                                      alignItems: "center",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    check
                                  </Button>
                                ) : this.props.user.user.roles.includes("dispatchStaff") ? (
                                  <Button
                                    onClick={() =>
                                      this.setState({
                                        addTrip: true,
                                        tp: singleTrip,
                                      })
                                    }
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      maxWidth: "100px",
                                      width: "100%",
                                      background: lightblue,
                                      justifyContent: "center",
                                      color: "white",
                                      // margin: "0 calc(50% - 50px)",
                                      // margin: "0 calc(50% - 50px)",
                                      textTransform: "capitalize",
                                      display: "flex",
                                      alignItems: "center",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    Edit
                                  </Button>
                                ) : singleTrip.dispatch_status === "CORRECTION" ? (
                                  <div
                                    style={{
                                      color: "grey",
                                      // textDecoration: "underline",
                                      padding: "5px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                      justifyContent: "center",
                                      borderRadius: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    -
                                  </div>
                                ) : singleTrip.dispatch_status === "NEW" ? (
                                  <Button
                                    onClick={() =>
                                      this.setState({
                                        addTrip: true,
                                        tp: singleTrip,
                                      })
                                    }
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      maxWidth: "100px",
                                      width: "100%",
                                      background: lightblue,
                                      justifyContent: "center",
                                      color: "white",
                                      // margin: "0 calc(50% - 50px)",
                                      // margin: "0 calc(50% - 50px)",
                                      textTransform: "capitalize",
                                      display: "flex",
                                      alignItems: "center",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    check
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div
                                align="center"
                                style={{
                                  padding: "12px 4px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {singleTrip.dispatch_status === "DISPATCHED" ? (
                                  <div
                                    className="cursorP"
                                    onClick={() => {
                                      this.setState({
                                        openInvoice: singleTrip,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      padding: "5px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                      justifyContent: "center",
                                      borderRadius: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    open
                                  </div>
                                ) : this.props.user.user.accessibleWidgets &&
                                  this.props.user.user.accessibleWidgets.filter(
                                    (data) => data.widget_name === "editTrips"
                                  ) &&
                                  this.props.user.user.accessibleWidgets.filter(
                                    (data) => data.widget_name === "editTrips"
                                  ).length > 0 &&
                                  this.props.user.user.accessibleWidgets.filter(
                                    (data) => data.widget_name === "editTrips"
                                  )[0].has_update_permission === true ? (
                                  singleTrip.dispatch_status === "READY" || singleTrip.dispatch_status === "BILLING" ? (
                                    <Button
                                      onClick={(event) => {
                                        if (singleTrip.dispatch_status === "READY") {
                                          this.setState({
                                            openInvoice: singleTrip,
                                          });
                                          // this.handleExportWithFunction1(singleTrip.invoice_file, event)
                                        } else {
                                          if (
                                            this.props.user.user.accessibleWidgets &&
                                            this.props.user.user.accessibleWidgets.filter(
                                              (data) => data.widget_name === "editTrips"
                                            ) &&
                                            this.props.user.user.accessibleWidgets.filter(
                                              (data) => data.widget_name === "editTrips"
                                            ).length > 0 &&
                                            this.props.user.user.accessibleWidgets.filter(
                                              (data) => data.widget_name === "editTrips"
                                            )[0].has_update_permission === true
                                          ) {
                                            this.setState({
                                              uploadInvoice: singleTrip,
                                            });
                                          }
                                        }
                                      }}
                                      style={{
                                        borderRadius: "5px",
                                        padding: "3px",
                                        maxWidth: "100px",
                                        width: "100%",
                                        background:
                                          singleTrip.dispatch_status === "BILLING" ||
                                          singleTrip.dispatch_status === "READY"
                                            ? lightblue
                                            : "",
                                        justifyContent: "center",
                                        color:
                                          singleTrip.dispatch_status === "BILLING" ||
                                          singleTrip.dispatch_status === "READY"
                                            ? "white"
                                            : lightGreen,
                                        textTransform: "capitalize",
                                        display: "flex",
                                        alignItems: "center",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {this.props.user.user.accessibleWidgets &&
                                      this.props.user.user.accessibleWidgets.filter(
                                        (data) => data.widget_name === "editTrips"
                                      ) &&
                                      this.props.user.user.accessibleWidgets.filter(
                                        (data) => data.widget_name === "editTrips"
                                      ).length > 0 &&
                                      this.props.user.user.accessibleWidgets.filter(
                                        (data) => data.widget_name === "editTrips"
                                      )[0].has_update_permission === true &&
                                      singleTrip.dispatch_status === "BILLING"
                                        ? "upload"
                                        : singleTrip.dispatch_status === "READY"
                                        ? "open"
                                        : ""}
                                    </Button>
                                  ) : (
                                    <div
                                      style={{
                                        color: "grey",
                                        // textDecoration: "underline",
                                        padding: "5px 4px",
                                        display: "flex",
                                        alignItems: "center",
                                        minWidth: "100px",
                                        maxWidth: "100px",
                                        justifyContent: "center",
                                        borderRadius: "15px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      -
                                    </div>
                                  )
                                ) : singleTrip.dispatch_status === "READY" ? (
                                  <Button
                                    onClick={(event) => {
                                      // this.handleExportWithFunction1(singleTrip.invoice_file, event)

                                      this.setState({
                                        // printINvoice:
                                        openInvoice: singleTrip,
                                      });
                                    }}
                                    style={{
                                      borderRadius: "5px",
                                      padding: "3px",
                                      maxWidth: "100px",
                                      width: "100%",
                                      background: lightblue,
                                      justifyContent: "center",
                                      color: white,
                                      textTransform: "capitalize",
                                      display: "flex",
                                      alignItems: "center",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    open
                                  </Button>
                                ) : (
                                  <div
                                    style={{
                                      color: "grey",
                                      // textDecoration: "underline",
                                      padding: "5px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                      justifyContent: "center",
                                      borderRadius: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    -
                                  </div>
                                )}
                              </div>{" "}
                              <div
                                align="center"
                                style={{
                                  padding: "12px 4px",
                                  minWidth: "120px",
                                  maxWidth: "120px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {console.log(
                                  singleTrip.consignee &&
                                    singleTrip.consignee.destination &&
                                    destinationCities &&
                                    destinationCities.length > 0,
                                  destinationCities.filter((sD) => sD.id === singleTrip.consignee.destination)[0].city,
                                  "singleTrip"
                                )}
                                {singleTrip.dispatch_status === "READY" ||
                                singleTrip.dispatch_status === "DISPATCHED" ? (
                                  <PDFPrint
                                    status={singleTrip.dispatch_status}
                                    {...this.props}
                                    singleDate={singleTrip.transit_pass.TransitPassDate}
                                    singleTrip={{
                                      consignee: singleTrip.consignee,
                                      consignor: singleTrip.consignor,
                                      ownerName: "Vaibhav Agrawal",
                                      DName: singleTrip.driver_name,
                                      DlNo: singleTrip.driver_number,
                                      weight: `${Number(singleTrip.net_weight).toFixed(3)} MT`,
                                      quantity: "2H.93 MT",
                                      descriptiomOfGood: singleTrip.item.name,
                                      from: singleTrip.source.city,
                                      to:
                                        singleTrip.consignee &&
                                        singleTrip.consignee.destination &&
                                        destinationCities &&
                                        destinationCities.length > 0
                                          ? destinationCities.filter(
                                              (sD) => sD.id === singleTrip.consignee.destination
                                            )[0].city
                                          : "",
                                      lr: singleTrip.lr_number,
                                      VehicleINo: singleTrip.vehicle.vehicle_no,
                                      party: singleTrip.consignee.name,
                                      NetMt: `${Number(singleTrip.net_weight).toFixed(3)} MT`,
                                      Group: singleTrip.transporter_group.name,
                                      tP: "approved",
                                      Invoice: "UPLOAD",
                                      bilti: "print",
                                      status:
                                        singleTrip.dispatch_status === "READY"
                                          ? "READY"
                                          : singleTrip.dispatch_status === "DISPATCHED"
                                          ? "COMPLETE"
                                          : singleTrip.dispatch_status,
                                    }}
                                  />
                                ) : singleTrip.dispatch_status === "DISPATCHED" ? (
                                  <div
                                    onClick={() => {
                                      this.setState({
                                        openBilti: singleTrip,
                                      });
                                    }}
                                    style={{
                                      color: "green",
                                      padding: "5px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                      justifyContent: "center",
                                      borderRadius: "15px",
                                      textTransform: "capitalize",
                                    }}
                                    className="cursorP"
                                  >
                                    open
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      color: "grey",
                                      // textDecoration: "underline",
                                      padding: "5px 4px",
                                      display: "flex",
                                      alignItems: "center",
                                      minWidth: "100px",
                                      maxWidth: "100px",
                                      justifyContent: "center",
                                      borderRadius: "15px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    -
                                  </div>
                                )}
                              </div>{" "}
                            </div>
                            {singleDate.is_finalised === true ? (
                              ""
                            ) : (
                              <div
                                onClick={(e) => this.handleTouchTap(e, singleTrip)}
                                align="center"
                                style={{
                                  padding: "12px 4px",
                                  minWidth: "70px",
                                  maxWidth: "70px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <MoreHorizOutlined />
                              </div>
                            )}
                          </div>
                          <Divider />{" "}
                        </>
                      ))
                    ) : (
                      <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                        <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>No trips yet !</span>
                      </div>
                    )}
                  </div>
                </Paper>
              </TabPanel>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"Summary"}
              >
                <SummaryTable
                  copyLiveParty={
                    this.props.liveParty && this.props.liveParty.total_per_transporter
                      ? this.props.liveParty.total_per_transporter
                      : []
                  }
                  fileName={`PLIPL-${singleDate.formattedDate}-Summary`}
                  {...this.props}
                  {...this.state}
                />
              </TabPanel>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: "1rem",
                color: "grey",
                background: "white",
              }}
            >
              <NoData />
            </div>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(Dispatches);
