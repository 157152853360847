import React, { useState, useRef, useEffect, useCallback } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import WebViewAppBar from "./WebViewAppBar";
import MobileViewAppBar from "./MobileViewAppBar";
import SubMenuLayout from "./SubMenuLayout";
import SwipeView from "./SwipeView";
import { withRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { green, primary, white } from "../styles/colors";
import { connect } from "react-redux";
import ContextMenu from "../components/ContextMenu";
import MobileViewBottomBar from "./MobileViewBottomBar";

const theme = createMuiTheme({
  // typography: {
  //   fontFamily: "Poppins",
  // },

  overrides: {
    MuiPickersDay: {
      // 	day: {
      // 		color: CURRENT_THEME.textInv, //days in calendar
      // 	},
      daySelected: {
        backgroundColor: primary,
        color: white, //calendar circle
      },
      //   dayDisabled: {
      //     color: white, // current day
      //   },
      current: {
        backgroundColor: green,
        color: white, //calendar circle
      },
    },
    // MuiCssBaseline: {
    //   "@global": {
    //     // "@font-face": [poppinsBold, poppinsLight, poppinsMedium, poppinsRegular],
    //   },
    // },
  },
  palette: {
    primary: {
      light: primary,
      main: primary,
      dark: primary,
    },
    secondary: {
      light: primary,
      main: primary,
      dark: primary,
    },
  },
  // palette: {
  //   primary: purple,
  //   secondary: green,
  // },
  status: {
    danger: "orange",
  },
});

function Layout(props) {
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = React.useState(true);
  const handler = useCallback(() => setWidth(window.innerWidth), [setWidth]);
  useEventListener("resize", handler);
  const isMobile = width <= 500;

  if (isMobile) {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <div className="height-100vh width-100 overflowH">
          <MobileViewAppBar {...props} isMobile={isMobile} />
          <div style={{ width: "100%", height: "calc(100% - 120px)" }}> {props.children}</div>
          <MobileViewBottomBar {...props} isMobile={isMobile} />
        </div>
        {/* <SwipeView {...props} isMobile={isMobile}>
          {props.children}
        </SwipeView> */}
      </MuiThemeProvider>
    );
  } else {
    return (
      <div class="noselect" style={{ display: "flex", height: "100vh" }}>
        {/* <ContextMenu {...props} /> */}
        <MuiThemeProvider theme={theme}>
          <CssBaseline /> <WebViewAppBar {...props} open={open} setOpen={setOpen} />
          {props.tabs && <SubMenuLayout setOpen={setOpen} {...props} />}
          <div
            style={{
              marginTop: 64,
              height: "calc(100% - 64px)",
              width: open
                ? props.centerWidth
                  ? `calc(100% - ${props.openWidth})`
                  : props.width
                  ? props.width
                  : "calc(100% - 561.47px)"
                : props.centerWidth
                ? `calc(100% - ${props.closeWidth})`
                : props.width
                ? props.width
                : "calc(100% - 327px",
            }}
          >
            {props.children}
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

function useEventListener(eventName, handler, element = window) {
  const savedHandler = useRef();
  useEffect(
    () => {
      savedHandler.current = handler;
    },
    [handler]
  );

  useEffect(
    () => {
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;
      const eventListener = (event) => savedHandler.current(event);
      element.addEventListener(eventName, eventListener);
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element]
  );
}
const mapStateToProps = (state) => ({
  version: state.authentication.version,
  user: state.authentication.user,
});
const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Layout)
);
