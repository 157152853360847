import React, { Component } from "react";

import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import moment from "moment";
import { Paper, Divider, Backdrop } from "@material-ui/core";
import { darkestGray, primary, white } from "../styles/colors";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { withStyles } from "@material-ui/styles";
import Moment from "moment";
import domtoimage from "dom-to-image";
import { BottomArrow, Reload, TopArrow } from "../styles/Icons";
import Layout from "../layouts/Layout";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#424242",
      borderColor: white,

      // background: "#F4F4FA",
    },
    "& .MuiInputBase-input": {
      padding: "14px 0",
      borderRadius: "2px",
      color: "transparent",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      // background: white,
      background: primary,
      borderRadius: "13px",
      color: white,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(13.5px, 13.5px) scale(1)",
      color: white,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: darkestGray,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInput-underline:before": { borderBottom: "none" },
    "& .MuiFormHelperText-root": {
      margin: "0",
      backgroundColor: white,
      color: white,
      height: "0",
      display: "none",
    },
    "& .MuiPickersBasePicker-container": {
      maxWidth: "350px",
      width: "100%",
      margin: "0 0 0 -43px",
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
    "& .MuiOutlinedInput-root": {
      "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
      "& .MuiOutlinedInput-input ": {
        padding: "13.5px  0 13.5px 14px",
        color: white,
        caretColor: "transparent",
      },
      "& .MuiIconButton-root": {
        padding: "2px 0 2px 0",
        margin: "10px 0 10px 12px",
        borderRadius: "2px",
        color: white,
      },
      "& fieldset": {
        // borderColor: darkestGray,
      },
      "&:hover fieldset": { borderColor: darkestGray },
      "&.Mui-focused fieldset": {
        // borderColor: darkestGray,
      },
    },
  },
})(KeyboardDatePicker);

const styles = {
  chip: {
    padding: "5px",
    borderRadius: "13px",
    background: primary,
    minWidth: "77px",
  },
};
export class EPermitsMobileView extends Component {
  constructor(props) {
    super(props);
    const loadedAt = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : new Date();
    this.state = {
      loadedAt: loadedAt,
      dates: [],
      selectedDateData: {},
      selectedDate: new Date(),
      salesOrders: {},
      noData: false,
      calenderOpen: false,
      loading: {},
      loadFirst: false,
      menuOpen: false,
    };
  }
  handleDateChange = (selectedDate) => {
    localStorage.setItem("selectedDate", JSON.stringify(moment(selectedDate).format("YYYY-MM-DD")));
    this.setState(
      {
        selectedDate: selectedDate,
      },
      () => {
        const { dates, selectedDate } = this.state;
        const selectedDateData = dates.filter(
          (singleDate) => moment(singleDate.date).format("Do MMM, ddd") === moment(selectedDate).format("Do MMM, ddd")
        )[0];
        const date = { date: selectedDateData ? selectedDateData.date : moment(selectedDate).format("YYYY-MM-DD") };
        this.props.ePermitDailyReport(date, this.props.user.user.uid);
        localStorage.setItem("loadedAt", new Date());

        this.setState({
          selectedDateData,
          noData: false,
        });
      }
    );
  };

  setInitialValues = (props) => {
    const loadedAt = localStorage.getItem("loadedAt") ? localStorage.getItem("loadedAt") : "";
    if (props) {
      if (props.dates && props.dates.length > 0) {
        props.dates.sort((a, b) => {
          var dateA = b.date; // moment(b.date).format("Do MMM");
          var dateB = a.date; //moment(a.date).format("Do MMM");
          return dateA > dateB ? 1 : -1;
        });
        props.dates.map((data, index) => {
          data.formattedDate = moment(data.date).format("Do MMM, ddd");
        });
      }

      if (this.state.loadFirst === false && props.loading.dates === false && props.dates.length > 0) {
        const ItemGradeName = [];
        const newbulkpermit = props.bulkpermit;
        newbulkpermit.map((sBP) => {
          if (!ItemGradeName.includes(sBP.grade)) {
            ItemGradeName.push(sBP.grade);
          }
        });
        this.setState(
          {
            loadFirst: true,
            loading: props.loading,
            ItemGradeName,
            loadedAt,
            dates: props.dates && props.dates.length > 0 ? props.dates : [],
            bulkpermit: props.bulkpermit,
          },
          () => {
            this.handleDateChange(new Date());
          }
        );
      } else {
        const ItemGradeName = [];
        const newbulkpermit = props.bulkpermit;
        newbulkpermit.map((sBP) => {
          if (!ItemGradeName.includes(sBP.grade)) {
            ItemGradeName.push(sBP.grade);
          }
        });
        this.setState(
          {
            loading: props.loading,
            ItemGradeName,
            loadedAt,
            dates: props.dates && props.dates.length > 0 ? props.dates : [],
            bulkpermit: props.bulkpermit,
          },
          () => {
            console.log(this.state.dates, "ItemGradeName");
          }
        );
      }
    }
  };
  UNSAFE_componentWillMount() {
    localStorage.setItem("loadedAt", new Date());
    this.setInitialValues(this.props);
  }
  componentDidMount() {
    this.setInitialValues(this.props, "componentDidMount");
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  // shareImageFunction = () => {
  //   Share.share({
  //     title: "See cool stuff",
  //     text: "Really awesome thing you need to see right meow",
  //     url: "http://ionicframework.com/",
  //     dialogTitle: "Share with buddies",
  //   });
  // };

  downloadImageFunction = () => {
    const { selectedDate } = this.state;
    const fileName = `PLIPL-${Moment(selectedDate).format("Do MMM, ddd")}`;
    this.setState({ toPrint: true }, () => {
      domtoimage
        .toJpeg(document.getElementById("mycanvas"), { quality: 0.95 })
        .then(function(dataUrl) {
          var link = document.createElement("a");
          link.download = fileName ? `${fileName}.jpeg` : "image.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          this.setState({ toPrint: false });
        });
    });
  };
  render() {
    const { loading, selectedDate, noData, calenderOpen, toPrint, loadedAt, bulkpermit, ItemGradeName } = this.state;
    const { items_grade } = this.props;
    const dateTimeAgo = moment(loadedAt).fromNow();
    return (
      <Layout
        title={"Bulk Permits"}
        subTitle={`Last updated ${dateTimeAgo}!`}
        // ShareImage={() => {
        //   this.shareImageFunction();
        // }}
        DownloadImage={() => {
          this.downloadImageFunction();
        }}
      >
        <div className="d-flex width-100 height-100 postionR d-flex-column padding20 ">
          {loading.dates === true ? (
            <div
              className="alignC justiyC d-flex"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <StyledCircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <div className="d-flex width-100 justifyC" style={{ margin: "0 0 20px 0" }}>
                <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                  <CssKeyboardDatePicker
                    variant="inline"
                    onOpen={() => {
                      this.setState({ calenderOpen: true });
                    }}
                    onClose={() => {
                      this.setState({ calenderOpen: false });
                    }}
                    keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                    maxDate={new Date()}
                    disableFuture
                    minDate={"2022-01-22"}
                    error={false}
                    invalidLabel=""
                    onKeyPress={(ev) => {
                      ev.preventDefault();
                    }}
                    allowKeyboardControl={false}
                    clearable
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label={selectedDate ? "" : "Date"}
                    format="do MMM, yyyy"
                    style={{ maxWidth: "350px", cursor: "none" }}
                    className="width-100"
                    value={selectedDate ? selectedDate : ""}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) => this.handleDateChange(date)}
                  />{" "}
                </MuiPickersUtilsProvider>{" "}
              </div>
              {loading.bulkpermit === true ? (
                <div
                  className="alignC justiyC d-flex"
                  style={{
                    height: "calc(100% - 66px)",
                    width: "100%",
                  }}
                >
                  <StyledCircularProgress color="inherit" />
                </div>
              ) : noData ? (
                <div className="width-100 d-flex justifyC alignC height-100"> No trips on this date.</div>
              ) : (
                <div
                  className=" d-flex d-flex-column alignC overflowYA"
                  style={{
                    height: "calc(100% - 66px)",
                    width: "calc(100% + 40px)",
                    padding: "0 20px",
                    margin: "0 -20px",
                  }}
                >
                  {toPrint ? (
                    <Backdrop style={{ zIndex: 1, color: "#fff" }} open={toPrint}>
                      <StyledCircularProgress color="inherit" />
                    </Backdrop>
                  ) : (
                    ""
                  )}{" "}
                  {bulkpermit && bulkpermit.length > 0 ? (
                    <div
                      id="mycanvas"
                      style={{
                        width: "fit-content",
                        height: "fit-content",
                        minWidth: "100%",
                        minHeight: toPrint ? "fit-content" : "100%",
                        padding: toPrint && "20px",
                        borderRadius: toPrint && "7px",
                        backgroundColor: toPrint && "#98AFC7",
                      }}
                    >
                      {" "}
                      {toPrint && (
                        <div
                          style={{
                            padding: "0 0 30px 0",
                            width: "100%",

                            height: "50px",
                            width: "fit-content",
                            minWidth: "100%",
                            color: primary,
                            fontWeight: "400",
                          }}
                        >{`Bulk Permits till :- ${moment(selectedDate).format("MMMM Do YYYY")}`}</div>
                      )}
                      <div
                        className="d-flex width-100"
                        style={{ margin: "0 0 10px 0", minHeight: "fit-content", height: "fit-content" }}
                      >
                        <Paper className="height-100 d-flex padding20 borderRadius13 d-flex-column width-100 alignC">
                          <div
                            className={`d-flex width-100 textC paddingRL20 overflowXA scrollBluePrimarythin justifyC marginB10`}
                          >
                            <div
                              className="d-flex  d-flex-column minWidthFC paddingRL10"
                              style={{ minWidth: "fit-content" }}
                            >
                              <div style={{ minWidth: "120px" }} className="d-flex  justifyC textC fontWeightBold">
                                {" "}
                                E-Permit Balance
                              </div>
                              <div
                                className="d-flex justifyC textColorprimary  textC textColorprimary "
                                style={{
                                  fontSize: "0.9rem",
                                  minWidth: "120px",
                                  width: "fit-content",
                                  maxHeight: "20.59px",
                                  display: "flex",
                                }}
                              >
                                {`till ${moment(selectedDate)
                                  .subtract(1, "days")
                                  .format("Do MMM, yyyy")}`}
                              </div>
                            </div>{" "}
                          </div>{" "}
                          <div
                            className="d-flex  d-flex-column minWidthFC paddingRL10 marginB20 width-100"
                            style={{ minWidth: "fit-content" }}
                          >
                            {" "}
                            <Divider className="width-100" />
                            {ItemGradeName &&
                              ItemGradeName.length > 0 &&
                              ItemGradeName.map((sGrade) => (
                                <>
                                  <div className="d-flex width-100 justifySB marginTB10">
                                    {" "}
                                    <div
                                      className="d-flex justifyC fontWeightBold "
                                      style={{ minWidth: "190px", width: "190px" }}
                                    >
                                      {items_grade &&
                                        items_grade.length > 0 &&
                                        items_grade.filter((sG) => sG.id === sGrade)[0]?.name}
                                    </div>
                                    <div className="d-flex justifyC textColorprimary">
                                      {bulkpermit &&
                                        bulkpermit.length > 0 &&
                                        bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                        bulkpermit
                                          .filter((sG) => sG.grade === sGrade)
                                          .reduce(
                                            (previousValue, currentValue) =>
                                              Number(previousValue) + Number(currentValue.yesterday_pending),
                                            0
                                          )
                                          .toFixed(3)}
                                    </div>
                                  </div>{" "}
                                  <Divider className="width-100" />{" "}
                                </>
                              ))}
                          </div>
                          <div
                            className="d-flex  d-flex-column widthFC backgroundColorPrimary borderRadius13 textCW"
                            style={{ minWidth: "fit-content", padding: "10px 25px" }}
                          >
                            {" "}
                            <div className="d-flex  justifyC textC fontWeightBold">Total MT</div>
                            <div
                              className="d-flex justifyC   textC "
                              style={{
                                fontSize: "0.9rem",
                                minWidth: "fit-content",
                                maxHeight: "20.59px",
                                display: "flex",
                                marginTop: "2px",
                              }}
                            >
                              {bulkpermit
                                .reduce(
                                  (previousValue, currentValue) =>
                                    Number(previousValue) + Number(currentValue.yesterday_pending),
                                  0
                                )
                                .toFixed(0)}
                              MT
                            </div>
                          </div>
                        </Paper>
                      </div>{" "}
                      {ItemGradeName &&
                        ItemGradeName.length > 0 &&
                        ItemGradeName.map((sGrade) => (
                          <div
                            className="d-flex width-100"
                            style={{ margin: "0 0 10px 0", minHeight: "fit-content", height: "fit-content" }}
                          >
                            <Paper className="height-100 d-flex padding20 borderRadius13 d-flex-column width-100">
                              <div
                                className={`d-flex width-100 textC padding20 overflowXA scrollBluePrimarythin justifyC`}
                              >
                                <div
                                  className="d-flex minWidthFC paddingRL10 fontSize1"
                                  style={{ minWidth: "fit-content", margin: "2px 0" }}
                                >
                                  <div className="d-flex fontWeightBold " style={{ minWidth: "190px", width: "190px" }}>
                                    {items_grade &&
                                      items_grade.length > 0 &&
                                      items_grade.filter((sG) => sG.id === sGrade)[0]?.name}{" "}
                                  </div>
                                </div>{" "}
                              </div>{" "}
                              <div
                                className="d-flex  d-flex-column minWidthFC paddingRL10"
                                style={{ minWidth: "fit-content" }}
                              >
                                <div className="d-flex width-100 justifySB marginTB10">
                                  {" "}
                                  <div className="d-flex justifyC fontWeightBold minWidthFC">Opening Balance</div>
                                  <div className="d-flex justifyC textColorprimary">
                                    {`${bulkpermit &&
                                      bulkpermit.length > 0 &&
                                      bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                      bulkpermit
                                        .filter((sG) => sG.grade === sGrade)
                                        .reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) +
                                            Number(currentValue.issue_quantity ? currentValue.issue_quantity : 0),
                                          0
                                        )
                                        .toFixed(0)}MT`}
                                  </div>
                                </div>{" "}
                                {/* <div className="d-flex width-100 justifySB marginTB10">
                              {" "}
                              <div className="d-flex justifyC fontWeightBold">Purchased Today</div>
                              <div className="d-flex justifyC textColorprimary">
                                {`${bulkpermit &&
                                  bulkpermit.length > 0 &&
                                  bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                  bulkpermit
                                    .filter((sG) => sG.grade === sGrade)
                                    .reduce(
                                      (previousValue, currentValue) =>
                                        Number(previousValue) + Number(currentValue.yesterday_pending),
                                      0
                                    )
                                    .toFixed(3)}MT`}
                              </div>
                            </div>{" "}
                            <Divider className="width-100" />{" "} */}
                                <div className="d-flex width-100 justifySB marginTB10 textColorprimary">
                                  {" "}
                                  <div className="d-flex justifyC fontWeightBold minWidthFC">Dispatched Today </div>
                                  <div className="d-flex justifyC fontWeightBold">
                                    {`${bulkpermit &&
                                      bulkpermit.length > 0 &&
                                      bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                      bulkpermit
                                        .filter((sG) => sG.grade === sGrade)
                                        .reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) + Number(currentValue.today_dispatch),
                                          0
                                        )
                                        .toFixed(0)}MT`}
                                  </div>
                                </div>
                                <Divider className="width-100" />{" "}
                                <div className="d-flex width-100 justifySB marginTB10 textColorprimary">
                                  {" "}
                                  <div className="d-flex justifyC fontWeightBold minWidthFC">Remaining</div>
                                  <div className="d-flex justifyC fontWeightBold">
                                    {`${bulkpermit &&
                                      bulkpermit.length > 0 &&
                                      bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                      bulkpermit
                                        .filter((sG) => sG.grade === sGrade)
                                        .reduce(
                                          (previousValue, currentValue) =>
                                            Number(previousValue) +
                                            Number(
                                              currentValue.remaining && typeof currentValue.remaining === "number"
                                                ? currentValue.remaining
                                                : 0
                                            ),
                                          0
                                        )
                                        .toFixed(0)}MT`}
                                  </div>
                                </div>
                                <Divider className="width-100" />{" "}
                                <Paper className="d-flex width-100 textC padding10 overflowXA scrollBluePrimarythin justifyC borderRadius13 marginTB10">
                                  <div
                                    className="d-flex  width-100 paddingRL10 fontSize1 d-flex-column"
                                    style={{ minWidth: "fit-content", margin: "2px 0" }}
                                  >
                                    <div className="d-flex fontWeightBold justifySB alignC width-100">
                                      <div className=" fontWeightBold " style={{ minWidth: "130px", width: "130px" }}>
                                        {" "}
                                        Total E Permits{" "}
                                      </div>
                                      <div className="d-flex justifyC alignC textColorprimary">
                                        {bulkpermit &&
                                          bulkpermit.length > 0 &&
                                          bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                          bulkpermit.filter((sG) => sG.grade === sGrade).length}
                                        <StyledButton
                                          minWidth="10px"
                                          margin="0"
                                          onClick={() => {
                                            this.setState({
                                              menuOpen:
                                                this.state.menuOpen === false
                                                  ? sGrade
                                                  : sGrade !== this.state.menuOpen
                                                  ? sGrade
                                                  : false,
                                            });
                                          }}
                                          name={
                                            this.state.menuOpen ? (
                                              <TopArrow color={"rgba(0, 0, 0, 0.87)"} />
                                            ) : (
                                              <BottomArrow color={"rgba(0, 0, 0, 0.87)"} />
                                            )
                                          }
                                        />
                                      </div>{" "}
                                    </div>
                                    {toPrint || (this.state.menuOpen && this.state.menuOpen === sGrade) ? (
                                      <>
                                        {bulkpermit &&
                                          bulkpermit.length > 0 &&
                                          bulkpermit.filter((sG) => sG.grade === sGrade) &&
                                          bulkpermit
                                            .filter((sG) => sG.grade === sGrade)
                                            .map((sBP) => (
                                              <>
                                                <div className="d-flex width-100 justifySB marginTB10 fontSize09">
                                                  {" "}
                                                  <div className="d-flex justifyC fontWeightBold">
                                                    {sBP.bulk_permit_no}
                                                  </div>{" "}
                                                  <div className="d-flex justifyC fontWeightBold">
                                                    {sBP.issue_date ? Moment(sBP.issue_date).format("D/MM") : "-"}
                                                  </div>
                                                </div>{" "}
                                                <div className="d-flex width-100 justifySB marginTB10">
                                                  {" "}
                                                  <div className="d-flex justifyC ">Opening</div>
                                                  <div className="d-flex justifyC textColorprimary">
                                                    {sBP.issue_quantity
                                                      ? `${Number(sBP.issue_quantity).toFixed(0)}MT`
                                                      : "-"}
                                                  </div>
                                                </div>{" "}
                                                <div className="d-flex width-100 justifySB marginTB10">
                                                  {" "}
                                                  <div className="d-flex justifyC ">Yesterday</div>
                                                  <div className="d-flex justifyC textColorprimary">
                                                    {sBP.yesterday_pending
                                                      ? `${sBP.yesterday_pending.toFixed(0)}MT`
                                                      : "-"}
                                                  </div>
                                                </div>{" "}
                                                <div className="d-flex width-100 justifySB marginTB10">
                                                  {" "}
                                                  <div className="d-flex justifyC ">Today</div>
                                                  <div className="d-flex justifyC textColorprimary">
                                                    {sBP.today_dispatch ? `${sBP.today_dispatch.toFixed(0)}MT` : "-"}
                                                  </div>
                                                </div>{" "}
                                                <div className="d-flex width-100 justifySB marginTB10">
                                                  {" "}
                                                  <div className="d-flex justifyC ">Remaining</div>
                                                  <div className="d-flex justifyC textColorprimary">
                                                    {typeof sBP.remaining === "number"
                                                      ? `${sBP.remaining.toFixed(0)}MT`
                                                      : "-"}
                                                  </div>
                                                </div>{" "}
                                                <Divider className="width-100" /> <Divider className="width-100" />
                                              </>
                                            ))}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Paper>
                              </div>{" "}
                            </Paper>
                          </div>
                        ))}{" "}
                      {toPrint && (
                        <div
                          className="d-flex justifyFE"
                          style={{
                            height: "50px",
                            width: "100%",
                            padding: "30px 0 0 0",
                            minWidth: "100%",
                            color: primary,
                            fontWeight: "400",
                          }}
                        >{`Summary generated at :- ${moment(new Date()).format("MMMM Do YYYY, h:mm A")}`}</div>
                      )}
                    </div>
                  ) : (
                    <div id="mycanvas" className="width-100 d-flex justifyC alignC height-100">
                      {" "}
                      No Bulk Permits yet.
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </Layout>
    );
  }
}

export default EPermitsMobileView;
