import React, { Component } from "react";
import { lightGrey, primary, white } from "../styles/colors";
import {
  Tooltip,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  DialogContent,
  Backdrop,
  MenuItem,
  Divider,
} from "@material-ui/core";
import {
  CssTextField,
  CssTextField10,
  StyledButton,
  StyledCircularProgress,
  StyledSearchBar,
} from "../styles/StyledComponents";
import { withStyles } from "@material-ui/styles";
import ReactDOM from "react-dom";
import { Paper } from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns"; // choose your libs
import { BottomArrow, CrossIcon, TopArrow, AddIcon, EditIcon } from "../styles/Icons";
import { CheckCircle, MoreHorizOutlined } from "@material-ui/icons";
import TableComponent from "./TableComponent";
import moment from "moment";
import SnackbarComponent from "./SnackbarComponent";

import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiFormControl-root": {
      backgroundColor: "rgb(242, 242, 242)",
    },

    "& .MuiOutlinedInput-root": {
      "& .MuiFormControl-root": {
        background: "rgb(242, 242, 242)",
      },
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
        background: "rgb(242, 242, 242)",
        padding: "10px 14px",
      },
    },
  },
})(KeyboardDatePicker);

const CssTextFieldNew = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "10px 5px 10px 14px",
      display: "flex",
      justifyContent: "center",
    },
    "& .MuiOutlinedInput-adornedEnd": { fontSize: "0.9rem" },
  },
})(CssTextField10);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
};
export default class RateMatixes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      colorArr: [white, lightGrey],
      addRateMatrix: false,
      rateMatrixes: [],
      added: false,
      error: "",
      success: "",
      destinationName: "",
      transportingRate: "",
      destinationList: [],
      selectedDate: new Date(),
      calenderOpen: false,
      loading: {},
      loadingFun: false,
      due_days: 30,
      errorMsg: { diesel_rate: false, destinationName: false, transportingRate: false },
      header: ["Sr.No.", "Date", "Diesel Rate", "Due days"],
      apiHeader: ["Sr.No.", "formattedDate", "diesel_rate", "due_days"],
      citiesTranportingRate: {},
      selectedList: false,
      tableListCopy: [],
      edited: false,
      dates: [],
      selectedMonth: new Date(),
    };
  }
  componentWillMount() {
    this.props.fetchDestinationCities(this.props.user.user.uid).then((res) => {
      if (res && res.length > 0) {
        this.setState({ destinationList: res });
      }
    });
    this.props.fetchRateMatrixs(this.props.selectedId, this.props.user.user.uid);
  }
  componentDidMount() {
    this.props.fetchDestinationCities(this.props.user.user.uid).then((res) => {
      if (res && res.length > 0) {
        this.setState({ destinationList: res });
      }
    });
    this.props.fetchRateMatrixs(this.props.selectedId, this.props.user.user.uid);
    this.setInitialValues(this.props);
  }
  longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].props?.title
            ? it[key].props?.title.toString().length
            : it[key].props?.children
            ? it[key].props?.children.toString().length
            : it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  setWidth = () => {
    const { dates, apiHeader } = this.state;
    if (dates && dates.length > 0) {
      if (dates[0]) {
        const widthList = {};
        const array = apiHeader ? apiHeader : Object.keys(dates[0]);
        array.map((single, i) => {
          return (widthList[apiHeader[i]] = `${
            Math.floor(
              10 *
                (single.props?.title
                  ? single.props?.title.toString().length > this.longest(single.props?.title, dates)
                    ? single.props?.title.toString().length
                    : this.longest(single.props?.title, dates)
                  : single.props?.children
                  ? single.props.children.toString().length > this.longest(single.props.children, dates)
                    ? single.props.children.toString().length
                    : this.longest(single.props.children, dates)
                  : single.toString().length > this.longest(single, dates)
                  ? single.toString().length
                  : this.longest(single, dates)
                  ? this.longest(single, dates)
                  : 1)
            ) < 110
              ? 110
              : Math.floor(
                  10 *
                    (single.props?.title
                      ? single.props?.title.toString().length > this.longest(single.props?.title, dates)
                        ? single.props?.title.toString().length
                        : this.longest(single.props?.title, dates)
                      : single.props?.children
                      ? single.props.children.toString().length > this.longest(single.props.children, dates)
                        ? single.props.children.toString().length
                        : this.longest(single.props.children, dates)
                      : single.toString().length > this.longest(single, dates)
                      ? single.toString().length
                      : this.longest(single, dates)
                      ? this.longest(single, dates)
                      : 1)
                )
          }px`);
        });
        this.setState({ widthList });
      }
    }
  };
  UNSAFE_componentWillReceiveProps(next) {
    if (next.selectedId !== this.state.selectedId) {
      this.props.fetchDestinationCities(this.props.user.user.uid).then((res) => {
        if (res && res.length > 0) {
          this.setState({ destinationList: res });
        }
      });
      this.props.fetchRateMatrixs(next.selectedId, this.props.user.user.uid);
    }
    this.setInitialValues(next);
  }
  createRM = () => {
    const {
      selectedDate,
      addRateMatrix,
      diesel_rate,
      due_days,
      destinationList,
      citiesTranportingRate,
      selectedList,
      rateMatrixes,
    } = this.state;
    const { selectedId } = this.props;
    if (selectedDate) {
      if (diesel_rate) {
        if (due_days && due_days > 0) {
          this.setState({ loadingFun: true });
          const data = [];
          destinationList.map((sD) => {
            if (citiesTranportingRate[sD.city] && citiesTranportingRate[sD.city] > 0) {
              if (selectedList) {
                if (
                  rateMatrixes &&
                  rateMatrixes.filter((sR) => sD.id === sR.destination) &&
                  rateMatrixes.filter((sR) => sD.id === sR.destination)[0] &&
                  rateMatrixes.filter((sR) => sD.id === sR.destination)[0].id
                ) {
                  data.push({
                    date: moment(addRateMatrix.date).format("YYYY-MM-DD"),
                    diesel_rate: Number(diesel_rate).toFixed(2),
                    transporting_rate: citiesTranportingRate[sD.city]
                      ? Number(citiesTranportingRate[sD.city]).toFixed(2)
                      : 0,
                    due_days: due_days,
                    id: rateMatrixes.filter((sR) => sD.id === sR.destination)[0].id,
                  });
                }
              } else {
                data.push({
                  date: moment(addRateMatrix.date).format("YYYY-MM-DD"),
                  diesel_rate: Number(diesel_rate).toFixed(2),
                  transporting_rate: citiesTranportingRate[sD.city]
                    ? Number(citiesTranportingRate[sD.city]).toFixed(2)
                    : 0,
                  due_days: due_days,
                  destination: sD.id,
                  transporter_group: selectedId,
                });
              }
            } else {
              var errorMsg = this.state.errorMsg;
              errorMsg[sD.city] = `Please add ${sD.city}'s Transporting Rate !`;
              this.setState({ errorMsg, loadingFun: false });
            }
          });
          if (destinationList.length === data.length) {
            this.props
              .postRateMatrix(data, this.props.user.user.uid, selectedList ? "-bulk-update" : "")
              .then((res) => {
                if (res.error) {
                  this.setState({
                    error: res.data,
                    loadingFun: false,
                  });
                } else {
                  this.props.fetchRateMatrixs(this.props.selectedId, this.props.user.user.uid).then(() => {
                    this.setState({
                      addRateMatrix: false,
                      success: selectedList ? "Rate Matrix Edited!" : "Rate Matrix Added!",
                      loadingFun: false,
                    });
                  });
                }
              });
          } else {
            this.setState({
              error: "Tranporting rate is missing",
              loadingFun: false,
            });
          }
        } else {
          var errorMsg = this.state.errorMsg;
          errorMsg.due_days = "Please give Due days !";
          this.setState({ errorMsg });
        }
      } else {
        var errorMsg = this.state.errorMsg;
        errorMsg.diesel_rate = "Please give Diesel Rate !";
        this.setState({ errorMsg });
      }
    } else {
      var errorMsg = this.state.errorMsg;
      errorMsg.selectedDate = "Please select a Date !";
      this.setState({ errorMsg });
    }
  };
  setInitialValues(props) {
    if (props) {
      if (props.dates && props.dates.length > 0) {
        props.dates.sort((a, b) => {
          var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
          var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
          return new Date(dateA) - new Date(dateB);
        });
        props.dates.map((data, index) => {
          data.formattedDate = moment(data.date).format("Do MMM, ddd");
        });
      }
      this.setState(
        {
          destinationList: props.destinationCities.length > 0 ? props.destinationCities : this.state.destinationList,
          rateMatrixes: props.rateMatrixes,
          selectedId: props.selectedId,
          dates: props.dates,
          tableListCopy: props.dates,
          loading: props.loading,
        },
        () => {
          const { header, destinationList, apiHeader, rateMatrixes, dates } = this.state;
          const newHead = header;
          const newApiHead = apiHeader;
          destinationList &&
            destinationList.length > 0 &&
            destinationList.map((sD, i) => {
              if (!newHead.includes(sD.city)) {
                newHead.push(sD.city);
                if (i === destinationList.length - 1) {
                  newHead.push("Action");
                }
              }
              if (!newApiHead.includes(sD.city)) {
                newApiHead.push(sD.city);
                if (i === destinationList.length - 1) {
                  newApiHead.push("Action");
                }
              }
            });
          if (dates.length > 0) {
            const newArr = [];
            const dates = [];
            rateMatrixes.map((sRM, i) => {
              const srmNew = {};
              if (!dates.includes(sRM.date)) {
                srmNew.date = sRM.date;

                srmNew.due_days = sRM.due_days;
                srmNew.diesel_rate = Number(sRM.diesel_rate).toFixed(2);
                srmNew[
                  destinationList &&
                    destinationList.length > 0 &&
                    destinationList.filter((sD) => sD.id === sRM.destination)[0]?.city
                ] = Number(sRM.transporting_rate).toFixed(2);
                srmNew.Action = (
                  <>
                    <Tooltip title="Edit" placement="top" disableFocusListener disableTouchListener arrow>
                      <StyledButton
                        name={<EditIcon />}
                        width="60px"
                        onClick={() => {
                          this.setSelectedListVal(srmNew);
                        }}
                      />
                    </Tooltip>{" "}
                  </>
                );
                dates.push(sRM.date);
                newArr.push(srmNew);
              } else {
                newArr.filter((sA) => sA.date === sRM.date)[0][
                  destinationList &&
                    destinationList.length > 0 &&
                    destinationList.filter((sD) => sD.id === sRM.destination)[0]?.city
                ] = Number(sRM.transporting_rate).toFixed(2);
              }
            });
            const newDateArr = [];

            this.state.dates &&
              this.state.dates.map((sD, i) => {
                sD["Sr.No."] =
                  this.state.dates.length - i < 10 ? `0${this.state.dates.length - i}` : this.state.dates.length - i;

                if (dates.includes(sD.date)) {
                  const sRM = newArr.filter((sA) => sA.date === sD.date)[0];
                  sD = { ...sD, ...sRM };
                  newDateArr.push(sD);
                } else {
                  sD.Action = (
                    <Tooltip title="Add" placement="top" disableFocusListener disableTouchListener arrow>
                      <StyledButton
                        name={<AddIcon />}
                        width="60px"
                        onClick={() => {
                          this.setState({
                            selectedDate: sD.date,
                            addRateMatrix: sD,
                            diesel_rate: false,
                            citiesTranportingRate: {},
                            due_days: 30,
                            selectedList: false,
                          });
                        }}
                      />
                    </Tooltip>
                  );
                  newDateArr.push(sD);
                }
              });

            this.setState(
              {
                dates: newDateArr,
                tableListCopy: newDateArr,
                header: newHead,
                apiHeader: newApiHead,
              },
              () => {
                this.setWidth();
              }
            );
          } else {
            this.setState(
              {
                header: newHead,
                apiHeader: newApiHead,
              },
              () => {
                this.setWidth();
              }
            );
          }
        }
      );
    }
  }
  handleClearFields = () => {
    this.setState({
      diesel_rate: "",
      destinationName: "",
      transportingRate: "",
      selectedDate: new Date(),
      errorMsg: { diesel_rate: false, destinationName: false, transportingRate: false },
      citiesTranportingRate: {},
      due_days: 1,
    });
  };
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    const TYPE = [event.target.name].toString();
    if (TYPE === "diesel_rate") {
      const { destinationList } = this.state;
      const srmNew = {};

      destinationList &&
        destinationList.length > 0 &&
        destinationList.map((sD) => {
          srmNew[sD.city] = Number(event.target.value * 2).toFixed(2);
        });
      this.setState({
        [event.target.name]: event.target.value,
        citiesTranportingRate: srmNew,
        errorMsg,
      });
    } else {
      if (TYPE !== "due_days") {
        const srmNew = this.state.citiesTranportingRate;
        srmNew[event.target.name] = event.target.value ? event.target.value : 0;
        this.setState({
          citiesTranportingRate: srmNew,
          errorMsg,
        });
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          errorMsg,
        });
      }
    }
  };
  reset = () => {
    const { diesel_rate, destinationName, transportingRate } = this.state;
    if (!diesel_rate && !destinationName && !transportingRate) {
      this.setState({ error: "Nothing to reset !", citiesTranportingRate: {} });
    } else {
      this.setState(
        {
          diesel_rate: "",
          destinationName: "",
          transportingRate: "",
          selectedDate: new Date(),
          due_days: 60,
          citiesTranportingRate: {},
          errorMsg: { diesel_rate: false, destinationName: false, transportingRate: false },
        },
        () => {
          this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };

  setSelectedListVal = (selectedList) => {
    const { destinationList, citiesTranportingRate } = this.state;
    const newD = citiesTranportingRate;
    destinationList &&
      destinationList.length > 0 &&
      destinationList.map((sD) => {
        newD[sD.city] = selectedList[destinationList.filter((sDest) => sD.id === sDest.id)[0].city];
      });
    this.setState({
      addRateMatrix: selectedList,
      selectedList: selectedList,
      diesel_rate: selectedList.diesel_rate,
      citiesTranportingRate: newD,
      selectedDate: selectedList.date,
      due_days: selectedList.due_days,
    });
  };
  clearSelected = () => {
    this.setState({
      addRateMatrix: false,
      diesel_rate: "",
      destinationName: "",
      transportingRate: "",
      selectedDate: new Date(),
      due_days: 60,
      citiesTranportingRate: {},
      errorMsg: { diesel_rate: false, destinationName: false, transportingRate: false },
    });
  };
  handleDateChange = (selectedDate) => {
    this.setState(
      {
        selectedMonth: selectedDate,
      },
      () => {
        const date = selectedDate;
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        this.props.fetchdates(month, year);
      }
    );
  };
  render() {
    const {
      calenderOpen,
      loading,
      loadingFun,
      apiHeader,
      tableListCopy,
      widthList,
      colorArr,
      header,
      dates,
      selectedMonth,
      addRateMatrix,
      errorMsg,
      citiesTranportingRate,
      due_days,
      diesel_rate,
      error,
      success,
    } = this.state;
    return (
      <div
        className="height-100 width-100"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {" "}
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          <Paper
            style={{
              width: "100%",
              height: "100%",
              padding: "15px",
              position: "relative",
            }}
          >
            {" "}
            {error || success ? (
              <SnackbarComponent
                error={error}
                success={success}
                snackbarClose={() => this.setState({ error: "", success: "" })}
              />
            ) : (
              ""
            )}{" "}
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
              <StyledSearchBar
                padding={"9px"}
                bgC={"#F2F2F2"}
                fieldName={apiHeader}
                copyData={tableListCopy}
                filteredData={(filterNotify) => {
                  this.setState({ dates: filterNotify }, () => {
                    this.setWidth();
                  });
                }}
                onClear={() => {
                  this.setState({ dates: tableListCopy }, () => {
                    this.setWidth();
                  });
                }}
                placeholder={`Search Rate Matrixes`}
              />{" "}
              <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                <CssKeyboardDatePicker
                  variant="inline"
                  onOpen={() => {
                    this.setState({ calenderOpen: true });
                  }}
                  onClose={() => {
                    this.setState({ calenderOpen: false });
                  }}
                  keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                  maxDate={new Date()}
                  disableFuture
                  minDate={"2022-01-01"}
                  error={false}
                  invalidLabel=""
                  onKeyPress={(ev) => {
                    ev.preventDefault();
                  }}
                  allowKeyboardControl={false}
                  clearable
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MMM, yyyy"
                  style={{
                    maxWidth: "200px",
                    minWidth: "200px",
                    cursor: "none",
                    fontSize: "0.5rem",
                    height: "38px",
                    background: "rgb(242, 242, 242)",
                  }}
                  value={selectedMonth ? selectedMonth : ""}
                  views={["month", "year"]}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={(date) => this.handleDateChange(date)}
                />
              </MuiPickersUtilsProvider>
            </div>{" "}
            <div
              className="width-100"
              style={{
                margin: "20px 0 0 0",
                height: "calc(100% - 58px)",
              }}
            >
              <Paper
                className="d-flex d-flex-column scrollBluePrimary overflowXA positionR"
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "7px",
                  // border: `1px solid ${primary}`,
                }}
              >
                {loadingFun ? (
                  <Backdrop style={{ zIndex: 1, color: "#fff" }} open={loadingFun}>
                    <StyledCircularProgress color="inherit" />
                  </Backdrop>
                ) : (
                  ""
                )}{" "}
                {loading.dates === true || loading.rateMatrixes === true ? (
                  <div
                    className="
                   justiyC d-flex"
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <StyledCircularProgress color="inherit" />
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        backgroundColor: "rgb(158 158 158 / 48%)",
                        color: primary,
                        borderColor: primary,
                        width: "fit-content",
                        minWidth: "100%",
                        tableLayout: "fixed",
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                        minHeight: "50px",
                        overflow: "hidden",
                        borderRadius: "7px 7px 0 0",
                      }}
                    >
                      <div className="d-flex alignC justifySE width-100">
                        {header &&
                          header.map((name, i) => (
                            <div
                              className="justifyC d-flex alignC"
                              style={{
                                margin: "12px 10px",
                                width: widthList && widthList[apiHeader[i]] ? widthList[apiHeader[i]] : "fit-content",
                                display: "flex",
                                alignItems: "center",
                                color: "#100f0f",
                                textTransform: "capitalize",
                              }}
                            >
                              {name}
                            </div>
                          ))}
                      </div>
                    </div>
                    {dates && dates.length > 0 ? (
                      <div
                        className="scrollBluePrimary"
                        style={{
                          height: "calc(100% - 84px)",
                          overflowY: "auto",
                          width: "fit-content",
                          minWidth: "100%",
                          position: "relative",
                        }}
                      >
                        {dates.map((singleDate, i) => (
                          <>
                            <div className="d-flex width-100 d-flex-column justifySE">
                              <div
                                className="d-flex width-100 justifySE"
                                style={{
                                  margin: "1px 0 0 0",
                                  backgroundColor: colorArr[i % colorArr.length],
                                  height: "54px",
                                }}
                              >
                                {addRateMatrix && singleDate.date === addRateMatrix.date ? (
                                  <>
                                    {apiHeader &&
                                      apiHeader.map((name, i) => (
                                        <div
                                          className="d-flex alignC justifyC minWidthFC"
                                          style={{
                                            margin: "12px 10px",
                                            width: widthList && widthList[name] ? widthList[name] : "fit-content",
                                            minWidth: widthList && widthList[name] ? widthList[name] : "fit-content",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {i === apiHeader.length - 1 ? (
                                            <>
                                              {" "}
                                              <StyledButton
                                                name={"Save"}
                                                width="60px"
                                                onClick={() => {
                                                  this.createRM(singleDate);
                                                }}
                                              />{" "}
                                              <Tooltip
                                                title="Clear"
                                                placement="top"
                                                disableFocusListener
                                                disableTouchListener
                                                arrow
                                              >
                                                <StyledButton
                                                  name={<CrossIcon color={primary} />}
                                                  width="60px"
                                                  onClick={() => {
                                                    this.clearSelected();
                                                  }}
                                                />
                                              </Tooltip>
                                            </>
                                          ) : i === 0 || i === 1 ? (
                                            singleDate[name] ? (
                                              singleDate[name]
                                            ) : (
                                              "-"
                                            )
                                          ) : (
                                            <>
                                              <CssTextFieldNew
                                                autoComplete="off"
                                                type="number"
                                                InputProps={{
                                                  inputProps: {
                                                    min: 1,
                                                  },
                                                }}
                                                autoFocus={[name].toString() === "diesel_rate"}
                                                required={true}
                                                onKeyDown={(evt) =>
                                                  (evt.key === "E" || evt.key === "e" || evt.key === "-") &&
                                                  evt.preventDefault()
                                                }
                                                style={{
                                                  width: widthList && widthList[name] ? widthList[name] : "110px",
                                                  maxWidth: "110px",
                                                }}
                                                id="outlined-basic"
                                                name={[name]}
                                                variant="outlined"
                                                value={
                                                  [name].toString() === "due_days"
                                                    ? due_days
                                                    : [name].toString() === "diesel_rate"
                                                    ? diesel_rate
                                                    : citiesTranportingRate[name]
                                                }
                                                error={errorMsg[name] ? true : false}
                                                onChange={this.handleChange}
                                                InputProps={{
                                                  endAdornment:
                                                    [name].toString() === "due_days"
                                                      ? due_days
                                                        ? due_days > 1
                                                          ? "Days"
                                                          : "Day"
                                                        : ""
                                                      : [name].toString() === "diesel_rate"
                                                      ? diesel_rate
                                                        ? "/Ltr"
                                                        : ""
                                                      : citiesTranportingRate[name]
                                                      ? "/Km"
                                                      : "",
                                                }}
                                              />
                                            </>
                                          )}
                                        </div>
                                      ))}
                                  </>
                                ) : (
                                  apiHeader &&
                                  apiHeader.map((name) => (
                                    <div
                                      className="d-flex alignC justifyC minWidthFC"
                                      style={{
                                        margin: "12px 10px",
                                        width: widthList && widthList[name] ? widthList[name] : "fit-content",
                                        minWidth: widthList && widthList[name] ? widthList[name] : "fit-content",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {singleDate[name] ? singleDate[name] : "-"}
                                    </div>
                                  ))
                                )}
                              </div>

                              <Divider className="width-100" />
                            </div>

                            <Divider />
                          </>
                        ))}
                      </div>
                    ) : (
                      <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                        <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>No Rate Matrixes yet !</span>
                      </div>
                    )}
                  </>
                )}
              </Paper>
            </div>
          </Paper>
        </Paper>
      </div>
    );
  }
}
