import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import LoginContainer from "./containers/Login";
import DispatchesContainer from "./containers/Dispatches";
import DashboardContainer from "./containers/Dashboard";
import SalesOrderContainer from "./containers/SalesOrder";
import MastersManagementsContainer from "./containers/MastersManagements";
import UserAndPermissions from "./containers/UserAndPermissions";
import Loader from "./components/Loader";
import "./styles/styles.css";
import { connect } from "react-redux";
import { fetchLoggedUser } from "./redux/index";
import { withRouter } from "react-router";
import TransporterContainer from "./containers/Transporter";
import EPermitsContainer from "./containers/EPermits";
import TranspotersGroupContainer from "./containers/TranspotersGroup";
import DestinationContainer from "./containers/Destination";
import ConsigneesContainer from "./containers/Consignees";
import SourceCitiesContainer from "./containers/SourceCities";
import ConsignorsContainer from "./containers/Consignor";
import VehiclesConatiner from "./containers/Vehicles";
import DriversConatiner from "./containers/Drivers";
import GradesConatiner from "./containers/Grades";
import ItemGradeContainer from "./containers/ItemsGrade";
import ContextMenu from "./components/ContextMenu";
// import ConsignorsContainer from "./containers/Consignor"
// If we won't accept anonymous user as logged in user
const Secure = ({ component: Component, user, isMobile, ...props }) => (
  <Route
    {...props}
    render={(props) =>
      user && !user.isAnonymous ? <Component {...props} isMobile={isMobile} /> : <Redirect to="/login" />
    }
  />
);

// Loader while login redirect
const RouteLoader = ({ component: Component, user, ...props }) => (
  <Route {...props} render={(props) => (user ? <Redirect to="/dispatches" /> : <Component {...props} />)} />
);
function App(props) {
  useEffect(
    () => {
      props.fetchLoggedUser();
    },
    [props.fetchLoggedUser]
  );
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const [isMobile, setMobile] = React.useState(width <= 500);
  const { user, loader } = props;
  return (
    <Router>
      {loader ? (
        <Loader />
      ) : (
        <>
          <Switch>
            <Secure path="/dashboard" component={DashboardContainer} user={user} isMobile={isMobile} />
            <Secure path="/dispatches" component={DispatchesContainer} user={user} isMobile={isMobile} />
            <Secure path="/salesorders" component={SalesOrderContainer} user={user} isMobile={isMobile} />{" "}
            <Secure path="/transporter" component={TransporterContainer} user={user} />{" "}
            <Secure path="/e-permit" component={EPermitsContainer} user={user} isMobile={isMobile} />{" "}
            <Secure path="/masters" EPermitsContainer component={MastersManagementsContainer} user={user} />
            <RouteLoader path="/authReloadWait" component={Loader} user={user} />
            <Route path="/userandpermmision" render={(props) => <UserAndPermissions {...props} />} />
            <Secure user={user} path="/transpotersgroup" component={TranspotersGroupContainer} />{" "}
            <Secure user={user} path="/transpoters" component={TransporterContainer} />{" "}
            <Secure user={user} path="/destinationcities" component={DestinationContainer} />{" "}
            <Secure user={user} path="/consignees" component={ConsigneesContainer} />{" "}
            <Secure user={user} path="/consignors" component={ConsignorsContainer} />{" "}
            <Secure user={user} path="/sourcecities" component={SourceCitiesContainer} />{" "}
            <Secure user={user} path="/vehicles" component={VehiclesConatiner} />{" "}
            <Secure user={user} path="/items" component={GradesConatiner} />{" "}
            <Secure user={user} path="/itemsgrades" component={ItemGradeContainer} />
            <Secure user={user} path="/driversDetails" component={DriversConatiner} />{" "}
            <Route exact path="/login" render={(props) => <LoginContainer {...props} />} />
            <Redirect
              from="/"
              to={
                isMobile ? "/dispatches" : user?.accessibleModules?.includes("dashboard") ? "/dashboard" : "/dispatches"
              }
            />
          </Switch>
        </>
      )}
    </Router>
  );
}
const mapStateToProps = (state) => ({
  version: state.authentication.version,
  user: state.authentication.user,
  loader: state.authentication.loading,
});
const mapDispatchToProps = { fetchLoggedUser };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
