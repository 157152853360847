import { ExposureNeg1 } from "@material-ui/icons";
import axios from "axios";
import {
  EditMatersURL,
  AddMatersURL,
  fetchMatersURL,
  transpoterEditURL,
  destinationEditURL,
  consignorEditURL,
  DispatchURL,
  SalesOrderURL,
  fetchConsigneeGradeUsingTransitPass,
  SalesOrderEDitURL,
  tripsURL,
  fetchMatersUrlPayment,
  rateMatrixesURL,
} from "../../http/config";

export const fetchTransportersGroups = (token) => async (dispatch, getState) => {
  console.log("executed");
  try {
    const type = "transpoterslist";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("transporter_groups"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      // axios({
      //   url: fetchMatersURL("transporter_groups"),
      //   method: "get",
      //   headers: {
      //     Authorization: `JWT ${token}`,
      //     "Content-Type": "application/json;charset=UTF-8",
      //   },
      // })
      //   .then((response) => {
      //     console.log("Success transport ========>", response);
      //   })
      .catch((error) => {
        console.log(error, "error");
      });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data.sort((a, b) => a.name.localeCompare(b.name)),
    });
    return response1.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchTransporters = (token) => async (dispatch, getState) => {
  console.log("executed");
  try {
    const type = "transporter";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("transporter"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      // axios({
      //   url: fetchMatersURL("transporter_groups"),
      //   method: "get",
      //   headers: {
      //     Authorization: `JWT ${token}`,
      //     "Content-Type": "application/json;charset=UTF-8",
      //   },
      // })
      //   .then((response) => {
      //     console.log("Success transport ========>", response);
      //   })
      .catch((error) => {
        console.log(error, "error");
      });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data.sort((a, b) => a.name.localeCompare(b.name)),
    });
    return response1.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const fetchDriversDetail = (token) => async (dispatch, getState) => {
  console.log("executed");
  try {
    const type = "drivers";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("drivers"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      // axios({
      //   url: fetchMatersURL("transporter_groups"),
      //   method: "get",
      //   headers: {
      //     Authorization: `JWT ${token}`,
      //     "Content-Type": "application/json;charset=UTF-8",
      //   },
      // })
      //   .then((response) => {
      //     console.log("Success transport ========>", response);
      //   })
      .catch((error) => {
        console.log(error, "error");
      });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data.sort((a, b) => a.name.localeCompare(b.name)),
    });
    return response1.data.sort((a, b) => a.name.localeCompare(b.name));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const fetchDestinationCities = (token) => async (dispatch, getState) => {
  try {
    const type = "destinationCities";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("destinations"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      console.log("Success destinations fetch ========>", response);
      return response;
      //console.log("Success transportNNNNNNNNNNNN ========>", response);
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchConsignors = (token) => async (dispatch, getState) => {
  try {
    const type = "consignors";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("consignors"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
      console.log("Success transport ========>", response);
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchConsignees = (token) => async (dispatch, getState) => {
  try {
    const type = "consignees";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("consignees"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
      console.log("Success transport ========>", response);
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchItems = (token) => async (dispatch, getState) => {
  try {
    const type = "items";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("items"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchitems_grade = (token) => async (dispatch, getState) => {
  try {
    const type = "items_grade";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("items_grade"),
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
      console.log("Success itemsitemsitemsitemsitemsitems ========>", response);
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchSalesOrders = (item_grade, consignee, token, onlyfilt) => async (dispatch, getState) => {
  try {
    const type = "salesOrders";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: SalesOrderURL("get", "salesorder/"),
      params: onlyfilt ? item_grade && consignee && { item_grade: item_grade, consignee: consignee } : {},
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = onlyfilt
      ? item_grade &&
        consignee &&
        (await axios(options).then((response) => {
          return response;
          console.log("Success salesOrderssalesOrderssalesOrderssalesOrders ========>", response);
        }))
      : await axios(options).then((response) => {
          return response;
          console.log("Success salesOrderssalesOrderssalesOrderssalesOrders ========>", response);
        });
    //api/sales-and-payments/get/salesorder/
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchSalesOrdersDaySummary = (date, token) => async (dispatch, getState) => {
  try {
    if (date) {
      const type = "salesOrders";
      dispatch(fetchingRequest(type));
      const options = {
        method: "get",
        url: SalesOrderURL("salesorder", "day-summary/"),
        params: { date: date },
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      const response1 = await axios(options).then((response) => {
        return response;
      });
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: response1.data.sort((a, b) => b.id - a.id),
      });
      return response1.data.sort((a, b) => b.id - a.id);
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchSalesOrdersDate = (consignee, token) => async (dispatch, getState) => {
  try {
    if (consignee) {
      const type = "salesOrders";
      dispatch(fetchingRequest(type));
      const options = {
        method: "get",
        url: SalesOrderURL("get", "salesorder/"),
        params: { consignee: consignee },
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      };
      const response1 = await axios(options).then((response) => {
        return response;
      });
      //api/sales-and-payments/get/salesorder/
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: response1.data.sort((a, b) => b.id - a.id),
      });
      return response1.data.sort((a, b) => b.id - a.id);
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const fetchSourceCities = (token) => async (dispatch, getState) => {
  try {
    const type = "sourceCities";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("sources"),

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
      console.log("Success transport ========>", response);
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchSalesOrderBulkPermit = (item_grade, token) => async (dispatch, getState) => {
  try {
    const type = "bulkpermit";
    dispatch(fetchingRequest(type));
    const response1 = await axios
      .get(SalesOrderURL("bulkpermit/"), {
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        params: { item_grade: item_grade },
      })
      .then((response) => {
        return response;
        console.log("Success transport ========>", response);
      });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const items_gradeEdit = (data, token, id) => async (dispatch, getState) => {
  try {
    console.log(data, "data");

    const options = {
      method: "patch",
      url: EditMatersURL("items_grade", id),
      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const items_gradeAdd = (data, token) => async (dispatch, getState) => {
  console.log(fetchMatersURL("items_grade"), data, "executed");
  try {
    // const type = "transpoterslist";
    // dispatch(fetchingRequest(type));

    const options = {
      method: "post",
      url: fetchMatersURL("items_grade"),
      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })

      .catch((error) => {
        return {
          data: {
            error: true,
            msg: error.response ? error.response.data : "Some error occured, please try again!",
          },
        };
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      msg: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const transpotersEdit = (data, token, id) => async (dispatch, getState) => {
  try {
    console.log(data, "data");

    const options = {
      method: "patch",
      url: EditMatersURL("transporter", id),
      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const transpotersAdd = (data, token) => async (dispatch, getState) => {
  try {
    // const type = "transpoterslist";
    // dispatch(fetchingRequest(type));

    const options = {
      method: "post",
      url: fetchMatersURL("transporter"),
      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })

      .catch((error) => {
        return {
          data: {
            error: true,
            msg: error.response ? error.response.data : "Some error occured, please try again!",
          },
        };
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      msg: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const ePermitEdit = (data, token, id) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: SalesOrderURL("bulkpermit", `${id}`),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const ePermitAdd = (data, token) => async (dispatch, getState) => {
  console.log(fetchMatersURL("bulkpermit"), data, "executed");
  try {
    // const type = "transpoterslist";
    // dispatch(fetchingRequest(type));

    const options = {
      method: "post",
      url: SalesOrderURL("bulkpermit/"),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })

      .catch((error) => {
        return {
          data: {
            error: true,
            msg: error.response ? error.response.data : "Some error occured, please try again!",
          },
        };
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      msg: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchVehicles = (token, singlId) => async (dispatch, getState) => {
  try {
    const type = "vehicles";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersURL("vehicles"),

      params: singlId ? { vehicle_number: singlId } : {},
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = singlId
      ? await axios(options).then((response) => {
          return response;
          console.log("Success transport ========>", response);
        })
      : await axios(options).then((response) => {
          return response;
          console.log("Success transport ========>", response);
        });

    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return singlId ? response1 : response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const salesorderEdit = (data, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: SalesOrderEDitURL("salesorder", editednameId),

      data: data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const salesorderAdd = (data, token) => async (dispatch, getState) => {
  console.log(fetchMatersURL("salesOrders"), data, "executed");
  try {
    const options = {
      method: "post",
      url: SalesOrderURL("get", "salesorder/"),
      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })

      .catch((error) => {
        return {
          data: {
            error: true,
            msg: error.response ? error.response.data : "Some error occured, please try again!",
          },
        };
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      msg: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const vehicleEdit = (data, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: EditMatersURL("vehicles", editednameId),
      data: data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const vehicleAdd = (data, token) => async (dispatch, getState) => {
  console.log(fetchMatersURL("vehicles"), data, "executed");
  try {
    // const type = "transpoterslist";
    // dispatch(fetchingRequest(type));

    const options = {
      method: "post",
      url: fetchMatersURL("vehicles"),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      // axios({
      //   url: fetchMatersURL("transporter_groups"),
      //   method: "get",
      //   headers: {
      //     Authorization: `JWT ${token}`,
      //     "Content-Type": "application/json;charset=UTF-8",
      //   },
      // })
      //   .then((response) => {
      //     console.log("Success transport ========>", response);
      //   })
      .catch((error) => {
        return {
          data: {
            error: true,
            msg: error.response ? error.response.data : "Some error occured, please try again!",
          },
        };
      });
    // dispatch({
    //   type: `FETCH_${type}_SUCCESS`,
    //   payload: type,
    //   data: response1.data,
    // });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      msg: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const gradesEdit = (name, is_active, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: EditMatersURL("items", editednameId),

      data: { name: name, is_active: is_active },

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const gradesAdd = (data, token) => async (dispatch, getState) => {
  console.log(fetchMatersURL("items"), data, "executed");
  try {
    // const type = "transpoterslist";
    // dispatch(fetchingRequest(type));
    const options = {
      method: "post",
      url: fetchMatersURL("items"),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      // axios({
      //   url: fetchMatersURL("transporter_groups"),
      //   method: "get",
      //   headers: {
      //     Authorization: `JWT ${token}`,
      //     "Content-Type": "application/json;charset=UTF-8",
      //   },
      // })
      //   .then((response) => {
      //     console.log("Success transport ========>", response);
      //   })
      .catch((error) => {
        console.log(error, "error");
      });
    // dispatch({
    //   type: `FETCH_${type}_SUCCESS`,
    //   payload: type,
    //   data: response1.data,
    // });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const transpoterEdit = (data, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: EditMatersURL("transporter_groups", editednameId),
      data: data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const addTransportersGroups = (data, token) => async (dispatch, getState) => {
  try {
    const type = "transpoterslist";
    dispatch(fetchingRequest(type));
    const options = {
      method: "post",
      url: fetchMatersURL("transporter_groups"),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      // axios({
      //   url: fetchMatersURL("transporter_groups"),
      //   method: "get",
      //   headers: {
      //     Authorization: `JWT ${token}`,
      //     "Content-Type": "application/json;charset=UTF-8",
      //   },
      // })
      //   .then((response) => {
      //     console.log("Success transport ========>", response);
      //   })
      .catch((error) => {
        console.log(error, "error");
      });
    // dispatch({
    //   type: `FETCH_${type}_SUCCESS`,
    //   payload: type,
    //   data: response1.data,
    // });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const destinationEdit = (city, is_active, editednameId, token) => async (dispatch, getState) => {
  try {
    const adddata = { city: city, is_active: is_active };
    const options = {
      method: "patch",
      url: EditMatersURL("destinations", editednameId),
      data: adddata,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const addDestinationCities = (data, token) => async (dispatch, getState) => {
  try {
    console.log(fetchMatersURL("destinations"), data, "executed");
    const options = {
      method: "post",
      url: fetchMatersURL("destinations"),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "response");

        return response;
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const consignorEdit = (data, is_active, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: EditMatersURL("consignors", editednameId),

      data: data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const consignorAdd = (data, token) => async (dispatch, getState) => {
  try {
    console.log(fetchMatersURL("consignors"), data, "executed");
    const options = {
      method: "post",
      url: fetchMatersURL("consignors"),

      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "response");

        return response;
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const consigneesEdit = (data, is_active, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: EditMatersURL("consignees", editednameId),
      data: {
        name: data.name,
        address: data.address,
        gstin_no: data.gstin_no,
        short_name: data.short_name,
        tp_identifier: data.tp_identifier,
        is_active: is_active,
        destination: data.destination,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const consigneesAdd = (data, token) => async (dispatch, getState) => {
  try {
    console.log(fetchMatersURL("consignees"), data, "executed");

    const options = {
      method: "post",
      url: fetchMatersURL("consignees"),

      data,

      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "response");

        return response;
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const sourcesEdit = (data, is_active, editednameId, token) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: EditMatersURL("sources", editednameId),
      data: {
        city: data,
        is_active: is_active,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const res = await axios(options)
      .then((response) => {
        console.log("Success transport ", response);
      })
      .catch((a) => console.log(a, "aaaaa"));
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const sourcesAdd = (data, token) => async (dispatch, getState) => {
  try {
    console.log(fetchMatersURL("sources"), data, "executed");
    const options = {
      method: "post",
      url: fetchMatersURL("sources"),

      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "response");

        return response;
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchDates = (token, month, year) => async (dispatch, getState) => {
  try {
    const type = "dates";
    dispatch(fetchingRequest(type));
    console.log(token, "tokentoken");
    const options = {
      method: "get",
      url: fetchMatersURL("dates"),

      params: month && year ? { month: month, year: year } : {},
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
      console.log("Success dates ========>", response);
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchSingleDateDetails = (id, token) => async (dispatch, getState) => {
  try {
    const type = "liveParty";
    console.log(id, token, "id, tokennnnnnnnnnnnnnn");
    dispatch(fetchingRequest(type));

    const options = {
      method: "get",
      url: DispatchURL("live-party", "list"),
      params: { date: id.date },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "responseeeeeeeeeeeeeeeee");
        return response;
      })
      .catch((err) => console.log(err, "errrrrrrrrrrrrrrrrrrrr"));
    var date = localStorage.getItem("selectedDate") ? JSON.parse(localStorage.getItem("selectedDate")) : "";
    console.log(date, "datedate");
    if (response1.data && response1.data.date) {
      if (date === response1.data.date) {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response1.data,
        });
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
    }
    return response1.data;
  } catch (error) {
    const type = "liveParty";
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: {},
    });
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchConsigneeGradeUsingTp = (data, token) => async (dispatch, getState) => {
  try {
    const type = "consigneesAndGrades";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchConsigneeGradeUsingTransitPass(),
      params: {
        tp_number: data,
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        return response;
        console.log("Success dates ========>", response);
      })
      .catch((error) => {
        return error.response;
        console.log(error.response, "error");
      });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const fetchTripsTransGroups = (transporter_group_id, token) => async (dispatch, getState) => {
  try {
    const type = "singleTransGroupsTrips";
    dispatch(fetchingRequest(type));

    const options = {
      method: "get",
      url: tripsURL("list/"),

      params: {
        transporter_group_id: transporter_group_id ? transporter_group_id : "",
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    console.log(options, "options");

    const response1 = await axios(options)
      .then((response) => {
        return response;
        console.log("Success dates ========>", response);
      })
      .catch((error) => {
        return error.response;
        console.log(error.response, "error");
      });
    // selectedTG
    var selectedTG = localStorage.getItem("selectedTG") ? JSON.parse(localStorage.getItem("selectedTG")) : "";

    if (response1 && response1.data && response1.data.results.length > 0) {
      if (selectedTG === response1.data.results[0].transporter_group.id) {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response1.data,
        });
        return response1.data;
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
      return [];
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const lazyLoadFetch = (url, module, token) => async (dispatch, getState) => {
  try {
    const type = module;
    dispatch(fetchingRequest(type));

    const options = {
      method: "get",
      url: url,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    console.log(options, "options");

    const response1 = await axios(options)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error.response;
      });
    console.log(response1, "response1");

    // selectedTG
    var selectedTG = localStorage.getItem("selectedTG") ? JSON.parse(localStorage.getItem("selectedTG")) : "";

    if (response1 && response1.data && response1.data.results.length > 0) {
      if (selectedTG === response1.data.results[0].transporter_group.id) {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response1.data,
        });
        return response1.data;
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
      return [];
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
const fetchingRequest = (data) => {
  return {
    type: "FETCH_REQUEST",
    payload: data,
    data: [],
  };
};
export const ePermitDailyReport = (date, token) => async (dispatch, getState) => {
  try {
    const type = "bulkpermit";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: SalesOrderURL("bulkpermit", `day-summary/`),

      params: date,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
    });
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: response1.data,
    });
    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const fetchPaymentVoucher = (token, transporter_group_id) => async (dispatch, getState) => {
  try {
    const type = "voucher";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: fetchMatersUrlPayment("payment-voucher", transporter_group_id),

      params: {
        transporter_group_id: transporter_group_id ? transporter_group_id : "",
      },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };

    const response1 = await axios(options).then((response) => {
      return response;
    });

    var selectedTG = localStorage.getItem("selectedTG") ? JSON.parse(localStorage.getItem("selectedTG")) : "";
    console.log(selectedTG, response1.data[0], "props.voucher 3");
    if (response1 && response1.data && response1.data.length > 0) {
      if (selectedTG === response1.data[0].transporter_group) {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response1.data,
        });
        return response1.data;
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
      return [];
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
// export const fetchPaymentVoucher = (id, token) => async (
//   dispatch,
//   getState,
// ) => {
//   try {
//     console.log('koushlendraaaaaaa', 'fetchhh', id)
//     const type = 'paymentvoucher'
//     const options = {
//       method: 'get',

//       url: fetchMatersUrlPayment('payment-voucher', id),

//       headers: {
//         Authorization: `JWT ${token}`,
//         'Content-Type': 'application/json;charset=UTF-8',
//       },
//     }
//     dispatch(fetchingRequest(type))
//     const response = await axios(options)
//       .then((response1) => {
//         // return response1.data
//         return response1.data
//       })
//       .catch((error) => {
//         return {}
//         //   dispatch({
//         //     type: `FETCH_${type}_SUCCESS`,
//         //     payload: type,
//         //     data: [],
//         // });
//         console.log(error, 'error')
//       })
//     var lr_number = localStorage.getItem('selectedlr_number')
//       ? JSON.parse(localStorage.getItem('selectedlr_number'))
//       : ''
//     console.log(response, 'responseresponse')
//     if (response && response.Trips && response.Trips.length > 0) {
//       if (lr_number === response.Trips[0].lr_number) {
//         console.log('bnewwwww', 'fetchhh', 'newwwwww', lr_number)
//         dispatch({
//           type: `FETCH_${type}_SUCCESS`,
//           payload: type,
//           data: response,
//         })
//       } else {
//         dispatch(fetchingRequest(type))
//       }
//     } else {
//       dispatch({
//         type: `FETCH_${type}_SUCCESS`,
//         payload: type,
//         data: [],
//       })
//     }
//     return response
//   } catch (error) {
//     const type = 'singleDateTrips'
//     dispatch({
//       type: `FETCH_${type}_SUCCESS`,
//       payload: type,
//       data: [],
//     })
//     return {
//       error: true,
//       data: error.response
//         ? error.response.data
//         : 'Some error occured, please try again!',
//     }
//   }
// }
// export const PaymentVoucherEdit = (data, is_active, editednameId, token) => async (
//   dispatch,
//   getState,
// ) => {
//   try {
//     const options = {
//       method: 'patch',
//       url: EditMatersURL('paymentvoucher', editednameId),
//       data: {
//         city: data,
//         is_active: is_active,
//       },
//       headers: {
//         Authorization: `JWT ${token}`,
//         'Content-Type': 'application/json;charset=UTF-8',
//       },
//     }
//     const res = await axios(options)
//       .then((response) => {
//         console.log('Success transport ', response)
//       })
//       .catch((a) => console.log(a, 'aaaaa'))
//   } catch (error) {
//     return {
//       error: true,
//       data: error.response
//         ? error.response.data
//         : 'Some error occured, please try again!',
//     }
//   }
// }
export const PaymentVoucherAdd = (data, token, id) => async (dispatch, getState) => {
  try {
    const options = {
      method: id ? "patch" : "post",
      url: fetchMatersUrlPayment(`payment-voucher${id ? `/${id}` : ""}`),
      params: id ? { id: id ? id : "" } : null,
      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "response");

        return response;
      })
      .catch((error) => {
        console.log(error, "error");
      });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchRateMatrixs = (tgID, token) => async (dispatch, getState) => {
  try {
    const type = "rateMatrixes";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: rateMatrixesURL("rate-matrix"),

      params: { transporter_group_id: tgID },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
    });
    var selectedTG = localStorage.getItem("selectedTG") ? JSON.parse(localStorage.getItem("selectedTG")) : "";
    if (response1 && response1.data && response1.data.length > 0) {
      console.log(response1.data, "response1.data");
      if (selectedTG === response1.data[0].transporter_group) {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response1.data,
        });
        return response1.data;
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
      return [];
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const postRateMatrix = (data, token, id) => async (dispatch, getState) => {
  try {
    const options = {
      method: "post",
      url: rateMatrixesURL(id ? `rate-matrix${id}` : "rate-matrix"),
      data: data ? data : [],
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
    });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchPaymentAdvices = (tgID, token) => async (dispatch, getState) => {
  try {
    const type = "paymentAdvices";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: rateMatrixesURL("payment-advice"),

      params: { transporter_group_id: tgID },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
    });
    var selectedTG = localStorage.getItem("selectedTG") ? JSON.parse(localStorage.getItem("selectedTG")) : "";
    if (response1 && response1.data && response1.data.length > 0) {
      if (selectedTG === response1.data[0].transporter_group) {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response1.data,
        });
        return response1.data;
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
      return [];
    }
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const postPaymentAdvices = (data, token, id) => async (dispatch, getState) => {
  try {
    const options = {
      method: id ? "patch" : "post",
      url: rateMatrixesURL(id ? `payment-advice/${id}` : "payment-advice"),
      data: data ? data : [],
      params: { id: id ? id : "" },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options).then((response) => {
      return response;
    });

    return response1.data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
