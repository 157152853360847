import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import SalesOrder from "../components/SalesOrder";
import {
  fetchSalesOrders,
  fetchConsignees,
  fetchItems,
  fetchitems_grade,
  salesorderEdit,
  salesorderAdd,
  fetchSalesOrdersDate,
  fetchDates,
  fetchSalesOrdersDaySummary,
} from "../redux/index";
class SalesOrderContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { users: [], salesOrders: [] };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ salesOrders: next.salesOrders });
  }
  fetchdates = async () => {
    if (this.props.user.user.uid) {
      await this.props.fetchDates(this.props.user.user.uid).then((res) => {
        if (res) {
          const length = [];
          if (res.length > 0) {
            res.map((data, index) => {
              if (data.date === moment(new Date()).format("YYYY-MM-DD")) {
                length.push(data);
              }
            });
          } else {
            res = [];
          }
          if (length.length === 0) {
            var newVal = {
              id: res.length + 1,
              dispatch_details: {
                total_trips: 0,
                total_net_weight: null,
                total_pending_trips: 0,
                total_net_weight_pending_trips: null,
                total_new_trips: 0,
                total_dispatched_trips: 0,
                total_correction_trips: 0,
                total_reached_trips: 0,
                total_ready_trips: 0,
                total_billing_trips: 0,
              },
              date: moment(new Date()).format("YYYY-MM-DD"),
              is_active: true,
            };
            res.push(newVal);
          }
          console.log(res, "resselectedDate1ssssssssss");
          this.setState({ dates: res });
        }
      });
    }
  };
  fetchUsers = async () => {
    console.log("this.props.user.uid", this.props.user.user.uid);

    await this.props.fetchConsignees(this.props.user.user.uid).then((res) => {
      if (res) {
        this.setState({ consignees: res });
      }
    });
    await this.props.fetchItems(this.props.user.user.uid).then((res) => {
      if (res) {
        this.setState({ items: res });
      }
    });
    await this.props.fetchitems_grade(this.props.user.user.uid).then((res) => {
      if (res) {
        this.setState({ items_grade: res });
      }
    });
    if (this.props.isMobile) {
      await this.fetchdates();
    }
  };
  render() {
    return (
      <Route
        exact
        path={["/salesorders", "/salesorders/:tab/:id", "/salesorders/new"]}
        render={(props) => (
          <SalesOrder
            {...this.props}
            {...this.state}
            setSalesOrders={(salesOrders) => {
              this.setState({ consignees: salesOrders });
            }}
          />
        )}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  salesOrders: state.masters.salesOrders,

  dates: state.masters.dates,
  loading: state.masters.loading,
  consignees: state.masters.consignees,
  items: state.masters.items,
  items_grade: state.masters.items_grade,
});
const mapDispatchToProps = {
  fetchSalesOrdersDaySummary,
  fetchSalesOrdersDate,
  fetchConsignees,
  fetchItems,
  fetchitems_grade,
  salesorderEdit,
  salesorderAdd,
  fetchDates,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SalesOrderContainer);
