import { Backdrop, Divider, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { Component } from "react";
import { lightblue, lightGrey, primary } from "../styles/colors";

import domtoimage from "dom-to-image";
import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import moment from "moment";
const CssPaper = withStyles({
  root: {
    "&:hover": {
      backgroundColor: primary,
    },
    "&.MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
})(Paper);
const styles = {
  paperStyle: (notEmpty) => ({
    //!notEmpty ?
    maxHeight: "calc(100% - 40px)", // : "calc(100% - 115.74px)",

    height: !notEmpty ? "283.75px" : "fit-content",
    borderRadius: "7px",

    width: "100%",
    margin: "20px 0px",
    padding: "20px",
    backgroundColor: "rgba(128, 128, 128, 0.04)",
  }),
};
class SummaryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      liveParty: [],
      total_weight: 0,
      total_trips: 0,
      colorArr: ["white", "#8080800a"],
      toPrint: false,
    };
  }
  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  setInitialValues(props) {
    this.setState(
      {
        liveParty: props.liveParty ? props.liveParty : [],
      },
      
      () => {
        if (this.state.liveParty.total_per_transporter) {
          var total_weight = 0;
          var total_trips = 0;
          this.state.liveParty.total_per_transporter.map((data, i) => {
            total_trips = total_trips + data.total_trips;
            total_weight = total_weight + data.total_net_weight;
            if (i === this.state.liveParty.total_per_transporter.length - 1) {
              this.setState({
                total_weight: Number(total_weight).toFixed(3),
                total_trips: total_trips,
              });
            }
          });
        }console.log(props.liveParty,"aaaaaaaaaaanuuuuuuuuuuuuuu");
      }
    );
  }
  downloadImage = (fileName) => {
    this.setState({ toPrint: true }, () => {
      domtoimage
        .toJpeg(document.getElementById("mycanvas"), { quality: 0.95 })
        .then(function(dataUrl) {
          var link = document.createElement("a");
          link.download = fileName ? `${fileName}.jpeg` : "image.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          this.setState({ toPrint: false });
        });
    });
  };
  render() {
    const { liveParty, total_weight, total_trips, colorArr, toPrint } = this.state;
    const { copyLiveParty, fromDashBoard } = this.props;
    return (
      <div className="d-flex width-100 height-100 d-flex-column ">
        {toPrint ? (
          <Backdrop style={{ zIndex: 1, color: "#fff" }} open={toPrint}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}{" "}
        <Paper
          className="d-flex d-flex-column"
          style={styles.paperStyle(
            liveParty && liveParty.total_per_transporter && liveParty.total_per_transporter.length > 0
          )}
          elevation={3}
          component="form"
        >
          {" "}
          {this.props.loading.liveParty ? (
            <div className="alignC justiyC d-flex" style={{ width: "100%", height: "100%" }}>
              <StyledCircularProgress color="inherit" />
            </div>
          ) : copyLiveParty && copyLiveParty.length > 0 ? (
            <>
              {this.props.user.user.accessibleWidgets &&
                this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "exportTrips") &&
                this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "exportTrips").length >
                  0 &&
                this.props.user.user.accessibleWidgets.filter((data) => data.widget_name === "exportTrips")[0]
                  .has_read_permission === true && (
                  <div
                    className=" d-flex justifyFE marginB20"
                    style={{
                      width: fromDashBoard ? "fit-content" : "100%",
                      position: fromDashBoard && "absolute",
                      top: fromDashBoard && "20px",
                      right: fromDashBoard && "20px",
                    }}
                  >
                    <StyledButton
                      name={"Download"}
                      withBg={true}
                      width="90px"
                      onClick={() => {
                        this.downloadImage(this.props.fileName);
                      }}
                    />
                  </div>
                )}

              <div
                className="d-flex d-flex-column scrollBlue"
                style={{
                  overflow: "auto",
                  borderRadius: "7px",
                  minHeight: "fit-content",
                  position: "relative",
                }}
              >
                <div
                  id="mycanvas"
                  style={
                    toPrint
                      ? {
                          height: "auto",
                          width: "fit-content",
                          padding: "20px",
                          borderRadius: "7px",
                          backgroundColor: "white",
                        }
                      : {}
                  }
                >
                  <Paper
                    style={
                      toPrint
                        ? {
                            height: "auto",
                            width: "fit-content",
                            padding: "20px",
                            borderRadius: "7px",
                            backgroundColor: "rgba(128, 128, 128, 0.04)",
                          }
                        : {}
                    }
                  >
                    {toPrint && (
                      <div
                        style={{
                          padding: "0 0 30px 0",
                          width: "100%",

                          height: "50px",
                          width: "fit-content",
                          minWidth: "100%",
                          color: primary,
                          fontWeight: "400",
                        }}
                      >{`Trip dispatch date :- ${moment(liveParty.date).format("MMMM Do YYYY")}`}</div>
                    )}

                    <Paper
                      style={{
                        height: "100%",
                        // borderRadius: "5px 5px 0 0",
                        background: "rgb(30 53 89)",
                        height: "70px",
                        width: "fit-content",
                        minWidth: "100%",
                      }}
                    >
                      {" "}
                      {liveParty.total_per_transporter && liveParty.total_per_transporter.length > 0 ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          <div
                            style={{
                              width: "200px",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "30px",
                              display: "flex",
                            }}
                          >
                            <span style={{ color: "white", fontWeight: "500", minWidth: "fit-content" }}>
                              Transpoters Group
                            </span>{" "}
                          </div>
                          {liveParty &&
                            liveParty.total_per_consignee &&
                            liveParty.total_per_consignee.map((singleTrans) => (
                              <div
                                style={
                                  {
                                    width: "250px",
                                    alignItems: "center",
                                    paddingTop: "20px",
                                    marginRight: "20px",
                                  }
                                  //display: "flex",
                                }
                              >
                                {" "}
                                <div
                                  className="textOEllips"
                                  style={{
                                    color: "white",
                                    // justifyContent: "center",
                                    // alignItems: "center",
                                    // display: "flex",
                                    fontWeight: "500",
                                    textTransform: "capitalize",
                                    height: "23.75px",
                                    width: "100%",
                                  }}
                                >
                                  {singleTrans.consignee}
                                </div>{" "}
                                <div
                                  style={{
                                    background: "rgb(59, 105, 176)",
                                    borderRadius: "10px 10px 0 0",
                                    height: "30px",
                                    color: "white",
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "white",
                                      width: "50%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    Trips
                                  </div>
                                  <span
                                    style={{
                                      borderLeft: "1px solid white",
                                      height: "30px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      color: "white",
                                      width: "50%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    MT
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div
                            style={
                              {
                                width: "250px",
                                alignItems: "center",
                                paddingTop: "20px",
                              }
                              //display: "flex",
                            }
                          >
                            {" "}
                            <span
                              style={{
                                color: "white",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                fontWeight: "500",
                              }}
                            >
                              Grand Total
                            </span>{" "}
                            <div
                              style={{
                                background: "white",
                                borderRadius: "10px 10px 0 0",
                                height: "30px",
                                color: "white",
                                width: "100%",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  color: "rgb(59, 105, 176)",
                                  width: "50%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                Trips
                              </div>
                              <span
                                style={{
                                  borderLeft: "1px solid rgb(59, 105, 176)",
                                  height: "30px",
                                }}
                              />
                              <div
                                style={{
                                  color: "rgb(59, 105, 176)",
                                  width: "50%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                }}
                              >
                                MT
                              </div>
                            </div>
                          </div>{" "}
                          {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  <ThreeDotIcon />
                </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </Paper>
                    {/* middle white portion */}
                    <Paper
                      style={{
                        borderRadius: "0px",
                        width: "fit-content",
                        minWidth: "100%",
                      }}
                    >
                      {liveParty && liveParty.total_per_transporter && liveParty.total_per_transporter.length > 0 ? (
                        liveParty &&
                        liveParty.total_per_transporter &&
                        liveParty.total_per_transporter.map((singleTrans) => (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              background: "#8080800a",
                            }}
                          >
                            <div
                              style={{
                                width: "200px",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingTop: "30px",
                                display: "flex",
                                padding: "20px 0",
                              }}
                            >
                              <div
                                style={{
                                  background: "white",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  width: "60%",
                                  display: "flex",
                                  justifyContent: "center",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                              >
                                {singleTrans.transporter_group}
                              </div>
                            </div>

                            {liveParty &&
                              liveParty.total_per_consignee &&
                              liveParty.total_per_consignee.map((single_dispatch_details) => (
                                <div
                                  style={{
                                    width: "250px",
                                    borderRight: "2px solid rgb(59 105 176)",
                                    borderLeft: "2px solid rgb(59 105 176)",
                                    background: "white",
                                    // boxShadow:
                                    //   " 0 0 3px 0px rgba(31, 73, 125, 0.8)",
                                    // clipPath: "inset(0px -15px 0px -15px)",

                                    display: "flex",
                                    marginRight: "20px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{
                                        width: "50%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      {liveParty.dispatch_details
                                        .filter((data) => data.consignee === single_dispatch_details.consignee)
                                        .filter(
                                          (single_dispatch_details) =>
                                            singleTrans.transporter_group ===
                                            single_dispatch_details.transporter_group_name
                                        )
                                        ? liveParty.dispatch_details
                                            .filter((data) => data.consignee === single_dispatch_details.consignee)
                                            .filter(
                                              (single_dispatch_details) =>
                                                singleTrans.transporter_group ===
                                                single_dispatch_details.transporter_group_name
                                            ).length > 0
                                          ? liveParty.dispatch_details
                                              .filter((data) => data.consignee === single_dispatch_details.consignee)
                                              .filter(
                                                (single_dispatch_details) =>
                                                  singleTrans.transporter_group ===
                                                  single_dispatch_details.transporter_group_name
                                              )[0].total_trips !== null &&
                                            liveParty.dispatch_details
                                              .filter((data) => data.consignee === single_dispatch_details.consignee)
                                              .filter(
                                                (single_dispatch_details) =>
                                                  singleTrans.transporter_group ===
                                                  single_dispatch_details.transporter_group_name
                                              )[0].total_trips !== "null"
                                            ? liveParty.dispatch_details
                                                .filter((data) => data.consignee === single_dispatch_details.consignee)
                                                .filter(
                                                  (single_dispatch_details) =>
                                                    singleTrans.transporter_group ===
                                                    single_dispatch_details.transporter_group_name
                                                )[0].total_trips
                                            : "-"
                                          : "-"
                                        : "-"}
                                    </div>

                                    <div
                                      style={{
                                        width: "50%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                      }}
                                    >
                                      {liveParty.dispatch_details
                                        .filter((data) => data.consignee === single_dispatch_details.consignee)
                                        .filter(
                                          (single_dispatch_details) =>
                                            singleTrans.transporter_group ===
                                            single_dispatch_details.transporter_group_name
                                        )
                                        ? liveParty.dispatch_details
                                            .filter((data) => data.consignee === single_dispatch_details.consignee)
                                            .filter(
                                              (single_dispatch_details) =>
                                                singleTrans.transporter_group ===
                                                single_dispatch_details.transporter_group_name
                                            ).length > 0
                                          ? liveParty.dispatch_details
                                              .filter((data) => data.consignee === single_dispatch_details.consignee)
                                              .filter(
                                                (single_dispatch_details) =>
                                                  singleTrans.transporter_group ===
                                                  single_dispatch_details.transporter_group_name
                                              )[0].total_net_weight !== null &&
                                            liveParty.dispatch_details
                                              .filter((data) => data.consignee === single_dispatch_details.consignee)
                                              .filter(
                                                (single_dispatch_details) =>
                                                  singleTrans.transporter_group ===
                                                  single_dispatch_details.transporter_group_name
                                              )[0].total_net_weight !== "null"
                                            ? Number(
                                                liveParty.dispatch_details
                                                  .filter(
                                                    (data) => data.consignee === single_dispatch_details.consignee
                                                  )
                                                  .filter(
                                                    (single_dispatch_details) =>
                                                      singleTrans.transporter_group ===
                                                      single_dispatch_details.transporter_group_name
                                                  )[0].total_net_weight
                                              ).toFixed(3)
                                            : "-"
                                          : "-"
                                        : "-"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      border: "1px solid #8080800a",
                                    }}
                                  />{" "}
                                </div>
                              ))}

                            <div
                              style={{
                                width: "250px",
                                border: "1px solid rgb(59 105 176)",
                                background: "rgb(59 105 176)", //boxShadow: "0 0 1px 1px white",
                                display: "flex",
                              }}
                            >
                              <div style={{ width: "100%", display: "flex" }}>
                                <div
                                  style={{
                                    width: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    color: "white",
                                  }}
                                >
                                  {singleTrans.total_trips}
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    color: "white",
                                  }}
                                >
                                  {" "}
                                  {Number(singleTrans.total_net_weight).toFixed(3)}
                                </div>
                              </div>
                              <div style={{ border: "1px solid #8080800a" }} />
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                          <span style={{ fontSize: "1rem", margin: "50px 0" }}>No trips yet !</span>
                        </div>
                      )}
                    </Paper>
                    {/* lower light blue portion */}
                    <Paper
                      style={{
                        height: "100%",
                        borderRadius: "0",
                        background: "#3b69b0",
                        height: "50px",
                        width: "fit-content",
                        minWidth: "100%",
                      }}
                    >
                      {liveParty.total_per_transporter && liveParty.total_per_transporter.length > 0 ? (
                        <div style={{ width: "100%", display: "flex" }}>
                          <div
                            style={{
                              width: "200px",
                              justifyContent: "center",
                              alignItems: "center",
                              paddingTop: "10px",
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                background: "white",
                                borderRadius: "5px",
                                padding: "6px 0px",
                                width: "60%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "rgb(59 105 176)",
                                fontWeight: "500",
                              }}
                            >
                              Grand Total
                            </div>
                          </div>
                          {liveParty &&
                            liveParty.total_per_consignee &&
                            liveParty.total_per_consignee.map((singleTrans) => (
                              <div
                                style={{
                                  width: "250px",
                                  display: "flex",
                                  margin: "0 20px 0 0",
                                }}
                              >
                                <div style={{ width: "100%", display: "flex" }}>
                                  <div
                                    style={{
                                      width: "50%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                      color: "white",
                                      padding: "10px 0 0 0",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {singleTrans.total_trips ? singleTrans.total_trips : "-"}
                                  </div>
                                  <div
                                    style={{
                                      width: "50%",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      display: "flex",
                                      color: "white",
                                      padding: "10px 0 0 0",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {singleTrans.total_net_weight
                                      ? Number(singleTrans.total_net_weight).toFixed(3)
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            ))}
                          <div style={{ width: "250px", display: "flex" }}>
                            <div style={{ width: "100%", display: "flex" }}>
                              <div
                                style={{
                                  width: "50%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  color: "white",
                                  padding: "10px 0 0 0",
                                  fontWeight: "500",
                                }}
                              >
                                {total_trips}
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  color: "white",
                                  padding: "10px 0 0 0",
                                  fontWeight: "500",
                                }}
                              >
                                {total_weight}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </Paper>
                    {toPrint && (
                      <div
                        className="d-flex justifyFE"
                        style={{
                          height: "50px",
                          width: "100%",
                          padding: "30px 0 0 0",
                          minWidth: "100%",
                          color: primary,
                          fontWeight: "400",
                        }}
                      >{`Summary generated at :- ${moment(new Date()).format("MMMM Do YYYY, h:mm A")}`}</div>
                    )}
                  </Paper>
                </div>
              </div>
              {/* <div className=" d-flex justifyFE marginTB20" style={{ width: "100%" }}>
                                <StyledButton name={"FINALISE & SUBMIT"} withBg={true} width="210px" />
                            </div>  */}
            </>
          ) : (
            <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
              <Paper
                style={{
                  height: "100%",
                  borderRadius: "5px 5px 0 0",
                  background: "rgb(30 53 89)",
                  height: "70px",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              />{" "}
              <Paper
                style={{
                  borderRadius: "0px",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              >
                {" "}
                <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                  <span style={{ fontSize: "1rem", margin: "50px 0" }}>No trips yet !</span>
                </div>
              </Paper>
              <Paper
                style={{
                  height: "100%",
                  borderRadius: "0",
                  background: "#3b69b0",
                  height: "50px",
                  width: "fit-content",
                  minWidth: "100%",
                }}
              />
            </div>
          )}
        </Paper>
      </div>
    );
  }
}

export default SummaryTable;
