import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white } from "../styles/colors";
import Button from "@material-ui/core/Button";
import { Paper, InputBase, Avatar } from "@material-ui/core";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import NoData from "../styles/Illustrations/NoData";
import Toggle from "./Toggle";
import SnackbarComponent from "./SnackbarComponent";
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  withDivider50: { width: "calc(50% - 5px)" },
  withDivider25: { width: "25%" },
};
const templateTabs = [
  {
    label: "Drivers",
    tooltip: "Click to approve pending users",
    data: [],
    route: "/driversDetails/cities",
    primaryField: "name",
    primarySecondValue: "created_at",

    secondaryField: "is_active",
    avatarField: "src",

    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class Drivers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: templateTabs,
      user: null,
      singleDestinationlist: null,
      SalesOrder: "",
      destinationCities: [],
      addNewDestination: false,
      users: [],
      Name: "",
      colorArr: [white, lightGrey],
      success: "",
      error: "",
      status: false,
    };
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      console.log(next.match.params["tab"], "singleDestinationlistyyyyyyyyyy");
      let tab = next.match.params["tab"];
      if (tab === "Drivers" && next.match.params.hasOwnProperty("id")) {
        console.log(
          this.state.tabs[0].data[next.match.params["id"]],
          "singleDestinationlistyyyyyyyyyy"
        );
        this.setState(
          {
            singleDestinationlist: this.state.tabs[0].data[
              next.match.params["id"]
            ],
            Name: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].city
              : "",
            addNewDestination: false,
            status: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].is_active
              : "",
          },
          () => {
            console.log(
              this.state.singleDestinationlist,
              "singleDestinationlistyyyyyyyyyy"
            );
          }
        );
      } else if (tab === "new") {
        this.setState({
          singleDestinationlist: null,
          Name: "",
          addNewDestination: true,
        });
      }
    } else this.setState({ singleDestinationlist: null });
  }

  listClickHandler = (value) => {
    console.log(value, "annuu11");
    this.setState({
      addNewDestination: false,
      singleDestinationlist: true,
      Name: value.city,
    });
    this.props.fetchDestinationCities(value.id);
  };
  setInitialValues(props) {
    console.log(props.drivers, " props.drivers");
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = []; // props.drivers;//ye cmnt ki bs
      return tab;
    });
    console.log(tabs, "tabs");
    this.setState({ drivers: props.drivers, tabs });
  }
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleClearFields = () => {
    this.setState({ Name: "" });
  };
  render() {
    const {
      tabs,
      destinationCities,
      addNewDestination,
      singleDestinationlist,
      Name,
      status,
      success,
      error,
    } = this.state;
    return (
      <Layout
        tabs={tabs}
        noList={true}
        listClickHandler={
          this.listClickHandler // }} //   this.props.history.push("/dashboard/new"); // fabClickHandler={() => {
        }
      >
        <Paper style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: "1rem",
              color: "grey",
              background: "#d3d3d35c",
            }}
          >
            <NoData />
          </div>
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(Drivers);
