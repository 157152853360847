import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import DrawerContents from "../components/layoutComponents/DrawerContents";
import RightIcon from "../components/layoutComponents/RightIcon";

import clsx from "clsx";
import { Download, MoreVertical, Reload, Share } from "../styles/Icons";
import { Popover, ClickAwayListener, List, ListItem } from "@material-ui/core";
import { primary } from "../styles/colors";
const drawerWidth = 100;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: { padding: "0" },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    zIndex: "10000",
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
    color: "white",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "transparent linear-gradient(1deg, #3B69B0 0%, #1E3558 100%) 0% 0% no-repeat padding-box",
    color: "white",
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
}));

function ButtonAppBar(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const [open, setopen] = React.useState(false);

  const [anchorEl, setanchorEl] = React.useState(null);

  const toggleDrawer = (open) => (e) => {
    if (e.type === "keydown" && (e.key === "Tab" || e.key === "Shift")) return;
    setState(open);
  };
  const MenuList = [
    // {
    //   name: "Share",
    //   icon: <Share color={primary} height="20" width="20" />,
    //   onClick: props.ShareImage,
    // },
    {
      name: "Download",
      icon: <Download color={primary} height="20" width="20" />,
      onClick: props.DownloadImage,
    },
    {
      name: "Refresh",
      icon: <Reload color={primary} height="20" width="20" />,
      onClick: () => {
        localStorage.setItem("loadedAt", new Date());
        window.location.reload();
      },
    },
  ];
  const handlePopoverClose = () => {
    handleRequestClose();
  };
  const handleTouchTap = (event) => {
    setopen(true);
    setanchorEl(event.currentTarget);
  };
  const handleRequestClose = () => {
    setopen(false);
    setanchorEl(null);
  };
  return (
    <div className={classes.root}>
      <Popover
        open={open}
        onRequestClose={handleRequestClose}
        anchorEl={anchorEl}
        style={{ marginTop: "-2.5%" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ClickAwayListener onClickAway={() => handlePopoverClose()}>
          <List>
            {MenuList &&
              MenuList.map((SingleMenu) => (
                <ListItem button className={classes.listItemsStyle} onClick={SingleMenu.onClick}>
                  {" "}
                  {SingleMenu.icon}
                  <p
                    style={
                      { margin: "0 0 0 15px", fontSize: "1rem", height: "24px", display: "flex", alignItems: "center" } // color: grey,
                    }
                  >
                    {SingleMenu.name}
                  </p>
                </ListItem>
              ))}
          </List>
        </ClickAwayListener>
      </Popover>
      <AppBar
        position="static"
        style={{ height: "60px", borderRadius: "0 0 14px 14px", flexDirection: "row" }}
        className="d-flex alignC justifyC"
      >
        <div className="d-flex alignC d-flex-column justifyC" style={{ width: "calc(100% - 24px)" }}>
          <div
            className="d-flex justifyC alignC width-100"
            style={{ fontSize: "1rem", textTransform: "uppercase", fontWeight: "bold" }}
          >
            {props.title && props.title}
          </div>
          <div
            className="d-flex justifyC alignC width-100"
            style={{ height: "20px", fontSize: "0.7rem", textTransform: "uppercase", fontWeight: "bold" }}
          >
            {props.subTitle && props.subTitle}
          </div>
        </div>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={(e) => {
            handleTouchTap(e);
          }}
        >
          <MoreVertical />
        </IconButton>
      </AppBar>
    </div>
  );
}

export default ButtonAppBar;
