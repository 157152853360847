import React from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import { primary, green } from "../../styles/colors"

export default function SnackbarComponent(props) {
    return (
        <Snackbar
            anchorOrigin={{
                vertical: props.center ? "center" : "bottom",
                horizontal: props.center
                    ? "center"
                    : props.success || props.error
                        ? "right"
                        : "center",
            }}
            open={true}
            autoHideDuration={2000}
            onClose={() => props.handleCloseSnackbar()}
        >
            <SnackbarContent
                style={{
                    backgroundColor: props.success
                        ? green
                        : props.error
                            ? "#DB0011"
                            : props.adminRequestPage
                                ? "grey"
                                : primary,
                }}
                message={
                    <span id="message-id" style={{ color: "white" }}>
                        {props.message ? props.message : "updated successfully"}
                    </span>
                }
            />
        </Snackbar>
    );
}
