import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white } from "../styles/colors";
import { CssFilledSelectTextField, CssTextField, StyledButton } from "../styles/StyledComponents";

import domtoimage from "dom-to-image";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {
  Paper,
  InputBase,
  Avatar,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Button,
  Divider,
  AppBar,
  ListItem,
  List,
  Box,
  Typography,
  ClickAwayListener,
  Popover,
  Backdrop,
} from "@material-ui/core";
import NoData from "../styles/Illustrations/NoData";
import { CancelIcon, SearchIcon, PlusIcon, CrossIcon } from "../styles/Icons";
import ReactDOM from "react-dom";
import moment from "moment";
import { StyledCircularProgress } from "../styles/StyledComponents";
import { CheckCircle, MoreHorizOutlined } from "@material-ui/icons";
import SalesOrderMobileView from "./SalesOrderMobileView";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",

    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
  withDivider50: { width: "calc(40% - 5px)" },
  withDivider25: { width: "20%" },
};

const templateTabs = [
  {
    //label: "Sales & Payment",
    tooltip: "Click to approve pending users",
    data: [],
    route: "/salesorders/Details",
    primaryField: "short_name",
    primarySecondValue: "total_pending_quantity",
    // primaryFieldFallback: "total_trips", // Optional
    // secondaryField: "total_open_sales_order",
    primaryFieldThirdFallback: "total_open_sales_order",
    avatarField: "src",
    dispatchList: true,
    salesList: true,
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class SalesOrder extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.state = {
      tabs: templateTabs,
      colorArr: [white, lightGrey],
      singleDate: null,
      singleSalesOrderList: false,
      salesOrders: "",
      sourceCities: [],
      addNewSourceCities: false,
      users: [],
      Name: "",
      validity: 1,
      colorArr: [white, lightGrey],
      error: "",
      success: "",
      editSourceCities: false,
      openMessage: true,
      anchorEl: null,
      open: false,
      fieldName: [
        "quantity",
        "status",
        "payment_status",
        "sales_order_number",
        "start_date",
        "GradeName",
        // "consignee",
        // "item", "grade",
        "rate",
        "gst",
        "tcs",
        "validity",
      ],
      SearchBarValue: "",
      total_quantity: 0,
      total_dispatch: 0,
      errorMsg: {
        validity: false,
        ConsigneeName: false,
        ItemName: false,
        GradeName: false,
        Rate: false,
        GST: false,
        TCS: false,
        validity: false,
      },
      openDialog: false,
      itemsGradeId: "",
      paymentStatusList: ["pending", "completed"],
      salesStatusList: ["open", "closed"],
      salesStatusListToFilter: ["all", "open", "closed"],
      selectedFilter: "all",
      paymentStatus: "pending",
      salesStatus: "open",
      singlesalesdate: [],
      singlesalesdateCopy: [],
      singleSelectedSales: null,
      salesEdit: false,
      singleselectedConsignee: "",
      downloding: false,
    };
  }

  componentWillMount() {
    const next = this.props;
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Details" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singleDate: this.state.tabs[0].data[next.match.params["id"]],

            singleselectedConsignee: this.state.tabs[0].data[next.match.params["id"]],
          },
          () => {
            if (this.state.fetched === false) {
              this.props.fetchSalesOrdersDate(this.state.ConsigneeId, this.props.user.user.uid);
              this.setState({ fetched: true, openMessage: false });
              this.setState({ ConsigneeName: this.state.singleDate.name, ConsigneeId: this.state.ConsigneeId });
            }
          }
        );
      } else if (tab === "new") {
        this.setState({
          singleDate: null,
          ConsigneeName: "",
          ConsigneeId: "",
        });
      }
    } else {
      this.setState({ singleDate: null });
    }
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }

  listClickHandler = (value) => {
    localStorage.setItem("selectedDate", JSON.stringify(value.date));
    console.log(value, "wwwwwwwwwww");
    this.setState(
      {
        singleselectedConsignee: value,
        ConsigneeName: value.name,
        ConsigneeId: value.id,
        singlesalesdate: [],
        singlesalesdateCopy: [],
        singleSalesOrderList: true,
      },
      () => {
        this.props.fetchSalesOrdersDate(this.state.ConsigneeId, this.props.user.user.uid);
      }
    );
  };
  setEditData = (singleSales) => {
    const { itemsGradelist, itemNamelist } = this.state;
    this.setState({
      itemsId: singleSales.item,
      ItemName:
        itemNamelist &&
        itemNamelist.filter((data) => data.id === singleSales.item).length > 0 &&
        itemNamelist.filter((data) => data.id === singleSales.item)[0] &&
        itemNamelist.filter((data) => data.id === singleSales.item)[0].name,
      GradeName:
        itemsGradelist &&
        itemsGradelist.filter((data) => data.id === singleSales.grade).length > 0 &&
        itemsGradelist.filter((data) => data.id === singleSales.grade)[0] &&
        itemsGradelist.filter((data) => data.id === singleSales.grade)[0].name,
      paymentStatus: singleSales.payment_status,
      salesStatus: singleSales.status,
      Quantity: singleSales.quantity,
      Rate: singleSales.rate,
      Status: singleSales.status,
      GST: singleSales.gst,
      TCS: singleSales.tcs,
      validity: singleSales.validity,
      itemsGradeId: singleSales.grade,
    });
  };
  handleTouchTap = (event, id) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      singleSelectedSales: id,
    });
  };
  handlePopoverClose = () => {
    this.handleRequestClose();
  };
  handleRequestClose = () => {
    this.setState({ open: false, anchorEl: null });
  };

  handleExportWithFunction = (event) => {
    savePDF(this.myRef.current, { paperSize: "A2" });
    this.setState({ downloding: false });
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;

      tab.data = props.consignees && props.consignees.length > 0 ? props.consignees : [];
      return tab;
    });
    const arr = props.salesOrders && props.salesOrders.length > 0 ? props.salesOrders.sort((a, b) => b.id - a.id) : [];
    const newArr = [];
    const total_quantity = [];
    const total_dispatch = [];
    if (props.salesOrders && props.salesOrders.length > 0) {
      const { itemNamelist, itemsGradelist } = this.state;

      arr.map((datas) => {
        datas.ItemName =
          itemNamelist &&
          itemNamelist.filter((data) => data.id === datas.item).length > 0 &&
          itemNamelist.filter((data) => data.id === datas.item)[0] &&
          itemNamelist.filter((data) => data.id === datas.item)[0].name;
        datas.GradeName =
          itemsGradelist &&
          itemsGradelist.filter((data) => data.id === datas.grade).length > 0 &&
          itemsGradelist.filter((data) => data.id === datas.grade)[0] &&
          itemsGradelist.filter((data) => data.id === datas.grade)[0].name;
        newArr.push(datas);
        total_quantity.push(Number(datas.quantity));

        total_dispatch.push(Number(datas.dispatched_quantity));
      });
    }
    if (this.state.selectedFilter !== "all") {
      const newArr2 = newArr.filter((sSale) => sSale.status === this.state.selectedFilter);
      this.setState({
        singlesalesdate: newArr2.sort((a, b) => b.id - a.id),
      });
    } else {
      this.setState({
        singlesalesdate: newArr.sort((a, b) => b.id - a.id),
      });
    }
    this.setState(
      {
        total_quantity: total_quantity.reduce((a, b) => Math.floor(a + b), 0),
        total_dispatch: total_dispatch.reduce((a, b) => Math.floor(a + b), 0),
        consignees: props.consignees,
        singlesalesdateCopy: newArr.sort((a, b) => b.id - a.id),
        tabs,
        consigneeslist: props.consignees,
        itemNamelist: props.items,
        itemsGradelist: props.items_grade,
        loading: props.loading,
      },
      () => {}
    );
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value, errorMsg });
  };

  handleClearFields = () => {
    this.setState({
      ItemName: "",
      itemsId: "",
      GradeName: "",
      paymentStatus: "pending",
      salesStatus: "open",
      Quantity: "",
      Rate: "",
      Status: "",
      GST: "",
      TCS: "",
      validity: 1,
      itemsGradeId: "",
      salesEdit: false,
      errorMsg: {
        validity: false,
        ConsigneeName: false,
        ItemName: false,
        GradeName: false,
        Rate: false,
        GST: false,
        TCS: false,
        validity: false,
      },
      success: "",
    });
  };
  downloadImage = (fileName) => {
    this.setState({ downloding: true }, () => {
      domtoimage
        .toJpeg(document.getElementById("mycanvas"), { quality: 0.95 })
        .then(function(dataUrl) {
          var link = document.createElement("a");
          link.download = fileName ? `${fileName}.jpeg` : "image.jpeg";
          link.href = dataUrl;
          link.click();
        })
        .then(() => {
          this.setState({ downloding: false });
        });
    });
  };
  render() {
    const {
      tabs,
      itemsGradeId,
      users,
      singleSalesOrderList,
      GradeName,
      paymentStatusList,
      salesStatusList,
      salesStatusListToFilter,
      selectedFilter,
      paymentStatus,
      salesStatus,
      GST,
      TCS,
      downloding,
      validity,
      itemsGradelist,
      itemNamelist,
      salesEdit,
      sales_order_number,
      ItemName,
      itemsId,
      openDialog,
      ConsigneeName,
      singleselectedConsignee,
      ConsigneeId,
      loading,
      consigneeslist,
      Quantity,
      Rate,
      Status,
      errorMsg,
      success,
      formattedDate,
      singleDateTrips,
      singlesalesdate,
      singlesalesdateCopy,
      total_quantity,
      total_dispatch,
      colorArr,
      fieldName,
    } = this.state;
    const { consignees, salesOrders, isMobile } = this.props;
    return isMobile ? (
      <SalesOrderMobileView
        {...this.state}
        {...this.props}
        fetchSalesOrdersDate={this.props.fetchSalesOrdersDate}
        fetchSalesOrdersDaySummary={this.props.fetchSalesOrdersDaySummary}
        // fetchSingleDateDetails={this.props.fetchSingleDateDetails}
      />
    ) : (
      <Layout
        loading={loading.consignees}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={{
          data: consigneeslist,
          hintText: "Search Consignee",
          labelField: "short_name",
        }}
        listClickHandler={this.listClickHandler}
        serchClick={this.listClickHandler}
        centerWidth="230px"
        openWidth="521.46px"
        closeWidth="287px"
      >
        {" "}
        <Popover
          open={this.state.open}
          onRequestClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
          style={{ marginTop: "-2.5%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <ClickAwayListener onClickAway={() => this.handlePopoverClose()}>
            <List>
              <ListItem
                button
                onClick={() => {
                  this.setEditData(this.state.singleSelectedSales);
                  this.setState({
                    openDialog: true,
                    salesEdit: true,
                  });
                }}
                style={styles.listItemsStyle}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "0.9rem", // color: grey,
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Edit
                </p>
              </ListItem>
            </List>
          </ClickAwayListener>
        </Popover>{" "}
        {downloding ? (
          <Backdrop style={{ zIndex: 1, color: "#fff" }} open={true}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}{" "}
        {openDialog == true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "5px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "517.98px";

                target.style.height = "517.98px";
                target.style.minWidth = "80%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({ openDialog: false });
              this.handleClearFields();
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                //display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.3rem",
                background: "rgb(30 53 89)",
                padding: "10px 24px",
              }}
            >
              <DialogActions
                classname="d-flex justifySB"
                style={
                  { background: primary, color: "white", padding: "0" }
                  //padding: "10px 24px",
                }
                onClick={() => {
                  this.setState({ openDialog: false });
                  this.handleClearFields();
                }}
              >
                <span
                  style={{
                    color: "white",
                    width: "calc(100% - 32.8px)",
                  }}
                >
                  {salesEdit ? "Edit Sales & Order" : "Add Sales & Order"}
                </span>

                <Tooltip title="close" arrow placement="top">
                  <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                    <CrossIcon strokeWidth="2" />
                  </IconButton>
                </Tooltip>
              </DialogActions>{" "}
            </div>
            {console.log(downloding, "downloding")}

            {success ? (
              <div
                style={{
                  position: "relative",

                  padding: "16px 24px",
                  height: "calc(100% - 49.73px)",
                  minHeight: "452.25px",
                }}
              >
                <div
                  className=" width-100 d-flex alignC justifyC d-flex-column"
                  style={{
                    height: "calc(100% - 96.5px)",
                    minHeight: "356.20px",
                  }}
                >
                  <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
                  <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                    {success}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    style={{
                      background: primary,
                      color: "white",
                      width: "200px",
                      position: "absolute",
                      bottom: "16px",
                      right: "24px",
                    }}
                    onClick={() => {
                      this.handleClearFields();
                    }}
                  >
                    Add Another
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  height: "calc(100% - 49.73px)",
                  padding: "16px 24px",
                }}
              >
                <div
                  style={
                    {
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0",
                    } //height: "100%",
                  }
                >
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    disabled
                    value={
                      ConsigneeName ? ConsigneeName : "" //
                    }
                    error={errorMsg.ConsigneeName ? true : false}
                    helperText={errorMsg.ConsigneeName ? errorMsg.ConsigneeName : ""}
                    onChange={(e) => {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.ConsigneeName = false;
                      this.setState({
                        errorMsg,
                        ConsigneeId: e.currentTarget.id,
                        ConsigneeName: e.target.value,
                      });
                    }}
                    style={
                      styles.withDivider50 //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    label="Consignee Name"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {consigneeslist.map((option) => (
                      <MenuItem key={option.name} value={option.name} id={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      ItemName ? ItemName : "" //
                    }
                    error={errorMsg.ItemName ? true : false}
                    helperText={errorMsg.ItemName ? errorMsg.ItemName : ""}
                    onChange={(e) => {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.ItemName = false;
                      this.setState({
                        errorMsg,
                        itemsId: e.currentTarget.id,
                        ItemName: e.target.value,
                      });
                    }}
                    style={
                      { width: "calc(40% - 5px)", margin: "0 25px" } //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    label="Item Name"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {itemNamelist.map((option) => (
                      <MenuItem key={option.name} value={option.name} id={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      GradeName ? GradeName : "" //
                    }
                    error={errorMsg.GradeName ? true : false}
                    helperText={errorMsg.GradeName ? errorMsg.GradeName : ""}
                    onChange={(e) => {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.GradeName = false;
                      this.setState({
                        errorMsg,
                        itemsGradeId: e.currentTarget.id,
                        GradeName: e.target.value,
                      });
                    }}
                    style={
                      styles.withDivider50 //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    label="Grade Name"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {itemsGradelist.map((option) => (
                      <MenuItem key={option.name} value={option.name} id={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "1rem",
                    color: "grey",
                    background: "white",
                    margin: "30px 0",
                  }}
                >
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    style={
                      styles.withDivider50 //disabled={tp}
                    }
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "-" || evt.key === "." || evt.key === "e" || evt.key === "E") && evt.preventDefault()
                    }
                    id="outlined-basic"
                    label="Quantity"
                    name="Quantity"
                    variant="outlined"
                    value={
                      Quantity // helperText={errorMsg.Quantity ? "Please Add Quantity!" : false} // error={errorMsg.Quantity ? true : false}
                    }
                    error={errorMsg.Quantity ? true : false}
                    helperText={errorMsg.Quantity ? errorMsg.Quantity : ""}
                    onChange={this.handleChange}
                  />

                  <CssTextField
                    type="number"
                    required={true}
                    onKeyDown={(evt) =>
                      (evt.key === "-" || evt.key === "." || evt.key === "e" || evt.key === "E") && evt.preventDefault()
                    }
                    autoComplete="off"
                    style={
                      { width: "calc(40% - 5px)", margin: "0 30px" } //disabled={tp}
                    }
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    id="outlined-basic"
                    label="Rate"
                    name="Rate"
                    variant="outlined"
                    error={errorMsg.Rate ? true : false}
                    helperText={errorMsg.Rate ? errorMsg.Rate : ""}
                    value={
                      Rate // helperText={errorMsg.Rate ? "Please Add Rate!" : false} // error={errorMsg.Rate ? true : false}
                    }
                    onChange={this.handleChange}
                  />
                  <CssTextField
                    autoComplete="off"
                    style={styles.withDivider50}
                    id="outlined-basic"
                    label="Validity"
                    name="validity"
                    variant="outlined"
                    type="number"
                    error={errorMsg.validity ? true : false}
                    helperText={errorMsg.validity ? errorMsg.validity : ""}
                    onKeyDown={(evt) =>
                      (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") && evt.preventDefault()
                    }
                    value={
                      Number(validity).toFixed(0) // helperText={errorMsg.Rate ? "Please Add Rate!" : false} // error={errorMsg.Rate ? true : false}
                    }
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                      endAdornment: validity ? (validity > 1 ? "Days" : "Day") : "",
                    }}
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "1rem",
                    color: "grey",
                    background: "white",
                    margin: "30px 0",
                  }}
                >
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    style={
                      styles.withDivider50 //disabled={tp}
                    }
                    type="number"
                    onKeyDown={(evt) =>
                      (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") && evt.preventDefault()
                    }
                    id="outlined-basic"
                    label="TCS"
                    name="TCS"
                    variant="outlined"
                    value={
                      TCS // helperText={errorMsg.Quantity ? "Please Add Quantity!" : false} // error={errorMsg.Quantity ? true : false}
                    }
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                      endAdornment: "%",
                    }}
                    error={errorMsg.TCS ? true : false}
                    helperText={errorMsg.TCS ? errorMsg.TCS : ""}
                    onChange={this.handleChange}
                  />

                  <CssTextField
                    autoComplete="off"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    required={true}
                    onKeyDown={(evt) =>
                      (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") && evt.preventDefault()
                    }
                    style={
                      { width: "calc(40% - 5px)", margin: "0 30px" } //disabled={tp}
                    }
                    id="outlined-basic"
                    label="GST"
                    name="GST"
                    variant="outlined"
                    value={
                      GST // helperText={errorMsg.Rate ? "Please Add Rate!" : false} // error={errorMsg.Rate ? true : false}
                    }
                    InputProps={{
                      endAdornment: "%",
                    }}
                    error={errorMsg.GST ? true : false}
                    helperText={errorMsg.GST ? errorMsg.GST : ""}
                    onChange={this.handleChange}
                  />
                  <CssTextField
                    autoComplete="off"
                    disabled={salesEdit ? false : true}
                    value={
                      salesStatus ? salesStatus : "" //
                    }
                    required={true}
                    onChange={(e) => {
                      this.setState({ salesStatus: e.target.value });
                    }}
                    style={
                      styles.withDivider50 //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    label="Status"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {salesStatusList.map((option) => (
                      <MenuItem key={option} value={option} id={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "1rem",
                    color: "grey",
                    background: "white",
                    margin: "30px 0",
                  }}
                >
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    disabled
                    value={
                      paymentStatus ? paymentStatus : "" //
                    }
                    onChange={(e) => {
                      this.setState({ paymentStatus: e.target.value });
                    }}
                    style={{ width: "calc(32% - 5px)" }}
                    id="outlined-select-currency-native"
                    select
                    label="Payment Status"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {paymentStatusList.map((option) => (
                      <MenuItem key={option} value={option} id={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>

                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    style={{
                      background: primary,
                      color: "white",
                      width: "120px",
                      position: "absolute",
                      bottom: "16px",
                      right: "24px",
                    }}
                    onClick={() => {
                      if (ConsigneeName) {
                        if (ItemName) {
                          if (GradeName) {
                            if (Quantity) {
                              if (Rate) {
                                if (GST) {
                                  if (TCS) {
                                    if (validity) {
                                      if (!salesEdit) {
                                        const addData = {
                                          consignee: ConsigneeId,
                                          item: itemsId,
                                          grade: itemsGradeId,
                                          quantity: Quantity,
                                          rate: Rate,
                                          status: salesStatus,
                                          gst: GST,
                                          tcs: TCS,
                                          validity: validity,
                                          payment_status: paymentStatus,
                                        };
                                        this.props.salesorderAdd(addData, this.props.user.user.uid).then(() => {
                                          this.props
                                            .fetchSalesOrdersDate(this.state.ConsigneeId, this.props.user.user.uid)
                                            .then((res) => {
                                              this.props.fetchConsignees(this.props.user.user.uid).then((resres) => {
                                                if (resres) {
                                                  const data = resres.filter((datas) => datas.id === ConsigneeId)[0];

                                                  this.props.setSalesOrders(resres);
                                                  this.setState({
                                                    singleselectedConsignee: data,
                                                    success: "Added successfully !",
                                                    // salesOrders: res,
                                                    editVehicles: false,
                                                    openMessage: true,
                                                  });
                                                }
                                              });
                                            });
                                        });
                                      } else {
                                        const editData = {
                                          consignee: ConsigneeId,
                                          item: itemsId,
                                          grade: itemsGradeId,
                                          quantity: Quantity,
                                          rate: Rate,
                                          status: salesStatus,
                                          gst: GST,
                                          tcs: TCS,
                                          validity: validity,
                                          payment_status: paymentStatus,
                                        };
                                        this.props
                                          .salesorderEdit(
                                            editData,
                                            this.state.singleSelectedSales.id,
                                            this.props.user.user.uid
                                          )
                                          .then(() => {
                                            this.props
                                              .fetchSalesOrdersDate(this.state.ConsigneeId, this.props.user.user.uid)
                                              .then((res) => {
                                                this.props.fetchConsignees(this.props.user.user.uid).then((resres) => {
                                                  if (resres) {
                                                    const data = resres.filter((data) => data.id === ConsigneeId)[0];

                                                    this.props.setSalesOrders(resres);
                                                    this.setState({
                                                      singleselectedConsignee: data,
                                                      success: "Edited successfully !",
                                                      salesOrders: res,
                                                      editVehicles: false,
                                                      openMessage: false,
                                                    });
                                                  }
                                                });
                                              });
                                          });
                                      }
                                    } else {
                                      var errorMsg = this.state.errorMsg;
                                      errorMsg.validity = "Please give an validity!";
                                      this.setState({ errorMsg });
                                    }
                                  } else {
                                    var errorMsg = this.state.errorMsg;
                                    errorMsg.TCS = "Please give an TCS!";
                                    this.setState({ errorMsg });
                                  }
                                } else {
                                  var errorMsg = this.state.errorMsg;
                                  errorMsg.GST = "Please give an GST!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                var errorMsg = this.state.errorMsg;
                                errorMsg.Rate = "Please give an rate!";
                                this.setState({ errorMsg });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.Quantity = "Please give an quantity!";
                              this.setState({ errorMsg });
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.GradeName = "Please select an grade!";
                            this.setState({ errorMsg });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.ItemName = "Please select an item!";
                          this.setState({ errorMsg });
                        }
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.ConsigneeName = "Please select a consignee!";
                        this.setState({ errorMsg });
                      }
                    }}
                  >
                    {salesEdit ? "Edit" : "Add"}
                  </Button>
                </div>
              </div>
            )}
          </Dialog>
        ) : (
          ""
        )}
        {singleSalesOrderList == true ? (
          <Paper
            style={{
              width: "100%",
              height: "100%",
              padding: "15px",
              position: "relative",
            }}
          >
            {" "}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Paper style={styles.paperStyle} elevation={3} component="form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 12px",
                  }}
                >
                  <SearchIcon color="rgb(16, 15, 15)" />
                </div>
                <InputBase
                  onChange={(event) => {
                    this.setState(
                      {
                        SearchBarValue: event.target.value,
                      },
                      () => {
                        const filterData = [];
                        if (this.state.SearchBarValue !== "") {
                          singlesalesdateCopy.map((data) => {
                            fieldName.map((singleField) => {
                              if (
                                (singleField === "start_date" &&
                                  data[singleField] &&
                                  moment(data[singleField])
                                    .format("DD/MM/YY")
                                    .toString()
                                    .toLowerCase()
                                    .includes(this.state.SearchBarValue.toString().toLowerCase())) ||
                                (data[singleField] &&
                                  data[singleField]
                                    .toString()
                                    .toLowerCase()
                                    .includes(this.state.SearchBarValue.toString().toLowerCase()))
                              ) {
                                if (!filterData.includes(data)) {
                                  filterData.push(data);
                                }
                              }
                            });
                          });
                          if (this.state.selectedFilter !== "all") {
                            const newArr = filterData.filter((sSale) => sSale.status === selectedFilter);
                            this.setState({
                              singlesalesdate: newArr.sort((a, b) => b.id - a.id),
                            });
                          } else {
                            this.setState({
                              singlesalesdate: filterData.sort((a, b) => b.id - a.id),
                            });
                          }
                        } else {
                          if (this.state.selectedFilter !== "all") {
                            const newArr = singlesalesdateCopy.filter((sSale) => sSale.status === selectedFilter);
                            this.setState({
                              singlesalesdate: newArr.sort((a, b) => b.id - a.id),
                            });
                          } else {
                            this.setState({
                              singlesalesdate: singlesalesdateCopy.sort((a, b) => b.id - a.id),
                            });
                          }
                        }
                      }
                    );
                  }}
                  disabled={singlesalesdateCopy && singlesalesdateCopy.length > 0 ? false : true}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                    }
                  }}
                  style={{
                    fontSize: "0.9rem",
                    width: "100%",
                    color: "rgb(16, 15, 15)",
                  }}
                  placeholder="Search Vehicle "
                  inputProps={{ "aria-label": "search google maps" }}
                  value={this.state.SearchBarValue}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 9.5px",
                    cursor: "pointer",
                  }}
                >
                  {this.state.SearchBarValue ? (
                    <Avatar
                      onClick={(event) => {
                        this.setState(
                          {
                            SearchBarValue: "",
                          },
                          () => {
                            if (this.state.selectedFilter !== "all") {
                              const newArr = singlesalesdateCopy.filter((sSale) => sSale.status === selectedFilter);
                              this.setState({
                                singlesalesdate: newArr.sort((a, b) => b.id - a.id),
                              });
                            } else {
                              this.setState({
                                singlesalesdate: singlesalesdateCopy.sort((a, b) => b.id - a.id),
                              });
                            }
                          }
                        );
                      }}
                      style={
                        {
                          color: "white",
                          backgroundColor: "#BEBCBC",
                          borderRadius: "50%",
                          width: "18px",
                          height: "18px",
                          fontSize: "0.7rem",
                          textAlign: "center",
                          justifyContent: "center",
                        } // }} //   ); //     } //       }); //         singleDateTrips: newData, //       this.setState({ //       newData.Trips = singleDateTripsCopy; //       const newData = singleDateTrips; //     () => { //     }, //       SearchBarValue: "", //     { //   this.setState( // onClick={(event) => {
                      }
                    >
                      <CancelIcon />
                    </Avatar>
                  ) : (
                    ""
                  )}
                </div>
              </Paper>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {" "}
                <div style={{ minWidth: "90px", margin: "0 10px" }}>
                  <CssFilledSelectTextField
                    autoComplete="off"
                    value={
                      selectedFilter ? selectedFilter : "" //
                    }
                    onChange={(e) => {
                      if (e.target.value !== "all") {
                        const newArr = singlesalesdateCopy.filter((sSale) => sSale.status === e.target.value);
                        console.log(newArr, e.target.value, "selectedFilter");
                        this.setState({
                          singlesalesdate: newArr.sort((a, b) => b.id - a.id),
                          selectedFilter: e.target.value,
                        });
                      } else {
                        this.setState({
                          selectedFilter: e.target.value,
                          singlesalesdate: singlesalesdateCopy.sort((a, b) => b.id - a.id),
                        });
                      }
                    }}
                    style={{ width: "100%" }}
                    id="outlined-select-currency-native"
                    select
                    // label="Status"
                    variant="filled"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {salesStatusListToFilter.map((option) => (
                      <MenuItem key={option} value={option} id={option} style={{ textTransform: "capitalize" }}>
                        {option}
                      </MenuItem>
                    ))}
                  </CssFilledSelectTextField>
                </div>
                <Button
                  onClick={() => {
                    this.setState({ openDialog: true });
                    this.handleClearFields();
                  }}
                  style={{
                    background: "#1e3559",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PlusIcon />
                  </div>
                  Add New Sales Order
                </Button>
              </div>
            </div>
            <div
              style={{
                margin: "20px 0 0 0",
                height: "calc(100% - 58px)",
                width: "100%",
              }}
            >
              <div
                className="scrollBluePrimary"
                // ref={this.myRef}
                style={
                  {
                    height: "calc(100% - 66px)",
                    width: "100%",
                    borderRadius: "7px",

                    overflow: downloding && "auto",

                    overflowX: !downloding && "auto",
                    overflowY: !downloding && "hidden",
                  }
                  // border: `1px solid ${primary}`,
                }
              >
                <Paper
                  id="mycanvas"
                  ref={this.myRef}
                  className="d-flex d-flex-column scrollBluePrimary"
                  style={
                    downloding
                      ? {
                          height: "fit-content",
                          width: "fit-content",
                          padding: "20px",
                          borderRadius: "7px",
                          backgroundColor: "white",
                        }
                      : {
                          height: "100%",
                          width: "fit-content",
                          borderRadius: "7px",
                          minWidth: "100%",
                        }
                  }
                >
                  {" "}
                  {downloding && (
                    <div
                      style={{
                        padding: "0 0 30px 0",
                        width: "100%",

                        height: "50px",
                        width: "fit-content",
                        minWidth: "100%",
                        color: primary,
                        fontWeight: "400",
                      }}
                    >{`Sales Orders of ${singleselectedConsignee.short_name} as on ${moment(new Date()).format(
                      "DD/MM/YYYY"
                    )}`}</div>
                  )}
                  <div
                    style={
                      {
                        backgroundColor: "rgb(158 158 158 / 48%)",
                        color: primary,

                        borderTop: downloding && `2px solid ${primary}`,
                        borderRight: downloding && `2px solid ${primary}`,
                        borderLeft: downloding && `2px solid ${primary}`,
                        width: "fit-content",
                        minWidth: "100%",
                        tableLayout: "fixed",
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                        minHeight: "50px",
                        overflow: "hidden",
                        borderRadius: "7px 7px 0 0",
                      } // justifyContent: "space-between",
                    }
                  >
                    <div className="d-flex alignC">
                      <div
                        style={{
                          padding: "12px 10px",
                          width: "63.79px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                        }}
                      >
                        Sr.No.
                      </div>
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "12px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "85px",
                          maxWidth: "85px",
                          fontWeight: "500",
                        }}
                      >
                        Date
                      </div>
                      <div
                        style={
                          {
                            color: "#100f0f",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            minWidth: "92.55px",
                            maxWidth: "92.55px",
                            fontWeight: "500",
                          }
                          // margin: "0 10px",
                        }
                      >
                        SO #
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "160px",
                          maxWidth: "160px",
                          fontWeight: "500",
                        }}
                      >
                        Grade
                      </div>
                    </div>
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "92.55px",
                        width: "92.55px",
                        maxWidth: "92.55px",
                        fontWeight: "500",
                      }}
                      className="d-flex justifyC alignC"
                    >
                      Rate
                    </div>{" "}
                    <div
                      className="d-flex justifyC alignC"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "92.55px",
                        width: "92.55px",
                        maxWidth: "92.55px",
                        fontWeight: "500",
                      }}
                    >
                      Quantity
                    </div>
                    <div className="d-flex alignC">
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "105px",
                          width: "105px",
                          maxWidth: "105px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        Dispatched MT
                      </div>{" "}
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "92.55px",
                          width: "92.55px",
                          maxWidth: "92.55px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        Pending MT
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Dispatch Status
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        Payment Status
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      >
                        {!downloding && "Action"}
                      </div>
                    </div>
                  </div>
                  <div
                    style={
                      {
                        borderRight: downloding && `2px solid ${primary}`,
                        borderLeft: downloding && `2px solid ${primary}`,
                        height: "fot-content",
                        maxHeight: "calc(100% - 50px)",
                        minHeight: "calc(100% - 100px)",
                        overflowY: "auto",
                        width: "fit-content",
                        minWidth: "100%",
                        position: "relative",
                      } // overflowX: "hidden",
                    }
                  >
                    <div
                      id="mycanvas"
                      style={{
                        height: singlesalesdate && singlesalesdate.length !== 0 ? "fit-content" : "100%",
                        width: singlesalesdate && singlesalesdate.length !== 0 ? "fit-content" : "100%",
                      }}
                    >
                      {loading.salesOrders === true ? (
                        <div
                          className="alignC justiyC d-flex"
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <StyledCircularProgress color="inherit" />
                        </div>
                      ) : singlesalesdate && singlesalesdate.length !== 0 ? (
                        singlesalesdate &&
                        singlesalesdate
                          // .sort((a, b) => {
                          //   var dateA = moment(b.date).format("Do MMM");
                          //   var dateB = moment(a.date).format("Do MMM");
                          //   return dateA > dateB ? 1 : -1;
                          // })
                          .sort((a, b) => b.id - a.id)
                          .map((singleSales, i) => (
                            <>
                              <div
                                className="d-flex"
                                style={{
                                  margin: "1px 0 0 0",
                                  width: "100%",
                                  backgroundColor: colorArr[i % colorArr.length],
                                  height: "54px",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="d-flex alignC">
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "63.79px",
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {singlesalesdate.length - i < 10
                                      ? `0${singlesalesdate.length - i}`
                                      : singlesalesdate.length - i}{" "}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "85px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {moment(singleSales.start_date).format("DD/MM/YY")}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "92.55px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {singleSales.sales_order_number}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "160px",
                                      maxWidth: "160px",
                                      display: "flex",
                                      alignItems: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {singleSales.GradeName}
                                    {/* {itemsGradelist &&
                                itemsGradelist.filter((data) => data.id === singleSales.grade).length > 0 &&
                                itemsGradelist.filter((data) => data.id === singleSales.grade)[0] &&
                                itemsGradelist.filter((data) => data.id === singleSales.grade)[0].name} */}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "92.55px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {Number(singleSales.rate).toFixed(0)}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "92.55px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {Number(singleSales.quantity).toFixed(0)}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "105px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {Number(singleSales.dispatched_quantity).toFixed(3)}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "92.55px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    {Number(Math.floor(singleSales.quantity - singleSales.dispatched_quantity)).toFixed(
                                      3
                                    )}
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "120px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "#f5eba3",
                                        borderRadius: "5px",
                                        padding: "5px 10px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {singleSales.status}
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      padding: "12px 10px",
                                      width: "120px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      color: "#808080d1",
                                    }}
                                  >
                                    <span
                                      style={{
                                        background: "#03a9f424",
                                        borderRadius: "5px",
                                        padding: "5px 10px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {" "}
                                      {singleSales.payment_status}
                                    </span>
                                  </div>{" "}
                                  <div
                                    onClick={(e) => this.handleTouchTap(e, singleSales)}
                                    align="center"
                                    style={{
                                      padding: "12px 4px",
                                      minWidth: "120px",
                                      maxWidth: "120px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {!downloding && <MoreHorizOutlined />}
                                  </div>
                                </div>
                              </div>
                              <Divider />
                            </>
                          ))
                      ) : (
                        <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                          <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>No Sales Orders yet !</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={
                      {
                        borderRadius: downloding && "0 0 7px 7px",
                        borderBottom: downloding && `2px solid ${primary}`,
                        borderRight: downloding && `2px solid ${primary}`,
                        borderLeft: downloding && `2px solid ${primary}`,
                        backgroundColor: "rgb(158 158 158 / 48%)",
                        color: primary,
                        borderColor: primary,
                        width: "fit-content",
                        minWidth: "100%",
                        tableLayout: "fixed",
                        display: "flex",
                        alignItems: "center",
                        height: "50px",
                        minHeight: "50px",
                        overflow: "hidden",
                        //borderRadius: "7px 7px 0 0",
                      } // justifyContent: "space-between",
                    }
                  >
                    <div className="d-flex alignC">
                      <div
                        style={{
                          padding: "12px 10px",
                          width: "55.69px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                        }}
                      />
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "12px 10px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          minWidth: "96.09px",
                          maxWidth: "93.09px",
                          fontWeight: "500",
                        }}
                      >
                        Grand Total
                      </div>
                      <div
                        style={
                          {
                            color: "#100f0f",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            minWidth: "92.55px",
                            maxWidth: "92.55px",
                            fontWeight: "500",
                          }
                          // margin: "0 10px",
                        }
                      />{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "154px",
                          maxWidth: "154px",
                          fontWeight: "500",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "92.55px",
                        width: "92.55px",
                        maxWidth: "92.55px",
                        fontWeight: "500",
                      }}
                      className="d-flex justifyC alignC"
                    />{" "}
                    <div
                      className="d-flex justifyC alignC"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "92.55px",
                        width: "92.55px",
                        maxWidth: "92.55px",
                        fontWeight: "500",
                      }}
                    >
                      {total_quantity} MT
                    </div>
                    <div className="d-flex alignC">
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "fit-content",
                          width: "105px",
                          maxWidth: "105px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {total_dispatch} MT{" "}
                      </div>{" "}
                      <div
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "fit-content",
                          width: "92.55px",
                          maxWidth: "92.55px",
                          fontWeight: "500",
                        }}
                        align="center"
                      >
                        {`${singleselectedConsignee && singleselectedConsignee.total_pending_quantity} MT`}{" "}
                      </div>{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      />{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      />{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "120px",
                          maxWidth: "120px",
                          fontWeight: "500",
                        }}
                      />
                    </div>
                  </div>{" "}
                  {downloding && (
                    <div
                      className="d-flex justifyFE"
                      style={{
                        height: "50px",
                        width: "100%",
                        padding: "30px 0 0 0",
                        minWidth: "100%",
                        color: primary,
                        fontWeight: "400",
                      }}
                    >{`Summary generated at :- ${moment(new Date()).format("MMMM Do YYYY, h:mm A")}`}</div>
                  )}
                </Paper>
              </div>
              <Paper>
                <div
                  style={{
                    padding: "15px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {singleselectedConsignee && (
                    <span style={{ color: "#666666b5" }}>{`Sales Orders of ${
                      singleselectedConsignee.short_name
                    } as on ${moment(new Date()).format("DD/MM/YYYY")}`}</span>
                  )}
                  {downloding
                    ? ""
                    : singlesalesdate &&
                      singlesalesdate.length !== 0 && (
                        <Button
                          onClick={() => {
                            this.setState({ downloding: true }, () => {
                              // this.handleExportWithFunction();
                              this.downloadImage(
                                `Sales-Orders-of-${singleselectedConsignee.short_name} as on ${moment(
                                  new Date()
                                ).format("DD/MM/YYYY")}`
                              );
                            });
                          }}
                          style={{ color: primary }}
                        >
                          Download {/* PDF */}
                        </Button>
                      )}
                </div>
              </Paper>
            </div>
          </Paper>
        ) : (
          <Paper style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: "1rem",
                color: "grey",
                background: "#d3d3d35c",
              }}
            >
              <NoData />
            </div>
          </Paper>
        )}
      </Layout>
    );
  }
}

export default withRouter(SalesOrder);
