import React, { Component } from "react";
import validator from "validator";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Paper,
  Typography,
  Chip,
} from "@material-ui/core";
import ReactDOM from "react-dom";
import { CssTextField, CssTextFieldOrange, StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import { green, disableColor, lightblue, mostLightGrey, orange, primary, red, lightGrey } from "../styles/colors";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { CancelIcon, CrossIcon, UploadIcon } from "../styles/Icons";
import { Cancel, CheckCircle } from "@material-ui/icons";
import SnackbarComponent from "./SnackbarComponent";
import moment from "moment";
import { withStyles } from "@material-ui/styles";
const CssChip = withStyles((theme) => ({
  root: {
    "& .MuiChip-label": {
      // color: primary,
      // textTransform: "capitalize",
    },
  },
}))(Chip);
const styles = {
  TransporterGroup: { width: "100%", margin: "20px 0", maxWidth: "400px" },
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  chip: (clr, i, selected) => ({
    borderRadius: "7px",
    height: "30px",
    width: "90px",
    background: selected ? primary : clr ? clr : "RGBA(30,50,250,0.1)",
    fontSize: "0.6rem",
    color: "white",
    fontWeight: "500",
    margin: "0 10px 10px",
  }),
};
export class TripEditDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: "",

      error: "",
      success: "",

      newDate: "",

      TransporterGroupName: "",
      TransporterGroupId: "",
      selectedalesOrderName: "",
      selectedSalesOrderId: "",
      errorMsg: {
        newDate: false,
        TransporterGroupName: false,
        selectedalesOrderName: false,
      },
      loading: false,

      dateArr: [],
    };
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({
      [event.target.name]: event.target.value,
      errorMsg,
    });
  };
  reset = () => {
    const { TransporterGroupName, selectedalesOrderName } = this.state;
    if (TransporterGroupName || selectedalesOrderName) {
      this.setState({ error: "Nothing to reset !" });
    } else {
      this.setState(
        {
          TransporterGroupName: "",
          TransporterGroupId: "",
          selectedalesOrderName: "",
          selectedSalesOrderId: "",
          newDate: "",
          errorMsg: {
            newDate: false,
            TransporterGroupName: false,
            selectedalesOrderName: false,
          },
        },
        () => {
          this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };
  componentWillReceiveProps(next) {
    const { tp, transpoterslist, salesOrders } = next;
    if (tp) {
      this.setState({
        selectedDate: this.props.selectedDate ? this.props.selectedDate : this.props.dates[0].date,
        // TransporterGroupName: tp.transporter_group.name,
        // TransporterGroupId: tp.transporter_group.id,
        salesOrders: salesOrders,
        transpoterslist: transpoterslist,
      });
    }
  }
  componentWillMount() {
    const { tp, dates } = this.props;
    if (tp) {
      //   var today = tp.trip_date; // ? this.props.selectedDate : this.props.dates[0].date;
      const today = moment(tp.trip_date).format("YYYY-MM-DD");
      //   var date = new Date();
      const dateArr = [];

      if (
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .subtract(2, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .subtract(2, "days")
                .format("YYYY-MM-DD")
          ).length > 0 &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .subtract(2, "days")
                .format("YYYY-MM-DD")
          )[0].is_finalised !== true) ||
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .subtract(2, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .subtract(2, "days")
                .format("YYYY-MM-DD")
          ).length === 0)
      ) {
        dateArr.push(
          moment(today, "YYYY-MM-DD")
            .subtract(2, "days")
            .format("YYYY-MM-DD")
        );
      }
      if (
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .subtract(1, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .subtract(1, "days")
                .format("YYYY-MM-DD")
          ).length > 0 &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .subtract(1, "days")
                .format("YYYY-MM-DD")
          )[0].is_finalised !== true) ||
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .subtract(1, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .subtract(1, "days")
                .format("YYYY-MM-DD")
          ).length === 0)
      ) {
        dateArr.push(
          moment(today, "YYYY-MM-DD")
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        );
      }
      //   dateArr.push(today);
      if (
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .add(1, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(1, "days")
                .format("YYYY-MM-DD")
          ).length > 0 &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(1, "days")
                .format("YYYY-MM-DD")
          )[0].is_finalised !== true) ||
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .add(1, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(1, "days")
                .format("YYYY-MM-DD")
          ).length === 0)
      ) {
        dateArr.push(
          moment(today, "YYYY-MM-DD")
            .add(1, "days")
            .format("YYYY-MM-DD")
        );
      }
      if (
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .add(2, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(2, "days")
                .format("YYYY-MM-DD")
          ).length > 0 &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(2, "days")
                .format("YYYY-MM-DD")
          )[0].is_finalised !== true) ||
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .add(2, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(2, "days")
                .format("YYYY-MM-DD")
          ).length === 0)
      ) {
        dateArr.push(
          moment(today, "YYYY-MM-DD")
            .add(2, "days")
            .format("YYYY-MM-DD")
        );
      }
      if (
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .add(3, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(3, "days")
                .format("YYYY-MM-DD")
          ).length > 0 &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(3, "days")
                .format("YYYY-MM-DD")
          )[0].is_finalised !== true) ||
        (this.props.dates.filter(
          (data) =>
            moment(data.date).format("YYYY-MM-DD") ===
            moment(today, "YYYY-MM-DD")
              .add(3, "days")
              .format("YYYY-MM-DD")
        ) &&
          this.props.dates.filter(
            (data) =>
              moment(data.date).format("YYYY-MM-DD") ===
              moment(today, "YYYY-MM-DD")
                .add(3, "days")
                .format("YYYY-MM-DD")
          ).length === 0)
      ) {
        dateArr.push(
          moment(today, "YYYY-MM-DD")
            .add(3, "days")
            .format("YYYY-MM-DD")
        );
      }

      this.setState({
        dateArr: dateArr,
        selectedDate: this.props.selectedDate ? this.props.selectedDate : this.props.dates[0].date,
        newDate: this.props.selectedDate ? this.props.selectedDate : this.props.dates[0].date,
        TransporterGroupName: tp.transporter_group.name,
        TransporterGroupId: tp.transporter_group.id,

        // selectedalesOrderName: "",
        // selectedSalesOrderId: "",
        salesOrders: this.props.salesOrders,
        transpoterslist: this.props.transpoterslist,
      });
    } else {
      this.setState({
        selectedDate: this.props.selectedDate,
      });
    }

    if (tp && this.props.changeSalesOrder) {
      console.log(tp, "tp");
      this.setState({
        selectedSalesOrderId: tp.sales_order.id,
        selectedalesOrderName: tp.sales_order.sales_order_number,
      });
      this.props
        .fetchSalesOrders(tp?.item_grade?.id, tp?.consignee?.id, this.props.user.user.uid, "onlyfilt")
        .then((res) => {
          if (res) {
            this.setState(
              {
                salesOrders: res.length > 0 ? res : [],
              },
              () => {
                // if (res.length > 0) {
                //   this.setState({
                //     // selectedSalesOrderId: res[0].id,
                //     // selectedalesOrderName: res[0].sales_order_number,
                //     // pendingQuantity: res[0].pending_quantity,
                //     // selectedSalesItemId: res[0].item,
                //   });
                // } else {
                //   this.setState({
                //     selectedSalesOrderId: "",
                //     pendingQuantity: "",
                //     selectedalesOrderName: "",
                //     selectedSalesItemId: "",
                //   });
                // }
              }
            );
          }
        });
    }
  }

  render() {
    const {
      errorMsg,
      tripAdded,
      error,
      success,
      loading,
      dateArr,
      TransporterGroupName,
      selectedalesOrderName,
      selectedSalesOrderId,
      TransporterGroupId,
      selectedDate,
      transpoterslist,
      salesOrders,
      pendingQuantity,
      newDate,
    } = this.state;
    const {
      singleDate,
      openFinalisedDialog,
      openTripEditDialog,
      tp,
      editTrip,
      changeTransporterGroup,
      changeSalesOrder,
      changeDate,
      cancelTrip,
    } = this.props;

    return (
      <Dialog
        open={openTripEditDialog}
        onClose={this.props.handleClose}
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children ? ReactDOM.findDOMNode(e).children[2].children[0] : null;
            target.style.borderRadius = 0;
            target.style.width = "50%";
            target.style.maxWidth = "400px";
            target.style.height = "calc(100vh - 200px)";
            // target.style.minHeight = "calc(100vh - 200px)";

            target.style.maxHeight = "400px";
            target.style.margin = "100px 0";
            target.style.position = "relative";
            target.style.overflow = "hidden";
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {loading ? (
          <Backdrop style={{ zIndex: 1, color: "#fff", position: "absolute" }} open={loading}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        <DialogActions
          classname="d-flex justifySB"
          style={{ background: primary, color: "white", padding: "10px 24px" }}
          onClick={() => this.props.handleClose()}
        >
          <h3 style={styles.heading}>
            {`${
              openFinalisedDialog
                ? "Submit and Finalise date"
                : changeSalesOrder
                ? "Edit Sales Order"
                : changeTransporterGroup
                ? "Edit Transporters Group"
                : changeDate
                ? "Change date of trip"
                : cancelTrip
                ? "Cancel trip"
                : "Edit"
            }  `}
            {/* <div style={{ margin: "0 10px 0 0" }} />( {selectedDate}) */}
          </h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>
        <DialogContent className="dashedDividerBottom" style={{ height: "100%" }}>
          {tripAdded ? (
            <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
              <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
              <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                {tripAdded}
              </span>
            </div>
          ) : (
            <div className="height-100 width-100 d-flex d-flex-column">
              {openFinalisedDialog ? (
                <Paper className="width-100 d-flex marginTB20 height-100 padding20">
                  <div>
                    This date {` `}
                    {selectedDate}
                    {` `}will be marked as finalised. Further no actions can be done in this date.
                  </div>
                </Paper>
              ) : (
                tp && (
                  <>
                    <Paper className="width-100 d-flex marginTB20">
                      <div style={{ width: "calc(100% - 0.67px)" }} className="d-flex d-flex-column">
                        <div
                          className="d-flex justifyC alignC"
                          style={{
                            color: "#100f0f",
                            padding: "4px",

                            fontWeight: "500",
                          }}
                        >
                          TP NO
                        </div>
                        <Divider className="width-100 " />
                        <div
                          style={{
                            textTransform: "capitalize",
                            padding: "12px 4px",

                            color: "#808080d1",
                          }}
                          className="d-flex justifyC alignC"
                        >
                          {tp.transit_pass.TransitPassId}
                        </div>
                      </div>
                      <Divider className="height-100 " variant="vertical" />
                      <div style={{ width: "calc(100% - 0.67px)" }} className="d-flex d-flex-column">
                        <div
                          style={{
                            color: "#100f0f",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: "4px",
                            fontWeight: "500",
                            minWidth: "100%",
                            // margin: "0 10px",
                          }}
                        >
                          Vehicle #{" "}
                        </div>
                        <Divider className="width-100 " />
                        <div
                          style={{
                            textTransform: "capitalize",
                            padding: "12px 4px",

                            color: "#808080d1",
                          }}
                          className="d-flex justifyC alignC"
                        >
                          {tp.vehicle.vehicle_no}
                        </div>
                      </div>

                      <Divider className="height-100 " variant="vertical" />
                      <div style={{ width: "calc(100% - 0.67px)" }} className="d-flex d-flex-column">
                        <div
                          s
                          style={{
                            color: "#100f0f",
                            padding: "4px",
                            width: "100%",
                            fontWeight: "500",
                          }}
                          className="d-flex justifyC alignC"
                        >
                          Party
                        </div>
                        <Divider className="width-100 " />
                        <div
                          style={{
                            textTransform: "capitalize",
                            padding: "12px 4px",

                            color: "#808080d1",
                          }}
                          className="d-flex justifyC alignC"
                        >
                          {tp.consignee.short_name}
                        </div>
                      </div>
                    </Paper>
                    <div style={{ width: "100%", height: "calc(100% - 112.97px)" }}>
                      {changeSalesOrder ? (
                        <>
                          <div className="d-flex width-100">
                            <CssTextField
                              required={true}
                              error={
                                errorMsg.selectedalesOrderName
                                  ? true
                                  : salesOrders.length === 0
                                  ? true
                                  : (pendingQuantity !== null && pendingQuantity < 150 && pendingQuantity > 0) ||
                                    (pendingQuantity !== null && pendingQuantity < 0)
                                  ? true
                                  : false
                              }
                              helperText={
                                errorMsg.selectedalesOrderName
                                  ? errorMsg.selectedalesOrderName
                                  : salesOrders.length === 0
                                  ? "No sales orders available for selected trpis's party and grade!"
                                  : pendingQuantity !== null && pendingQuantity < 150 && pendingQuantity > 0
                                  ? `${pendingQuantity} MT Remaining!`
                                  : pendingQuantity !== null && pendingQuantity < 0
                                  ? "All quantity in this sales order has been dispatched. No new trips can be created!!"
                                  : false
                              }
                              autoComplete="off"
                              style={styles.TransporterGroup}
                              id="outlined-select-currency-native"
                              select
                              label={"Sales Order"}
                              SelectProps={{ disableUnderline: true }}
                              variant="outlined"
                              value={selectedalesOrderName ? selectedalesOrderName : ""}
                            >
                              {salesOrders &&
                                salesOrders.map((option) => (
                                  <MenuItem
                                    onClick={(e) => {
                                      this.setState({
                                        selectedSalesOrderId: option.id,
                                        pendingQuantity: option.pending_quantity,
                                        selectedalesOrderName: option.sales_order_number,
                                      });
                                    }}
                                    disabled={
                                      option.pending_quantity !== null && option.pending_quantity <= 0 ? true : false
                                    }
                                    key={option.sales_order_number}
                                    value={option.sales_order_number}
                                    id={option.id}
                                  >
                                    {`${option.sales_order_number}-${moment(option.start_date).format("Do MMM")}`}
                                  </MenuItem>
                                ))}
                            </CssTextField>
                          </div>
                          <div className="d-flex width-100 padding20">{`${tp.sales_order.sales_order_number}-${moment(
                            tp.sales_order.start_date
                          ).format("Do MMM")}`}</div>
                        </>
                      ) : changeTransporterGroup ? (
                        <>
                          <div className="d-flex width-100">
                            <CssTextField
                              error={errorMsg.TransporterGroupName ? true : false}
                              helperText={errorMsg.TransporterGroupName ? errorMsg.TransporterGroupName : false}
                              value={TransporterGroupName}
                              autoComplete="off"
                              disabled={!changeTransporterGroup}
                              style={styles.TransporterGroup}
                              id="outlined-select-currency-native"
                              select
                              label="Transpoters Group"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              SelectProps={{
                                // native: true,
                                disableUnderline: true,
                              }}
                              variant="outlined"
                              onChange={(e) => {
                                var errorMsg = this.state.errorMsg;
                                errorMsg.TransporterGroupName = false;
                                this.setState({
                                  errorMsg,
                                  TransporterGroupId: e.currentTarget.id,
                                  TransporterGroupName: e.target.value,
                                });
                              }}
                            >
                              {transpoterslist &&
                                transpoterslist.map((option) => (
                                  <MenuItem key={option.name} value={option.name} id={option.id}>
                                    {option.name}
                                  </MenuItem>
                                ))}
                            </CssTextField>
                          </div>
                          <div className="d-flex width-100 padding20">{`${tp.transporter_group.name}`}</div>
                        </>
                      ) : changeDate ? (
                        <div className="d-flex width-100 flexWrap">
                          {dateArr &&
                            dateArr.map((singleChip, i) => (
                              <>
                                <CssChip
                                  onClick={() => {
                                    this.setState({
                                      newDate: singleChip, //moment(singleChip).format("Do MMM, ddd"),
                                    });
                                  }}
                                  style={styles.chip(
                                    "rgb(0 0 0 / 0.4)",
                                    i,
                                    newDate === singleChip // moment(singleChip).format("YYYY-MM-DD")
                                  )}
                                  label={`${moment(singleChip).format("Do MMM, ddd")}`}
                                />
                              </>
                            ))}

                          {/* {newDate} */}
                        </div>
                      ) : cancelTrip ? (
                        <div className="d-flex height-100 width-100" style={{ fontSize: "1rem" }}>
                          Are you sure you want to cancel this trip ?
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
          {tripAdded ? (
            ""
          ) : changeTransporterGroup || cancelTrip || changeDate || openFinalisedDialog || changeSalesOrder ? (
            <Button
              disabled={
                changeDate
                  ? newDate === selectedDate
                  : changeSalesOrder
                  ? selectedSalesOrderId === this.props.tp.sales_order.id
                  : changeTransporterGroup
                  ? TransporterGroupId === this.props.tp.transporter_group.id
                  : false
              }
              style={{
                borderRadius: "5px",
                padding: "3px 10px",
                background:
                  (changeTransporterGroup && TransporterGroupId === this.props.tp.transporter_group.id) ||
                  (changeDate && newDate === selectedDate)
                    ? disableColor
                    : green,
                justifyContent: "center",
                color: "white",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                margin: "0 10px 0 0",
              }}
              onClick={() => {
                this.setState({ loading: true });
                var newData = {};
                if (openFinalisedDialog) {
                  if (singleDate.dispatch_details.total_dispatched_trips === singleDate.dispatch_details.total_trips) {
                    var newData = {
                      is_finalised: true,
                    };
                    this.props.editDate(this.props.user.user.uid, newData, singleDate.id).then((res) => {
                      if (res.error) {
                        this.setState({ error: res.data, loading: false });
                      } else {
                        console.log(res, "res.data");
                        this.props
                          .fetchDates(
                            this.props.user.user.uid,
                            this.props.selectedMonth.monthId,
                            this.props.selectedMonth.yearId
                          )
                          .then((ress) => {
                            this.props.setsingleDate(res, ress);
                            this.setState({ tripAdded: "Date finalised!", loading: false });
                          });
                      }
                    });
                  } else {
                    this.setState({
                      error:
                        singleDate.dispatch_details.total_pending_trips !== 0
                          ? `You can't finalise! as you have ${
                              singleDate.dispatch_details.total_pending_trips
                            } pending ${singleDate.dispatch_details.total_pending_trips > 1 ? "trips" : "trip"}.`
                          : `You can't finalise! as you have ${singleDate.dispatch_details.total_trips -
                              singleDate.dispatch_details.total_dispatched_trips} ${
                              singleDate.dispatch_details.total_trips -
                                singleDate.dispatch_details.total_dispatched_trips >
                              1
                                ? "trips"
                                : "trip"
                            } to be dispatched.`,
                      loading: false,
                    });
                  }
                } else {
                  if (changeSalesOrder) {
                    if (selectedSalesOrderId !== "") {
                      newData = {
                        sales_order: selectedSalesOrderId,
                      };
                      this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                        if (res.error) {
                          this.setState({ error: res.data, loading: false });
                        } else {
                          this.props.fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid).then(() => {
                            this.props
                              .fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid)
                              .then(() => {
                                this.props
                                  .fetchDates(
                                    this.props.user.user.uid,
                                    this.props.selectedMonth.monthId,
                                    this.props.selectedMonth.yearId
                                  )
                                  .then((resres) => {
                                    this.props.setDate(resres);
                                    this.setState({
                                      tripAdded: "Sales Order edited ! ",
                                      loading: false,
                                    });
                                  });
                              });
                          });
                        }
                      });
                    } else {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.selectedalesOrderName = "Please select any one sales order !";
                      this.setState({ errorMsg, loading: false });
                    }
                  } else if (changeTransporterGroup) {
                    if (TransporterGroupId !== "") {
                      console.log(TransporterGroupId, "TransporterGroupId");
                      newData = {
                        transporter_group: TransporterGroupId,
                      };
                      this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                        if (res.error) {
                          this.setState({ error: res.data, loading: false });
                        } else {
                          this.props.fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid).then(() => {
                            this.props
                              .fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid)
                              .then(() => {
                                this.props
                                  .fetchDates(
                                    this.props.user.user.uid,
                                    this.props.selectedMonth.monthId,
                                    this.props.selectedMonth.yearId
                                  )
                                  .then((resres) => {
                                    this.props.setDate(resres);
                                    this.setState({
                                      tripAdded: "Transporter group edited ! ",
                                      loading: false,
                                    });
                                  });
                              });
                          });
                        }
                      });
                    } else {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.TransporterGroupName = "Please select any one transporter group !";
                      this.setState({ errorMsg, loading: false });
                    }
                  } else if (changeDate) {
                    if (newDate !== "") {
                      console.log(newDate, moment(newDate).format("YYYY-MM-DD"), "newDate");
                      newData = {
                        trip_date: moment(newDate).format("YYYY-MM-DD"),
                      };
                      this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                        if (res.error) {
                          this.setState({ error: res.data, loading: false });
                        } else {
                          this.props
                            .fetchDates(
                              this.props.user.user.uid,
                              this.props.selectedMonth.monthId,
                              this.props.selectedMonth.yearId
                            )
                            .then((resres) => {
                              this.props.setDate(resres);
                              this.props
                                .fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid)
                                .then(() => {
                                  this.props
                                    .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                                    .then((res) => {
                                      // console.log(res, "ress");
                                      // const next = this.props;
                                      // // next.dates = [];
                                      // // next.dates = res;
                                      // Object.assign({}, next, { dates: res });
                                      // Object.assign(next, { dates: res });
                                      // console.log(next, "nextsssssssssssssssssssssssssssssssssss");
                                      // this.props.setInitialValues(next);
                                      this.setState({
                                        tripAdded: "Trip date changed ! ",
                                        loading: false,
                                      });
                                    });
                                });
                            });
                        }
                      });
                    } else {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.newDate = "Please select any one date to change !";
                      this.setState({ errorMsg, loading: false });
                    }
                  }
                  if (cancelTrip) {
                    newData = {
                      dispatch_status: "CANCELLED",
                    };
                    this.props.sendForCorrection(this.props.user.user.uid, newData, tp.id).then((res) => {
                      if (res.error) {
                        this.setState({ error: res.data, loading: false });
                      } else {
                        this.props.fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid).then(() => {
                          this.props
                            .fetchSingleDateDetails(this.state.singleDate, this.props.user.user.uid)
                            .then(() => {
                              this.props
                                .fetchDates(
                                  this.props.user.user.uid,
                                  this.props.selectedMonth.monthId,
                                  this.props.selectedMonth.yearId
                                )

                                .then((resres) => {
                                  this.props.setDate(resres);
                                  this.setState({
                                    tripAdded: "Trip canceled ! ",
                                    loading: false,
                                  });
                                });
                            });
                        });
                      }
                    });
                  }
                }
              }}
            >
              {openFinalisedDialog ? "Finalise" : changeDate ? "Save" : cancelTrip ? "Cancel Trip" : "Save"}
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default TripEditDialog;
