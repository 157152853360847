import React, { Component } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { darkGrey, white } from "../styles/colors";
import ReactDOM from "react-dom";
import { primary, red } from "../styles/colors";
import { CrossIcon } from "../styles/Icons";
import { StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import { CheckCircle } from "@material-ui/icons";
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
};
class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = { file: false, loading: false, uploadError: false };
  }
  // componentWillReceiveProps(next) {
  //     if (next.openInvoice) {
  //         this.setState({ file: { url: next.openInvoice.invoice_file, edited: true } })
  //     }
  // }
  componentWillMount() {
    if (this.props.openInvoice) {
      this.setState({ file: { url: this.props.openInvoice.invoice_file, edited: true } });
    }
  }

  handleUpdate = (e) => {
    this.setState({ cancel: false });
    e.preventDefault();
    let image = {};

    if (e.target.files[0]) {
      const kb = (e.target.files[0].size / 1024).toFixed(2);
      const mb = (e.target.files[0].size / 1024.0 / 1024.0).toFixed(2);

      if (kb <= 1024) {
        const size = `${kb}${" "}kb`;
        image.mainSize = size;
        this.setState({ size });
      }
      if (1024 < kb) {
        const size = `${mb}${" "}mb`;
        image.mainSize = size;
        this.setState({ size });
      }

      image.img = e.target.files[0];
      image.url = URL.createObjectURL(e.target.files[0]);

      this.setState({ file: image, uploadError: false, uploadSuccess: false });
      console.log(image, "imageimage");
    }
  };

  render() {
    const { file, loading, uploadError, uploadSuccess } = this.state;
    const { selectedTrip, openInvoice } = this.props;
    return (
      <Dialog
        open={true}
        onClose={this.props.handleClose}
        ref={(e) => {
          try {
            const target = ReactDOM.findDOMNode(e).children ? ReactDOM.findDOMNode(e).children[2].children[0] : null;
            target.style.borderRadius = 0;
            target.style.width = "50%";
            target.style.maxWidth = "100%";
            target.style.height = "calc(100vh - 200px)";
            target.style.minHeight = "calc(100vh - 200px)";

            target.style.maxHeight = "calc(100vh - 200px)";
            target.style.margin = "100px 0";
            target.style.position = "relative";
            target.style.overflow = "hidden";
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {loading ? (
          <Backdrop style={{ zIndex: 1, color: "#fff", position: "absolute" }} open={loading}>
            <StyledCircularProgress color="inherit" />
          </Backdrop>
        ) : (
          ""
        )}
        {/* {error || success ? <SnackbarComponent error={error} success={success} snackbarClose={() => this.setState({ error: "", success: "" })} /> : ""} */}

        <DialogActions
          classname="d-flex justifySB"
          style={{ background: primary, color: "white", padding: "10px 24px" }}
          onClick={() => this.props.handleClose()}
        >
          <h3 style={styles.heading}>{openInvoice ? "Edit Invoice" : "Upload Invoice"}</h3>

          <Tooltip title="close" arrow placement="top">
            <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
              <CrossIcon strokeWidth="2" />
            </IconButton>
          </Tooltip>
        </DialogActions>

        <DialogContent className="dashedDividerBottom" style={{ height: "100%" }}>
          {console.log(file.edited ? `https://app.pitambarainfra.com/${file.url}` : file.url, "vvvvvvvvvvvv")}
          <div className="height-100 width-100 d-flex alignC justifyC">
            {uploadSuccess ? (
              <div
                className="d-flex justifyC alignC d-flex-column"
                style={{
                  width: "100%",
                  height: "100%",
                  color: "green",
                }}
              >
                <CheckCircle fontSize="small" style={{ width: "100px", height: "100px", color: "green" }} />
                <span style={{ fontSize: "1rem", margin: "20px 0 0 0" }}>
                  {openInvoice ? "Invoice Edited !" : "Invoice uploaded !"}
                </span>
              </div>
            ) : file ? (
              <iframe
                src={file.edited ? `https://app.pitambarainfra.com/${file.url}` : file.url}
                style={{
                  margin: "0 0 0 24px",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <>
                {" "}
                <input
                  accept="application/pdf, .pdf"
                  ref={(ref) => (this.fileInput = ref)}
                  id="files"
                  style={{
                    visibility: "hidden",
                    height: 0,
                    width: 0,
                  }}
                  type="file"
                  onChange={this.handleUpdate}
                />
                <label htmlFor="files" class="btn cursorP">
                  <div
                    className="d-flex justifyC alignC d-flex-column"
                    style={{
                      width: "100%",
                      height: "100%",
                      color: uploadError ? "red" : primary,
                    }}
                  >
                    <AddCircleOutlineIcon
                      fontSize="small"
                      style={{ width: "120px", height: "120px", color: uploadError ? "red" : darkGrey }}
                    />
                    <span style={{ fontSize: "1rem", margin: "20px 0 0 0" }}>Please upload invoice</span>
                  </div>
                </label>
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions style={{ width: "100%" }}>
          {uploadSuccess ? (
            ""
          ) : (
            <>
              {file ? (
                selectedTrip.dispatch_status === "DISPATCHED" || this.props.singleDate.is_finalised === true ? (
                  ""
                ) : (
                  <StyledButton
                    onClick={() => {
                      this.setState({ file: false, uploadSuccess: false });
                    }}
                    background={red}
                    onlyTextColor={white}
                    name={"Remove"}
                    withBg={true}
                    width="120px"
                  />
                )
              ) : (
                ""
              )}
              {uploadError || file.edited ? (
                ""
              ) : (
                <StyledButton
                  onClick={() => {
                    if (file) {
                      this.setState({ loading: true });
                      let tripData = new FormData();

                      tripData.append("dispatch_status", openInvoice ? openInvoice.dispatch_status : "READY");
                      tripData.append("invoice_file", file.img);

                      this.props.sendForCorrection(this.props.user.user.uid, tripData, selectedTrip.id).then((res) => {
                        if (res.error) {
                          this.setState({ uploadError: res.data, loading: false });
                        } else {
                          this.props
                            .fetchDates(
                              this.props.user.user.uid,
                              this.props.selectedMonth.monthId,
                              this.props.selectedMonth.yearId
                            )
                            .then((resres) => {
                              if (resres) {
                                this.props.setsingleDate(
                                  resres.filter((data) => data.id === this.props.singleDate.id)[0],
                                  resres
                                );
                              }
                              this.props
                                .fetchSingleDateTrips(this.props.singleDate, this.props.user.user.uid)
                                .then(() => {
                                  this.setState({ uploadSuccess: true, loading: false });
                                });
                            });
                        }
                      });
                    } else {
                      this.setState({ uploadError: "Please upload a file!" });
                    }
                  }}
                  name={"Upload"}
                  withBg={true}
                  background={"green"}
                  width="120px"
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default UploadFile;
