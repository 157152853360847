import axios from "axios";
import { loginUrl, verifyotp } from "../../http/config";

export const fetchLoggedUser = () => async (dispatch, getState) => {
  try {
    const token = localStorage.getItem("PLIPL_USER") ? JSON.parse(localStorage.getItem("PLIPL_USER")) : "";

    dispatch({ type: "AUTH_USER_REQUEST" });
    var response = "";
    response = token ? token : null;

    const data = response; // response.data.data;

    dispatch({ type: "AUTH_USER_LOGGEDIN", payload: response });
    return {
      error: false,
    };
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data.msg : "Some error occured, please try again!",
    };
  }
};
export const verifyOtp = (otp) => async (dispatch, getState) => {
  try {
    dispatch({ type: "AUTH_USER_REQUEST" });
    const response1 = await axios
      .post(verifyotp, JSON.stringify(otp), {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        const data = response.data.data;
        console.log(data, "ssssssssssssssssssssssssssssssssssssssssss");
        // data.roles.push("dispatchStaff")
        const user1 = {
          uid: data.token, //"4300261de1a00f95318e76846b885e5ba87e5c62", // data.uid,
          username: data.first_name,
          email: data.email,
          roles: data.roles,
          accessibleModules: data.accessibleModules,

          accessibleWidgets: [
            {
              id: 1153617242,
              has_create_permission: true,
              has_read_permission: true,
              has_update_permission: true,
              has_delete_permission: true,
              is_accessible_on_web: true,
              is_accessible_on_mobile: true,
              is_ip_restricted: false,
              linked_to: null,
              key_code: "",
              white_listed_ips: [],
              widget_name: "exportTrips",
            },
            {
              id: 1154697376,
              has_create_permission: true,
              has_read_permission: true,
              has_update_permission: true,
              has_delete_permission: true,
              is_accessible_on_web: true,
              is_accessible_on_mobile: true,
              is_ip_restricted: false,
              linked_to: null,
              key_code: "",
              white_listed_ips: [],
              widget_name: "editTrips",
            },
            {
              id: 914557510,
              has_create_permission: true,
              has_read_permission: true,
              has_update_permission: true,
              has_delete_permission: true,
              is_accessible_on_web: true,
              is_accessible_on_mobile: true,
              is_ip_restricted: false,
              linked_to: null,
              key_code: "",
              white_listed_ips: [],
              widget_name: "tripAdmin",
            },
          ], // data.accessibleWidgets,
        };
        localStorage.setItem("PLIPL_USER", JSON.stringify(user1));
        const user = {
          uid: data.token, //"4300261de1a00f95318e76846b885e5ba87e5c62", // data.uid,
          username: data.first_name,
          email: data.email,
          userData: data,
          roles: data.roles,
          accessibleModules: data.accessibleModules,

          accessibleWidgets: [
            {
              id: 1153617242,
              has_create_permission: true,
              has_read_permission: true,
              has_update_permission: true,
              has_delete_permission: true,
              is_accessible_on_web: true,
              is_accessible_on_mobile: true,
              is_ip_restricted: false,
              linked_to: null,
              key_code: "",
              white_listed_ips: [],
              widget_name: "exportTrips",
            },
            {
              id: 1154697376,
              has_create_permission: true,
              has_read_permission: true,
              has_update_permission: true,
              has_delete_permission: true,
              is_accessible_on_web: true,
              is_accessible_on_mobile: true,
              is_ip_restricted: false,
              linked_to: null,
              key_code: "",
              white_listed_ips: [],
              widget_name: "editTrips",
            },
            {
              id: 914557510,
              has_create_permission: true,
              has_read_permission: true,
              has_update_permission: true,
              has_delete_permission: true,
              is_accessible_on_web: true,
              is_accessible_on_mobile: true,
              is_ip_restricted: false,
              linked_to: null,
              key_code: "",
              white_listed_ips: [],
              widget_name: "tripAdmin",
            },
          ], // data.accessibleWidgets,
        };
        dispatch({ type: "AUTH_USER_LOGGEDIN", payload: user });
        const response1 = { user };
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
        console.log(error.response.data, "error w");
      });
    return await response1;
  } catch (error) {
    console.log(error, "error");
    return dispatch({
      type: "AUTH_USER_LOGGEDIN_ERROR",
      payload: error.response ? error.response.data : "Some error occured, please try again!",
    });
  }
};
export const LoginFunction = (userData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "AUTH_USER_REQUEST" });
    const response1 = await axios
      .post(loginUrl, {
        username: userData.username,
        password: userData.password,
      })
      .then((response) => {
        console.log("Success ========>", response);
        response.data = userData;
        const data = response.data; // response.data.data;
        const user = {
          username: userData.username,
          password: userData.password,
        };
        localStorage.setItem("PLIPL_USER", JSON.stringify(user));
        console.log(response, "response");
        dispatch({ type: "AUTH_USER_LOGGEDIN", payload: response.data });
        return response;
      })
      .catch((error) => {
        console.log(error.response, "error w");
        dispatch({
          type: "AUTH_USER_LOGGEDIN_ERROR",
          payload: error.response ? error.response.data.msg : "Some error occured, please try again!",
        });
        return error.response.data;
      });
    return await response1;
  } catch (error) {
    // console.log(error, "errorerror")
    // return error
    dispatch({
      type: "AUTH_USER_LOGGEDIN_ERROR",
      payload: error.response ? error.response.data : "Some error occured, please try again!",
    });
  }
};
export const setErrorClose = () => async (dispatch, getState) => {
  dispatch({ type: "AUTH_USER_LOGGEDIN_ERROR", payload: false });
};
export const LogOutFunction = () => async (dispatch, getState) => {
  localStorage.removeItem("PLIPL_USER");

  dispatch({ type: "AUTH_USER_LOGGEDIN", payload: null });
  return {
    error: false,
  };
};
