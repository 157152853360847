import React, { Component } from "react";
import Layout from "../layouts/Layout";
import Paper from "@material-ui/core/Paper";

export default class MastersManagements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      tabs: [
        {
          label: "Masters",
          tooltip: "Click to approve users",
          data: [],
          route: "/masters/user",
          avatarField: "logo",
          primaryField: "name",
          secondaryField: "email"
        }
      ]
    };
  }

  componentDidMount() {
    this.setInitialValues(this.props)
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.path === "/masters/new")
      console.log("Handle New Button Events");
  }

  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data =
        props.users && props.users.length > 0 ? props.users : [];
      return tab;
    });
    this.setState({ users: props.users, tabs });
  }

  render() {

    const { tabs, users, user } = this.state;
    return (
      <Layout
        tabs={tabs}
        search={{
          data: users, // Optional, In case if you not providing this, tabs data will be placed.
          hintText: "Search Dates", // Optional
          labelField: "name"
        }}
        fabClickHandler={() => this.props.history.push("/masters/new")}
      >
        <Paper
          style={{
            width: "100%",
            height: "100%"
          }}
        >
          Your modules will be here.
        </Paper>
      </Layout>
    );
  }
}
