import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white, darkestGray, black } from "../styles/colors";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import { withStyles } from "@material-ui/styles";
import BiltiPrint from "./BiltiPrint";
import PDFPrint from "./PDFPrint";
import { CheckCircle, MoreHorizOutlined } from "@material-ui/icons";
import {
  Paper,
  InputBase,
  Avatar,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Button,
  Divider,
  AppBar,
  ListItem,
  List,
  Box,
  Typography,
  ClickAwayListener,
  Popover,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import { StyledCircularProgress } from "../styles/StyledComponents";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your libs

import Moment from "moment";
import { BottomArrow, Reload, TopArrow } from "../styles/Icons";
import NoData from "../styles/Illustrations/NoData";
import { CancelIcon, SearchIcon, PlusIcon, CrossIcon } from "../styles/Icons";
import ReactDOM from "react-dom";
import EPermitsMobileView from "./EPermitsMobileView";
const CssKeyboardDatePicker = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input ": {
        caretColor: "transparent",
      },
    },
  },
})(KeyboardDatePicker);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const CssKeyboardDateTimePicker = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#424242",
      borderColor: white,

      // background: "#F4F4FA",
    },
    "& .MuiInputBase-input": {
      padding: "14px 0",
      borderRadius: "2px",
      color: "transparent",
      cursor: "pointer",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      // background: white,
      background: white,
      border: "2px solid lightgrey",
      borderRadius: "5px",
      color: white,
    },
    "& .MuiSvgIcon - root": {
      fill: "none",
      width: " 1em",
      height: " 1em",
      display: "inline - block",
      fontSize: "1.5rem",
      transition: "fill 200ms cubic - bezier(0.4, 0, 0.2, 1) 0ms",
      flexshrink: "0",
      userSelect: "none",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(13.5px, 13.5px) scale(1)",
      color: "rgb(0 0 0 / 87%)",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      color: white,
    },
    "& .MuiInput-underline:after": {
      // borderBottomColor: darkestGray,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInput-underline:before": { borderBottom: "none" },
    "& .MuiFormHelperText-root": {
      margin: "0",
      backgroundColor: white,
      color: white,
      height: "0",
    },
    "& .MuiPickersBasePicker-container": {
      maxWidth: "350px",
      width: "100%",
      margin: "0 0 0 -43px",
      display: "flex",
      justifyContent: "center",
    },

    "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
    "& .MuiOutlinedInput-root": {
      "& .MuiPickersToolbar-toolbar": { backgroundColor: primary },
      "& .MuiOutlinedInput-input ": {
        padding: "19px  0 19px 14px",
        color: "rgba(0, 0, 0, 0.87)",
      },
      "& .MuiIconButton-root": {
        padding: "2px 0 2px 0",
        margin: "10px 0 10px 12px",
        borderRadius: "2px",
        color: " #1e3559",
      },
      "& fieldset": {
        // borderColor: darkestGray,
      },
      "&:hover fieldset": { borderColor: darkestGray },
      "&.Mui-focused fieldset": {
        // borderColor: darkestGray,
      },
    },
  },
})(KeyboardDatePicker);
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",

    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  withDivider50: { width: "calc(40% - 5px)" },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
};
const templateTabs = [
  {
    //label: "E-Permits",
    tooltip: "Click to approve pending users",
    data: [],
    route: "/e-permit/Details",
    primaryField: "name",
    primarySecondValue: "pending_quantity",
    primaryFieldThirdFallback: "total_open_permits",

    salesList: true,
    //primaryFieldThirdFallback: "total_open_permits",
    avatarField: "src",
    dispatchList: true,
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class EPermits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: templateTabs,
      user: null,
      SalesOrder: "",
      singleDate: null,
      singleselectedEpermit: "",
      bulkpermit: [],
      bulkpermitCopy: [],

      selectedDate: new Date(),
      RoyaltyMonth: new Date(),
      salesEdit: "",
      selectedEpermit: "",
      success: "",
      epermitStatusList: ["open", "draft", "closed", "paused"],
      epermitStatus: "open",
      SearchBarValue: "",
      error: "",
      errorMsg: {
        ItemName: false,
        BulkPermitNo: false,
        epermitStatus: false,
        IssueQuantity: false,
        RoyaltyMonth: false,
        IbmRatePerMT: false,
        selectedDate: false,
      },
      total_quantity: 0,
      total_dispatch: 0,
      users: [],
      fieldName: ["bulk_permit_no", "issue_quantity", "royalty_month", "ibm_rate_per_mt", "issue_date"],
      colorArr: [white, lightGrey],
      singleEPermit: false,
      openDialogEpermit: false, //cardColor2, cardColor3, cardColor4],
      calenderOpen: false,
      calenderOpen1: false,
    };
  }

  componentWillMount() {
    const next = this.props;
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Details" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singleDate: this.state.tabs[0].data[next.match.params["id"]],

            singleselectedEpermit: this.state.tabs[0].data[next.match.params["id"]],
          },
          () => {
            if (this.state.fetched === false) {
              this.props.fetchSalesOrderBulkPermit(this.state.ItemGradeName, this.props.user.user.uid);
              this.setState({ fetched: true, openMessage: false });
              // this.setState({ ItemGradeName: this.state.ItemGradeName });
            }
          }
        );
      } else if (tab === "new") {
        this.setState({
          singleDate: null,
          ConsigneeName: "",
          ItemGradeName: "",
          itemGradeId: "",
        });
      }
    } else {
      this.setState({ singleDate: null });
    }
    this.setInitialValues(this.props);
  }
  handleTouchTap = (event, id) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      selectedEpermit: id,
    });
  };
  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Dates" && next.match.params.hasOwnProperty("id")) {
        this.setState({
          user: this.state.tabs[0].data[next.match.params["id"]],
        });
      } else if (tab === "new") {
        this.setState({ user: null });
      }
    } else this.setState({ user: null });
  }

  listClickHandler = (value) => {
    console.log(value, "aaaaaaaaanuuuuuuuuuuuuuuuuuuuuu");
    this.setState(
      {
        singleselectedEpermit: value,
        singleEPermit: true,
        ItemGradeName: value.name,
        itemGradeId: value.id,
        bulkpermit: [],
        bulkpermitCopy: [],
      },

      () => {
        this.props.fetchSalesOrderBulkPermit(this.state.ItemGradeName, this.props.user.user.uid);
      }
    );
    console.log(this.state.singleselectedEpermit, "ffffffffffffffffffff");
  };

  handleDateChange = (selectedDate, RoyaltyMonth) => {
    console.log(selectedDate, "selectedDateselectedDate");
    this.setState(
      RoyaltyMonth
        ? {
            RoyaltyMonth: selectedDate,
          }
        : {
            selectedDate: selectedDate,
          },

      () => {
        console.log(selectedDate, "ppppppppppppppppppp");
        // liveParty:
      }
    );
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;

      tab.data = props.items_grade && props.items_grade.length > 0 ? props.items_grade : [];

      return tab;
    });
    const arr = props.bulkpermit && props.bulkpermit.length > 0 ? props.bulkpermit.sort((a, b) => b.id - a.id) : [];
    const newArr = [];
    const total_quantity = [];
    const total_dispatch = [];
    if (props.bulkpermit && props.bulkpermit.length > 0) {
      const { itemNamelist, itemsGradelist } = this.state;

      arr.map((datas) => {
        datas.ItemName =
          itemNamelist &&
          itemNamelist.filter((data) => data.id === datas.item).length > 0 &&
          itemNamelist.filter((data) => data.id === datas.item)[0] &&
          itemNamelist.filter((data) => data.id === datas.item)[0].name;
        datas.ItemGradeName =
          itemsGradelist &&
          itemsGradelist.filter((data) => data.id === datas.grade).length > 0 &&
          itemsGradelist.filter((data) => data.id === datas.grade)[0] &&
          itemsGradelist.filter((data) => data.id === datas.grade)[0].name;
        newArr.push(datas);
        total_quantity.push(Number(datas.issue_quantity));

        total_dispatch.push(Number(datas.ibm_rate_per_mt));
      });
    }
    console.log(props.bulkpermit, "bulkpermitbulkpermit");
    this.setState(
      {
        total_quantity: total_quantity.reduce((a, b) => Math.floor(a + b), 0),
        total_dispatch: total_dispatch.reduce((a, b) => Math.floor(a + b), 0),
        items_grade: props.items_grade,
        tabs,
        itemNamelist: props.items,
        itemsGradelist: props.items_grade,
        loading: props.loading,
        bulkpermit: newArr,
        bulkpermitCopy: newArr,

        // bulkpermit: props.bulkpermit
      },
      () => {}
    );
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;

    this.setState({ [event.target.name]: event.target.value });
  };
  handlePopoverClose = () => {
    this.handleRequestClose();
  };
  handleRequestClose = () => {
    this.setState({ open: false, anchorEl: null });
  };
  handleClearFields = () => {
    this.setState({
      ItemName: "",
      itemsId: "",
      salesEdit: false,
      BulkPermitNo: "",
      epermitStatus: "open",
      IssueQuantity: "",
      IbmRatePerMT: "",
      selectedDate: new Date(),
      RoyaltyMonth: new Date(),
      errorMsg: {
        ItemName: false,
        BulkPermitNo: false,
        epermitStatus: false,
        IssueQuantity: false,
        RoyaltyMonth: false,
        IbmRatePerMT: false,
        selectedDate: false,
      },
    });
  };
  setEditData = (singleSales) => {
    const { itemsGradelist, itemNamelist } = this.state;
    this.setState({
      itemGradeId: singleSales.grade,
      itemsId: singleSales.item,
      ItemName:
        itemNamelist &&
        itemNamelist.filter((data) => data.id === singleSales.item).length > 0 &&
        itemNamelist.filter((data) => data.id === singleSales.item)[0] &&
        itemNamelist.filter((data) => data.id === singleSales.item)[0].name,
      ItemGradeName:
        itemsGradelist &&
        itemsGradelist.filter((data) => data.id === singleSales.grade).length > 0 &&
        itemsGradelist.filter((data) => data.id === singleSales.grade)[0] &&
        itemsGradelist.filter((data) => data.id === singleSales.grade)[0].name,
      // ItemName: singleSales.item,
      BulkPermitNo: singleSales.bulk_permit_no.split("EP_NO_")[1],
      IssueQuantity: singleSales.issue_quantity,
      RoyaltyMonth: singleSales.royalty_month,
      IbmRatePerMT: singleSales.ibm_rate_per_mt,
      selectedDate: singleSales.issue_date,
      epermitStatus: singleSales.status,
      success: "",
    });
    console.log(singleSales.bulk_permit_no.split("EP_NO_")[1], "vvvvvvvvvvv");
  };
  render() {
    const {
      tabs,
      users,
      user,
      name,
      page,
      rowsPerPage,
      contentArea,
      pdfExportComponent,
      colorArr,
      copyTripList,
      selectedDate,
      searchbarvalue,
      singleEPermit,
      openDialogEpermit,
      success,
      itemNamelist,
      itemsGradelist,
      itemsId,
      ItemName,
      ItemGradeName,
      itemGradeId,
      Issuequantity,
      Royaltymonth,
      IbmRatePerMT,
      BulkPermitNo,
      bulkpermit,
      loading,
      IssueQuantity,
      RoyaltyMonth,
      bulkpermitCopy,
      salesEdit,
      epermitStatusList,
      epermitStatus,
      fieldName,
      total_quantity,
      errorMsg,
      total_dispatch,
      calenderOpen,
      calenderOpen1,
    } = this.state;
    const { isMobile } = this.props;
    return isMobile ? (
      <EPermitsMobileView
        {...this.state}
        {...this.props}
        // fetchSalesOrdersDate={this.props.fetchSalesOrdersDate}
        // fetchSalesOrdersDaySummary={this.props.fetchSalesOrdersDaySummary}
        // fetchSingleDateDetails={this.props.fetchSingleDateDetails}
      />
    ) : (
      <Layout
        loading={loading.items_grade}
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          {
            data: name,
            hintText: "Search Grades",
            labelField: "name",
          } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={this.listClickHandler}
        serchClick={this.listClickHandler}
        // centerWidth="230px"
        // openWidth="521.46px"
        // closeWidth="287px"
      >
        {" "}
        <Popover
          open={this.state.open}
          onRequestClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
          style={{ marginTop: "-2.5%" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <ClickAwayListener onClickAway={() => this.handlePopoverClose()}>
            <List>
              <ListItem
                button
                onClick={() => {
                  this.setEditData(this.state.selectedEpermit);
                  this.setState({
                    openDialogEpermit: true,
                    salesEdit: true,
                  });
                }}
                style={styles.listItemsStyle}
              >
                <p
                  style={{
                    margin: "0",
                    fontSize: "0.9rem", // color: grey,
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Edit
                </p>
              </ListItem>
            </List>
          </ClickAwayListener>
        </Popover>
        {openDialogEpermit == true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "5px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "517.98px";
                target.style.height = "517.98px";
                target.style.minWidth = "80%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({ openDialogEpermit: false, success: "" });
              this.handleClearFields();
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                //display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1.3rem",
                background: "rgb(30 53 89)",
                padding: "10px 24px",
              }}
            >
              <DialogActions
                classname="d-flex justifySB"
                style={
                  { background: primary, color: "white", padding: "0" }
                  //padding: "10px 24px",
                }
                onClick={() => {
                  this.setState({ openDialogEpermit: false });
                  this.handleClearFields();
                }}
              >
                <span
                  style={{
                    color: "white",
                    width: "calc(100% - 32.8px)",
                  }}
                >
                  {salesEdit ? "Edit Bulk Permit" : "Add Bulk Permit"}
                </span>

                <Tooltip title="close" arrow placement="top">
                  <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                    <CrossIcon strokeWidth="2" />
                  </IconButton>
                </Tooltip>
              </DialogActions>{" "}
            </div>{" "}
            {success ? (
              <div
                style={{
                  position: "relative",

                  padding: "16px 24px",
                  height: "calc(100% - 49.73px)",
                  minHeight: "452.25px",
                }}
              >
                <div
                  className=" width-100 d-flex alignC justifyC d-flex-column"
                  style={{
                    height: "calc(100% - 96.5px)",
                    minHeight: "356.20px",
                  }}
                >
                  <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
                  <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                    {success}
                  </span>
                </div>
                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    style={{
                      background: primary,
                      color: "white",
                      width: "200px",
                      position: "absolute",
                      bottom: "16px",
                      right: "24px",
                    }}
                    onClick={() => {
                      this.setState({ success: "" });
                      this.handleClearFields();
                    }}
                  >
                    Add Another
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "relative",
                  height: "calc(100% - 49.73px)",
                  padding: "16px 24px",
                }}
              >
                <div
                  style={
                    {
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0",
                    } //height: "100%",
                  }
                >
                  <CssTextField
                    autoComplete="off"
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    required={true}
                    onKeyDown={(evt) =>
                      (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") && evt.preventDefault()
                    }
                    style={
                      { width: "calc(33.34% - 40px)", margin: "0 30px" } //disabled={tp}
                    }
                    id="outlined-basic"
                    label="Bulk Permit No #"
                    name="BulkPermitNo"
                    variant="outlined"
                    value={BulkPermitNo}
                    error={errorMsg.BulkPermitNo ? true : false}
                    helperText={errorMsg.BulkPermitNo ? errorMsg.BulkPermitNo : ""}
                    InputProps={{
                      startAdornment: BulkPermitNo ? " EP_NO_" : "",
                    }}
                    onChange={this.handleChange}
                  />
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    value={
                      ItemName ? ItemName : "" //
                    }
                    error={errorMsg.ItemName ? true : false}
                    helperText={errorMsg.ItemName ? errorMsg.ItemName : ""}
                    onChange={(e) => {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.ItemName = false;
                      this.setState({
                        errorMsg,
                        itemsId: e.currentTarget.id,
                        ItemName: e.target.value,
                      });
                    }}
                    style={
                      { width: "calc(33.34% - 40px)", margin: "0 30px 0 0" } //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    label="Item Name"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {itemNamelist.map((option) => (
                      <MenuItem key={option.name} value={option.name} id={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                  <CssTextField
                    disabled={true}
                    required={true}
                    autoComplete="off"
                    value={
                      ItemGradeName ? ItemGradeName : "" //
                    }
                    // error={errorMsg.ItemGradeName ? true : false}
                    // helperText={errorMsg.ItemGradeName ? errorMsg.ItemGradeName : ""}
                    onChange={(e) => {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.ItemGradeName = false;
                      this.setState({
                        errorMsg,
                        itemGradeId: e.currentTarget.id,
                        ItemGradeName: e.target.value,
                      });
                    }}
                    style={
                      { width: "calc(33.33% - 40px)", margin: "0 30px" } //disabled={tp}
                    }
                    id="outlined-select-currency-native"
                    select
                    label="Grade Name"
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {itemsGradelist.map((option) => (
                      <MenuItem key={option.name} value={option.name} id={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "1rem",
                    color: "grey",
                    background: "white",
                    margin: "30px 0",
                  }}
                >
                  <CssTextField
                    type="number"
                    required={true}
                    onKeyDown={(evt) => (evt.key === "-" || evt.key === "e" || evt.key === "E") && evt.preventDefault()}
                    autoComplete="off"
                    style={
                      { width: "calc(33.34% - 40px)", margin: "0 30px" } //disabled={tp}
                    }
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    id="outlined-basic"
                    label="Issue Quantity"
                    name="IssueQuantity"
                    variant="outlined"
                    error={errorMsg.IssueQuantity ? true : false}
                    helperText={errorMsg.IssueQuantity ? errorMsg.IssueQuantity : ""}
                    value={
                      IssueQuantity // helperText={errorMsg.Rate ? "Please Add Rate!" : false} // error={errorMsg.Rate ? true : false}
                    }
                    onChange={this.handleChange}
                  />
                  <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                    <CssKeyboardDatePicker
                      variant="inline"
                      onOpen={() => {
                        this.setState({ calenderOpen1: true });
                      }}
                      onClose={() => {
                        this.setState({ calenderOpen1: false });
                      }}
                      keyboardIcon={calenderOpen1 ? <TopArrow /> : <BottomArrow />}
                      maxDate={new Date()}
                      disableFuture
                      // minDate={"2022-01-22"}
                      error={false}
                      invalidLabel=""
                      onKeyPress={(ev) => {
                        ev.preventDefault();
                      }}
                      allowKeyboardControl={false}
                      clearable
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label={"Royalty Month"}
                      format="MMM, yyyy"
                      style={{ maxWidth: "calc(33.34% - 40px)", minWidth: "calc(33.34% - 40px)", cursor: "none" }}
                      value={RoyaltyMonth ? RoyaltyMonth : ""}
                      views={["year", "month"]}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(date) => this.handleDateChange(date, "RoyaltyMonth")}
                    />
                  </MuiPickersUtilsProvider>{" "}
                  <CssTextField
                    type="number"
                    required={true}
                    onKeyDown={(evt) => (evt.key === "-" || evt.key === "e" || evt.key === "E") && evt.preventDefault()}
                    autoComplete="off"
                    style={
                      { width: "calc(33.33% - 40px)", margin: "0 30px" } //disabled={tp}
                    }
                    InputProps={{
                      inputProps: {
                        min: 1,
                      },
                    }}
                    id="outlined-basic"
                    label="IBM Rate Per MT"
                    name="IbmRatePerMT"
                    InputProps={{
                      endAdornment: IbmRatePerMT ? "MT" : "",
                    }}
                    variant="outlined"
                    error={errorMsg.IbmRatePerMT ? true : false}
                    helperText={errorMsg.IbmRatePerMT ? errorMsg.IbmRatePerMT : ""}
                    value={
                      IbmRatePerMT // helperText={errorMsg.Rate ? "Please Add Rate!" : false} // error={errorMsg.Rate ? true : false}
                    }
                    onChange={this.handleChange}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: "1rem",
                    color: "grey",
                    background: "white",
                    margin: "0 30px",
                  }}
                >
                  <MuiPickersUtilsProvider libInstance={moment()} utils={DateFnsUtils}>
                    <CssKeyboardDatePicker
                      variant="inline"
                      onOpen={() => {
                        this.setState({ calenderOpen: true });
                      }}
                      onClose={() => {
                        this.setState({ calenderOpen: false });
                      }}
                      keyboardIcon={calenderOpen ? <TopArrow /> : <BottomArrow />}
                      maxDate={new Date()}
                      disableFuture
                      // minDate={"2022-01-22"}
                      error={false}
                      invalidLabel=""
                      onKeyPress={(ev) => {
                        ev.preventDefault();
                      }}
                      allowKeyboardControl={false}
                      clearable
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      label={"Date"}
                      format="do MMM, yyyy"
                      style={{ maxWidth: "calc(33.34% - 40px)", minWidth: "calc(33.34% - 40px)", cursor: "none" }}
                      value={selectedDate ? selectedDate : ""}
                      InputAdornmentProps={{ position: "end" }}
                      onChange={(date) => this.handleDateChange(date)}
                    />{" "}
                  </MuiPickersUtilsProvider>
                  <CssTextField
                    required={true}
                    autoComplete="off"
                    disabled={salesEdit ? false : true}
                    value={
                      epermitStatus ? epermitStatus : "" //
                    }
                    onChange={(e) => {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.GradeName = false;
                      this.setState({ epermitStatus: e.target.value, errorMsg });
                    }}
                    // error={errorMsg.epermitStatus ? true : false}
                    // helperText={errorMsg.epermitStatus ? errorMsg.epermitStatus : ""}
                    style={{ width: "calc(33.34% - 40px)", margin: "0 30px" }}
                    id="outlined-select-currency-native"
                    select
                    label="E-permit Status "
                    variant="outlined"
                    InputLabelProps={
                      {} // } //     : false //     ? "Please Add TransporterName!" //   errorMsg.Transporter // helperText={ // error={errorMsg.Transporter ? true : false}
                    }
                  >
                    {epermitStatusList.map((option) => (
                      <MenuItem key={option} value={option} id={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </CssTextField>
                </div>

                <div style={{ display: "flex", justifyContent: "right" }}>
                  <Button
                    style={{
                      background: primary,
                      color: "white",
                      width: "120px",
                      position: "absolute",
                      bottom: "16px",
                      right: "24px",
                    }}
                    onClick={() => {
                      // disabled={VehicleNo && Transporter && TpIdentifier && TransporterGroup ? false : true}
                      if (BulkPermitNo) {
                        if (ItemName) {
                          if (IssueQuantity && IssueQuantity !== 0) {
                            if (RoyaltyMonth) {
                              if (IbmRatePerMT) {
                                if (selectedDate) {
                                  if (epermitStatus) {
                                    if (!salesEdit) {
                                      const addData = {
                                        bulk_permit_no: `EP_NO_${BulkPermitNo}`,
                                        item: itemsId,
                                        grade: itemGradeId,
                                        issue_quantity: IssueQuantity,
                                        royalty_month: moment(RoyaltyMonth).format("YYYY-MM"),
                                        ibm_rate_per_mt: IbmRatePerMT,
                                        issue_date: selectedDate,
                                        status: epermitStatus,
                                      };
                                      this.props.ePermitAdd(addData, this.props.user.user.uid).then(() => {
                                        this.props
                                          .fetchSalesOrderBulkPermit(this.state.ItemGradeName, this.props.user.user.uid)
                                          .then((res) => {
                                            if (res) {
                                              this.setState(
                                                {
                                                  success: "Added successfully !",
                                                  epermits: res,
                                                  editVehicles: false,
                                                  openMessage: true,
                                                },
                                                () => {
                                                  this.handleClearFields();
                                                }
                                              );
                                            }
                                          });
                                      });
                                    } else {
                                      const editData = {
                                        bulk_permit_no: `EP_NO_${BulkPermitNo}`,
                                        item: itemsId,
                                        grade: itemGradeId,
                                        issue_quantity: IssueQuantity,
                                        royalty_month: moment(RoyaltyMonth).format("YYYY-MM"),
                                        ibm_rate_per_mt: IbmRatePerMT,
                                        issue_date: selectedDate,
                                        status: epermitStatus,
                                      };
                                      this.props
                                        .ePermitEdit(
                                          editData,

                                          this.props.user.user.uid,
                                          this.state.selectedEpermit.id
                                        )
                                        .then(() => {
                                          this.props
                                            .fetchSalesOrderBulkPermit(
                                              this.state.ItemGradeName,
                                              this.props.user.user.uid
                                            )
                                            .then((res) => {
                                              if (res) {
                                                this.setState({
                                                  success: "Edited successfully !",
                                                  epermits: res,
                                                  editVehicles: false,
                                                  openMessage: false,
                                                });
                                              }
                                            });
                                        });
                                    }
                                  } else {
                                    var errorMsg = this.state.errorMsg;
                                    errorMsg.epermitStatus = "Please select Epermit status!";
                                    this.setState({ errorMsg });
                                  }
                                } else {
                                  var errorMsg = this.state.errorMsg;
                                  errorMsg.selectedDate = "PLease select a date!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                var errorMsg = this.state.errorMsg;
                                errorMsg.IbmRatePerMT = "Please add ibm rate!";
                                this.setState({ errorMsg });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.RoyaltyMonth = "Please add royalty month!";
                              this.setState({ errorMsg });
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.IssueQuantity = "Please add issue quantity !";
                            this.setState({ errorMsg });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.ItemName = "Please Select any item !";
                          this.setState({ errorMsg });
                        }
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.BulkPermitNo = "Please give bulk permit number !";
                        this.setState({ errorMsg });
                      }
                    }}
                  >
                    {salesEdit ? "Edit" : "Add"}
                  </Button>
                </div>
              </div>
            )}
          </Dialog>
        ) : (
          ""
        )}
        {singleEPermit == true ? (
          <Paper
            style={{
              width: "100%",
              height: "100%",
              padding: "15px",
              position: "relative",
            }}
          >
            {" "}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Paper style={styles.paperStyle} elevation={3} component="form">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 12px",
                  }}
                >
                  <SearchIcon color="rgb(16, 15, 15)" />
                </div>
                <InputBase
                  onChange={(event) => {
                    this.setState(
                      {
                        SearchBarValue: event.target.value,
                      },
                      () => {
                        const filterData = [];
                        if (this.state.SearchBarValue !== "") {
                          bulkpermitCopy.map((data) => {
                            fieldName.map((singleField) => {
                              if (
                                (singleField === "start_date" &&
                                  data[singleField] &&
                                  moment(data[singleField])
                                    .format("DD/MM/YY")
                                    .toString()
                                    .toLowerCase()
                                    .includes(this.state.SearchBarValue.toString().toLowerCase())) ||
                                (data[singleField] &&
                                  data[singleField]
                                    .toString()
                                    .toLowerCase()
                                    .includes(this.state.SearchBarValue.toString().toLowerCase()))
                              ) {
                                if (!filterData.includes(data)) {
                                  filterData.push(data);
                                }
                              }
                            });
                          });
                          this.setState({
                            bulkpermit: filterData.sort((a, b) => b.id - a.id),
                          });
                        } else {
                          this.setState({
                            bulkpermit: bulkpermitCopy.sort((a, b) => b.id - a.id),
                          });
                        }
                      }
                    );
                  }}
                  disabled={bulkpermitCopy && bulkpermitCopy.length > 0 ? false : true}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                    }
                  }}
                  style={{
                    fontSize: "0.9rem",
                    width: "100%",
                    color: "rgb(16, 15, 15)",
                  }}
                  placeholder="Search"
                  inputProps={{ "aria-label": "search google maps" }}
                  value={this.state.SearchBarValue}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0 9.5px",
                    cursor: "pointer",
                  }}
                >
                  {this.state.SearchBarValue ? (
                    <Avatar
                      onClick={(event) => {
                        this.setState(
                          {
                            SearchBarValue: "",
                          },
                          () => {
                            this.setState({
                              bulkpermit: bulkpermitCopy.sort((a, b) => b.id - a.id),
                            });
                          }
                        );
                      }}
                      style={
                        {
                          color: "white",
                          backgroundColor: "#BEBCBC",
                          borderRadius: "50%",
                          width: "18px",
                          height: "18px",
                          fontSize: "0.7rem",
                          textAlign: "center",
                          justifyContent: "center",
                        } // }} //   ); //     } //       }); //         singleDateTrips: newData, //       this.setState({ //       newData.Trips = singleDateTripsCopy; //       const newData = singleDateTrips; //     () => { //     }, //       SearchBarValue: "", //     { //   this.setState( // onClick={(event) => {
                      }
                    >
                      <CancelIcon />
                    </Avatar>
                  ) : (
                    ""
                  )}
                </div>
              </Paper>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    this.setState({ openDialogEpermit: true, success: "" });
                    this.handleClearFields();
                  }}
                  style={{
                    background: "#1e3559",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <PlusIcon />
                  </div>
                  Add New E-Permit
                </Button>
              </div>
            </div>
            <div
              ref={this.myRef}
              style={{
                margin: "20px 0 0 0",
                height: "calc(100% - 58px)",
                width: "100%",
              }}
            >
              <Paper
                className="d-flex d-flex-column scrollBluePrimary"
                style={
                  {
                    height: "fot-content",
                    width: "100%",
                    borderRadius: "7px",
                    maxHeight: "calc(100% - 66px)",
                    overflowX: "auto",
                  }
                  // border: `1px solid ${primary}`,
                }
              >
                <div
                  style={
                    {
                      backgroundColor: "rgb(158 158 158 / 48%)",
                      color: primary,
                      borderColor: primary,
                      width: "fit-content",
                      minWidth: "100%",
                      tableLayout: "fixed",
                      display: "flex",
                      alignItems: "center",
                      height: "50px",
                      minHeight: "50px",
                      overflow: "hidden",
                      borderRadius: "7px 7px 0 0",
                    } // justifyContent: "space-between",
                  }
                >
                  <div className="d-flex alignC">
                    <div
                      style={{
                        padding: "12px 10px",
                        width: "63.79px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                      }}
                    >
                      Sr.No.
                    </div>
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "12px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "135px",
                        maxWidth: "135px",
                        fontWeight: "500",
                      }}
                    >
                      Issue Date
                    </div>
                    <div
                      style={
                        {
                          color: "#100f0f",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px",
                          minWidth: "135px",
                          maxWidth: "135px",
                          fontWeight: "500",
                        }
                        // margin: "0 10px",
                      }
                    >
                      Bulk Permit No
                    </div>{" "}
                    <div
                      align="center"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "154px",
                        maxWidth: "154px",
                        fontWeight: "500",
                      }}
                    >
                      Item Name
                    </div>
                  </div>
                  <div
                    style={{
                      color: "#100f0f",
                      padding: "4px",
                      minWidth: "92.55px",
                      width: "125px",
                      maxWidth: "125px",
                      fontWeight: "500",
                    }}
                    className="d-flex justifyC alignC"
                  >
                    Issue Quantity
                  </div>{" "}
                  <div
                    className="d-flex justifyC alignC"
                    style={{
                      color: "#100f0f",
                      padding: "4px",
                      minWidth: "92.55px",
                      width: "125px",
                      maxWidth: "125px",
                      fontWeight: "500",
                    }}
                  >
                    Royalty Month
                  </div>
                  <div className="d-flex alignC">
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "105px",
                        width: "125px",
                        maxWidth: "125px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      Ibm Rate per MT
                    </div>{" "}
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "105px",
                        width: "105px",
                        maxWidth: "105px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      Dispatched MT
                    </div>{" "}
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "92.55px",
                        width: "92.55px",
                        maxWidth: "92.55px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      Pending MT
                    </div>{" "}
                    <div
                      align="center"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "125px",
                        maxWidth: "125px",
                        fontWeight: "500",
                      }}
                    >
                      {" "}
                      Dispatch Status
                    </div>{" "}
                    <div
                      align="center"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "120px",
                        maxWidth: "120px",
                        fontWeight: "500",
                      }}
                    >
                      Action
                    </div>
                  </div>
                </div>

                <div
                  style={
                    {
                      height: "fot-content",
                      maxHeight: "calc(100% - 50px)",
                      minHeight: "250px",
                      overflowY: "auto",
                      width: "fit-content",
                      minWidth: "100%",
                      position: "relative",
                    } // overflowX: "hidden",
                  }
                >
                  <div
                    id="mycanvas"
                    style={
                      bulkpermit && bulkpermit.length !== 0
                        ? { height: "fit-content", width: "fit-content" }
                        : { height: "100%", width: "100%" }
                    }
                  >
                    {loading.items_grade === true ? (
                      <div
                        className="alignC justiyC d-flex"
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <StyledCircularProgress color="inherit" />
                      </div>
                    ) : bulkpermit && bulkpermit.length !== 0 ? (
                      bulkpermit &&
                      bulkpermit
                        // .sort((a, b) => {
                        //   var dateA = moment(b.date).format("Do MMM");
                        //   var dateB = moment(a.date).format("Do MMM");
                        //   return dateA > dateB ? 1 : -1;
                        // })
                        .sort((a, b) => b.id - a.id)
                        .map((singleSales, i) => (
                          <>
                            <div
                              className="d-flex"
                              style={{
                                margin: "1px 0 0 0",
                                width: "100%",
                                backgroundColor: colorArr[i % colorArr.length],
                                height: "54px",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="d-flex alignC">
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "63.79px",
                                    display: "flex",
                                    alignItems: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {bulkpermit.length - i < 10 ? `0${bulkpermit.length - i}` : bulkpermit.length - i}{" "}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "135px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleSales.issue_date ? moment(singleSales.issue_date).format("YYYY-MM-DD") : "-"}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "135px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleSales.bulk_permit_no}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "154px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleSales.ItemName}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "125px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleSales.issue_quantity}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "125px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleSales.royalty_month}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "125px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {singleSales.ibm_rate_per_mt}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "92.55px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {Number(singleSales.dispatch_details.dispatched_quantity).toFixed(0)}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "105px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {typeof singleSales.dispatch_details.pending_quantity === "number"
                                    ? Number(singleSales.dispatch_details.pending_quantity).toFixed(3)
                                    : "-"}
                                </div>
                                <div
                                  style={{
                                    padding: "12px 10px",
                                    width: "125px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    color: "#808080d1",
                                  }}
                                >
                                  {" "}
                                  <span
                                    style={{
                                      background: "#03a9f424",
                                      borderRadius: "5px",
                                      padding: "5px 10px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {singleSales.status}
                                  </span>
                                </div>

                                <div
                                  onClick={(e) => this.handleTouchTap(e, singleSales)}
                                  align="center"
                                  style={{
                                    padding: "12px 4px",
                                    minWidth: "120px",
                                    maxWidth: "120px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <MoreHorizOutlined />
                                </div>
                              </div>
                            </div>
                            <Divider />
                          </>
                        ))
                    ) : (
                      <div
                        className="d-flex width-100 height-100 alignC justifyC d-flex-column"
                        style={{ minHeight: "250px" }}
                      >
                        <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>No Epermits yet !</span>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={
                    {
                      backgroundColor: "rgb(158 158 158 / 48%)",
                      color: primary,
                      borderColor: primary,
                      width: "fit-content",
                      minWidth: "100%",
                      tableLayout: "fixed",
                      display: "flex",
                      alignItems: "center",
                      height: "50px",
                      minHeight: "50px",
                      overflow: "hidden",
                      //borderRadius: "7px 7px 0 0",
                    } // justifyContent: "space-between",
                  }
                >
                  <div className="d-flex alignC">
                    <div
                      style={{
                        padding: "12px 10px",
                        width: "63.79px",
                        display: "flex",
                        alignItems: "center",
                        color: "#100f0f",
                      }}
                    />
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "12px 10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minWidth: "135px",
                        maxWidth: "135px",
                        fontWeight: "500",
                      }}
                    >
                      Grand Total
                    </div>
                    <div
                      style={
                        {
                          color: "#100f0f",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "4px",
                          minWidth: "135px",
                          maxWidth: "135px",
                          fontWeight: "500",
                        }
                        // margin: "0 10px",
                      }
                    />{" "}
                    <div
                      align="center"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "154px",
                        maxWidth: "154px",
                        fontWeight: "500",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "#100f0f",
                      padding: "4px",
                      minWidth: "125px",
                      // width: "125px",
                      maxWidth: "125px",
                      fontWeight: "500",
                    }}
                    className="d-flex justifyC alignC"
                  >
                    {" "}
                    {total_quantity} MT
                  </div>
                  <div
                    className="d-flex justifyC alignC"
                    style={{
                      color: "#100f0f",
                      padding: "4px",
                      minWidth: "125px",
                      //width: "125px",
                      maxWidth: "125px",
                      fontWeight: "500",
                    }}
                  />

                  <div className="d-flex alignC">
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "125px",
                        //width: "125px",
                        maxWidth: "125px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {`${bulkpermit
                        .reduce(
                          (previousValue, currentValue) => Number(previousValue) + Number(currentValue.ibm_rate_per_mt),
                          0
                        )
                        .toFixed(3)}`}
                    </div>{" "}
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "105px",
                        width: "105px",
                        maxWidth: "105px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {`${bulkpermit
                        .reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) + Number(currentValue.dispatch_details.dispatched_quantity),
                          0
                        )
                        .toFixed(3)} MT`}
                    </div>{" "}
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "92.55px",
                        width: "92.55px",
                        maxWidth: "92.55px",
                        fontWeight: "500",
                      }}
                      align="center"
                    >
                      {`${bulkpermit
                        .filter((data) => typeof data.dispatch_details.pending_quantity === "number")
                        .reduce(
                          (previousValue, currentValue) =>
                            Number(previousValue) +
                            (currentValue.dispatch_details.pending_quantity
                              ? Number(currentValue.dispatch_details.pending_quantity)
                              : 0),
                          0
                        )
                        .toFixed(3)} MT`}
                    </div>{" "}
                    <div
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "125px",
                        //width: "125px",
                        maxWidth: "125px",
                        fontWeight: "500",
                      }}
                      align="center"
                    />{" "}
                    <div
                      align="center"
                      style={{
                        color: "#100f0f",
                        padding: "4px",
                        minWidth: "125px",
                        maxWidth: "125px",
                        fontWeight: "500",
                      }}
                    />{" "}
                    {/* <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "125px",
                          maxWidth: "125px",
                          fontWeight: "500",
                        }}
                      />{" "}
                      <div
                        align="center"
                        style={{
                          color: "#100f0f",
                          padding: "4px",
                          minWidth: "125px",
                          maxWidth: "125px",
                          fontWeight: "500",
                        }}
                      /> */}
                  </div>
                </div>
              </Paper>
            </div>
          </Paper>
        ) : (
          <Paper style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: "1rem",
                color: "grey",
                background: "#d3d3d35c",
              }}
            >
              <NoData />
            </div>
          </Paper>
        )}
      </Layout>
    );
  }
}

export default withRouter(EPermits);
