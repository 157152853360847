import React from "react";
import { primary } from "../../styles/colors";

const styles = {
  container: {
    whiteSpace: "nowrap",
    fontSize: 12,
    color: "white",
    paddingRight: 45
  }
};

export default function StaticVersionDisplay(props) {

  return <span style={styles.container}>Version {props.version}</span>;
}
