import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import SubMenuList from "../components/layoutComponents/SubMenuList";
import { primary } from "../styles/colors";

const styles = {
  container: (isMobile, centerWidth) => ({
    height: isMobile ? 0 : "calc(100% - 64px)",
    width: isMobile ? "100%" : centerWidth ? "230px" : 291.47,
    minWidth: isMobile ? "100%" : centerWidth ? centerWidth : 270,
    marginTop: isMobile ? 0 : 64,
    position: "relative",
    borderRight: centerWidth && "1px solid rgb(30, 53, 89)"
  }),
  fab: { position: "absolute", bottom: 16, right: 16 },
};

function SubMenuLayout(props) {
  const fabHandler = (e) => {
    e.preventDefault();
    if (props.setOpen) props.setOpen(false);
    props.fabClickHandler();
  };

  return (
    <div style={styles.container(props.isMobile, props.centerWidth)}>
      <SubMenuList {...props} />
      {props.tabs && props.fabClickHandler && (
        <Fab
          style={styles.fab}
          color="primary"
          aria-label="add"
          size="medium"
          onClick={fabHandler}
        >
          <AddIcon />
        </Fab>
      )}
    </div>
  );
}

export default SubMenuLayout;
