const initialState = {
  loading: {
    transpoterslist: false,
    sourceCities: false,
    consignees: false,
    consignors: false,
    destinationCities: false,
    singleDateTrips: false,
    lrDetails :false
  },
  transpoterslist: [],
  sourceCities: [],
  consignees: [],
  consignors: [],
  destinationCities: [],
  singleDateTrips: {},
  lrDetails :{}
  
};

const dispatchReducer = (state = initialState, action) => {
  const load = state.loading;
  console.log(action, "actionsnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");

  switch (action.type) {
    case "FETCH_REQUEST":
      console.log(action.type, "bnewwwww");

      load[action.payload] = true;
      state[action.payload] = [];
      state.loading = load;
      return { ...state };
    case `FETCH_${action.payload}_SUCCESS`:
      console.log(action.data, "bnewwwww");
      load[action.payload] = false;
      state[action.payload] = action.data;
      state.loading = load;
      return { ...state };
    default:
      return { ...state };
  }
};

export default dispatchReducer;
