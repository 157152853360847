import React, { useState, useEffect } from "react";
import { lightGrey, primary, white } from "../styles/colors";
import { StyledButton, StyledCircularProgress, StyledSearchBar } from "../styles/StyledComponents";
import { Divider, Popover, List, ListItem, ClickAwayListener } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import { BottomArrow, Download, PlusIcon, TopArrow } from "../styles/Icons";
import { MoreHorizOutlined } from "@material-ui/icons";
// import CreateDialog from "./CreateDialog";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/styles";
import moment from "moment";
const CssTablePagination = withStyles({
  root: {
    "& .MuiSelect-icon": {
      color: primary,
    },
    "& .MuiIconButton-root": {
      color: primary,
      padding: "0",
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: primary,
      padding: "0",
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "0px",
    },
  },
})(TablePagination);
const styles = {
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
  withDivider50: { width: "calc(40% - 5px)" },
};
const TableComponent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedList, setSelectedList] = useState(null);
  const [editList, setEditList] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [pagination, setpagination] = useState(false);
  const [menuOpen, setmenuOpen] = useState(false);
  const [inputArr, setInputArray] = useState([
    { name: "title", type: "textField", dataType: "text" },
    // { name: "user", type: "select", dataType: "text" },
  ]);
  const [tableList, setTableList] = useState([]);
  const [widthList, setWidthList] = useState({});
  const [page, setPage] = React.useState(0);
  const [header, setHeader] = useState(["Sr.No."]);
  const [subMenuheader, setHeadersubMenu] = useState(["Sr.No."]);
  const [loading, setloading] = useState({});
  const [actions, setActions] = useState([
    {
      name: "edit",
      onClick: () => {
        // setEditList(true);
        if (props.onEdit) {
          props.onEdit(selectedList);
        }
      },
    },
  ]);
  const [tableListCopy, setTableListCopy] = useState([]);
  const colorArr = [white, lightGrey];

  const longest = (key, array) => {
    return Math.max(
      ...array.map((it) => {
        if (it[key]) {
          return it[key].props?.title
            ? it[key].props?.title.toString().length
            : it[key].props?.children
            ? it[key].props?.children.toString().length
            : it[key].toString().length;
        } else {
          return 1;
        }
      })
    );
  };
  const setSubmenuWidth = (subList) => {
    if (subList[0] && !Array.isArray(subList[0])) {
      const widthList1 = widthList;
      const array = props.subMenuheader ? props.subMenuheader : Object.keys(subList[0]);
      array.map((single, i) => {
        return (widthList1[`SUB${props.subMenuheader[i]}`] = `${Math.floor(
          10 *
            ((single.props?.title
              ? single.props?.title.toString().length
              : single.props?.children
              ? single.props.children.toString().length
              : single.toString().length) > longest(single, subList)
              ? single.props?.title
                ? single.props?.title.toString().length
                : single.props?.children
                ? single.props?.children?.toString().length
                : single.toString().length
              : longest(single, subList)
              ? longest(single, subList)
              : 1)
        )}px`);
      });

      setWidthList(widthList1);
    }
  };
  useEffect(
    () => {
      if (props.tableList && props.tableList.length > 0) {
        if (props.tableList[0] && !Array.isArray(props.tableList[0])) {
          const widthList = {};
          const array = props.apiHeader
            ? props.apiHeader
            : props.header
            ? props.header
            : Object.keys(props.tableList[0]);
          array.map((single, i) => {
            return (widthList[props.header[i]] = `${Math.floor(
              10 *
                ((single.props?.title
                  ? single.props?.title.toString().length
                  : single.props?.children
                  ? single.props.children.toString().length
                  : single.toString().length) > longest(single, props.tableList)
                  ? single.props?.title
                    ? single.props?.title.toString().length
                    : single.props?.children
                    ? single.props?.children?.toString().length
                    : single.toString().length
                  : longest(single, props.tableList)
                  ? longest(single, props.tableList)
                  : 1)
            )}px`);
          });

          setWidthList(widthList);
          setTableList(props.tableList ? props.tableList : []);
          setTableListCopy(props.tableList ? props.tableList : []);
          setActions(
            props.actions
              ? props.actions
              : [
                  {
                    name: "edit",
                    onClick: () => {
                      if (props.onEdit) {
                        props.onEdit(selectedList);
                      }
                    },
                  },
                ]
          );
          setloading(props.loading);
          if (props.pagination) {
            setpagination(props.pagination);
          }
          if (Object.keys(props.tableList[0]) && Object.keys(props.tableList[0]).length > 0) {
            const inputArr = [];
            Object.keys(props.tableList[0]).map((single) => {
              return inputArr.push({
                name: single,
                type: "textField",
                dataType: "text",
              });
            });
            setInputArray(inputArr);
          }
          setHeader(
            props.apiHeader
              ? props.apiHeader
              : props.header
              ? props.header
              : props.tableList && props.tableList.length > 0 && props.tableList[0]
              ? Object.keys(props.tableList[0])
              : []
          );
        }
      } else {
        setTableList([]);
        setTableListCopy([]);
      }
    },
    [props.tableList, props.loading]
  );
  const handleChangePage = (event, newPage) => {
    console.log(page, newPage, "newPage");
    if (page > newPage) {
      props.lazyLoadFetch(pagination.previous, props.loadingTitle, props.user.user.uid).then(() => {
        setPage(newPage);
      });
    } else {
      if (page < newPage) {
        props.lazyLoadFetch(pagination.next, props.loadingTitle, props.user.user.uid).then(() => {
          setPage(newPage);
        });
      }
    }
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  const { module, subMenuAction } = props;
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        padding: "15px",
        position: "relative",
      }}
    >
      {/* {openDialog === true || editList ? (
        <CreateDialog
          {...props}
          inputArray={props.inputArray ? props.inputArray : inputArr}
          editList={editList}
          selectedList={selectedList === null ? false : selectedList}
          handleClose={() => {
            setOpenDialog(false);
            setEditList(false);
          }}
          postData={props.postData}
        />
      ) : (
        ""
      )}{" "} */}
      <Popover
        open={openPopover}
        onRequestClose={() => {
          setOpenPopover(false);
          setAnchorEl(null);
        }}
        anchorEl={anchorEl}
        style={{ marginTop: "-2.5%" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <ClickAwayListener
          onClickAway={() => {
            setOpenPopover(false);
            setAnchorEl(null);
          }}
        >
          <List>
            {// menuOpen !== false
            //   ? subMenuAction &&
            //     subMenuAction.map((singleAction) => (
            //       <ListItem button onClick={singleAction.onClick} style={styles.listItemsStyle}>
            //         <p className="d-flex alignC textC" style={{ margin: "0", fontSize: "0.9rem", height: "24px" }}>
            //           {singleAction.name}
            //         </p>
            //       </ListItem>
            //     ))
            //   :
            actions &&
              actions.map((singleAction) => (
                <ListItem button onClick={singleAction.onClick} style={styles.listItemsStyle}>
                  <p className="d-flex alignC textC" style={{ margin: "0", fontSize: "0.9rem", height: "24px" }}>
                    {singleAction.name}
                  </p>
                </ListItem>
              ))}
          </List>
        </ClickAwayListener>
      </Popover>
      <>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <StyledSearchBar
            padding={"9px"}
            bgC={"#F2F2F2"}
            fieldName={
              props.apiHeader
                ? props.apiHeader
                : props.header
                ? props.header
                : Object.keys(props.tableList[0])
                ? Object.keys(props.tableList[0])
                : ["id"]
            }
            copyData={tableListCopy}
            filteredData={(filterNotify) => {
              setTableList(filterNotify);
            }}
            onClear={() => {
              setTableList(tableListCopy);
            }}
            placeholder={`Search ${module}`}
          />

          {props.noAddButton ? (
            ""
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              {props.download && (
                <Button
                  onClick={() => {
                    props.download.onClick();
                  }}
                  style={{
                    background: "#1e3559",
                    color: "white",
                    textTransform: "capitalize",
                    borderRadius: "5px",
                    margin: "0 20px 0 0",
                  }}
                >
                  <div
                    style={{
                      marginRight: "5px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Download height={"20"} width={"20"} />
                  </div>
                  Download
                </Button>
              )}
              <Button
                onClick={() => {
                  props.onAdd();
                }}
                style={{
                  background: "#1e3559",
                  color: "white",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PlusIcon />
                </div>
                Add New {module}
              </Button>
            </div>
          )}
        </div>
        <div
          className="width-100"
          style={{
            margin: "20px 0 0 0",
            height: "calc(100% - 58px)",
          }}
        >
          <Paper
            className="d-flex d-flex-column scrollBluePrimary overflowXA positionR"
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "7px",
              // border: `1px solid ${primary}`,
            }}
          >
            {" "}
            {loading[props.loadingTitle] === true ? (
              <div
                className="
                 justiyC d-flex"
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <StyledCircularProgress color="inherit" />
              </div>
            ) : tableList && tableList.length > 0 ? (
              <>
                <div
                  style={{
                    backgroundColor: "rgb(158 158 158 / 48%)",
                    color: primary,
                    borderColor: primary,
                    width: "fit-content",
                    minWidth: "100%",
                    tableLayout: "fixed",
                    display: "flex",
                    alignItems: "center",
                    height: "50px",
                    minHeight: "50px",
                    overflow: "hidden",
                    borderRadius: "7px 7px 0 0",
                  }}
                >
                  <div className="d-flex alignC justifySE width-100">
                    {props.subMenu && (
                      <div
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width: "23px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}
                      />
                    )}
                    {props.header &&
                      props.header.map((name) => (
                        <div
                          className="justifyC d-flex alignC"
                          style={{
                            margin: "12px 10px",
                            width: widthList[name] ? widthList[name] : "fit-content",
                            display: "flex",
                            alignItems: "center",
                            color: "#100f0f",
                            textTransform: "capitalize",
                          }}
                        >
                          {name}
                        </div>
                      ))}
                    {actions && actions.length > 0 && (
                      <div
                        className="justifyC d-flex alignC"
                        style={{
                          margin: "12px 10px",
                          width: "50px",
                          display: "flex",
                          alignItems: "center",
                          color: "#100f0f",
                          textTransform: "capitalize",
                        }}
                      >
                        Action
                      </div>
                    )}
                  </div>
                </div>{" "}
                <div
                  className="scrollBluePrimary"
                  style={{
                    height: "calc(100% - 84px)",
                    overflowY: "auto",
                    width: "fit-content",
                    minWidth: "100%",
                    position: "relative",
                  }}
                >
                  {tableList
                    .sort((a, b) => {
                      var dateA = moment(b.date).format("MMM D YYYY h:mm:ss A");
                      var dateB = moment(a.date).format("MMM D YYYY h:mm:ss A");
                      return new Date(dateA) - new Date(dateB);
                    })
                    .map((singleList, i) => (
                      <>
                        <div className="d-flex width-100 d-flex-column">
                          <div
                            className="d-flex width-100 justifySE"
                            style={{
                              margin: "1px 0 0 0",
                              backgroundColor: colorArr[i % colorArr.length],
                              height: "54px",
                            }}
                          >
                            {props.subMenu && (
                              <StyledButton
                                minWidth="10px"
                                margin="0"
                                onClick={() => {
                                  setSubmenuWidth(singleList.subList);
                                  setmenuOpen(menuOpen === false ? i : i !== menuOpen ? i : false);
                                }}
                                name={
                                  menuOpen === i ? (
                                    <TopArrow color={"rgba(0, 0, 0, 0.87)"} />
                                  ) : (
                                    <BottomArrow color={"rgba(0, 0, 0, 0.87)"} />
                                  )
                                }
                              />
                            )}
                            {props.apiHeader
                              ? props.apiHeader &&
                                props.apiHeader.map((name, i2) => (
                                  <div
                                    onClick={() => {
                                      if (props.subMenu) {
                                        setSubmenuWidth(singleList.subList);
                                        setmenuOpen(menuOpen === false ? i : i !== menuOpen ? i : false);
                                      }
                                    }}
                                    className="d-flex alignC justifyC minWidthFC"
                                    style={{
                                      margin: "12px 10px",
                                      width: widthList[props.header[i2]] ? widthList[props.header[i2]] : "fit-content",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {name === "Sr.No."
                                      ? props.descending
                                        ? tableList.length - i < 10
                                          ? `0${tableList.length - i}`
                                          : tableList.length - i
                                        : i + 1 < 10
                                        ? `0${i + 1}`
                                        : i + 1
                                      : singleList[name]
                                      ? singleList[name]
                                      : "-"}
                                  </div>
                                ))
                              : header &&
                                header.map((name) => (
                                  <div
                                    onClick={() => {
                                      if (props.subMenu) {
                                        setSubmenuWidth(singleList.subList);
                                        setmenuOpen(menuOpen === false ? i : i !== menuOpen ? i : false);
                                      }
                                    }}
                                    className="d-flex alignC justifyC minWidthFC"
                                    style={{
                                      margin: "12px 10px",
                                      width: widthList[name] ? widthList[name] : "fit-content",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {name === "Sr.No."
                                      ? props.descending
                                        ? tableList.length - i < 10
                                          ? `0${tableList.length - i}`
                                          : tableList.length - i
                                        : i + 1 < 10
                                        ? `0${i + 1}`
                                        : i + 1
                                      : singleList[name]
                                      ? singleList[name]
                                      : "-"}
                                  </div>
                                ))}
                            {actions && actions.length > 0 && (
                              <div
                                onClick={(e) => {
                                  if (props.setSelectedListVal) {
                                    props.setSelectedListVal(singleList);
                                  }
                                  setSelectedList(singleList);
                                  setOpenPopover(true);
                                  setAnchorEl(e.currentTarget);
                                  console.log(singleList, "singleList");
                                }}
                                className="d-flex alignC justifyC"
                                style={{
                                  margin: "12px 10px",
                                  width: "50px",
                                }}
                              >
                                <MoreHorizOutlined />
                              </div>
                            )}
                          </div>

                          <Divider className="width-100" />
                          {menuOpen === i ? (
                            singleList && singleList.subList && singleList.subList.length > 0 ? (
                              <div className="d-flex width d-flex-column">
                                <div
                                  style={{
                                    // backgroundColor: 'rgb(158 158 158 / 48%)',
                                    color: primary,
                                    borderColor: primary,
                                    width: "100%",
                                    minWidth: "fit-content",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "34px",
                                    minHeight: "34px",
                                    overflow: "hidden",
                                    borderRadius: "7px 7px 0 0",
                                  }}
                                >
                                  <div className="d-flex alignC width-100">
                                    {props.subMenu && (
                                      <div
                                        className="justifyC d-flex alignC"
                                        style={{
                                          margin: "12px 10px",
                                          width: "23px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#100f0f",
                                          textTransform: "capitalize",
                                        }}
                                      />
                                    )}
                                    {props.subMenuheader &&
                                      props.subMenuheader.map((name) => (
                                        <div
                                          className="justifyC d-flex alignC"
                                          style={{
                                            margin: "12px 10px",
                                            width: widthList[`SUB${name}`] ? widthList[`SUB${name}`] : "fit-content",
                                            display: "flex",
                                            alignItems: "center",
                                            color: "#100f0f",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {name}
                                        </div>
                                      ))}
                                    {subMenuAction && subMenuAction.length > 0 && (
                                      <div
                                        className="justifyC d-flex alignC"
                                        style={{
                                          margin: "12px 10px",
                                          width: "50px",
                                          display: "flex",
                                          alignItems: "center",
                                          color: "#100f0f",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        Action
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {singleList && singleList.subList && singleList.subList.length > 0
                                  ? singleList.subList.map((sList, i3) => (
                                      <div
                                        className="d-flex width-100"
                                        style={{
                                          margin: "1px 0 0 0",
                                          backgroundColor: colorArr[i3 % colorArr.length],
                                          height: "34px",
                                          minHeight: "34px",
                                          minWidth: "fit-content",
                                        }}
                                      >
                                        {props.subMenu && (
                                          <div
                                            className="justifyC d-flex alignC"
                                            style={{
                                              margin: "12px 10px",
                                              width: "23px",
                                              display: "flex",
                                              alignItems: "center",
                                              color: "#100f0f",
                                              textTransform: "capitalize",
                                            }}
                                          />
                                        )}
                                        {props.subMenuheader &&
                                          props.subMenuheader.map((name) => (
                                            <div
                                              className="d-flex alignC justifyC"
                                              style={{
                                                margin: "12px 10px",
                                                width: widthList[`SUB${name}`]
                                                  ? widthList[`SUB${name}`]
                                                  : "fit-content",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {name === "Sr.No."
                                                ? props.descending
                                                  ? singleList.subList.length - i3 < 10
                                                    ? `0${singleList.subList.length - i3}`
                                                    : singleList.subList.length - i3
                                                  : i3 + 1 < 10
                                                  ? `0${i3 + 1}`
                                                  : i3 + 1
                                                : sList[name]
                                                ? sList[name]
                                                : "-"}
                                            </div>
                                          ))}
                                        {/* {subMenuAction && subMenuAction.length > 0 && (
                                        <div
                                          onClick={(e) => {
                                            if (props.setSubMenuSelectedListVal) {
                                              props.setSubMenuSelectedListVal(sList);
                                            }
                                            setSelectedList(sList);
                                            setOpenPopover(true);
                                            setAnchorEl(e.currentTarget);
                                            console.log(singleList, "singleList");
                                          }}
                                          className="d-flex alignC justifyC"
                                          style={{
                                            margin: "12px 10px",
                                            width: "50px",
                                          }}
                                        >
                                          <MoreHorizOutlined />
                                        </div>
                                      )} */}
                                      </div>
                                    ))
                                  : ""}
                              </div>
                            ) : (
                              <div className="d-flex width d-flex-column">
                                <div
                                  style={{
                                    // backgroundColor: 'rgb(158 158 158 / 48%)',
                                    color: primary,
                                    borderColor: primary,
                                    justifyContent: "center",
                                    width: "fit-content",
                                    minWidth: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    height: "34px",
                                    minHeight: "34px",
                                    overflow: "hidden",
                                    borderRadius: "7px 7px 0 0",
                                  }}
                                >
                                  LR not added yet!
                                </div>
                              </div>
                            )
                          ) : (
                            ""
                          )}
                        </div>

                        <Divider />
                      </>
                    ))}
                </div>
                {pagination && (
                  <div className="positionAB width-100" style={{ bottom: "30px", right: "20px" }}>
                    {" "}
                    <CssTablePagination
                      rowsPerPageOptions={[]}
                      component="div"
                      count={pagination.count}
                      rowsPerPage={pagination.rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="d-flex width-100 height-100 alignC justifyC d-flex-column">
                <span style={{ fontSize: "1rem", margin: "15px 0 0 0" }}>No {module} yet !</span>
              </div>
            )}
          </Paper>
        </div>
      </>
    </Paper>
  );
};

export default TableComponent;
