import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
import StarBorder from "@material-ui/icons/StarBorder";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ClientIcon from "@material-ui/icons/AddCircleOutline";
import PostAdd from "@material-ui/icons/PostAdd";
import Tooltip from "@material-ui/core/Tooltip";
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import { GlobalHotKeys } from "react-hotkeys";
import { background, primary } from "../../styles/colors";
import { connect } from "react-redux";
// import withUser from "../../hoc/withUser";
import StaticVersionDisplay from "./StaticVersionDisplay";
import { Avatar, Divider, Typography } from "@material-ui/core";
import ProjectName from "./ProjectName";
import {
  DashBoardIcon,
  AssignmentIcon,
  TransportIcon,
  ReciptsIcon,
  UserPermissionIcon,
  DispatchIcon,
  MastersManagementsIcon,
  TransportGroupIcon,
  DestinationIcon,
  SourceIcon,
  ConsigneeIcon,
  ConsignorIcon,
  CarIcon,
  ItemIcon,
} from "./icons";

const drawerWidth = 240;
const childRoutesARR = [
   "/transpotersgroup",
  "/destinationcities",
  "/consignors",
  "/consignees",
  "/sourcecities",
  "/vehicles",
  "/items",
  "/driversDetails",
];
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    position: "absolute",
    bottom: 0,
    right: 5,
    width: 240,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedClose: {
    paddingLeft: theme.spacing(3),
  },
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: "transparent linear- gradient(1deg, #3B69B0 0 %, #1E3558 100 %) 0% 0 % no - repeat padding- box",
    height: "100%",
  },
  divBigAvatar: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: 10,
  },
}));

const keyMap = {
  DASHBOARD: { name: "Expand square area", sequence: "1" },
  SELLORDERS: { name: "Expand square area", sequence: "2" },
  DISPATCHES: { name: "Expand square area", sequence: "3" },
  MODULES: { name: "Expand square area", sequence: "4" },
  USERANDPERMISSION: { name: "Expand square area", sequence: "5" },
};

function DrawerContent(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(props.open);
  useEffect(
    () => {
      setOpen(props.open);
    },
    [props.open]
  );
  const [innerMenu, setInnerMenu] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const handlePageTitle = (title, index) => setSelectedIndex(index);

  const handleDrawerOpen = () => {
    setOpen(true);
    if (props.setOpen) props.setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    if (props.setOpen) props.setOpen(false);
  };
  const handleShortcut = {
    DASHBOARD: (e) => props.history.push("/dashboard"),
    SELLORDERS: (e) => props.history.push("/selloders"),
    DISPATCHES: (e) => props.history.push("/dispatches"),
    MODULES: (e) => props.history.push("/masters"),
    USERANDPERMISSION: (e) => props.history.push("/userandpermmision"),
  };
  const chectSelected = (path) => {
    const tofilterWIth =
      props.match.path.indexOf("/", props.match.path.indexOf("/") + 1) === -1
        ? props.match.path
        : props.match.path.toString().slice(0, props.match.path.indexOf("/", props.match.path.indexOf("/") + 1));
    if (path === "/masters" && !innerMenu && childRoutesARR.includes(tofilterWIth)) {
      return true;
    } else {
      if (tofilterWIth === path) {
        return true;
      } else {
        return false;
      }
    }
  };
  const selectedStyle = (path, noIcon) => ({
    color: chectSelected(path) ? primary : "white",
    background: chectSelected(path) ? "white" : "",
    borderRadius: "7px 0 0 7px",
    margin: chectSelected(path) ? "5px 0 5px 0" : open ? "5px" : "5px 0",
    width: chectSelected(path) ? "100%" : open ? "calc(100% - 10px)" : "100%",
    padding: open ? "" : noIcon ? "8px 0" : "0",
    minWidth: !open ? "0" : "40px",
    justifyContent: !open ? "center" : "",
    display: !open ? "flex" : "",
  });
  const listItems = [
    {
      path: "/dashboard",
      title: "Dashboard",
      access: "dashboard",
      icon: (path) => <DashBoardIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
    },
    {
      path: "/salesorders",
      title: "Sales Order",
      access: "sales_order",
      icon: (path) => <AssignmentIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
    },
    {
      path: "/dispatches",
      title: "Dispatches",
      access: "dispatches",
      icon: (path) => <DispatchIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
    },
    {
      path: "/transpoters",
      title:"Transpoters",
      access: "transporters",
      icon: (path) => <TransportIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
      //  childRoutes: [
      //      {
      //     path: "/transpotersgroup",
      //     title: "Transpoters Group",
      //     icon: (path) => (
      //       <TransportGroupIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />
      //     ),
      //   },
      //  ]
    },
      
    {
      path: "/e-permit",
      title: "E-Permit",
      access: "e_permit",
      icon: (path) => <ReciptsIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
    },
    {
      path: "/userandpermmision",
      title: <span style={{ color: "#9e9e9ef0" }}>Users & Permissions</span>,
      access: "	users_and_permissions",
      icon: (path) => (
        <UserPermissionIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />
      ),
    },
    {
      path: "/masters",
      title: "Masters Management",
      access: "master_management",
      icon: (path) => (
        <MastersManagementsIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />
      ),
      childRoutes: [
        {
          path: "/transpotersgroup",
          title: "Transpoters Group",
          icon: (path) => (
            <TransportGroupIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />
          ),
        },
        {
          path: "/destinationcities",
          title: "Destination Cities",
          icon: (path) => (
            <DestinationIcon
              style={selectedStyle(path)}
              color={chectSelected(path) ? primary : "white"}
              color={chectSelected(path) ? primary : "white"}
            />
          ),
        },
        {
          path: "/consignors",
          title: "Consignors",
          icon: (path) => <ConsignorIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
        {
          path: "/consignees",
          title: "Consignees",
          icon: (path) => <ConsigneeIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
        {
          path: "/sourcecities",
          title: "Source Cities",
          icon: (path) => <SourceIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
        {
          path: "/vehicles",
          title: "Vehicles",
          icon: (path) => <CarIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
        {
          path: "/items",
          title: "Items",
          icon: (path) => <ItemIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
         {
          path: "/itemsgrades",
          title: "Items Grade",
          icon: (path) => <ItemIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
        {
          path: "/driversDetails",
          title: <span style={{ color: "#9e9e9ef0" }}>{"Drivers"}</span>,
          icon: (path) => <CarIcon style={selectedStyle(path)} color={chectSelected(path) ? primary : "white"} />,
        },
      ],
    },
  ];
  // {
  //   path: "/drivers",
  //   title: "Drivers",
  //   icon: (path) => <CarIcon style={selectedStyle(path)} color={ chectSelected(path)   ? primary : "white"} />,
  // },
  // {
  //   path: "/drivers",
  //   title: "Drivers",
  //   icon: (path) => <SourceIcon style={selectedStyle(path)} />,
  // },
  console.log(props?.user, "props?.user");
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GlobalHotKeys keyMap={keyMap} handlers={handleShortcut} />{" "}
      {
        <div
          style={{
            height: "calc(100% - 68px)",
            width: "100%",
            marginTop: "18px",
          }}
        >
          <List disablePadding className={classes.root}>
            {open && (
              <div className={classes.divBigAvatar}>
                {/* <Avatar
            variant="rounded"
            alt="Project Name"
            className={classes.logoAvatar}
            onClick={e => props.setOpen(!props.open)}
          // style={{ background: "transparent", border: "1px solid white" }}
          >
            AS */}
                {/* </Avatar> */}
                <ProjectName {...props} />
              </div>
            )}
            {open ? <Divider variant="middle" /> : <div style={{ height: "92px" }} />}
            <div
              style={{
                height: "calc(100% - 70.19px)",
                overflowY: "auto",
                width: "100%",
              }}
            >
              {listItems.map((list, index) => {
                console.log(props?.user?.accessibleModules, list.access, "vvvvvvvvvvvvvvvvvvvvvvvvvvvv");
                if (props?.user?.accessibleModules.includes(list.access)) {
                  return (
                    <div key={`ListItemContainer-${index}`}>
                      <ListItem
                        button
                        key={`ListItem-${index}`}
                        selected={selectedIndex === index}
                        onClick={
                          typeof list.childRoutes === "undefined"
                            ? (e) => {
                              e.preventDefault();
                              handlePageTitle(list.title, index);
                              props.history.push(list.path);
                            }
                            : (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setInnerMenu(!innerMenu);
                            }
                        }
                        style={selectedStyle(list.path, "noIcon", innerMenu)}
                      >
                        <ListItemIcon
                          style={{
                            padding: open ? "" : "0",
                            minWidth: !open ? "0" : "",
                          }}
                        >
                          <Tooltip title={list.title} placement="right-start">
                            {list.icon(list.path)}
                          </Tooltip>
                        </ListItemIcon>
                        {open && <ListItemText primary={list.title} />}
                        {open ? (
                          <>
                            {typeof list?.childRoutes !== "undefined" ? (
                              innerMenu ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )
                            ) : null}
                          </>
                        ) : (
                          ""
                        )}
                      </ListItem>
                      {typeof list?.childRoutes !== "undefined" && (
                        <Collapse in={innerMenu} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {list?.childRoutes?.map((e, i) => (
                              <ListItem
                                button
                                key={`ListItem-${index}-${i}`}
                                className={!open ? classes.nestedClose : classes.nested}
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  // handlePageTitle(e.title, i);
                                  props.history.push(e.path);
                                }}
                                style={selectedStyle(e.path, "noIcon")}
                              >
                                <ListItemIcon
                                  style={{
                                    minWidth: !open ? "0" : "",
                                    margin: !open && "0 0 0 10px",
                                  }}
                                >
                                  {e?.icon(e?.path)}
                                </ListItemIcon>
                                {!open ? "" : <ListItemText primary={e.title} />}
                              </ListItem>
                            ))}
                          </List>
                        </Collapse>
                      )}
                    </div>
                  );
                } else return null;
              })}
            </div>
          </List>
        </div>
      }
      <div className={classes.toolbar}>
        {props.open && <StaticVersionDisplay version={props.version} />}
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
          <Tooltip title={!props.open ? "Click to Expand" : "Click to Collapse"} placement="right-start">
            {!props.open ? (
              <ChevronRightIcon style={{ color: "white" }} />
            ) : (
              <ChevronLeftIcon style={{ color: "white" }} />
            )}
          </Tooltip>
        </IconButton>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  version: state.authentication.version,
  user: state.authentication.user,
});
const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(DrawerContent)
);
