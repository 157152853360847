import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import axios from "axios";
import validator from "validator";
import { lightblue, lightGreen, lightGrey, primary, purple, red, white, yellow } from "../styles/colors";
import {
  AppBar,
  ListItem,
  List,
  Box,
  Divider,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  ClickAwayListener,
  Popover,
  Backdrop,
  MenuItem,
  Tooltip,
  Dialog,
  Slide,
  DialogActions,
  IconButton,
  DialogContent,
} from "@material-ui/core";
import { CssTextField, StyledButton, StyledCircularProgress } from "../styles/StyledComponents";
import { withStyles } from "@material-ui/styles";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import { Paper, InputBase, Avatar } from "@material-ui/core";
import { CancelIcon, SearchIcon, PlusIcon, Download, CrossIcon } from "../styles/Icons";
import { CheckCircle, MoreHorizOutlined } from "@material-ui/icons";
import TableComponent from "./TableComponent";
import SnackbarComponent from "./SnackbarComponent";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PoperCaseCssTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      textTransform: "capitalize",
    },
  },
})(CssTextField);
const UpperCaseCssTextField = withStyles({
  root: {
    "& .MuiInputBase-input": {
      textTransform: "uppercase",
    },
  },
})(CssTextField);
const styles = {
  heading: {
    margin: 0,
    color: "white",
    width: "calc(100% - 32.81px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    display: "flex",
  },
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "300px",
    height: "38px",
    fontSize: "0.5rem",
    backgroundColor: "rgba(158, 158, 158, 0.18)",
    color: "white",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  listItemsStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 0 0 0",
    padding: "10px",
  },
};
class TranspotersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      TranspotersDialog: false,
      addTranspoter: false,
      tableEdit: false,
      error: false,
      success: false,
      loadingDialog: false,
      added: false,
      loading: {},
      errorMsg: {
        TranspoterName: false,
        BankAccNo: false,
        PAN: false,
        IFSC: false,
        BankName: false,
        BranchName: false,
        PhoneNumber: false,
      },
    };
  }
  reset = () => {
    const { TranspoterName, BankAccNo, PAN, IFSC, BankName, BranchName, PhoneNumber } = this.state;
    if (!TranspoterName && !BankAccNo && !PAN && !IFSC && !BankName && !BranchName && !PhoneNumber) {
      this.setState({
        error: "Nothing to reset !",
        errorMsg: {
          TranspoterName: false,
          BankAccNo: false,
          PAN: false,
          IFSC: false,
          BankName: false,
          BranchName: false,
          PhoneNumber: false,
        },
      });
    } else {
      this.setState(
        {
          TranspoterName: "",
          BankAccNo: "",
          PAN: "",
          IFSC: "",
          BankName: "",
          BranchName: "",
          PhoneNumber: "",
          errorMsg: {
            TranspoterName: false,
            BankAccNo: false,
            PAN: false,
            IFSC: false,
            BankName: false,
            BranchName: false,
            PhoneNumber: false,
          },
        },
        () => {
          this.setState({ success: "Reset successfully !" });
        }
      );
    }
  };
  handleClearFields = () => {
    this.setState({
      TranspoterName: "",
      BankAccNo: "",
      PAN: "",
      IFSC: "",
      BankName: "",
      BranchName: "",
      PhoneNumber: "",
      errorMsg: {
        TranspoterName: false,
        BankAccNo: false,
        PAN: false,
        IFSC: false,
        BankName: false,
        BranchName: false,
        PhoneNumber: false,
      },
      error: false,
      success: false,
      loadingDialog: false,
      added: false,
    });
  };
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg[event.target.name] = false;
    this.setState({
      [event.target.name]: event.target.value,
      errorMsg,
    });
  };
  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
  }
  setInitialValues(props) {
    this.setState(
      {
        transporter: props.transporter ? props.transporter : [],
        transpoterslist: props.transpoterslist ? props.transpoterslist : [],
        loading: props.loading,
      },

      () => {}
    );
  }
  render() {
    const {
      TranspotersDialog,
      TranspoterName,
      BankAccNo,
      PAN,
      IFSC,
      BankName,
      BranchName,
      PhoneNumber,
      tableEdit,
      transporter,
      error,
      success,
      loadingDialog,
      loading,
      added,
      errorMsg,
    } = this.state;
    const { copytransporter, transpoterslist, Name, selectedId } = this.props;
    return (
      <>
        <Paper className="width-100 height-100 padding15 backgroundWhite">
          <TableComponent
            setSelectedListVal={(selectedList) => {
              if (selectedList) {
                this.setState({
                  TranspoterName: selectedList.name ? selectedList.name : "",
                  BankAccNo: selectedList.bank_acc_no ? selectedList.bank_acc_no : "",
                  PAN: selectedList.pan ? selectedList.pan : "",
                  IFSC: selectedList.ifsc ? selectedList.ifsc : "",
                  BankName: selectedList.bank_name ? selectedList.bank_name : "",
                  BranchName: selectedList.bank_branch ? selectedList.bank_branch : "",
                  PhoneNumber: selectedList.phone ? selectedList.phone : "",
                  selectedList: selectedList,
                });
              }
            }}
            loadingTitle={"transporter"}
            onEdit={() => {
              this.setState({
                TranspotersDialog: true,
                tableEdit: true,
              });
            }}
            onAdd={() => {
              this.setState({ TranspotersDialog: true });
            }}
            module={"Transpoter"}
            {...this.state}
            {...this.props}
            header={[
              "Sr.No.",
              "Name",
              "Bank Acc No.",
              "Pan Card No.",
              "IFSC Code",
              "Bank Name",
              "Branch Name",
              "Phone Number",
            ]}
            apiHeader={[
              "Sr.No.",
              "name",
              "bank_acc_no",
              "Pan_Card_No_",
              "IFSC_Code",
              "bank_name",
              "bank_branch",
              "Phone_Number",
            ]}
            tableList={
              transporter && transporter.filter((sT) => sT.group === selectedId).length > 0
                ? transporter
                    .filter((sT) => sT.group === selectedId)
                    .map((STs) => {
                      STs.Phone_Number = STs.phone;
                      STs.Pan_Card_No_ = STs.pan;
                      STs.IFSC_Code = STs.ifsc;
                      return STs;
                    })
                : []
            }
          />
        </Paper>

        {TranspotersDialog == true ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "5px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "517.98px";
                target.style.height = "517.98px";
                target.style.minWidth = "80%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({ TranspotersDialog: false, success: "" });
              this.handleClearFields();
            }}
            TransitionComponent={Transition}
          >
            <DialogActions
              classname="d-flex justifySB"
              style={{ background: primary, color: "white", padding: "10px 24px" }}
              onClick={() => {
                this.setState({ TranspotersDialog: false });
                this.handleClearFields();
              }}
            >
              <h3 style={styles.heading}>
                Transpoters Master <div style={{ margin: "0 10px 0 0" }} />( Transporter Group :-
                <div style={{ margin: "0 10px 0 0" }} /> {Name} )
              </h3>
              <Tooltip title="close" arrow placement="top">
                <IconButton style={{ padding: "5px", borderRadius: "5px" }}>
                  <CrossIcon strokeWidth="2" />
                </IconButton>
              </Tooltip>
            </DialogActions>{" "}
            <DialogContent className="dashedDividerBottom" style={{ height: "100%" }}>
              {" "}
              {error || success ? (
                <SnackbarComponent
                  error={error}
                  success={success}
                  snackbarClose={() => this.setState({ error: "", success: "" })}
                />
              ) : (
                ""
              )}{" "}
              {loadingDialog ? (
                <Backdrop style={{ zIndex: 1, color: "#fff" }} open={loadingDialog}>
                  <StyledCircularProgress color="inherit" />
                </Backdrop>
              ) : (
                ""
              )}
              {added ? (
                <div className="height-100 width-100 d-flex alignC justifyC d-flex-column">
                  <CheckCircle style={{ width: "100px", height: "100px", color: "green" }} />
                  <span className="heading" style={{ color: "green", margin: "10px 0 0 0" }}>
                    {added}
                  </span>
                </div>
              ) : (
                <div className="height-100 width-100 d-flex padding20 d-flex-column ">
                  <div className="width-100 d-flex justifySB">
                    <PoperCaseCssTextField
                      autoComplete="off"
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      required={true}
                      style={{ width: "calc(50% - 15px)", margin: "0 30px 0 0", textTransform: "capitalize" }}
                      id="outlined-basic"
                      label="Name"
                      name="TranspoterName"
                      variant="outlined"
                      value={TranspoterName}
                      error={errorMsg.TranspoterName ? true : false}
                      helperText={errorMsg.TranspoterName ? errorMsg.TranspoterName : ""}
                      onChange={this.handleChange}
                      autoFocus={true}
                    />

                    <CssTextField
                      autoComplete="off"
                      type="number"
                      autoFocus={TranspoterName && !BankAccNo ? true : false}
                      required={true}
                      onKeyDown={(evt) =>
                        (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") &&
                        evt.preventDefault()
                      }
                      style={{ width: "calc(50% - 15px)", margin: "0 0 0 0" }}
                      id="outlined-basic"
                      label="Bank Account No."
                      name="BankAccNo"
                      variant="outlined"
                      value={BankAccNo}
                      error={errorMsg.BankAccNo ? true : false}
                      helperText={errorMsg.BankAccNo ? errorMsg.BankAccNo : ""}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="width-100 d-flex justifySB marginT20">
                    <UpperCaseCssTextField
                      autoComplete="off"
                      InputProps={{
                        pattern: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                      }}
                      required={true}
                      onKeyDown={(evt) => (evt.key === "-" || evt.key === ".") && evt.preventDefault()}
                      style={{ width: "calc(50% - 15px)", margin: "0 30px 0 0" }}
                      id="outlined-basic"
                      label="PAN Card Number"
                      name="PAN"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 10);
                      }}
                      variant="outlined"
                      value={PAN}
                      error={errorMsg.PAN ? true : false}
                      helperText={errorMsg.PAN ? errorMsg.PAN : ""}
                      onChange={this.handleChange}
                    />
                    <UpperCaseCssTextField
                      autoComplete="off"
                      required={true}
                      onKeyDown={(evt) => (evt.key === "-" || evt.key === ".") && evt.preventDefault()}
                      style={{ width: "calc(50% - 15px)", margin: "0 0 0 0" }}
                      id="outlined-basic"
                      label="IFSC Code"
                      name="IFSC"
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 11);
                      }}
                      variant="outlined"
                      value={IFSC}
                      error={errorMsg.IFSC ? true : false}
                      helperText={errorMsg.IFSC ? errorMsg.IFSC : ""}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="width-100 d-flex justifySB marginT20">
                    <UpperCaseCssTextField
                      autoComplete="off"
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      required={true}
                      style={{ width: "calc(50% - 15px)", margin: "0 30px 0 0" }}
                      id="outlined-basic"
                      label="Bank Name"
                      name="BankName"
                      variant="outlined"
                      value={BankName}
                      error={errorMsg.BankName ? true : false}
                      helperText={errorMsg.BankName ? errorMsg.BankName : ""}
                      onChange={this.handleChange}
                    />
                    <UpperCaseCssTextField
                      autoComplete="off"
                      required={true}
                      style={{ width: "calc(50% - 15px)", margin: "0 0 0 0" }}
                      id="outlined-basic"
                      label="Branch Name"
                      name="BranchName"
                      variant="outlined"
                      value={BranchName}
                      error={errorMsg.BranchName ? true : false}
                      helperText={errorMsg.BranchName ? errorMsg.BranchName : ""}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="width-100 d-flex justifySB marginT20">
                    <CssTextField
                      autoComplete="off"
                      type="number"
                      InputProps={{
                        inputProps: {
                          min: 1,
                        },
                      }}
                      required={true}
                      onKeyDown={(evt) =>
                        (evt.key === "E" || evt.key === "e" || evt.key === "-" || evt.key === ".") &&
                        evt.preventDefault()
                      }
                      style={{ width: "calc(50% - 15px)", margin: "0 30px 0 0" }}
                      id="outlined-basic"
                      label="Phone Number"
                      name="PhoneNumber"
                      variant="outlined"
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 10);
                      }}
                      value={PhoneNumber}
                      error={errorMsg.PhoneNumber ? true : false}
                      helperText={errorMsg.PhoneNumber ? errorMsg.PhoneNumber : ""}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              )}
            </DialogContent>{" "}
            <DialogActions
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {added ? (
                <StyledButton
                  onClick={() => {
                    this.handleClearFields();
                  }}
                  name={"Add Another"}
                  withBg={true}
                  background={primary}
                  width="120px"
                  margin="0 10px 0 0"
                />
              ) : (
                <>
                  <StyledButton
                    onClick={() => {
                      this.reset();
                    }}
                    name={"Reset"}
                    width="60px"
                    margin="0 10px 0 0"
                  />
                  <StyledButton
                    onClick={() => {
                      const addData = {
                        name: TranspoterName,
                        bank_acc_no: BankAccNo,
                        pan: PAN,
                        ifsc: IFSC,
                        bank_name: BankName,
                        bank_branch: BranchName,
                        phone: PhoneNumber,
                        name: Name,
                      };

                      if (TranspoterName) {
                        if (BankAccNo && BankAccNo?.toString().length > 8) {
                          if (PAN && PAN?.toString().length === 10) {
                            if (IFSC && IFSC?.toString().length === 11) {
                              if (BankName) {
                                if (BranchName) {
                                  if (PhoneNumber) {
                                    if (validator.isMobilePhone(PhoneNumber)) {
                                      if (!tableEdit) {
                                        const addData = {
                                          name: TranspoterName,
                                          bank_acc_no: BankAccNo,
                                          pan: PAN,
                                          ifsc: IFSC,
                                          bank_name: BankName,
                                          bank_branch: BranchName,
                                          phone: PhoneNumber,
                                          group: selectedId,
                                        };
                                        this.props.transpotersAdd(addData, this.props.user.user.uid).then(() => {
                                          this.props.fetchTransporters(this.props.user.user.uid).then((res) => {
                                            if (res) {
                                              this.setState(
                                                {
                                                  added: "Added successfully !",
                                                  transporter: res,
                                                },
                                                () => {}
                                              );
                                            }
                                          });
                                        });
                                      } else {
                                        const editData = {
                                          name: TranspoterName,
                                          bank_acc_no: BankAccNo,
                                          pan: PAN,
                                          ifsc: IFSC,
                                          bank_name: BankName,
                                          bank_branch: BranchName,
                                          phone: PhoneNumber,
                                        };
                                        this.props
                                          .transpotersEdit(
                                            editData,
                                            this.props.user.user.uid,
                                            this.state.selectedList.id
                                          )
                                          .then(() => {
                                            this.props.fetchTransporters(this.props.user.user.uid).then((res) => {
                                              if (res) {
                                                this.setState(
                                                  {
                                                    added: "Edited successfully !",
                                                    transporter: res,
                                                  },
                                                  () => {}
                                                );
                                              }
                                            });
                                          });
                                      }
                                    } else {
                                      var errorMsg = this.state.errorMsg;
                                      errorMsg.PhoneNumber = "Incorrect Phone Number!";
                                      this.setState({ errorMsg });
                                    }
                                  } else {
                                    var errorMsg = this.state.errorMsg;
                                    errorMsg.PhoneNumber = "Please add Phone Number!";
                                    this.setState({ errorMsg });
                                  }
                                } else {
                                  var errorMsg = this.state.errorMsg;
                                  errorMsg.BranchName = "Please add Branch Name!";
                                  this.setState({ errorMsg });
                                }
                              } else {
                                var errorMsg = this.state.errorMsg;
                                errorMsg.BankName = "Please add Bank Name!";
                                this.setState({ errorMsg });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.IFSC =
                                IFSC && IFSC?.toString().length !== 11
                                  ? "Incorrect IFSC Code!"
                                  : "Please add IFSC code!";
                              this.setState({ errorMsg });
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.PAN =
                              PAN && PAN?.toString().length !== 10
                                ? "Incorrect PAN Card Number"
                                : "Please add PAN Card number!";
                            this.setState({ errorMsg });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.BankAccNo =
                            BankAccNo && BankAccNo?.toString().length < 8
                              ? "Incorrect Account Number"
                              : "PLease add Bank Acc No!";
                          this.setState({ errorMsg });
                        }
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.TranspoterName = "Please add Transpoter's Name";
                        this.setState({ errorMsg });
                      }
                    }}
                    name={tableEdit ? "Edit" : "Add"}
                    withBg={true}
                    background={"green"}
                    width="120px"
                  />
                </>
              )}
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default withRouter(TranspotersDetail);
