import React from "react";
import Typography from "@material-ui/core/Typography";

const ProjectName = props => (
  <Typography
    variant="h6"
    noWrap
    style={{ fontSize: "2rem", textTransform: "uppercase", fontFamily: "serif" }}
  >
    {props.title || `Pitambara`}
  </Typography>
);

export default ProjectName;
