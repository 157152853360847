import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white } from "../styles/colors";
import Button from "@material-ui/core/Button";
import { Paper, Dialog, Tooltip, Slide, MenuItem } from "@material-ui/core";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import NoData from "../styles/Illustrations/NoData";
import Toggle from "./Toggle";
import SnackbarComponent from "./SnackbarComponent";
import EditIcon from "@material-ui/icons/Create";
import ReactDOM from "react-dom";
import Moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  contentStyle: {
    display: "flex",
    marginBottom: "10px",
    color: "black",
    fontSize: "1rem",
  },
  withDivider50: { width: "calc(40% - 5px)" },
  withDivider25: { width: "25%" },
};
const templateTabs = [
  {
    label: "Consigneess",
    tooltip: "Consignees List",
    data: [],
    route: "/consignees/consigneesEdit",
    primaryField: "name",
    primarySecondValue: "date2",
    primaryFieldFallback: "phone", // Optional
    secondaryField: "address",
    avatarField: "src",

    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class Consignees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: templateTabs,
      user: null,
      SalesOrder: "",
      consignees: [],
      addNewConsigneess: false,
      users: [],
      Name: "",
      Address: "",
      GstNo: "",
      ShortName: "",
      TpIdentifier: "",
      colorArr: [white, lightGrey],
      status: false,
      success: "",
      error: "",
      openMessage: true,
      editConsignees: false,
      destinationCities: [],
      cityName: "",
      cityId: "",
      errorMsg: { cityName: "", Name: false, Address: false, GstNo: false, ShortName: false, TpIdentifier: false },
    }; //cardColor2, cardColor3, cardColor4],
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      console.log(next.match.params["tab"], "singleConsigneesssssssss");
      let tab = next.match.params["tab"];
      if (tab === "consigneesEdit" && next.match.params.hasOwnProperty("id")) {
        const { destinationCities } = this.state;
        this.setState(
          {
            singleConsigneeslist: this.state.tabs[0].data[next.match.params["id"]],
            Name: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].name
              : "",
            Address: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].address
              : "",
            GstNo: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].gstin_no
              : "",
            ShortName: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].short_name
              : "",
            TpIdentifier: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].tp_identifier
              : "",
            status: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].is_active
              : "",
            addNewConsigneess: false,
            openMessage: false,
            cityId: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].destination
                ? this.state.tabs[0].data[next.match.params["id"]].destination
                : ""
              : "",
            cityName: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].destination
                ? destinationCities &&
                  destinationCities.length > 0 &&
                  destinationCities.filter(
                    (sCity) => sCity.id === this.state.tabs[0].data[next.match.params["id"]].destination
                  ) &&
                  destinationCities.filter(
                    (sCity) => sCity.id === this.state.tabs[0].data[next.match.params["id"]].destination
                  )[0].city
                : ""
              : "",
          },
          () => {
            console.log(this.state.singleConsigneeslist, "singleDestinationlistyyyyyyyyyy");
          }
        );
      } else if (tab === "new") {
        this.setState({
          singleConsigneeslist: null,
          Name: "",
          Address: "",
          GstNo: "",
          ShortName: "",
          TpIdentifier: "",
          addNewConsigneess: true,
        });
      }
    } else this.setState({ singleConsigneeslist: null });
  }

  listClickHandler = (value) => {
    const { destinationCities } = this.state;
    this.setState({
      addNewConsigneess: false,
      singleConsigneeslist: true,
      Name: value.name,
      Address: value.address,
      GstNo: value.gstin_no,
      ShortName: value.short_name,
      TpIdentifier: value.tp_identifier,
      cityId: value.destination ? value.destination : "",
      cityName: value.destination
        ? destinationCities &&
          destinationCities.length > 0 &&
          destinationCities.filter((sCity) => sCity.id === value.destination) &&
          destinationCities.filter((sCity) => sCity.id === value.destination)[0].city
        : "",
      Status: value.status,
      createdAt: value.createdAt, //openMessage: false,
      editConsignees: false,
      openMessage: false,
    });
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.consignees && props.consignees.length > 0 ? props.consignees : [];
      return tab;
    });
    console.log(tabs, "tabs");
    this.setState({
      consignees: props.consignees,
      tabs,
      destinationCities: props.destinationCities && props.destinationCities.length > 0 ? props.destinationCities : [],
    });
  }
  handleChange = (event) => {
    var errorMsg = this.state.errorMsg;
    errorMsg.Name = false;
    errorMsg.Address = false;
    errorMsg.GstNo = false;
    errorMsg.ShortName = false;
    errorMsg.TpIdentifier = false;

    this.setState({
      [event.target.name]: event.target.value,
      errorMsg,
    });
  };
  handleClearFields = () => {
    this.setState({
      Name: "",

      Address: "",
      GstNo: "",
      ShortName: "",
      TpIdentifier: "",
    });
  };

  render() {
    const {
      tabs,
      addNewConsigneess,
      consignees,
      singleConsigneeslist,
      Name,
      Address,
      GstNo,
      ShortName,
      TpIdentifier,
      status,
      success,
      error,
      name,
      errorMsg,
      destinationCities,
      cityName,
      cityId,
    } = this.state;
    return (
      <Layout
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          { data: name, hintText: "Search Name", labelField: "name" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={
          this.listClickHandler // }} //   this.props.history.push("/dashboard/new"); // fabClickHandler={() => {
        }
        fabClickHandler={() => {
          this.props.history.push("/consignees/new");
          this.setState({
            addNewConsigneess: true,
            singleConsigneeslist: false,
            status: true,
            editConsignees: true,
          });
          this.handleClearFields();
        }}
      >
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}{" "}
        {this.state.openMessage ? (
          <div
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoData />
          </div>
        ) : (
          ""
        )}
        {this.state.editConsignees ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "20px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "fit-content";
                target.style.minWidth = "70%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
                target.style.padding = "0 50px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({
                editConsignees: false,
                openMessage: true,
                errorMsg: {
                  Name: false,
                  Address: false,
                  GstNo: false,
                  ShortName: false,
                  TpIdentifier: false,
                },
              });
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 0px",
                fontSize: "1.5rem",
              }}
            >
              {" "}
              <span style={{ color: "black" }}>
                {addNewConsigneess ? " Add Consignees Detail" : "Edit Consignees Detail"}
              </span>
              <div>
                <Toggle
                  status={status}
                  handleChange={() => {
                    this.setState({ status: !status });
                  }}
                />
              </div>
            </div>
            {/* <Paper
              style={{
                border: "1px solid #8080802b",
                padding: "20px 50px",
                margin: " 0 80px",
              }}
            > */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1rem",
                color: "grey",
                background: "white",
                margin: "30px 0",
              }}
            >
              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsg.Name ? true : false}
                helperText={errorMsg.Name ? "Please Add Name!" : false}
                id="outlined-basic"
                label="Name"
                variant="outlined"
                name="Name"
                variant="outlined"
                value={Name}
                onChange={this.handleChange}
              />

              <CssTextField
                autoComplete="off"
                style={
                  styles.withDivider50 //disabled={tp}
                }
                error={errorMsg.Address ? true : false}
                helperText={errorMsg.Address ? "Please Add Address !" : false}
                id="outlined-basic"
                label="Address"
                variant="outlined"
                name="Address"
                variant="outlined"
                value={Address}
                onChange={this.handleChange}
              />
            </div>
            <div
              style={
                {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: "1rem",
                  color: "grey",
                  background: "white",
                  margin: "20px 0",
                } //height: "100%",
              }
            >
              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsg.GstNo ? true : false}
                helperText={errorMsg.GstNo ? "Please Add GstNo !" : false}
                id="outlined-basic"
                label="GstNo"
                variant="outlined"
                name="GstNo"
                variant="outlined"
                value={GstNo}
                onChange={this.handleChange}
                inputProps={{ style: { textTransform: "uppercase" } }}
              />

              <CssTextField
                autoComplete="off"
                style={
                  styles.withDivider50 //disabled={tp}
                }
                error={errorMsg.ShortName ? true : false}
                helperText={errorMsg.ShortName ? "Please Add ShortName !" : false}
                id="outlined-basic"
                label="ShortName"
                variant="outlined"
                name="ShortName"
                variant="outlined"
                value={ShortName}
                onChange={this.handleChange}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "1rem",
                color: "grey",
                background: "white",
                margin: "30px 0",
              }}
            >
              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsg.TpIdentifier ? true : false}
                helperText={errorMsg.TpIdentifier ? "Please Add TpIdentifier !" : false}
                id="outlined-basic"
                label="TpIdentifier"
                variant="outlined"
                name="TpIdentifier"
                variant="outlined"
                value={TpIdentifier}
                onChange={this.handleChange}
              />{" "}
              <CssTextField
                required={true}
                autoComplete="off"
                value={
                  cityName ? cityName : "" //
                }
                error={errorMsg.cityName ? true : false}
                helperText={errorMsg.cityName ? errorMsg.cityName : ""}
                onChange={(e) => {
                  var errorMsg = this.state.errorMsg;
                  errorMsg.cityName = false;
                  this.setState({
                    errorMsg,
                    cityId: e.currentTarget.id,
                    cityName: e.target.value,
                  });
                }}
                style={styles.withDivider50}
                id="outlined-select-currency-native"
                select
                label="Destination's Name"
                variant="outlined"
              >
                {destinationCities.map((option) => (
                  <MenuItem key={option.city} value={option.city} id={option.id}>
                    {option.city}
                  </MenuItem>
                ))}
              </CssTextField>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: "40px 0",
                width: "100%",
              }}
            >
              <Button
                style={{ background: primary, color: "white", width: "200px" }}
                onClick={() => {
                  if (Name) {
                    if (Address) {
                      if (GstNo) {
                        if (ShortName) {
                          if (TpIdentifier) {
                            if (cityName) {
                              if (addNewConsigneess) {
                                const addData = {
                                  name: Name,
                                  address: Address,
                                  gstin_no: GstNo,
                                  short_name: ShortName,
                                  tp_identifier: TpIdentifier,
                                  is_active: status,
                                  destination: cityId,
                                };
                                this.props.consigneesAdd(addData, this.props.user.user.uid).then(() => {
                                  this.props.fetchConsignees(this.props.user.user.uid).then((res) => {
                                    if (res) {
                                      this.setState(
                                        {
                                          success: "Consignees added successfully !",
                                          editConsignees: false,
                                          consignees: res,
                                          openMessage: true,
                                        },
                                        () => {
                                          this.handleClearFields();
                                        }
                                      );
                                    }
                                  });
                                });
                              } else {
                                const editData = {
                                  name: Name,
                                  address: Address,
                                  gstin_no: GstNo,
                                  short_name: ShortName,
                                  tp_identifier: TpIdentifier,
                                  destination: cityId,
                                };
                                this.props
                                  .consigneesEdit(
                                    editData,
                                    status,
                                    this.state.singleConsigneeslist.id,
                                    this.props.user.user.uid
                                  )
                                  .then(() => {
                                    this.props.fetchConsignees(this.props.user.user.uid).then((res) => {
                                      if (res) {
                                        this.setState({
                                          success: "Consignees edited successfully !",
                                          editConsignees: false,
                                          consignees: res,
                                          openMessage: false,
                                        });
                                      }
                                    });
                                  });
                              }
                            } else {
                              var errorMsg = this.state.errorMsg;
                              errorMsg.cityName = true;
                              this.setState({
                                errorMsg,
                              });
                            }
                          } else {
                            var errorMsg = this.state.errorMsg;
                            errorMsg.TpIdentifier = true;
                            this.setState({
                              errorMsg,
                            });
                          }
                        } else {
                          var errorMsg = this.state.errorMsg;
                          errorMsg.ShortName = true;
                          this.setState({
                            errorMsg,
                          });
                        }
                      } else {
                        var errorMsg = this.state.errorMsg;
                        errorMsg.GstNo = true;
                        this.setState({ errorMsg });
                      }
                    } else {
                      var errorMsg = this.state.errorMsg;
                      errorMsg.Address = true;
                      this.setState({ errorMsg });
                    }
                  } else {
                    var errorMsg = this.state.errorMsg;
                    errorMsg.Name = true;
                    this.setState({ errorMsg });
                  }
                }}
              >
                {addNewConsigneess ? "Add" : "Edit"}
              </Button>
            </div>
          </Dialog>
        ) : (
          ""
        )}
        {this.state.openMessage == false ? (
          <>
            {(consignees.length > 0 && singleConsigneeslist) || addNewConsigneess ? (
              <Paper style={{ width: "100%", height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px 100px",
                    fontSize: "1.5rem",
                  }}
                >
                  {" "}
                  <span style={{ color: "black" }}>Consignees Detail</span>
                </div>
                <Paper
                  style={{
                    border: "1px solid #8080802b",
                    padding: "20px 0",
                    margin: " 0 100px",
                    borderRadius: "10px",
                    boxShadow: "3px 3px #8080805c",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip
                      title="Edit Consignees"
                      aria-label="add"
                      placement="top"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <EditIcon
                        onClick={() => {
                          this.setState({ editConsignees: true });
                        }}
                        style={{
                          width: 50,
                          marginRight: "3%",
                          color: "black",
                          cursor: "pointer",
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      fontSize: "1rem",
                      color: "grey",
                      background: "white",
                      margin: "20px 0 20px 30px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ width: "90%" }}>
                        <div style={{ borderRadius: "10px" }}>
                          <span style={styles.contentStyle}>
                            Name :<span style={{ marginLeft: "10px", color: "grey" }}> {Name ? Name : "Null"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Address :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {Address ? Address : "Null"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            GstNo : <span style={{ marginLeft: "10px", color: "grey" }}> {GstNo ? GstNo : "Null"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            ShortName :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {ShortName ? ShortName : "Null"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            TpIdentifier :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}>
                              {" "}
                              {TpIdentifier ? TpIdentifier : "Null"}
                            </span>
                          </span>{" "}
                          <span style={styles.contentStyle}>
                            Status :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {status ? "Active" : "Inactive"}</span>
                          </span>
                          <span style={styles.contentStyle}>
                            Destination :{" "}
                            <span style={{ marginLeft: "10px", color: "grey" }}> {cityName ? cityName : "-"}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Paper>{" "}
              </Paper>
            ) : (
              <div
                style={{
                  height: 500,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NoData />
              </div>
            )}{" "}
          </>
        ) : (
          ""
        )}
      </Layout>
    );
  }
}

export default withRouter(Consignees);
