import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Layout from "../layouts/Layout";
import { primary, lightGrey, white, red } from "../styles/colors";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import { Paper, Tab, Tabs, TablePagination, Typography, Box, Tooltip, Slide, AppBar, Dialog } from "@material-ui/core";
import { CssTextField, StyledButton } from "../styles/StyledComponents";
import NoData from "../styles/Illustrations/NoData";
import Toggle from "./Toggle";
import SnackbarComponent from "./SnackbarComponent";
import ReactDOM from "react-dom";
import EditIcon from "@material-ui/icons/Create";
import PropTypes from "prop-types";
import Moment from "moment";
import PaymentVoucher from "./PaymentVoucher";
import TranspotersDetail from "./TranspotersDetail";
import TableComponent from "./TableComponent";
import moment from "moment";
import RateMatixes from "./RateMatixes";
import PaymentAdvices from "./PaymentAdvices";
import { CheckCircle } from "@material-ui/icons";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className="height-100">
          <Typography
            style={{
              margin: "0 -24px -24px -24px",
              height: "calc(100% + 24px)",
              padding: "2px",
              overflowY: "auto",
            }}
          >
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const CssTab = withStyles({
  root: {
    "&.PrivateTabIndicator-colorSecondary-1003": {
      backgroundColor: "#1E32FA",
    },
  },
})(Tab);
const CssTabs = withStyles({
  root: {
    "& .PrivateTabIndicator-colorSecondary-5": {
      backgroundColor: "#00867E",
    },
    "& .MuiTabs-flexContainer": {
      display: " flex",
      height: "40px",
      // justifyContent: "",
    },
  },
})(Tabs);
const CssTablePagination = withStyles({
  root: {
    "& .MuiSelect-icon": {
      color: primary,
    },
    "& .MuiIconButton-root.Mui-disabled": {
      color: primary,
      padding: "0",
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "30px",
    },
  },
})(TablePagination);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const styles = {
  paperStyle: {
    borderRadius: "7px",
    display: "flex",
    width: "210px",
    height: "30px",
    fontSize: "0.5rem",
    backgroundColor: "white",
    marginLeft: "20px",
  },
  paperStyleDynamic: {
    borderRadius: "7px",
    display: "flex",
    width: "100%",
    height: "40px",
    fontSize: "0.5rem",
    // minWidth: "350px",
    backgroundColor: "white",
    padding: "0 0 0 10px",
    position: "relative",
  },
  contentStyle: {
    display: "flex",
    marginBottom: "10px",
    color: "black",
    fontSize: "1rem",
  },
  withDivider50: { width: "calc(50% - 5px)" },
  withDivider25: { width: "25%" },
};

const templateTabs = [
  {
    label: "TranspotersGroup",
    tooltip: "TranspotersGroup list",
    data: [],
    route: "/transpoters/Transporter",
    primaryField: "name",
    primarySecondValue: "date2",
    primaryFieldFallback: "phone", // Optional
    secondaryField: "is_active",
    avatarField: "src",
    decorators: {
      conditionField: "status",
      options: ["pending", "approved", "unidentified"],
      colors: ["yellow", "green", "red"],
    },
  },
];
class TransportersGroup extends React.PureComponent {
  constructor(props) {
    const tabValue = props.history.location.state ? props.history.location.state : "Trips";
    super(props);
    this.state = {
      tabs: templateTabs,
      singletranspoterslist: null,
      SalesOrder: "",
      transpoterslist: [],
      rateMatrixes: [],
      openTranspoter: false,
      colorArr: [white, lightGrey],
      Name: "",
      Status: false,
      addNew: false,
      success: "",
      error: "",
      editTranspoters: false,
      openMessage: false,
      errorMsgName: false,
      tabValue: tabValue,
      singleTransGroupsTrips: [],
      loading: {},
      destinationCities: [],
      voucher: [],
      paymentAdvices: [],
      dates: [],
    }; //cardColor2, cardColor3, cardColor4],
  }

  componentDidMount() {
    this.setInitialValues(this.props);
  }

  UNSAFE_componentWillReceiveProps(next) {
    this.setInitialValues(next);
    if (next.match.params.hasOwnProperty("tab")) {
      let tab = next.match.params["tab"];
      if (tab === "Transporter" && next.match.params.hasOwnProperty("id")) {
        this.setState(
          {
            singletranspoterslist: this.state.tabs[0].data[next.match.params["id"]],
            Name: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].name
              : "",

            Status: this.state.tabs[0].data[next.match.params["id"]]
              ? this.state.tabs[0].data[next.match.params["id"]].is_active
              : false,
            addNew: false,
          },
          () => {}
        );
      } else if (tab === "new") {
        this.setState({
          singletranspoterslist: null,
          Name: "",
          addNew: true,
          Status: true,
        });
      }
    } else this.setState({ singletranspoterslist: null });
  }

  listClickHandler = (value) => {
    localStorage.setItem("selectedTG", JSON.stringify(value.id));
    this.props.history.push({
      state: this.state.tabValue,
    });
    this.setState(
      {
        selectedId: value.id,
        openTranspoter: true,
        addNew: false,
        Name: value.name,
        Status: value.is_active,
        createdAt: value.created_at,
        openMessage: false,
      },
      () => {
        this.props.fetchTripsTransGroups(this.state.selectedId, this.props.user.user.uid);
        this.props.fetchPaymentVoucher(this.props.user.user.uid, this.state.selectedId);
      }
    );
  };
  setInitialValues(props) {
    const tabs = this.state.tabs.map((e, i) => {
      const tab = e;
      tab.data = props.transpoterslist && props.transpoterslist.length > 0 ? props.transpoterslist : [];

      return tab;
    });

    const allTrips = props.singleTransGroupsTrips.results;
    const newArr = [];

    if (allTrips && allTrips.length > 0) {
      allTrips
        .sort((a, b) => a.lr_number - b.lr_number)
        .map((data, i) => {
          if (!data.Vehicle_N) {
            var newData = {};
            newData.SNo = i + 1 > 9 ? i + 1 : `0${i + 1}`;
            newData.Date = moment(data.transit_pass_date).format("Do MMM, ddd");
            newData.LR_N = data.lr_number;
            newData.Vehicle_N = data.vehicle ? data.vehicle?.vehicle_no : "";
            newData.Party = data.consignee?.short_name;
            newData.Net_Weight = Number(data.net_weight);
            newData.lr_shortage = data.lr_shortage;
            newData.Shortage_Deduction_Amount = data.lr_shortage_deducted;
            newData.payment_rate = data.payment_rate;
            newData.Transporter =
              data.payment_voucher && data.transporter ? (
                data.transporter.name ? (
                  <div className={"padding10"}>{data.transporter.name}</div>
                ) : (
                  "-"
                )
              ) : data.vehicle?.transporter ? (
                <Tooltip
                  title={data.transit_pass.TransporterName}
                  placement="top"
                  disableFocusListener
                  disableTouchListener
                  arrow
                >
                  <div
                    style={{
                      padding: "3px",
                      color: red,
                      borderRadius: "5px",
                      textTransform: "capitalize",
                      fontSize: "0.9rem",
                    }}
                  >
                    {data.vehicle?.transporter}
                  </div>
                </Tooltip>
              ) : data.transit_pass?.TransporterName ? (
                <div
                  className={"bGGrayTCG padding5"}
                  style={{
                    borderRadius: "5px",
                    textTransform: "capitalize",
                    fontSize: "0.9rem",
                  }}
                >
                  {data.transit_pass.TransporterName}
                </div>
              ) : (
                "-"
              );
            newData.Bilti =
              data.lr_recieving_status && data.lr_recieving_status === "RECEIVED" ? (
                <div className={"padding5 tgbg7 d-flex justifyC width100px"}>
                  <CheckCircle style={{ color: "green" }} />
                </div>
              ) : (
                "-"
              );
            newData.payment = data.payment_voucher?.status ? (
              data.payment_voucher?.status === "paid" ? (
                <div className={"tCgreen"}>paid</div>
              ) : (
                <div className={"tCYellow"}>Ready</div>
              )
            ) : data.payment_status === "Unpaid" ? (
              "-"
            ) : (
              ""
            );

            newArr.push(newData);
          } else {
            newArr.push(data);
          }
        });
    }
    const newFinal = props.singleTransGroupsTrips;
    newFinal.results = newArr.sort((a, b) => b.id - a.id);
    console.log(newArr, "newArr");
    this.setState({
      transpoterslist: props.transpoterslist,
      tabs,
      singleTransGroupsTrips: newFinal,
      loading: props.loading,
      rateMatrixes: props.rateMatrixes,
      destinationCities: props.destinationCities,
      paymentAdvices: props.paymentAdvices,
      voucher: props.voucher,
      dates: props.dates,
    });
  }
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      errorMsgName: false,
    });
  };
  handleChangeTab = (event) => {
    this.setState({
      tabValue: event,
    });
    this.props.history.push({
      state: event,
    });
  };
  handleClearFields = () => {
    this.setState({ Name: "" });
  };
  render() {
    const {
      tabs,
      transpoterslist,
      singletranspoterslist,
      Name,
      addNew,
      Status,
      success,
      error,
      errorMsgName,
      createdAt,
      name,
      tabValue,
      newArr,
      singleTransGroupsTrips,
      loading,
      payment,
    } = this.state;
    return (
      <Layout
        tabs={tabs}
        noTab={true}
        RaisedSearchBar={
          { data: name, hintText: "Search Name", labelField: "name" } // Optional, In case if you not providing this, tabs data will be placed. // Optional
        }
        listClickHandler={this.listClickHandler}
        serchClick={this.listClickHandler}
        centerWidth="230px"
        openWidth="521.46px"
        closeWidth="287px"
      >
        {error || success ? (
          <SnackbarComponent
            error={error}
            success={success}
            snackbarClose={() => this.setState({ error: "", success: "" })}
          />
        ) : (
          ""
        )}

        {this.state.openMessage ? (
          <div
            style={{
              height: 500,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NoData />
          </div>
        ) : (
          ""
        )}
        {this.state.editTranspoters ? (
          <Dialog
            ref={(e) => {
              try {
                const target = ReactDOM.findDOMNode(e).children[2].children[0];
                target.style.borderRadius = "20px";
                target.style.maxHeight = "calc(100% - 62px)";
                target.style.minHeight = "fit-content";
                target.style.minWidth = "45%";
                target.style.maxWidth = "calc(100% - 150px)";
                target.style.margin = "32px 75px";
              } catch (e) {}
            }}
            open={
              true // fullScreen
            }
            onClose={() => {
              this.setState({
                editTranspoters: false,
                // openMessage: true,
                errorMsgName: false,
              });
            }}
            TransitionComponent={Transition}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px 30px",
                fontSize: "1.5rem",
              }}
            >
              {" "}
              <span style={{ color: "black" }}>
                {addNew ? " Add TranspotersGroup Detail" : "Edit TranspotersGroup Detail"}
              </span>
              <div>
                <Toggle
                  status={Status}
                  handleChange={() => {
                    this.setState({ Status: !Status });
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                fontSize: "1rem",
                color: "grey",
                background: "white",
                margin: "20px 0 20px 30px",
              }}
            >
              <CssTextField
                autoComplete="off"
                style={styles.withDivider50}
                error={errorMsgName ? true : false}
                helperText={errorMsgName ? "Please Add Name !" : false}
                id="outlined-basic"
                label="Name"
                name="Name"
                variant="outlined"
                value={Name}
                onChange={this.handleChangeTab}
              />{" "}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                padding: "40px 0 40px 30px",
                width: "100%",
              }}
            >
              <Button
                style={{ background: primary, color: "white", width: "200px" }}
                onClick={() => {
                  if (Name) {
                    if (addNew) {
                      const addData = {
                        name: Name,
                        group: 2,
                        is_active: Status,
                      };
                      this.props.addTransportersGroups(addData, this.props.user.user.uid).then(() => {
                        this.props.fetchTransportersGroups(this.props.user.user.uid).then((res) => {
                          if (res) {
                            this.setState(
                              {
                                success: "Added successfully !",
                                openMessage: true,
                                editTranspoters: false,
                                transpoterslist: res,
                              },
                              () => {
                                this.handleClearFields();
                              }
                            );
                          }
                        });
                      });
                    } else {
                      const addData = {
                        name: Name,
                        group: 2,
                        is_active: Status,
                      };
                      this.props
                        .transpoterEdit(addData, this.state.singletranspoterslist.id, this.props.user.user.uid)
                        .then(() => {
                          this.props.fetchTransportersGroups(this.props.user.user.uid).then((res) => {
                            if (res) {
                              this.setState({
                                success: "Edited successfully !",
                                openMessage: false,
                                editTranspoters: false,
                                //   transpoterslist: res,
                              });
                            }
                          });
                          this.handleClearFields();
                        });
                    }
                  } else {
                    this.setState({ errorMsgName: true });
                  }
                }}
              >
                {addNew ? "add " : "edit"}
              </Button>
            </div>
          </Dialog>
        ) : (
          ""
        )}

        <Paper
          style={{
            width: "100%",
            height: "100%",
            padding: "15px",
            position: "relative",
          }}
        >
          {(transpoterslist.length > 0 && singletranspoterslist) || addNew ? (
            <>
              <AppBar
                style={{
                  boxShadow: "none",
                  borderBottom: "1px solid #0000000B",
                  width: "100%",
                  backgroundColor: "white",
                }}
                position="static"
              >
                <CssTabs
                  style={{ backgroundColor: white, color: "#00867E" }}
                  value={tabValue}
                  indicatorColor="secondary"
                  textColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <CssTab
                    onClick={(event) => {
                      this.handleChangeTab("Trips");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"Trips"}
                    label={"Trips"}
                    wrapped
                    {...a11yProps("Trips")}
                  />

                  <CssTab
                    onClick={(event) => {
                      this.handleChangeTab("Transpoters");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"Transpoters"}
                    label={"Transpoters"}
                    wrapped
                    {...a11yProps("Transpoters")}
                  />
                  <CssTab
                    onClick={(event) => {
                      this.handleChangeTab("Payment Vouchers");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"Payment Vouchers"}
                    label={"Payment Vouchers"}
                    wrapped
                    {...a11yProps("Payment Vouchers")}
                  />
                  <CssTab
                    onClick={(event) => {
                      this.handleChangeTab("Payment Advices");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"Payment Advices"}
                    label={"Payment Advices"}
                    wrapped
                    {...a11yProps("Payment Advices")}
                  />
                  <CssTab
                    onClick={(event) => {
                      this.handleChangeTab("Rate Matrix");
                    }}
                    key={`s`}
                    style={{
                      fontSize: "0.9rem",
                      minWidth: "fit-content",
                      letterSpacing: "1px",
                      textTransform: "capitalize",
                    }}
                    value={"Rate Matrix"}
                    label={"Rate Matrix"}
                    wrapped
                    {...a11yProps("Rate Matrix")}
                  />
                </CssTabs>
              </AppBar>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"Trips"}
              >
                <Paper className="width-100 height-100 padding15 backgroundWhite">
                  <TableComponent
                    pagination={{
                      count: singleTransGroupsTrips && singleTransGroupsTrips.count,
                      next: singleTransGroupsTrips && singleTransGroupsTrips.next,
                      previous: singleTransGroupsTrips && singleTransGroupsTrips.previous,
                      rowsPerPage: 20,
                    }}
                    // lazyLoadFetch={this.props.lazyLoadFetch}
                    apiHeader={[
                      "Sr.No.",
                      "Date",
                      "LR_N",
                      "Vehicle_N",
                      "Party",
                      "Net_Weight",
                      "lr_shortage",
                      "Shortage_Deduction_Amount",
                      "payment_rate",
                      "Transporter",
                      "Bilti",
                      "payment",
                    ]}
                    noAddButton={true}
                    actions={[]}
                    module={"Trips"}
                    {...this.state}
                    {...this.props}
                    header={[
                      "Sr.No.",
                      "Date",
                      "LR #",
                      "Vehicle #",
                      "Party",
                      "Net Weight",
                      "Shortage MT",
                      "Shortage Deduction Amount",
                      "Payment Rate",
                      "Transporter",
                      "Bilti",
                      "Payment",
                    ]}
                    tableList={
                      singleTransGroupsTrips && singleTransGroupsTrips.results.length > 0
                        ? singleTransGroupsTrips.results.sort((a, b) => b.LR_N - a.LR_N)
                        : []
                    }
                    loadingTitle={"singleTransGroupsTrips"}
                  />
                </Paper>
              </TabPanel>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"Transpoters"}
              >
                <TranspotersDetail copytransporter={this.props.transporter} {...this.props} {...this.state} />
              </TabPanel>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"Payment Vouchers"}
              >
                <PaymentVoucher {...this.props} {...this.state} />
              </TabPanel>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"Payment Advices"}
              >
                <PaymentAdvices {...this.props} {...this.state} paymentVouchersList={this.state.voucher} />
              </TabPanel>
              <TabPanel
                className="list width-100 height-100-49 overflowYA"
                style={{ backgroundColor: white }}
                value={tabValue}
                index={"Rate Matrix"}
              >
                <RateMatixes singletranspoterslist={singletranspoterslist} {...this.props} {...this.state} />
              </TabPanel>
            </>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: "1rem",
                color: "grey",
                background: "white",
              }}
            >
              <NoData />
            </div>
          )}
        </Paper>
      </Layout>
    );
  }
}

export default withRouter(TransportersGroup);
