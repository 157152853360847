import moment from "moment";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import TranspotersGroup from "../components/TranspotersGroup";
import SourceCities from "../components/SourceCities";
import { fetchSourceCities, sourcesEdit, sourcesAdd } from "../redux/index";
class SourceCitiesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentWillMount() {
    this.fetchUsers();
  }
  componentWillReceiveProps(next) {
    this.setState({ sourceCities: next.sourceCities });
  }
  // fetchUsers = () => {
  //     console.log("this.props.user.uid", this.props.user.user.uid);
  //     if (this.props.user.user.uid) {
  //         this.props
  //             .fetchSourceCities = () => async () => {
  //                 (this.props.user.user.uid)
  //                     .then((res) => {
  //                         if (res) {
  //                             this.setState({ sourceCities: res });
  //                         }
  //                     });
  //             }
  //     };
  fetchUsers = () => {
    console.log(
      "this.props.user.uidaaaaaaaaaaaaaaaa",
      this.props.user.user.uid
    );
    if (this.props.user.user.uid) {
      this.props.fetchSourceCities(this.props.user.user.uid).then((res) => {
        if (res) {
          this.setState({ sourceCities: res });
        }
      });
    }
  };
  render() {
    return (
      <Route
        exact
        path={["/sourcecities", "/sourcecities/:tab/:id", "/sourcecities/new"]}
        render={(props) => <SourceCities {...this.props} {...this.state} />}
      />
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.authentication,
  sourceCities: state.masters.sourceCities,
});
const mapDispatchToProps = {
  fetchSourceCities,
  sourcesEdit,
  sourcesAdd,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceCitiesContainer);
