import { combineReducers } from "redux";

import authenticationReducer from "./authentications/authenticationReducer";
import dispatchReducer from "./dispatches/dispatchReducer";
import mastersReducer from "./masters/matersReducer";
const rootReducer = combineReducers({
  authentication: authenticationReducer,
  masters: mastersReducer,
  dispatches: dispatchReducer
});

export default rootReducer;
