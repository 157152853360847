import axios from "axios";
import { DispatchURL, tripsURL, tripEdit, fetchMatersURL, dateEdit } from "../../http/config";
const fetchingRequest = (data) => {
  return {
    type: "FETCH_REQUEST",
    payload: data,
    data: [],
  };
};

export const fetchSingleDateTrips = (id, token) => async (dispatch, getState) => {
  try {
    console.log("bnewwwww", "fetchhh", id.date);
    var date = id.date;
    const type = "singleDateTrips";
    const options = {
      method: "get",
      url: tripsURL("datewise/"),

      params: { date: date },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    dispatch(fetchingRequest(type));
    const response = await axios(options)
      .then((response1) => {
        // return response1.data
        return response1.data;
      })
      .catch((error) => {
        return {};
        //   dispatch({
        //     type: `FETCH_${type}_SUCCESS`,
        //     payload: type,
        //     data: [],
        // });
        console.log(error, "error");
      });
    var date = localStorage.getItem("selectedDate") ? JSON.parse(localStorage.getItem("selectedDate")) : "";
    console.log(response, "responseresponse");
    if (response && response.Trips && response.Trips.length > 0) {
      if (date === response.Trips[0].trip_date) {
        console.log("bnewwwww", "fetchhh", "newwwwww", date);
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response,
        });
      } else {
        dispatch(fetchingRequest(type));
      }
    } else {
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: type,
        data: [],
      });
    }
    return response;
  } catch (error) {
    const type = "singleDateTrips";
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: [],
    });
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const fetchLRdetails = (LRNumber, token, data) => async (dispatch, getState) => {
  try {
    const type = "lrDetails ";
    const options = {
      method: data ? "patch" : "get",
      data: data ? data : "",
      url: DispatchURL("lr-details", LRNumber),
      params: { lr_number: LRNumber },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    dispatch(fetchingRequest(type));
    const response = await axios(options)
      .then((response1) => {
        return response1.data;
      })
      .catch((error) => {
        return {};
      });
    var lr_number = localStorage.getItem("selectedlr_number")
      ? JSON.parse(localStorage.getItem("selectedlr_number"))
      : "";
    if (!data) {
      if (response && response.Trips && response.Trips.length > 0) {
        if (lr_number === response.Trips[0].lr_number) {
          dispatch({
            type: `FETCH_${type}_SUCCESS`,
            payload: type,
            data: response,
          });
        } else {
          dispatch(fetchingRequest(type));
        }
      } else {
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: [],
        });
      }
    }
    return response;
  } catch (error) {
    const type = "singleDateTrips";
    dispatch({
      type: `FETCH_${type}_SUCCESS`,
      payload: type,
      data: [],
    });
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const postTrip = (token, data) => async (dispatch, getState) => {
  try {
    const options = {
      method: "post",
      url: tripsURL("create/"),
      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const type = "singleDateTrips";
    // dispatch(fetchingRequest(type));
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "responseresponse");
        return response;
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrr1");
      });

    return response1.data;
  } catch (error) {
    console.log(error, "errorrrrrrrrrrr");
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};

export const sendForCorrection = (token, data, id) => async (dispatch, getState) => {
  try {
    const type = "singleDateTrips";
    // dispatch(fetchingRequest(type));
    console.log(tripEdit(id), id, data, "bbbbbbbbbbbbbbbbbb");
    const options = {
      method: "patch",
      url: tripEdit(`${id}/`),
      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "responseresponse");
        return response;
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrr1");
      });

    return response1.data;
  } catch (error) {
    console.log(error, "errorrrrrrrrrrr");
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const editDate = (token, data, id) => async (dispatch, getState) => {
  try {
    const options = {
      method: "patch",
      url: dateEdit(`${id}/`),
      data,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const type = "singleDateTrips";
    const response1 = await axios(options)
      .then((response) => {
        console.log(response, "responseresponse");
        return response;
      })
      .catch((error) => {
        console.log(error, "errorrrrrrrrrrr1");
      });

    return response1.data;
  } catch (error) {
    console.log(error, "errorrrrrrrrrrr");
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
export const fetchDetailsOfTp = (id, token) => async (dispatch, getState) => {
  try {
    const type = "transit_pass_Details";
    dispatch(fetchingRequest(type));
    const options = {
      method: "get",
      url: DispatchURL("transit_pass", id),

      params: { TP_No: id },
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json;charset=UTF-8",
      },
    };
    const data = await axios(options)
      .then((response) => {
        console.log(response, "responseresponse111111111");
        dispatch({
          type: `FETCH_${type}_SUCCESS`,
          payload: type,
          data: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        console.log(error, "error");
        return error;
      });
    return data;
  } catch (error) {
    return {
      error: true,
      data: error.response ? error.response.data : "Some error occured, please try again!",
    };
  }
};
